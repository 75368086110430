import { IBenchRequest } from '../apiSlice.types';
import { categoryIdValueForNoCategoryId } from '../../../Bench/const';

export function convertToFilterStr(params: IBenchRequest) {
  let paramsString = '';
  if (params?.offices?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    params.offices.forEach((office) => {
      paramsString += `offices=${office.id}&`;
    });
  }
  if (params?.positions?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    params.positions.forEach((position) => {
      paramsString += `positions=${position.id}&`;
    });
  }
  if (params?.seniorities?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    params.seniorities.forEach((seniority) => {
      paramsString += `seniorities=${seniority.id}&`;
    });
  }
  if (params?.mainTechValues?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    let techParamString = 'main_tech=';
    const mainTechParamList = params.mainTechValues.map((tech) => {
      let categoryId = '';
      if (!(tech.category.id === categoryIdValueForNoCategoryId)) {
        categoryId = tech.category.id.toString();
      }
      return `${tech.id}.${categoryId}`;
    });
    const mainTechParamString = mainTechParamList.join(',');
    techParamString += mainTechParamString;
    techParamString += `&`;
    paramsString += techParamString;
  }
  if (params?.tags?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    params.tags.forEach((tag) => {
      paramsString += `bench_tags=${tag.id}&`;
    });
  }
  if (params?.tagsToExclude?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    params.tagsToExclude.forEach((tag) => {
      paramsString += `not_bench_tags=${tag.id}&`;
    });
  }
  if (params?.skillRows?.length) {
    if (paramsString === '') {
      paramsString = '?';
    }
    const skillRowsArray: string[] = [];
    params.skillRows.forEach((skillRow) => {
      const skillsString = skillRow.skills
        .map((skill) => {
          let categoryId = '';
          if (!(skill.category.id === categoryIdValueForNoCategoryId)) {
            categoryId = skill.category.id.toString();
          }
          return `${skill.id}.${categoryId}`;
        })
        .join(',');
      const levelString = skillRow.level?.id ? skillRow.level.id : '';
      const skillRowString = [skillsString, levelString].join(';');
      skillRowsArray.push(skillRowString);
    });
    const result = encodeURI(JSON.stringify(skillRowsArray));
    paramsString += `skills_levels_in=${result}&`;
  }
  if (params?.isIncludeUnconfirmed) {
    if (paramsString === '') {
      paramsString = '?';
    }
    paramsString += `include_unconfirmed=True&`;
  }
  return paramsString;
}
