import { Grid, Tooltip } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { getTimeInProfession } from './getTimeInProfession';

interface IProps {
  startYear?: number;
}

export const YearsInProfession = ({ startYear }: IProps) => {
  if (!startYear) {
    return null;
  }
  return (
    <Grid item container xs={'auto'} spacing={1}>
      <Grid item>
        <Tooltip title={`IT experience from ${startYear}`}>
          <WorkHistoryIcon fontSize={'small'} color={'primary'} />
        </Tooltip>
      </Grid>
      <Grid item>{getTimeInProfession(startYear)}</Grid>
    </Grid>
  );
};
