import { Grid } from '@mui/material';
import classNames from 'classnames';
import { GridContainerItem } from './GridContainerItem';

const renderSkill = (skill) => (
  <Grid item key={skill.name}>
    <span
      className={classNames(
        'profile_block__skill_item',
        {
          'profile_block__skill_item__blue-back': skill.manager,
        },
        {
          'profile_block__main_tech_item__green-back': skill.is_main_tech,
        }
      )}
    >
      {skill.name}
    </span>
  </Grid>
);

export const Skills = ({ skills }) => (
  <Grid container className='profile_block__delimiter' direction='column'>
    <Grid item xs={6} mb={2}>
      <span className='profile_block__title'>Skills</span>
    </Grid>
    <GridContainerItem xs={12} spacing={2}>
      {skills.map(renderSkill)}
    </GridContainerItem>
  </Grid>
);
