import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Badge } from '../../common/badges/Badges';
import { getColorFromString } from './getColor';
import CustomIcon from '../../common/icon';
import {
  deleteAuthData,
  setFlagLogout,
  selectAvatar,
  selectEmail,
  selectEmployeeId,
} from '../../../store/slices/auth/authSlice';
import './Header.css';
import { buttonStyles } from '../../../styles';
import { selectHeaderReducer } from '../../../store/slices/header/headerSlice';
import { CustomMenu } from '../../common/customMenu/CustomMenu';
import { CustomMenuItem } from '../../common/customMenu/CustomMenuItem';
// NOTE: Commented out it for now because SUPPORT button uses for open slack channel
// import SupportDialog from '../../common/dialogs/SupportDialog';
// import { showDialog } from '../../../store/slices/modalDialog/modalDialogSlice';

const Header = () => {
  const user = useSelector(selectEmail);
  const avatar = useSelector(selectAvatar);
  const userId = useSelector(selectEmployeeId);
  const component = useSelector(selectHeaderReducer).component;
  const isTitle = useSelector(selectHeaderReducer).isTitle;
  const isLink = useSelector(selectHeaderReducer).isLink;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/employees/${userId}/`);
  };

  const handleLogout = () => {
    dispatch(deleteAuthData());
    dispatch(setFlagLogout());
    localStorage.clear();
  };

  const showSupportDialog = () => {
    window.open('https://akvelon.slack.com/archives/C04B65ZG9JP', '_blank');
    // NOTE: Commented out it for now because SUPPORT button uses for open slack channel
    // dispatch(showDialog(SupportDialog));
  };

  return (
    <div className='header-cont'>
      <div className='main-part'>
        {isTitle ? (
          <div className='projects-amount'>
            <span>{component}</span>
          </div>
        ) : null}
        {isLink ? (
          <Link
            to={{
              pathname: component.pathname,
            }}
            className='links__text-decoration--none'
          >
            <div className='back-button'>
              <CustomIcon iconName='arrow-left-details' />
              <span>{component.text}</span>
            </div>
          </Link>
        ) : null}
      </div>
      <Button onClick={showSupportDialog} sx={buttonStyles}>
        Support
      </Button>
      <CustomMenu
        buttonContent={
          <>
            <div className='user-photo'>
              {avatar ? (
                <img src={avatar} alt={'avatar'} className='cell_avatar' />
              ) : (
                <Badge color={getColorFromString(user)} item={user} />
              )}
            </div>
            <div className='user-name'>
              <span>{user}</span>
            </div>
          </>
        }
      >
        <CustomMenuItem onClick={goToProfile}>My profile</CustomMenuItem>
        <CustomMenuItem onClick={handleLogout}>Log out</CustomMenuItem>
      </CustomMenu>
    </div>
  );
};

export default Header;
