import {
  DataGrid,
  GridComparatorFn,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { renderRiskCell } from './RenderRiskCell';
import './MuiDataGrid.override.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeedbackRiskLevels } from '../../../api/apiSlice.types';

const EXTERNAL = 'external';

const feedbackRiskLevelComparator: GridComparatorFn<{
  risk: {
    id: string;
    name: string;
  };
  date: string;
}> = (v1, v2) => {
  const map = new Map();
  map.set(FeedbackRiskLevels.DANGER, 0);
  map.set(FeedbackRiskLevels.WARNING, 1);
  map.set(FeedbackRiskLevels.NO_RISK, 2);
  map.set(FeedbackRiskLevels.RESOLVED, 3);
  map.set(EXTERNAL, 4);

  if (map.get(v1.risk?.id) > map.get(v2.risk?.id)) {
    return 1;
  }
  if (map.get(v1.risk?.id) < map.get(v2.risk?.id)) {
    return -1;
  }

  if (v1?.date > v2?.date) {
    return -1;
  }
  if (v1?.date < v2?.date) {
    return 1;
  }

  return 0;
};

interface Row {
  type: string;
  risk: { id: string; name: FeedbackRiskLevels };
  editor: string;
  created: object;
}

interface Props {
  rows: Row[] | undefined;
  isLoading: boolean;
}

const columns = [
  {
    headerName: 'Feedback Type',
    field: 'type',
    sortable: false,
    width: 260,
  },
  {
    headerName: 'Risk level',
    field: 'risk',
    renderCell: (params: GridRenderCellParams) =>
      params?.row?.is_external ? (
        <Chip label='External' variant='outlined' size='small' />
      ) : (
        renderRiskCell(params)
      ),
    width: 130,
    valueGetter: (params: GridValueGetterParams) => ({
      risk: params?.row?.is_external ? { id: EXTERNAL } : params.row.risk,
      date: params.row.created,
    }),
    sortComparator: feedbackRiskLevelComparator,
  },
  {
    headerName: 'Updated by',
    field: 'editor',
    sortable: false,
    width: 160,
  },
  {
    headerName: 'Feedback date',
    field: 'feedback_date',
    width: 160,
  },
];

export function DataTable({ rows, isLoading }: Props) {
  // move up
  const navigate = useNavigate();
  const location = useLocation();

  const onCellClick = ({ id }: { id: any }) => {
    navigate(`${id}`, { state: { pathname: location.pathname } });
  };

  return (
    <DataGrid
      rows={rows || []}
      columns={columns}
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      loading={isLoading}
      hideFooter
      onCellClick={onCellClick}
    />
  );
}
