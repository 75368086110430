import {
  Autocomplete,
  Checkbox,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Skill } from './SkillFilterPanel';
import { Option } from '../../../Bench/types';

interface IProps {
  isLoading: boolean;
  skillOptions: Skill[];
  value: { skills: Skill[]; level: { id: number; name: string } };
  onChangeSkill: Function;
  levelOptions: Option[];
  onChangeLevel: Function;
  onClick: () => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export const SkillInputGroup = ({
  isLoading,
  skillOptions,
  value,
  onChangeSkill,
  levelOptions,
  onChangeLevel,
  onClick,
}: IProps) => {
  return (
    <Stack direction='row' spacing={2} mb={2}>
      <Autocomplete
        size={'small'}
        multiple
        disableCloseOnSelect
        loading={isLoading}
        options={skillOptions}
        getOptionLabel={(option) => option.name}
        style={{ width: 240 }}
        limitTags={1}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label='Skills' />}
        value={value?.skills}
        onChange={(_, value) => onChangeSkill(value)}
      />

      <Autocomplete
        size={'small'}
        loading={isLoading}
        options={levelOptions}
        getOptionLabel={(option) => option.name}
        style={{ width: 240 }}
        renderInput={(params) => <TextField {...params} label='Min. Level' />}
        value={value?.level}
        onChange={(_, value) => onChangeLevel(value)}
        disableClearable
      />

      <IconButton onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
