import { useCallback, useEffect, useMemo, useState } from 'react';
import RadioButtonGroup from '../../../../common/form-items/radio-group/radio-group';

const defaultValue = { id: null, name: 'N/A' };

export const SkillOption = ({ skillOption, options, handleChange }) => {
  const [level, setLevel] = useState(defaultValue);

  useEffect(() => {
    setLevel(skillOption.self_assessed_level || defaultValue);
  }, [skillOption]);

  const radioOptions = useMemo(
    () => [
      { label: defaultValue.name, value: defaultValue.id },
      ...options.map((x) => ({
        label: x.name,
        value: x.id,
      })),
    ],
    [skillOption]
  );
  const handleUpdate = useCallback(
    (event) => {
      const value = options.find((x) => x.id === Number(event.target.value));
      setLevel(value || defaultValue);
      handleChange({
        id: skillOption.id,
        skill: {
          id: skillOption.skill.id,
          name: skillOption.skill.name,
        },
        skill_category: skillOption.skill_category,
        self_assessed_level: value || null,
      });
    },
    [skillOption]
  );

  return (
    <div
      data-testid='skill-questionnaire-option-container'
      className='skill-questionnaire__option'
    >
      <p className='skill-questionnaire__option--title'>
        {skillOption.skill.name}
      </p>
      <RadioButtonGroup
        isRaw
        selectedValue={level.id}
        options={radioOptions}
        handleChange={handleUpdate}
        className='skill-questionnaire__option--label'
      />
    </div>
  );
};
