import { useGetCDPReportMutation } from '../../EmployeesPage/api/apiSlice';
import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ChartAppCDP } from './ChartAppCDP';

import {
  ICDPReportResponse,
  IFilterReportParams,
} from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import PeriodSelect from '../Filters/PeriodSelect';

export const CDPReport = () => {
  const [getReport, { isLoading }] = useGetCDPReportMutation();
  const [data, setData] = useState<ICDPReportResponse>({});

  const initialValues: any = {
    months: 6,
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    fetchReport({ ...formValues });
  }, [formValues]);

  const fetchReport = async ({ months }: IFilterReportParams) => {
    try {
      const response: any = await getReport({
        months,
      });
      setData(response?.data || {});
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box py={2}>
      {isLoading && <Preloader />}
      <h1>CDP Report</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setFormValues(values);
        }}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item width={160}>
                <Field name='months'>
                  {({ field }: any) => (
                    <PeriodSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item>
                <Button
                  sx={{ height: '40px' }}
                  variant='contained'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  onClick={async () => {
                    await formik.resetForm(initialValues as any);
                    setFormValues(initialValues);
                  }}
                  sx={{ height: '40px', marginLeft: '12px' }}
                  variant='outlined'
                  type='reset'
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {!!data && (
        <Box width={1000} height={550} pt={3}>
          <ChartAppCDP data={data} />
        </Box>
      )}
    </Box>
  );
};
