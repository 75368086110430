export const pencil = (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.42749 2.53983C8.38526 2.49029 8.32571 2.45962 8.26161 2.45441C8.1975
    2.44919 8.13395 2.46984 8.08458 2.51193L0.668173 10.5268C0.580167 10.6231 0.522087
    10.7439 0.501273 10.8739L0.000574679 13.8121C-0.00152877 13.8382 0.00215754 13.8644
    0.0113582 13.8888C0.0205588 13.9132 0.0350358 13.9351 0.053709 13.953C0.0723821 13.9709
    0.0947686 13.9842 0.119197 13.992C0.143625 13.9998 0.169465 14.0019 0.194785 13.9981L3.03815
    13.3255C3.16429 13.2973 3.27941 13.2315 3.36891 13.1365L10.7884 5.11847C10.8265 5.06596 10.8432
    5.0003 10.8347 4.93546C10.8261 4.87063 10.7932 4.81175 10.7428 4.77135L8.42749 2.53983Z'
      fill='#C4C4C4'
    />
    <path
      d='M13.0491 2.31044L10.7398 0.0789194C10.6903 0.0283864 10.6232 1.68086e-07 10.5532
    1.68086e-07C10.4832 1.68086e-07 10.4161 0.0283864 10.3665 0.0789194L8.90086 1.66268C8.84435
    1.72075 8.81262 1.7993 8.81262 1.88118C8.81262 1.96306 8.84435 2.04162 8.90086 2.09969L11.1586
    4.26922C11.2154 4.32695 11.2923 4.35935 11.3725 4.35935C11.4527 4.35935 11.5296 4.32695 11.5864
    4.26922L13.0521 2.68546C13.0994 2.63477 13.1254 2.56731 13.1249 2.49732C13.1243 2.42734 13.0971
    2.36033 13.0491 2.31044Z'
      fill='#C4C4C4'
    />
  </svg>
);
