import { UserAutocompleteField } from './UserAutocompleteField';
import { FC, useMemo } from 'react';
import {
  useGetAllHRManagersQuery,
  useGetEmployeeDetailsQuery,
} from '../../../api/apiSlice';
import { useParams } from 'react-router-dom';

interface IHRManagerFieldProps {
  setData: (newValue: number) => void;
}

export const HRManagerAutocompleteField: FC<IHRManagerFieldProps> = ({
  setData,
}) => {
  const { id } = useParams();
  const employeeId = Number(id);
  const { data: employee } = useGetEmployeeDetailsQuery(employeeId);

  const { data: managers, isLoading } = useGetAllHRManagersQuery();

  const filteredManagers = useMemo(() => {
    return managers?.filter((manager) => manager.id !== employee?.id);
  }, [managers, employee]);

  return (
    <UserAutocompleteField
      isLoading={isLoading}
      sx={{
        margin: '10px 0',
      }}
      defaultValue={employee?.hr ?? null}
      options={filteredManagers ?? []}
      onChange={(value) => {
        if (value) {
          setData(value.id);
        }
      }}
    />
  );
};
