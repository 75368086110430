import { Chip } from '@mui/material';
import React from 'react';

export const FilterChip = ({
  label,
  onDelete,
  color,
}: {
  label: string;
  color?: string;
  onDelete: () => void;
}) => (
  <Chip label={label} onDelete={onDelete} sx={{ backgroundColor: color }} />
);
