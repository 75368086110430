import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, Grid } from '@mui/material';
import { IFeedback } from '../../../EmployeesPage/api/apiSlice.types';
import {
  useGetAllFeedbacksQuery,
  useGetFeedbackFiltersQuery,
} from '../../../EmployeesPage/api/apiSlice';
import { Table } from './Table';
import { TableToolbar } from './TableToolbar';
import './FeedbacksList.css';
import { InfiniteScroll } from '../../../common/infiniteScroll/InfiniteScroll';
import { Link, useLocation } from 'react-router-dom';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import {
  IFilters,
  selectFeedbacksFilters,
  selectFeedbacksOrdering,
  setFilters,
  setOrdering,
} from '../../slices/feedbacksSlice';
import { canCreateFeedback } from '../../../../utils/permissions/feedbacks';

const DEFAULT_AMOUNT_FEEDBACKS = 30;
const directionMap = { Up: '', Down: '-' };

const areObjectsEqual = (target: IFilters, income: IFilters) => {
  let equal = true;
  for (let key in target) {
    // @ts-ignore
    if (target[key] !== income[key]) {
      equal = false;
    }
  }
  return equal;
};
const filterEmptyValues = (values: any) => {
  const filteredValues = {};
  for (let key in values) {
    if (values[key]) {
      // @ts-ignore
      filteredValues[key] = values[key];
    }
  }
  return filteredValues;
};

export const FeedbacksList = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState<number>(DEFAULT_AMOUNT_FEEDBACKS);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [items, setItems] = useState<IFeedback[]>([]);
  const location = useLocation();

  const filters: IFilters = useSelector(selectFeedbacksFilters);
  const ordering: string = useSelector(selectFeedbacksOrdering);

  const { data: feedbacks, isFetching } = useGetAllFeedbacksQuery(
    filterEmptyValues({
      ...filters,
      ordering,
      limit,
    })
  );
  const { data: feedbackFilters, isFetching: isFiltersFetching } =
    useGetFeedbackFiltersQuery();

  useEffect(() => {
    dispatch(
      setComponent({
        component: `Feedbacks (${feedbacks?.results?.length ?? 0} / ${
          feedbacks?.count ?? 0
        })`,
        isTitle: true,
      })
    );

    return () => {
      dispatch(resetComponent());
    };
  }, [feedbacks]);

  useEffect(() => {
    if (feedbacks && feedbackFilters) {
      const modified = getModifiedFeedbacks(feedbacks.results, feedbackFilters);
      setItems(modified);
    }
  }, [feedbacks, feedbackFilters]);

  const onSortChange = (column: string, direction: string) => {
    setLimit(DEFAULT_AMOUNT_FEEDBACKS);
    setHasMore(true);
    if (column && direction) {
      // @ts-ignore
      dispatch(setOrdering(directionMap[direction] + column));
      return;
    }
    dispatch(setOrdering(''));
  };

  const handleToolbarValues = (values: IFilters) => {
    if (!areObjectsEqual(filters, values)) {
      setLimit(DEFAULT_AMOUNT_FEEDBACKS);
      setHasMore(true);
      dispatch(setFilters(values));
    }
  };

  const getModifiedFeedbacks = (
    feedbacks: IFeedback[],
    feedbackFilters: any
  ) => {
    const typeMap = feedbackFilters.type.reduce((acc: any, type: any) => {
      acc[type.id] = type.name;
      return acc;
    }, {});
    return feedbacks.map((feedback) => ({
      ...feedback,
      feedback_type: typeMap[feedback.feedback_type],
    }));
  };

  const loadMoreFeedbacks = () => {
    const count = Number(feedbacks?.count);
    if (count && count < DEFAULT_AMOUNT_FEEDBACKS) {
      setHasMore(false);
      return;
    }
    if (!feedbacks) {
      return;
    }
    if (count === feedbacks?.results.length) {
      setHasMore(false);
      return;
    }
    if (limit >= feedbacks?.count) {
      setHasMore(false);
      return;
    }
    if (isFetching) {
      return;
    }

    const nextOffset = limit + DEFAULT_AMOUNT_FEEDBACKS;
    setLimit(nextOffset);
  };

  // @ts-ignore
  const canCreate = canCreateFeedback(feedbacks?.zone || '');

  return (
    <Box className='feedbacks_list__wrapper'>
      <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingBottom: '24px' }}
        >
          <Grid item>
            <TableToolbar
              values={filters}
              onToolbarChange={handleToolbarValues}
            />
          </Grid>
          <Grid item>
            {canCreate && (
              <Link
                to='/feedbacks/create'
                state={{ pathname: location.pathname }}
              >
                <button disabled={isFetching} className='button__main'>
                  Create Feedback
                </button>
              </Link>
            )}
          </Grid>
        </Grid>
        <InfiniteScroll loadMore={loadMoreFeedbacks} hasMore={hasMore}>
          <Table
            items={items}
            isFetching={isFetching || isFiltersFetching}
            risks={feedbackFilters?.risk}
            onSortChange={onSortChange}
          />
        </InfiniteScroll>
      </Scrollbars>
    </Box>
  );
};
