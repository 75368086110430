export const calendarAkvelon = (
  <svg
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 2H11.25V0.5H9.75V2H3.75V0.5H2.25V2H1.5C0.6675 2 0.00749999 2.675 0.00749999 3.5L0 14C0 14.825 0.6675 15.5 1.5 15.5H12C12.825 15.5 13.5 14.825 13.5 14V3.5C13.5 2.675 12.825 2 12 2ZM12 14H1.5V5.75H12V14ZM3 7.25H6.75V11H3V7.25Z'
      fill='#EF7530'
    />
  </svg>
);
