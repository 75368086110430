import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: 0,
};

// todo: this is blocking UI. refactor logic
// todo: relocate not connected state
const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    startRequest: (state) => {
      state.isLoading += 1;
    },
    finishRequest: (state) => {
      if (state.isLoading > 0) {
        state.isLoading -= 1;
      }
    },
    finishAllRequests: (state) => {
      state.isLoading = 0;
    },
  },
});

export const { startRequest, finishRequest, finishAllRequests } =
  loaderSlice.actions;

export const selectIsLoading = (store) => store.loaderReducer.isLoading;

export default loaderSlice.reducer;
