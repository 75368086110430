import { Autocomplete, IconButton, Stack, TextField } from '@mui/material';
import { GroupHeader, GroupItems } from '../../../Bench/OptionsGroup';
import { Skill as MainSkill } from '../../api/apiSlice.types';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  isLoading: boolean;
  options: MainSkill[];
  value?: MainSkill[];
  onChange: Function;
  onClick: () => void;
  label: string;
}

export const FilterInput = ({
  isLoading,
  options,
  value,
  onChange,
  onClick,
  label,
}: IProps) => (
  <Stack direction='row' spacing={2} mb={2}>
    <Autocomplete
      size={'small'}
      multiple
      disableCloseOnSelect
      loading={isLoading}
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ minWidth: 240 }}
      limitTags={3}
      isOptionEqualToValue={(option, value) => {
        return (
          option?.id === value?.id &&
          option?.category?.id === value?.category?.id
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={value}
      onChange={(_, value) => onChange(value)}
      {...(label === 'Main technology' && {
        groupBy: (option: MainSkill) => option.category.name,
      })}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
    <div>
      <IconButton onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </div>
  </Stack>
);
