import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './tabs.css';

const renderLink = ({ name, link }, i, activePath) => {
  return (
    <Link to={link} key={i}>
      <span
        className={classNames('c-tabs__title', [
          {
            'c-tabs__title--selected':
              activePath.endsWith(link) || activePath.endsWith(link + '/edit'),
          },
        ])}
      >
        <span className='c-tabs__title__text' data-cy={'tabs-' + name}>
          {name}
        </span>
      </span>
    </Link>
  );
};

const Tabs = function ({ linkList, className }) {
  const location = useLocation();
  const [activePath, setActivePath] = useState(window.location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  return (
    <div className={classNames('c-tabs', className)}>
      {linkList.map(({ access, name, link }, i) => {
        if (access) {
          return renderLink({ name, link }, i, activePath);
        }
      })}
    </div>
  );
};

Tabs.propTypes = {
  linkList: PropTypes.array,
  className: PropTypes.string,
};

export default Tabs;
