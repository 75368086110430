import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  component: null,
  isTitle: false,
  isLink: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setComponent: (state, action) => {
      state.component = action.payload.component;
      state.isTitle = action.payload.isTitle;
      state.isLink = action.payload.isLink;
    },
    resetComponent: () => {
      return initialState;
    },
  },
});

export const { setComponent, resetComponent } = headerSlice.actions;

export const selectHeaderReducer = (store) => store.headerReducer;

export default headerSlice.reducer;
