import { all, call, put, spawn } from 'redux-saga/effects';
import LoginRootSaga from '../../components/LoginPage/slice/loginSaga';
import EmployeesRootSaga from '../../components/EmployeesPage/slices/employeesSaga';
import { finishRequest } from '../slices/loader/loaderSlice';

function* rootSaga() {
  const sagas = [LoginRootSaga, EmployeesRootSaga];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log('error from root saga \n', error);
            yield put(finishRequest());
          }
        }
      })
    )
  );
}

export default rootSaga;
