export const portfolio = (
  <svg
    width='15'
    height='17'
    viewBox='0 0 15 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3498 3.50005H10.1998V2.15005C10.1998 1.40305 9.5968 0.800049 8.8498 0.800049H6.1498C5.4028 0.800049 4.7998 1.40305 4.7998 2.15005V3.50005H1.6498C0.902805 3.50005 0.299805 4.10305 0.299805 4.85005V12.95C0.299805 13.697 0.902805 14.3 1.6498 14.3H6.4918C6.2758 13.742 6.1498 13.139 6.1498 12.5C6.1498 9.76405 8.3638 7.55005 11.0998 7.55005C12.5218 7.55005 13.7998 8.15305 14.6998 9.10705V4.85005C14.6998 4.10305 14.0968 3.50005 13.3498 3.50005ZM8.8498 3.50005H6.1498V2.15005H8.8498V3.50005Z'
      fill='#EF7530'
    />
    <path
      d='M11.1 8.90002C9.111 8.90002 7.5 10.511 7.5 12.5C7.5 14.489 9.111 16.1 11.1 16.1C13.089 16.1 14.7 14.489 14.7 12.5C14.7 10.511 13.089 8.90002 11.1 8.90002ZM12.135 14.165L10.65 12.68V10.7H11.55V12.311L12.765 13.526L12.135 14.165Z'
      fill='#EF7530'
    />
  </svg>
);
