import { ChangeEvent, useState } from 'react';
import { Box } from '@mui/material';
import { Checkbox } from '../../form-items/checkbox/checkbox';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import { UserSkill } from '../../../EmployeesPage/api/apiSlice.types';

interface IProps {
  item: UserSkill;
  column: any;
  config: any;
}

const Cell = ({ item, column, config }: IProps) => {
  const isDisabled =
    item?.manager_assessed_level?.id !== 3 || config[column.Id].disabled;
  const isShowCell =
    config[column?.Id].isHaveRights && item?.manager_assessed_level?.id === 3;

  const [isChecked, setIsChecked] = useState(item?.interview_enabled);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const editItem = {
      ...item,
      [column.Id]: checked,
    };
    setIsChecked(checked);
    config[column.Id].valueSetter(editItem);
  };

  return isShowCell ? (
    <Box>
      <Checkbox disabled={isDisabled} checked={isChecked} onChange={onChange} />
    </Box>
  ) : null;
};

export const CellInterviewer = withCellWrapper(Cell);
