export const questionary = (
  <svg
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 14H12V16H4V14ZM4 10H12V12H4V10ZM10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z'
      fill='#EF7530'
    />
  </svg>
);
