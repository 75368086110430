import PropTypes from 'prop-types';

export const CustomMenuItem = ({ onClick, children }) => (
  <div className='menu_option' onClick={onClick}>
    {children}
  </div>
);

CustomMenuItem.propTypes = {
  onClick: PropTypes.func,
};

CustomMenuItem.defaultProps = {
  onClick: () => {},
};
