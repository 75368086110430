import React, { ReactNode } from 'react';
import {
  Box,
  Tooltip,
  Button,
  Stack,
  ButtonProps,
  TooltipProps,
} from '@mui/material';

interface IListButtonGroup extends ButtonProps {
  text: string | ReactNode;
  visibility?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
}

const ButtonGroup = ({
  buttonList,
}: {
  buttonList: IListButtonGroup[];
}): React.ReactElement => {
  return (
    <Stack direction='row' spacing={1}>
      {buttonList.map((button: IListButtonGroup, index: number) => {
        if (
          !button.text ||
          (button.visibility !== undefined && !button.visibility)
        )
          return;
        delete button.visibility;
        return button.tooltip ? (
          <Tooltip key={button.text + '-' + index} {...button.tooltip}>
            {/* Do not remove Box wrapper otherwise Tooltip throws error for disabled Button */}
            <Box>
              <Button {...button}>{button.text}</Button>
            </Box>
          </Tooltip>
        ) : (
          <Button key={button.text + '-' + index} {...button}>
            {button.text}
          </Button>
        );
      })}
    </Stack>
  );
};

export default ButtonGroup;
