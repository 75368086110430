import {
  useGetEmployeeFiltersQuery,
  useGetAttritionReportMutation,
  useGetAttritionReportTypesQuery,
} from '../../EmployeesPage/api/apiSlice';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import ClearIcon from '../Filters/ClearIcon';
import { useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ChartAppAttrition } from './ChartAppAttrition';
import { useSelector } from 'react-redux';
import { selectOffice } from '../../../store/slices/auth/authSlice';
import StatusSelect from '../Filters/StatusSelect';
import {
  IAttritionReportResponse,
  IFilterReportParams,
  AttritionReportType,
} from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import PeriodSelect from '../Filters/PeriodSelect';

export const AttritionReport = () => {
  const [getReport] = useGetAttritionReportMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAttritionReportResponse>({});
  const [attritionReportType, setAttritionReportType] =
    useState<AttritionReportType>('dismissed_reason');

  const office = useSelector(selectOffice);

  const initialValues: any = {
    office: office.name,
    months: 6,
    manager: '',
    project: '',
    status: '',
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    fetchReport(initialValues);
  }, []);

  useEffect(() => {
    fetchReport({ ...formValues, chartType: attritionReportType });
  }, [formValues, attritionReportType]);

  const {
    data: { offices, managers, projects } = {
      offices: [],
      managers: [],
      projects: [],
    },
  } = useGetEmployeeFiltersQuery();

  const { data: attritionReportTypes } = useGetAttritionReportTypesQuery();

  const attritionReportValues = useMemo(() => {
    if (attritionReportTypes) {
      return Object.entries(attritionReportTypes);
    }
    return [];
  }, [attritionReportTypes]);

  const fetchReport = async ({
    office,
    months,
    manager,
    project,
    status,
    chartType,
  }: IFilterReportParams) => {
    try {
      setLoading(true);
      const response: any = await getReport({
        office,
        months,
        manager,
        project,
        status,
        chartType,
      });
      setData(response?.data || {});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleChangeAttritionReportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttritionReportType(event.target.value as AttritionReportType);
  };

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <h1>Attrition Report</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setFormValues(values);
        }}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item width={240}>
                <Field name='office'>
                  {({ field }: any) => (
                    <TextField
                      disabled
                      select
                      label='Delivery Center'
                      size='small'
                      fullWidth
                      {...field}
                    >
                      {offices.map((item: any) => (
                        <MenuItem value={item.name} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='months'>
                  {({ field }: any) => (
                    <PeriodSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='project'>
                  {({ field }: FieldProps) => (
                    <TextField
                      select
                      label='Project'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {projects.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='manager'>
                  {({ field }: any) => (
                    <TextField
                      select
                      label='Manager'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            sx={{
                              display:
                                field.value &&
                                field.value !== initialValues[field.name]
                                  ? 'block'
                                  : 'none',
                              cursor: 'pointer',
                              fontSize: '21px',
                              position: 'absolute',
                              right: '26px',
                              color: 'rgba(0, 0, 0, 0.54)',
                            }}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {managers.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='status'>
                  {({ field }: any) => (
                    <StatusSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item>
                <Button
                  sx={{ height: '40px' }}
                  variant='contained'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  onClick={async () => {
                    await formik.resetForm(initialValues as any);
                    setFormValues(initialValues);
                  }}
                  sx={{ height: '40px', marginLeft: '12px' }}
                  variant='outlined'
                  type='reset'
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <FormControl>
        <RadioGroup
          row
          name='attrition_report_type'
          onChange={handleChangeAttritionReportType}
          value={attritionReportType}
        >
          {attritionReportValues.map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!!data && (
        <Box width={1000} height={550} pt={3}>
          <ChartAppAttrition data={data} />
        </Box>
      )}
    </Box>
  );
};
