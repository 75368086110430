import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { CustomFormFieldset } from './CustomFormFieldset';
import { ReactNode, RefObject } from 'react';

interface Props {
  children: ReactNode;
  onSubmit: () => void;
  initialValues: object;
  validationSchema: object;
  validateOnBlur?: boolean;
  formClassName: string;
  formRef: RefObject<any>;
  onTouchForm: () => void;
}

export const CustomForm = (props: Props) => {
  const {
    children,
    onSubmit,
    initialValues,
    validationSchema,
    validateOnBlur,
    formClassName,
    formRef,
    onTouchForm,
  } = props;

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues || {}}
      enableReinitialize={true}
      validateOnMount={false}
      validateOnChange={!!validationSchema}
      validateOnBlur={!!validationSchema && validateOnBlur}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (onSubmit) {
          // @ts-ignore
          onSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {(formProps) => {
        const {
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          setFieldTouched,
        } = formProps;

        return (
          <Form className={formClassName}>
            <Box pt={3} pb={3}>
              <CustomFormFieldset
                formProps={{
                  values,
                  setFieldValue,
                  isSubmitting,
                  errors,
                  touched,
                  setFieldTouched,
                }}
                onTouchForm={onTouchForm}
              >
                {children}
              </CustomFormFieldset>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

CustomForm.defaultProps = {
  onSubmit: null,
  initialValues: {},
  validationSchema: undefined,
  validateOnBlur: true,
};
