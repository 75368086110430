import React from 'react';
import { IconButton } from '@mui/material';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const ToastProvider = ({ children }: { children: React.ReactNode }) => (
  <SnackbarProvider
    maxSnack={3}
    preventDuplicate={true}
    autoHideDuration={15000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    style={{
      maxHeight: '200px',
      overflowX: 'hidden',
      overflowY: 'auto',
      wordBreak: 'break-word',
    }}
    action={(key) => (
      <IconButton
        aria-label='close'
        color='inherit'
        sx={{ p: 0.5 }}
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </IconButton>
    )}
  >
    {children}
  </SnackbarProvider>
);

export default ToastProvider;
