import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../../EmployeesPage/api/apiSlice';
import { ScopeType, ZoneType } from '../../../utils/permissions/types';

export interface IFilters {
  search: string;
  feedback_date_after: null | string;
  feedback_date_before: null | string;
}

interface IFeedbacksState {
  scope: ScopeType;
  zone: ZoneType;
  filters: IFilters;
  ordering: string;
}

const feedbacksZone = localStorage.getItem('feedbacksZone');
const feedbacksScope = localStorage.getItem('feedbacksScope');

const initialState = {
  scope: feedbacksScope || '',
  zone: feedbacksZone || '',
  filters: {
    search: '',
    feedback_date_after: null,
    feedback_date_before: null,
  },
  ordering: '',
};

const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: initialState as IFeedbacksState,
  reducers: {
    setOrdering: (state, { payload }) => ({
      ...state,
      ordering: payload,
    }),
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllFeedbacks.matchFulfilled,
      (state, { payload }) => {
        // @ts-ignore
        localStorage.setItem('feedbacksZone', payload.zone);
        // @ts-ignore
        localStorage.setItem('feedbacksScope', payload.scope);
        // @ts-ignore
        state.scope = payload.scope;
        // @ts-ignore
        state.zone = payload.zone;
      }
    );
  },
});

export const { setOrdering, setFilters } = feedbacksSlice.actions;

export default feedbacksSlice.reducer;

export const selectFeedbacksScope = (store: {
  feedbacksReducer: IFeedbacksState;
}) => store.feedbacksReducer.scope;
export const selectFeedbacksZone = (store: {
  feedbacksReducer: IFeedbacksState;
}) => store.feedbacksReducer.zone;
export const selectFeedbacksFilters = (store: {
  feedbacksReducer: IFeedbacksState;
}) => store.feedbacksReducer.filters;
export const selectFeedbacksOrdering = (store: {
  feedbacksReducer: IFeedbacksState;
}) => store.feedbacksReducer.ordering;
