import { Link, useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../../api/apiSlice';
import { Grid, Typography } from '@mui/material';

export const EmployeeManagers = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const manager = employee?.manager;
  const newManager = employee?.new_manager_for_employee;
  const isManagerChanging = !!newManager;
  const hr = employee?.hr;

  return (
    <div>
      <Grid container mt={3}>
        {manager && (
          <Grid item xs={3}>
            <Typography fontSize={14}>Report to</Typography>
            <Link to={`/employees/${manager.id}/`}>
              <Typography display='inline'>{manager.name}</Typography>
            </Link>
          </Grid>
        )}
        {isManagerChanging && (
          <Grid item xs={3}>
            <Typography variant='body2'>Pending approval</Typography>
            <Link to={`/employees/${newManager?.id}/`}>
              <Typography display='inline'>{newManager?.name}</Typography>
            </Link>
          </Grid>
        )}
        {hr && (
          <Grid item xs={3}>
            <Typography fontSize={14}>HR</Typography>
            <Link to={`/employees/${hr.id}/`}>
              <Typography display='inline'>{hr.name}</Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
