export const report = (
  <svg
    width='17'
    height='15'
    viewBox='0 0 17 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.5075 14.25L16.25 7.5L0.5075 0.75L0.5 6L11.75 7.5L0.5 9L0.5075 14.25Z'
      fill='#EF7530'
    />
  </svg>
);
