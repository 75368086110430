export const getFilterName = (key: string) => {
  switch (key) {
    case 'positions':
      return 'Position';
    case 'projects':
      return 'Project';
    case 'employee_status':
      return 'Status';
    case 'offices':
      return 'DC';
    case 'managers':
      return 'Direct Manager';
    case 'country':
      return 'Country (include)';
    case 'not_country':
      return 'Country (exclude)';
    case 'main_tech_skills':
      return 'Main technology';
    case 'skills':
      return 'Skills';
    case 'bench_tags':
      return 'Tag (include)';
    case 'not_bench_tags':
      return 'Tag (exclude)';
    case 'interview_enabled_skills':
      return 'Interviewer';
    default:
      return 'Filter';
  }
};
