import Chip from '@mui/material/Chip';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import PropTypes from 'prop-types';

const Cell = ({ item, column, config }) => {
  const risk = item[column.Id];
  const risks = config[column.Id].risks;
  if (!risk) return;
  const currentRisk = risks.find((r) => r.id === risk);

  let color = '#fff';
  let backgroundColor = '#9b9b9b';

  if (risk === 'danger') {
    color = '#fff';
    backgroundColor = '#d32f2f';
  }
  if (risk === 'warning') {
    color = '#fff';
    backgroundColor = '#ED6C03';
  }
  if (risk === 'resolved') {
    color = 'rgba(0, 0, 0, 0.87)';
    backgroundColor = 'rgba(0, 0, 0, 0.08)';
  }
  return (
    <span className='col-span'>
      <Chip
        label={currentRisk?.name}
        size={'small'}
        sx={{ backgroundColor: backgroundColor, color: color }}
      />
    </span>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export const CellRisk = withCellWrapper(Cell);
