import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import { apiSlice } from '../components/EmployeesPage/api/apiSlice';
import { rtkQueryErrorMessages } from '../components/EmployeesPage/api/errorHandlingMiddleware';
import { saveState } from './localStorage';
import throttle from 'lodash/throttle';

const sagaMiddleware = createSagaMiddleware();

// todo: add preloadedState to store
// const persistedState = loadState();

// todo: investigate (serializableCheck) error for new common thunks
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiSlice.middleware)
      .concat(rtkQueryErrorMessages)
      .concat(sagaMiddleware),
  // preloadedState: persistedState || {},
  devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(
  throttle(() => {
    saveState({
      employeesReducer: {
        employeePage: store.getState().employeesReducer.employeePage,
        quickFilters: store.getState().employeesReducer.quickFilters,
      },
    });
  }, 1000)
);

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
