import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Autocomplete, InputLabel, Stack } from '@mui/material';
import DatePickerValue from './DatePicker';
import DialogActions from '@mui/material/DialogActions';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  useGetEmployeesMinimalAllQuery,
  useGetEmployeesMinimalDCQuery,
} from '../../../api/apiSlice';
import { DateTime } from 'luxon';
import {
  EmployeeMinimalResponseInterface,
  ITask,
} from '../../../api/apiSlice.types';
import { useSelector } from 'react-redux';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';
export const validationSchemaCDPGoal = Yup.object({
  owner: Yup.object().required('Required'),
  dueDate: Yup.date()
    .typeError('The value must be a date (YYYY-MM-DD)')
    .required('Required')
    .min(new Date(), 'Should be greater than today'),
  short_summary: Yup.string()
    .trim()
    .max(100, 'Must be 100 characters or less')
    .required('Required'),
});

interface IProps {
  onSubmitTask: (newTask: ITask) => void;
  showAddButton: boolean;
  visibility?: string;
}

export const AddTaskModal = ({
  onSubmitTask,
  showAddButton,
  visibility,
}: IProps) => {
  const currentUserId = useSelector(selectEmployeeId);
  const [open, setOpen] = React.useState(false);

  const {
    data: employeesForExternal,
    isLoading: isEmployeeForExternalLoading,
  } = useGetEmployeesMinimalAllQuery();
  const {
    data: employeesForInternal,
    isLoading: isEmployeeForInternalLoading,
  } = useGetEmployeesMinimalDCQuery();

  const ownersData =
    visibility === 'internal' ? employeesForInternal : employeesForExternal;

  const owners = ownersData
    ? ownersData.map((item: EmployeeMinimalResponseInterface) => ({
        label: item.name,
        id: item.id,
      }))
    : [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values: FormikValues) => {
    onSubmitTask({
      due_date: values.dueDate.toISODate(),
      short_summary: values.short_summary,
      owner: { name: values.owner.label, id: values.owner.id },
    });
    setOpen(false);
  };

  const initialValues = {
    dueDate: DateTime.now(),
    short_summary: '',
    owner: owners.find((item: any) => item.id === currentUserId),
  };

  return (
    <div>
      {showAddButton && (
        <Button variant='contained' onClick={handleClickOpen}>
          + Add Task
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 648, width: 648 } }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaCDPGoal}
        >
          <Form>
            <DialogTitle>Adding task</DialogTitle>
            <DialogContent>
              <Stack direction='row' spacing={2}>
                <div style={{ width: '50%' }}>
                  <InputLabel sx={{ fontSize: 13 }}>Owner</InputLabel>
                  <Field name='owner'>
                    {({ field, form, meta }: FieldProps) => (
                      <Autocomplete
                        disablePortal
                        disableClearable
                        id='autocomlete'
                        options={owners}
                        sx={{ width: 300 }}
                        value={field.value}
                        onChange={(_, value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        loading={
                          isEmployeeForExternalLoading ||
                          isEmployeeForInternalLoading
                        }
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            sx={{ width: '100%' }}
                            margin='dense'
                            id='name'
                            variant='outlined'
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched ? meta.error : undefined}
                          />
                        )}
                      />
                    )}
                  </Field>
                </div>
                <Field name='dueDate'>
                  {({ field, form, meta }: FieldProps) => (
                    <DatePickerValue
                      value={field.value}
                      setValue={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : undefined}
                    />
                  )}
                </Field>
              </Stack>
              <div style={{ marginTop: '15px' }}>
                <InputLabel sx={{ fontSize: 13 }}>Short Summary</InputLabel>
                <Field name='short_summary'>
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      multiline
                      rows={4}
                      margin='dense'
                      fullWidth
                      variant='outlined'
                    />
                  )}
                </Field>
              </div>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button onClick={handleClose} color='inherit'>
                Cancel
              </Button>
              <Button type='submit' variant='contained'>
                Submit
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};
