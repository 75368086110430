import PropTypes from 'prop-types';
import Select from '../../form-items/select/select';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import CustomIcon from '../../icon';

const setSelectOptions = (item, column, config) => {
  const currentValue = item[column.Id];

  const selectOptions = [...config[column.Id].options];
  const notSelectedOption = { name: 'Not Selected', id: 0 };

  if (!selectOptions.some((el) => el.id === notSelectedOption.id)) {
    selectOptions.push(notSelectedOption);
  }

  return {
    selectOptions: selectOptions,
    selectValue:
      currentValue === null ? notSelectedOption.id : currentValue?.id,
    inputProps: currentValue === null ? notSelectedOption : currentValue,
  };
};

const Cell = ({ item, column, config }) => {
  const onSelectChange = (e) => {
    const editItem = {
      ...item,
      [column.Id]: e.target.value,
    };
    config[column.Id].valueSetter(editItem);
  };

  const renderIcon = () => {
    if (item.is_confirmed === null) {
      return null;
    }
    if (item.is_confirmed === 'confirmed') {
      return <CustomIcon iconName='confirmed' />;
    }
    if (item.is_confirmed === 'unconfirmed') {
      return <CustomIcon iconName='skill-unconfirmed' />;
    }
    return null;
  };

  const { selectOptions, inputProps, selectValue } = setSelectOptions(
    item,
    column,
    config
  );
  const isShowCell = config[column.Id].isHaveRights;
  const withIcon = config[column.Id].withIcon;

  return isShowCell ? (
    <>
      <Select
        formClassName='skills-level-select_cell-list'
        value={selectValue}
        selectOptions={selectOptions}
        inputProps={inputProps}
        onChange={onSelectChange}
        disabled={config[column.Id].disabled}
        customId={item.display_name && item.display_name}
      />
      {withIcon && renderIcon()}
    </>
  ) : null;
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export const CellSelect = withCellWrapper(Cell);
