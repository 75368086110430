import { FC } from 'react';
import { Grid, Slider, TextField } from '@mui/material';
import {
  MAX_CHECKLIST_ITEM_VALUE,
  MIN_CHECKLIST_ITEM_VALUE,
  CHECKLIST_ITEM_VALUE_STEP,
} from '../../../../utils/const-variable';
import { ChecklistItemInterface } from '../../../EmployeesPage/api/apiSlice.types';

interface Props {
  disabled?: boolean;
  onChange: (data: { scale_answer: number }) => void;
  item: ChecklistItemInterface;
}

export const SliderField: FC<Props> = ({ disabled, item, onChange }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (
      value >= MIN_CHECKLIST_ITEM_VALUE &&
      value <= MAX_CHECKLIST_ITEM_VALUE
    ) {
      onChange({ scale_answer: value });
    }
  };

  const handleSliderChange = (event: Event, scale_answer: any) =>
    onChange({ scale_answer });

  return (
    <>
      <Grid item sx={{ mr: 3 }}>
        <TextField
          value={`${item.scale_answer || MIN_CHECKLIST_ITEM_VALUE}`}
          onChange={handleInputChange}
          disabled={disabled}
          inputProps={{
            min: MIN_CHECKLIST_ITEM_VALUE,
            max: MAX_CHECKLIST_ITEM_VALUE,
          }}
          type='number'
          className='step__form checklist_item--input-number'
        />
      </Grid>
      <Grid item xs>
        <Slider
          step={CHECKLIST_ITEM_VALUE_STEP}
          min={MIN_CHECKLIST_ITEM_VALUE}
          max={MAX_CHECKLIST_ITEM_VALUE}
          marks={new Array(11)
            .fill({})
            .map((el, index) => ({ value: index, label: index }))}
          value={
            typeof item.scale_answer === 'number'
              ? item.scale_answer
              : MIN_CHECKLIST_ITEM_VALUE
          }
          onChange={handleSliderChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};
