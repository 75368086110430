import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { forwardRef } from 'react';
import { Box, Grid } from '@mui/material';
import { CustomForm } from '../../../../common/formikFormControls/CustomForm';
import { SelectField } from '../../../../common/formikFormControls/SelectField';
import { TextField } from '../../../../common/formikFormControls/TextField';
import {
  useGetEmployeeQuery,
  useGetEmployeesMinimalQuery,
  useGetMentorsMinimalQuery,
  useGetPositionsQuery,
  useGetSenioritiesQuery,
} from '../../../api/apiSlice';
import { ICDPFileResponse, ICDPRequest } from '../../../api/apiSlice.types';

export interface ICDPFormValues
  extends Omit<
    ICDPRequest,
    'employee_id' | 'mentor_id' | 'short_summary' | 'files_ids'
  > {
  employee_id?: number;
  mentor_id?: number;
  short_summary?: string;
  files?: ICDPFileResponse[];
}

interface IDetailsCreateProps {
  onSubmit: (values: ICDPRequest) => void;
  initialValues?: ICDPFormValues;
  onTouchForm?: () => void;
  setCDPMentorId?: (id: number) => void;
}

const validationSchema = Yup.object().shape({
  short_summary: Yup.string().trim().required('Required'),
  mentor_id: Yup.number().required('Required'),
  employee_id: Yup.number().required('Required'),
});

export const DetailsCreate = forwardRef(
  (
    {
      onSubmit,
      initialValues = {},
      onTouchForm,
      setCDPMentorId,
    }: IDetailsCreateProps,
    ref
  ) => {
    const { id } = useParams();

    if (id && !initialValues?.employee_id) {
      initialValues.employee_id = Number(id);
    }

    const { data: employee } = useGetEmployeeQuery(Number(id), { skip: !id });
    const { data: positions, isLoading: isPositionsLoading } =
      useGetPositionsQuery();
    const { data: seniorities, isLoading: isSenioritiesLoading } =
      useGetSenioritiesQuery();
    const { data: mentors, isLoading: isMentorsLoading } =
      useGetMentorsMinimalQuery();
    const { data: employees, isFetching: isEmployeesLoading } =
      useGetEmployeesMinimalQuery(undefined, { skip: !!id });

    const getOptions = (options: Array<{ id: number; name: string }>) =>
      options.map((item) => ({
        label: item.name,
        value: item.id,
      }));

    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomForm
            // @ts-ignore
            formRef={ref}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            // @ts-ignore
            onSubmit={onSubmit}
            // @ts-ignore
            onTouchForm={onTouchForm}
          >
            {/* @ts-ignore */}
            <TextField
              name='short_summary'
              label='Short Summary'
              placeholder='Rump-up Employee Project'
              xs={4}
              isRequired
            />

            {id ? (
              <Grid item xs={4} height='100%'>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <label className='form_field__label'>
                    <span>Employee</span>
                    <span className='redux-form__required'> *</span>
                  </label>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Link
                      key={0}
                      to={`/employees/${id}`}
                      style={{ color: '#1976d2', textDecoration: 'none' }}
                    >
                      {employee?.name}
                    </Link>
                  </Box>
                </Box>
              </Grid>
            ) : (
              // @ts-ignore
              <SelectField
                name='employee_id'
                label='Employee'
                xs={4}
                options={getOptions(employees || [])}
                isLoading={isEmployeesLoading}
                getValue={(option) => option?.value}
                isRequired
              />
            )}

            {/* @ts-ignore */}
            <SelectField
              name='mentor_id'
              label='Mentor'
              xs={4}
              options={getOptions(mentors || [])}
              isLoading={isMentorsLoading}
              getValue={(option) => option?.value}
              isRequired
              onChange={(id: number) => setCDPMentorId && setCDPMentorId(id)}
            />

            {/* @ts-ignore */}
            <SelectField
              name='position'
              label='Target Position'
              xs={4}
              options={getOptions(positions || [])}
              isLoading={isPositionsLoading}
              getValue={(option) => option?.label}
            />

            {/* @ts-ignore */}
            <SelectField
              name='seniority_level'
              label='Target Seniority'
              xs={4}
              options={getOptions(seniorities || [])}
              isLoading={isSenioritiesLoading}
              getValue={(option) => option?.label}
            />

            {/* @ts-ignore */}
            <TextField
              name='notes'
              label='Notes'
              placeholder='You can add links here'
              xs={8}
              multiline
              rows={4}
            />
          </CustomForm>
        </Grid>
      </Grid>
    );
  }
);
