import Tooltip from '@mui/material/Tooltip';
import { GridContainerItem } from '../GridContainerItem';
import { Avatar } from './utils';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../../api/apiSlice';

export const Mentors2 = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const mentors = employee?.mentors || [];

  return (
    <GridContainerItem xs={3}>
      <span style={{ paddingRight: '8px' }}>Mentored by</span>
      {mentors.length === 0 ? (
        <Typography fontSize={'small'} color={'gray.light'}>
          none
        </Typography>
      ) : null}
      {mentors.map((item) => (
        <Tooltip
          arrow
          className='profile_block__members_default_avatar'
          key={item.id}
          title={item.name}
        >
          <div>
            <Avatar item={item} />
          </div>
        </Tooltip>
      ))}
    </GridContainerItem>
  );
};
