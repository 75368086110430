import React from 'react';
import { Button } from '../../../common/list-toolbar/components/button.js';

export const getListToolbarButtons = (
  onAddSkills,
  onQuestionary,
  isOwner,
  isEditMode
) => {
  return [
    isOwner && (
      <Button
        iconName='questionary'
        className='toolbar-button'
        title='Questionary'
        onClick={onQuestionary}
        key={0}
      />
    ),
    isOwner && isEditMode && (
      <Button
        iconName='plus-orange'
        className='toolbar-button'
        title='Add Skills'
        onClick={onAddSkills}
        key={1}
      />
    ),
  ];
};

export const getButtonsTemplate = (
  onSubmit,
  onCancel,
  onEdit,
  isEditMode,
  isEditVisible
) => [
  isEditVisible && !isEditMode && (
    <button key={0} className='button__main' onClick={onEdit}>
      Edit
    </button>
  ),
  isEditMode && (
    <button key={1} className='button__secondary' onClick={onCancel}>
      Cancel
    </button>
  ),
  isEditMode && (
    <button key={2} className='button__main' onClick={onSubmit}>
      Save changes
    </button>
  ),
];

export const columnsToDisplay = [
  {
    Name: 'Skill',
    Id: 'skill',
    isObject: true,
    deleteArrow: 'delete-arrow',
    className: 'biggest-col',
  },
  {
    Name: 'Self-assessment level',
    Id: 'self_assessed_level',
    className: 'biggest-col',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: 'Manager-assessment level',
    Id: 'manager_assessed_level',
    className: 'biggest-col',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: 'Action',
    Id: 'action',
    className: 'action-col',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: 'Main technology',
    Id: 'current_main_tech',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: 'Interviewer',
    Id: 'interview_enabled',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
];
