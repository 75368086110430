import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Popover, IconButton, Stack } from '@mui/material';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import { BoxView } from '../../DocViewer/BoxView';

const Cell = ({ column, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const files = item[column.Id];
  const handleClick = (event) => event.stopPropagation();

  const onClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return files ? (
    <Stack direction='row' alignItems='center'>
      <Tooltip title='Click here to preview' placement='top'>
        <IconButton onClick={onClick}>
          <DescriptionOutlinedIcon sx={{ color: '#1976D2' }} />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <BoxView cvId={item.id} fileURL={files} onClose={handleClose} />
      </Popover>
      <a download href={files} onClick={handleClick}>
        <Tooltip title={'Download'} placement='top'>
          <DownloadIcon sx={{ color: '#797979' }} />
        </Tooltip>
      </a>
    </Stack>
  ) : (
    '———'
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellCvFile = withCellWrapper(Cell, 'without_padding');
