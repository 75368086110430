import { Button, Grid, Stack, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import { GridContainerItem } from '../GridContainerItem';

export const PendingManager = ({
  newManagerId,
  newManagerName,
  operationStatus,
  isEdit,
  canEdit,
  rejectNewManager,
  hasRightToApproveNewManager,
  approveNewManager,
  statusChangeManager,
  currentValue,
}) => {
  const isRejected =
    statusChangeManager === null ||
    currentValue?.status_change_manager === null;

  const isApproved =
    statusChangeManager === 'true' ||
    currentValue?.status_change_manager === 'true';
  return (
    <>
      <Grid item container>
        <Stack direction='row' spacing={1}>
          <SendIcon fontSize='small' color='primary' />
          <Typography color={'#4b4b4b'}>
            Pending approval
            <span>{` `}</span>
            <Link to={`/employees/${newManagerId}/`}>{newManagerName}</Link>
          </Typography>
        </Stack>
      </Grid>
      {isEdit && canEdit && (
        <GridContainerItem>
          {operationStatus === 'request_sent' ? (
            <span className='profile_block__contacts_item'>
              Waiting for sync.
            </span>
          ) : (
            <Stack direction='row' spacing={2}>
              <Button
                variant={isRejected ? 'contained' : 'outlined'}
                disabled={!canEdit}
                onClick={rejectNewManager}
                color={isRejected ? 'error' : 'primary'}
              >
                Reject{isRejected && 'ed'}
              </Button>
              {hasRightToApproveNewManager && (
                <Button
                  variant={isApproved ? 'contained' : 'outlined'}
                  disabled={!canEdit}
                  onClick={approveNewManager}
                  color={isApproved ? 'success' : 'primary'}
                >
                  Approve{isApproved && 'd'}
                </Button>
              )}
            </Stack>
          )}
        </GridContainerItem>
      )}
    </>
  );
};
