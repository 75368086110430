import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { InfiniteScroll } from '../../../common/infiniteScroll/InfiniteScroll';
import RadioButtonGroup from '../../../common/form-items/radio-group/radio-group';
import { CustomStatusCell } from '../../../common/list/cells/CustomStatusCell';
import { List } from '../../../common/list/List';
import { CellDismissed } from '../../../common/list/cells/CellDismissed/CellDismissed';
import { ManagerStatusCell } from '../../../common/list/cells/ManagerStatusCell.js';
import { CellAvatar } from '../../../common/list/cells/CellAvatar';
import { CellClipboard } from '../../../common/list/cells/CellClipboard';
import { CellProjectsList } from '../../../common/list/cells/CellProjectsList';
import { CellGroupedFields } from '../../../common/list/cells/CellGroupedFields';
import { CellPosition } from '../../../common/list/cells/CellPosition';
import { CellRelation } from '../../../common/list/cells/CellRelation';
import SearchBox from './SearchBox';
import '../../SearchEmployee.css';
import '../../../common/list-toolbar/list-toolbar.css';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import {
  selectAccessScope,
  selectHasSubordinates,
  selectHasSupervised,
} from '../../../../store/slices/auth/authSlice';
import { selectIsLoading } from '../../../../store/slices/loader/loaderSlice';
import {
  clearEmployees,
  selectEmployeesListEmployees,
  selectEmployeesListReducer,
} from '../../slices/employeesListSlice';
import {
  changeColumnsToDisplay,
  clearQuickFilters,
  loadEmployees,
  resetFilters,
  resetSearchValue,
  resetSkills,
  resetSortSettings,
  selectEmployeeScope,
  selectEmployeesReducer,
  selectSkillFilter,
  selectSkills,
  setEmployeePage,
  setFilters,
  setQuickFilters,
  setSearchValue,
  setSkillFilter,
  setSkills,
  setSortSettings,
} from '../../slices/employeesSlice';
import { canViewRiskColumn } from '../../../../utils/permissions/checkAccess';
import { useGetEmployeeFiltersQuery } from '../../api/apiSlice';
import { QuickFilters } from './QuickFilters';
import { renderRow } from './renderRow';
import { getRadioButtonGroupOptions } from './getRadioButtonGroupOptions';
import {
  Filters,
  FiltersOptions,
  IColumnToDisplay,
  IQuickFilter,
} from './types';
import { SkillFilter } from '../../slices/employeeSlice.types';
import { CellMainTech } from '../../../common/list/cells/CellMainTech';

const DEFAULT_AMOUNT_OF_ITEMS = 30;

export const EmployeesList: FC = () => {
  const isLoading = useSelector(selectIsLoading);
  const employees = useSelector(selectEmployeesListEmployees);
  const count = useSelector((store) => selectEmployeesListReducer(store).count);
  const columnsToDisplay: IColumnToDisplay[] = useSelector(
    (store) => selectEmployeesReducer(store).columnsToDisplay
  );
  const quickFilters: IQuickFilter[] = useSelector(
    (store) => selectEmployeesReducer(store).quickFilters
  );
  const searchSettings = useSelector(
    (store) => selectEmployeesReducer(store).searchSettings
  );
  const scope = useSelector(selectEmployeeScope);
  const hasSubordinates = useSelector(selectHasSubordinates);
  const hasSupervised = useSelector(selectHasSupervised);
  const hasAccessToHR = useSelector((store) =>
    selectAccessScope(store)?.includes('hr_access')
  );
  const employeePage: string = useSelector(
    (store) => selectEmployeesReducer(store).employeePage
  );

  const dispatch = useDispatch();

  const filters: Filters = searchSettings?.filters;
  const skills = useSelector(selectSkills);
  const skillFilter = useSelector(selectSkillFilter);
  const searchValue: string = searchSettings?.searchValue;
  const column: string = searchSettings?.sortSettings.column;
  const sortDirection: string = searchSettings?.sortSettings.sortDirection;

  const [offSet, setOffSet] = useState<number>(0);
  const [hasMoreEmployees, setHasMoreEmployees] = useState<boolean>(true);
  const [rights, setRights] = useState<{ viewRisk: boolean }>({
    viewRisk: canViewRiskColumn(scope),
  });
  const [shouldLoadEmployees, setShouldLoadEmployees] = useState<boolean>(true);

  const { data: employeeFilters } = useGetEmployeeFiltersQuery();

  const employeeStatusOptions = employeeFilters?.employee_status;
  const pageSize = DEFAULT_AMOUNT_OF_ITEMS;

  useEffect(() => {
    dispatch(
      setComponent({
        component: `Search Employees (${employees.length} / ${count})`,
        isTitle: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, [count, employees.length]);

  useEffect(() => {
    toggleRiskColumn(employeePage);
  }, [employeePage]);

  useEffect(() => {
    dispatch(clearEmployees());
    setOffSet(0);
    setShouldLoadEmployees(true);
  }, [
    searchValue,
    filters,
    skills,
    skillFilter,
    column,
    sortDirection,
    employeePage,
    quickFilters,
  ]);

  useEffect(() => {
    setShouldLoadEmployees(true);
  }, [offSet]);

  useEffect(() => {
    if (shouldLoadEmployees) {
      dispatch(loadEmployees({ offSet: offSet }));
      setShouldLoadEmployees(false);
    }
  }, [shouldLoadEmployees]);

  useEffect(() => {
    setHasMoreEmployees(employees.length < count);
  }, [count, employees.length]);

  useEffect(() => {
    setRights({ viewRisk: canViewRiskColumn(scope) });
  }, [scope]);

  const loadMoreEmployees = () => {
    if (!isLoading && hasMoreEmployees) {
      setOffSet((prevOffset) => prevOffset + pageSize);
    }
  };

  const handleChangeSearchOrFilterOrSkill = (newFilters?: {
    filters: FiltersOptions;
    skills: { id: number; name: string }[];
    searchValue: string;
    skillFilter: SkillFilter;
  }) => {
    if (newFilters) {
      if (newFilters.filters) {
        dispatch(setFilters(newFilters.filters));
      }
      if (newFilters.skills) {
        dispatch(setSkills(newFilters.skills));
      }
      if (newFilters.skillFilter) {
        dispatch(setSkillFilter(newFilters.skillFilter));
      }
      dispatch(setSearchValue(newFilters.searchValue));
    }
  };

  const handleChangeSortSetting = (column: string, sortDirection: string) => {
    dispatch(setSortSettings({ column, sortDirection }));
  };

  const resetEmployees = () => {
    dispatch(clearQuickFilters());
    dispatch(resetSearchValue());
    dispatch(resetFilters());
    dispatch(resetSkills());
    dispatch(resetSortSettings());
  };

  const toggleRiskColumn = (value = 'all') => {
    const newColumnsToDisplay = columnsToDisplay.map((col) => {
      if (col.Id === 'risk_level') {
        const isSelected = value !== 'all' && value !== 'supervised';
        const newCol = {
          ...col,
          isSelected: isSelected,
        };
        return newCol;
      }

      return col;
    });

    dispatch(changeColumnsToDisplay(newColumnsToDisplay));
  };

  const handleChangeEmployeePage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(setEmployeePage(value));
  };

  const handleChangeQuickFilters = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    const newQuickFilters = quickFilters.map((quickFilter) => {
      if (quickFilter.name === name) {
        return {
          ...quickFilter,
          selected: checked || '',
        };
      }

      return quickFilter;
    });

    dispatch(setQuickFilters(newQuickFilters));
  };

  // todo: pass less props
  const setClipBoardText = (item: { username: string }) =>
    `${window.location.origin}/employee/${item.username}`;

  // todo: filtersOptions - prop drilling - fix
  return (
    <div className='page-cont'>
      <Scrollbars
        autoHide
        autoHideTimeout={300}
        className='custom_scrollbar-container'
      >
        <div className='page-container_for-scroll' id='employees'>
          <InfiniteScroll
            loadMore={loadMoreEmployees}
            hasMore={hasMoreEmployees}
          >
            <SearchBox
              searchEmployees={handleChangeSearchOrFilterOrSkill}
              reset={resetEmployees}
              searchValuePassed={searchValue}
              isVisibleFilterTag={employeePage !== 'all'}
            />
            <div className='button-panel employee_list_panel'>
              <div className='button-panel_left'>
                <RadioButtonGroup
                  handleChange={handleChangeEmployeePage}
                  selectedValue={employeePage}
                  options={getRadioButtonGroupOptions(
                    scope,
                    hasSubordinates,
                    hasAccessToHR,
                    hasSupervised
                  )}
                />
                <QuickFilters
                  quickFilters={quickFilters}
                  employeePage={employeePage}
                  handleChangeQuickFilters={handleChangeQuickFilters}
                />
              </div>
            </div>
            <List
              columnsToDisplay={columnsToDisplay}
              items={employees}
              renderRow={renderRow}
              setSortSettings={handleChangeSortSetting}
              sortColumnName={column}
              sortDirection={sortDirection}
              config={{
                risk_level: {
                  cell: CustomStatusCell,
                  isHaveRights: rights.viewRisk,
                },
                employee_main_tech: {
                  cell: CellMainTech,
                },
                avatar_url: { cell: CellAvatar },
                employee_status: {
                  cell: CellDismissed,
                  statuses: employeeStatusOptions || [],
                  isVisibleTag: employeePage !== 'all',
                },
                projects: { cell: CellProjectsList },
                username: {
                  cell: CellClipboard,
                  valueSetter: setClipBoardText,
                },
                admin_manager: {
                  cell: ManagerStatusCell,
                },
                grouped_fields: {
                  cell: CellGroupedFields,
                },
                employee_position: {
                  cell: CellPosition,
                },
                grouped_relation: {
                  cell: CellRelation,
                  isHaveRights: employeePage === 'supervised',
                },
              }}
            />
          </InfiniteScroll>
        </div>
      </Scrollbars>
    </div>
  );
};

export default EmployeesList;
