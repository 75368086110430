import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { Checkbox } from '../../form-items/checkbox/checkbox';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ item, column, config }) => {
  const isDisabled =
    (!item?.current_main_tech && config[column.Id].withTooltip) ||
    config[column.Id].disabled;
  const isShowCell =
    config[column.Id].isHaveRights && item?.skill?.is_main_tech;
  const isShowTooltip = config[column.Id].withTooltip && isDisabled;

  const [isChecked, setIsChecked] = useState(item?.current_main_tech);

  const onChange = (e) => {
    const { checked } = e.target;
    const editItem = {
      ...item,
      [column.Id]: checked,
    };
    setIsChecked(checked);
    config[column.Id].valueSetter(editItem);
  };

  return isShowCell ? (
    <Tooltip
      title={
        isShowTooltip &&
        'You can mark no more than three skills as Main technology'
      }
      placement='top'
    >
      <Box>
        <Checkbox
          disabled={isDisabled}
          checked={isChecked}
          onChange={onChange}
        />
      </Box>
    </Tooltip>
  ) : null;
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export const CellMainTechnology = withCellWrapper(Cell);
