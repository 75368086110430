import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { ITagsState, QueryOptions } from './types';
import {
  useGetBenchQuery,
  useGetExportBenchFileMutation,
} from '../EmployeesPage/api/apiSlice';
import { canEditBenchTags } from '../../utils/permissions/checkAccess';
import { IBenchData } from '../EmployeesPage/api/apiSlice.types';
import WarningIcon from '@mui/icons-material/Warning';
import { stringAvatar } from './utils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TagsList } from './BenchTags/TagsList';
import BenchComment from './BenchComment/BenchComment';
import Box from '@mui/material/Box';
import { BenchFilters } from './BenchFilters';
import { sortMap } from './const';
import Export from '../common/Export';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectIsIncludeUnconfirmed,
  selectMainTechValues,
  selectOffices,
  selectPage,
  selectPositions,
  selectSeniorities,
  selectSkillRows,
  selectSortModel,
  selectTags,
  selectTagsToExclude,
  setIsIncludeUnconfirmed as setIsIncludeUnconfirmedAction,
  setMainTechValues as setMainTechValuesAction,
  setOffices as setOfficesAction,
  setPage as setPageAction,
  setPositions as setPositionsAction,
  setSeniorities as setSenioritiesAction,
  setSkillRows as setSkillRowsAction,
  setSortModel as setSortModelAction,
  setTags as setTagsAction,
  setTagsToExclude as setTagsToExcludeAction,
} from '../../store/slices/bench/benchSlice';
import { useActions } from '../../hooks';

const renderStackCell = (params: any) => {
  const { row, field } = params;
  const text = row[field]
    .map((stack: { name: string }) => stack.name)
    .join(', ');
  return (
    <Tooltip title={text}>
      <Typography noWrap fontSize={14}>
        {text}
      </Typography>
    </Tooltip>
  );
};

interface IProps {
  setTagsToEdit: (tags: ITagsState) => void;
  setIsOpenDialog: (value: boolean) => void;
}

const pageSize = 50;

export const BenchLayout = ({ setTagsToEdit, setIsOpenDialog }: IProps) => {
  const page = useSelector(selectPage);
  const sortModel = useSelector(selectSortModel);
  const offices = useSelector(selectOffices);
  const positions = useSelector(selectPositions);
  const seniorities = useSelector(selectSeniorities);
  const mainTechValues = useSelector(selectMainTechValues);
  const tags = useSelector(selectTags);
  const tagsToExclude = useSelector(selectTagsToExclude);
  const skillRows = useSelector(selectSkillRows);
  const isIncludeUnconfirmed = useSelector(selectIsIncludeUnconfirmed);
  const [downloadFile] = useGetExportBenchFileMutation();

  const [
    setPage,
    setSortModel,
    setOffices,
    setPositions,
    setSeniorities,
    setMainTechValues,
    setTags,
    setTagsToExclude,
    setSkillRows,
    setIsIncludeUnconfirmed,
  ] = useActions([
    setPageAction,
    setSortModelAction,
    setOfficesAction,
    setPositionsAction,
    setSenioritiesAction,
    setMainTechValuesAction,
    setTagsAction,
    setTagsToExcludeAction,
    setSkillRowsAction,
    setIsIncludeUnconfirmedAction,
  ]);

  const queryOptions = useMemo(() => {
    const options: QueryOptions = {
      offset: pageSize * page,
    };

    if (sortModel?.length) {
      const { field, sort } = sortModel[0];
      // @ts-ignore
      const fieldName = sortMap[field];
      options.ordering = sort === 'desc' ? '-' + fieldName : fieldName;
    }
    if (offices?.length) {
      options.offices = offices;
    }
    if (positions?.length) {
      options.positions = positions;
    }
    if (seniorities?.length) {
      options.seniorities = seniorities;
    }
    if (mainTechValues?.length) {
      options.mainTechValues = mainTechValues;
    }
    if (tags?.length) {
      options.tags = tags;
    }
    if (tagsToExclude?.length) {
      options.tagsToExclude = tagsToExclude;
    }
    if (
      skillRows?.length &&
      skillRows.filter((row) => row.skills.length).length
    ) {
      options.skillRows = skillRows.filter((row) => row.skills.length);
    }
    if (isIncludeUnconfirmed) {
      options.isIncludeUnconfirmed = isIncludeUnconfirmed;
    }

    return options;
  }, [
    page,
    sortModel,
    offices,
    positions,
    seniorities,
    mainTechValues,
    tags,
    tagsToExclude,
    skillRows,
    isIncludeUnconfirmed,
  ]);

  const { data, isFetching } = useGetBenchQuery(queryOptions);

  const rows = data?.results || [];
  const rowCount = data?.count;
  const columns = useMemo<GridColumns<IBenchData>>(
    () => [
      {
        field: 'moreThan3Months',
        sortable: false,
        headerName: '',
        width: 40,
        renderCell: (params: GridRenderCellParams<Date>) => {
          const threeMonths = 90 * 24 * 60 * 60 * 1000;
          const isMore =
            new Date().getTime() -
              new Date(params.row.startDateOnBench).getTime() >
            threeMonths;
          return isMore ? (
            <Tooltip title='More than 3 months on a bench'>
              <WarningIcon fontSize='small' color='warning' />
            </Tooltip>
          ) : null;
        },
      },
      {
        field: 'name',
        headerName: 'Employee',
        width: 240,
        renderCell: (params: GridRenderCellParams<Date>) => {
          const name = params.row[params.field];
          const avatarUrl = params.row.avatarUrl;
          return (
            <Stack direction='row' spacing={2} alignItems='center'>
              <Avatar alt={name} src={avatarUrl} {...stringAvatar(name)} />
              <Link
                to={`/employees/${params.row.id}`}
                style={{ textDecoration: 'none' }}
              >
                <Typography fontSize={14} color='#1976D2'>
                  {name}
                </Typography>
              </Link>
            </Stack>
          );
        },
      },
      {
        field: 'location',
        headerName: 'Actual Location',
        width: 200,
      },
      {
        field: 'startDateOnBench',
        headerName: 'Start Date on Bench',
        width: 200,
      },
      {
        field: 'position',
        headerName: 'Position',
        width: 200,
      },
      {
        field: 'seniority',
        headerName: 'Seniority',
        width: 120,
      },
      {
        field: 'yearsOfExperience',
        headerName: 'Years of Experience',
        width: 200,
        renderCell: (params: GridRenderCellParams<Date>) => {
          const year = 365 * 24 * 60 * 60 * 1000;
          let yearsOfExperience = '';
          const startInProfession =
            params.row.yearStartInProfession?.toString();
          const startInCompany = params.row.startDateInCompany;

          let isCompanyTimeOnly = false;

          if (startInProfession) {
            yearsOfExperience = Math.floor(
              (new Date().getTime() - new Date(startInProfession).getTime()) /
                year
            ).toFixed();
          } else if (params.row.startDateInCompany) {
            yearsOfExperience = Math.floor(
              (new Date().getTime() - new Date(startInCompany).getTime()) / year
            ).toFixed();
            isCompanyTimeOnly = true;
          }

          const isLessThanOneYear = yearsOfExperience === '0';

          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography fontSize={14}>
                {isLessThanOneYear ? '< 1' : yearsOfExperience}
              </Typography>
              {isCompanyTimeOnly && (
                <Tooltip title='Year with Akvelon'>
                  <ErrorOutlineIcon fontSize='small' color='disabled' />
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'mainTech',
        headerName: 'Main Technology',
        width: 240,
        sortable: false,
        renderCell: renderStackCell,
      },
      {
        field: 'stack',
        headerName: 'Stack',
        width: 240,
        sortable: false,
        renderCell: renderStackCell,
      },
      {
        field: 'workedPreviouslyOn',
        headerName: 'Worked Previously On',
        width: 200,
        sortable: false,
        renderCell: renderStackCell,
      },
      {
        field: 'currentUtilization',
        headerName: 'Current Utilization',
        width: 200,
        sortable: false,
        renderCell: renderStackCell,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 350,
        sortable: false,
        renderCell: (params) => (
          <TagsList
            employeeId={params.row.id}
            tags={params.value}
            setTagsToEdit={setTagsToEdit}
            setIsOpenDialog={setIsOpenDialog}
            canEditTags={data?.zone ? canEditBenchTags(data?.zone) : false}
          />
        ),
      },
      {
        field: 'comment',
        headerName: 'Comment',
        width: 130,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <BenchComment
            employeeId={params?.row?.id}
            benchComment={params?.row?.comment}
            zone={data?.zone}
          />
        ),
      },
    ],
    [data?.zone]
  );

  const [rowCountState, setRowCountState] = useState(rowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState?: number) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setSortModel(sortModel);
  }, []);

  return (
    <Box p={3} overflow='auto'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <BenchFilters
          offices={offices}
          setOffices={setOffices}
          positions={positions}
          setPositions={setPositions}
          seniorities={seniorities}
          setSeniorities={setSeniorities}
          mainTechValues={mainTechValues}
          setMainTechValues={setMainTechValues}
          tags={tags}
          setTags={setTags}
          tagsToExclude={tagsToExclude}
          setTagsToExclude={setTagsToExclude}
          skillRows={skillRows}
          setSkillRows={setSkillRows}
          isIncludeUnconfirmed={isIncludeUnconfirmed}
          setIsIncludeUnconfirmed={setIsIncludeUnconfirmed}
        />
        <Export onDownloadFile={() => downloadFile(queryOptions)} />
      </Box>

      <Box height='75vh'>
        <DataGrid
          columns={columns}
          rows={rows}
          loading={isFetching}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          paginationMode='server'
          rowCount={rowCountState}
          pageSize={pageSize}
          rowsPerPageOptions={[50]} // disable page size selector
          onPageChange={(newPage) => setPage(newPage)}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </Box>
  );
};
