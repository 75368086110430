import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import CustomIcon from '../../icon';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ column, item, config }) => {
  const handleClick = config[column.Id].onClick;
  const readMoreIdArr = config[column.Id].readMoreIdArr;
  const icon = readMoreIdArr.includes(item.id)
    ? 'blue-arrow-down'
    : 'blue-arrow-right';
  const disabledStyles = {
    pointerEvents: !item[column.Id] ? 'none' : 'all',
    opacity: !item[column.Id] ? 0.5 : 1,
    cursor: 'pointer',
    color: '#1976D2',
  };
  return (
    <Stack
      direction='row'
      alignItems='center'
      onClick={() => handleClick(item.id)}
      sx={disabledStyles}
    >
      Read More <CustomIcon iconName={icon} />
    </Stack>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellReadMore = withCellWrapper(Cell);
