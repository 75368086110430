import { FC, useEffect, useState } from 'react';
import Viewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Mammoth from 'mammoth';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import './DocViewer.css';
import { useGetCVFileQuery } from '../../EmployeesPage/api/apiSlice';

// todo: add docx support after fixing "mammoth": "^1.5.1" issue with react-scripts 5.1 and node core libraries fallbacks
interface Props {
  cvId: number;
  fileURL: string;
}

const getMimeType = (fileURL: string): string => {
  const splittedURL = fileURL.split('.');
  return splittedURL[splittedURL.length - 1];
};

export const DocViewer: FC<Props> = ({ cvId, fileURL }) => {
  const [htmlString, setHtmlString] = useState<string>('');
  const [pdfDoc, setPdfDoc] = useState<any>([]);

  const { data, isFetching } = useGetCVFileQuery(cvId);

  const onDocxRenderer = (arrayBuffer: ArrayBuffer) =>
    Mammoth.convertToHtml({ arrayBuffer })
      .then((res: { value: string }) => setHtmlString(res.value))
      .catch(() => setError());

  const onPdfRenderer = (arrayBuffer: ArrayBuffer) => {
    const file = new File([arrayBuffer], fileURL, { type: 'application/pdf' });
    setPdfDoc([file]);
  };

  const mapper = {
    docx: onDocxRenderer,
    pdf: onPdfRenderer,
  } as any;

  useEffect(() => {
    if (data) {
      const mimeType = getMimeType(fileURL);
      const renderer = mapper[mimeType];
      if (renderer) {
        renderer(data);
      } else {
        setError();
      }
    }
  }, [data]);

  const setError = () =>
    setHtmlString("<p>This document doesn't support preview mode</p>");

  return (
    <div className={classNames({ doc_viewer__progress: isFetching })}>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          {htmlString && (
            <div
              dangerouslySetInnerHTML={{ __html: htmlString }}
              className='doc_viewer__docx'
            />
          )}
          <Viewer
            documents={pdfDoc.map((file: any) => ({
              uri: window.URL.createObjectURL(file),
            }))}
            config={{
              header: {
                disableFileName: true,
                disableHeader: true,
              },
            }}
            pluginRenderers={DocViewerRenderers}
          />
        </>
      )}
    </div>
  );
};
