import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCellWrapper } from '../../../HOC/withCellWrapper';
import { Tooltip, Chip } from '@mui/material';
import { filterProject } from '../../../../EmployeesPage/components/EmployeeProfile/ActiveProjects/ActiveProjects';
import { ProbationStatuses } from '../../../../EmployeesPage/api/apiSlice.types';
import { DateTime } from 'luxon';
import {
  AccessTimeFilled,
  Dangerous,
  EventAvailableTwoTone,
  InsertInvitationTwoTone,
} from '@mui/icons-material';
import './CellDismissed.css';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
const getMessage = (status, dateString) => {
  if (status === ProbationStatuses.FAILED) {
    return 'Probation period failed';
  }

  if (status === ProbationStatuses.IN_PROGRESS) {
    const expiredDate = DateTime.fromISO(dateString);
    if (DateTime.now() > expiredDate) {
      return `Probation period expired ${expiredDate.toFormat('dd/MM/yyyy')}`;
    } else {
      return `Probation period ends ${expiredDate.toFormat('dd/MM/yyyy')}`;
    }
  }
};

const Cell = ({ item, column, config }) => {
  const projects = item.projects.filter(filterProject);
  const statuses = config[column.Id].statuses;
  const employeeStatus = item[column.Id];
  const probationStatus = item.probation_status;
  const probationDueDate = item.probation_due_date;
  const isProbationExpired =
    DateTime.now() > DateTime.fromISO(probationDueDate);

  const renderValue = statuses.find((status) => status.id === employeeStatus);

  const isVisible = config[column.Id].isVisibleTag;
  const getTooltipTitle = () => {
    const { type, start_date, end_date, status } = item.vacation;
    if (status === 'upcoming') {
      return `Upcoming ${type} will be since ${start_date} by ${end_date}`;
    }
    return `On ${type} since ${start_date} by ${end_date}`;
  };

  const renderTagIcon = () => {
    const tags = item.bench_tags;
    if (!tags?.length) {
      return;
    }
    return (
      <Tooltip
        title={tags?.map((tag) => tag.name).join(', ')}
        placement='bottom'
      >
        <BookmarkBorderOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
      </Tooltip>
    );
  };
  const renderVacationIcon = () => {
    if (!item.vacation) {
      return;
    }
    return (
      <Tooltip title={getTooltipTitle()} placement='top'>
        {item.vacation.status === 'upcoming' ? (
          <InsertInvitationTwoTone />
        ) : (
          <EventAvailableTwoTone />
        )}
      </Tooltip>
    );
  };

  return (
    <div style={{ display: 'flex', gap: 12 }}>
      <Tooltip title={projects.map((project) => project.name).join(', ')}>
        <Chip
          label={renderValue?.name}
          size='small'
          className={classNames(
            'col-span',
            `c-list__status-bench-${employeeStatus}`
          )}
        />
      </Tooltip>
      {probationStatus && probationStatus !== ProbationStatuses.FINISHED && (
        <Tooltip title={getMessage(probationStatus, probationDueDate)}>
          {probationStatus === ProbationStatuses.IN_PROGRESS ? (
            <AccessTimeFilled
              style={{
                color: isProbationExpired
                  ? '#EF7530'
                  : 'rgba(163, 216, 241, 1)',
              }}
            />
          ) : probationStatus === ProbationStatuses.FAILED ? (
            probationStatus === ProbationStatuses.FAILED && (
              <Dangerous color='error' />
            )
          ) : (
            ''
          )}
        </Tooltip>
      )}
      {isVisible && renderTagIcon()}
      {renderVacationIcon()}
    </div>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export const CellDismissed = withCellWrapper(Cell);
