import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { InputLabel, TextField } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

interface IProps extends BaseTextFieldProps {
  value: DateTime | null;
  setValue: (value: DateTime | null) => void;
}

export default function DatePickerValue({
  value,
  setValue,
  ...mainParams
}: IProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        disablePast
        inputFormat='yyyy-MM-dd'
        renderInput={(params) => (
          <div style={{ width: '50%' }}>
            <InputLabel sx={{ fontSize: 13 }}>Due date</InputLabel>
            <TextField
              {...params}
              {...mainParams}
              margin='dense'
              id='name'
              variant='outlined'
              sx={{ width: '100%' }}
            />
          </div>
        )}
      />
    </LocalizationProvider>
  );
}
