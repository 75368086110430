import { Link } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import { EmployeeManager, IEmployee } from '../../api/apiSlice.types';
import { Grid, Typography } from '@mui/material';

interface IContactLinkProps {
  employee: IEmployee['manager'] | EmployeeManager;
  icon: ReactNode;
  title?: string;
}

// TODO: refactor this, too much edit modes here
export const ContactLink: FC<IContactLinkProps> = ({
  title = 'Report to: ',
  employee,
  icon,
}) => {
  return (
    <Grid item container>
      {icon && (
        <span style={{ display: 'inline-flex', paddingRight: '8px' }}>
          {icon}
        </span>
      )}
      <Typography color={'#4b4b4b'}>
        {title}
        <Link to={`/employees/${employee.id}/`}>{employee.name}</Link>
      </Typography>
    </Grid>
  );
};
