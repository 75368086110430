import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './employee-skills.css';
import BasicEmployeeInfo from '../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import { ListToolbar } from '../../../common/list-toolbar/list-toolbar';
import { recursiveSearch } from '../../../../utils/recursiveSearch';
import usePrompt from '../../../common/Guards/usePrompt';
import {
  createCategoriesData,
  prepareSkillsToAPI,
  removeCategorySkill,
  removeInitialSkill,
  updateCategorySkillItems,
  updateSkillItems,
} from './utils';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';
import { getButtonsTemplate, getListToolbarButtons } from './config';
import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import { AddSkillsModal } from './AddSkillsModal';
import {
  selectEmployeeScope,
  selectEmployeeZone,
} from '../../slices/employeesSlice';
import { canEditSupervisorInfo } from '../../../../utils/permissions/checkAccess';
import { SkillsList } from './SkillsList';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  useGetEmployeeQuery,
  useGetSkillLevelsQuery,
  useGetUserSkillsQuery,
  useUpdateSkillsMutation,
} from '../../api/apiSlice';
import { formatDate } from '../../../../utils/formatDate';
import { EMPTY_VALUE } from '../EmployeeProfile/utils';

export const EmployeeSkills = ({ isCurrentUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [updateSkillsMutation] = useUpdateSkillsMutation();
  const { data: skills = [], isFetching } = useGetUserSkillsQuery(id);
  const { data: options = [] } = useGetSkillLevelsQuery();

  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [openedLists, handleOpenList] = useState({});
  const [isEditMode, setEditMode] = useState(false);
  const [dataWasTouched, setTouched] = useState(false);
  const [initialSkills, setInitialSkills] = useState([]);
  const [employeeMainTechs, setEmployeeMainTechs] = useState([]);

  const scope = useSelector(selectEmployeeScope);
  const zone = useSelector(selectEmployeeZone);

  const { data: employee } = useGetEmployeeQuery(Number(id));

  useEffect(() => {
    setInitialSkills(skills);
    const employeeMainTechs = skills?.filter(
      (skill) => skill?.current_main_tech
    );

    setEmployeeMainTechs(employeeMainTechs);
    const categories = createCategoriesData(skills);
    defaultOpenLists(categories);
    setCategories(categories);

    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${id}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
      dispatch(closeDialog(AddSkillsModal.NAME));
    };
  }, [isFetching]);

  usePrompt('Changes you made will not be saved.', dataWasTouched);

  const defaultOpenLists = (categories) => {
    const openedLists = categories.reduce((acc, item, idx) => {
      acc[idx] = true;
      return acc;
    }, {});
    handleOpenList(openedLists);
  };

  const searchSkills = (searchValue) => setSearchValue(searchValue.trim());

  const closeDeleteSkillDialog = (dialogName) =>
    dispatch(closeDialog(dialogName));

  const confirmDeleteSkill = (data) => (dialogName) => {
    setCategories(removeCategorySkill(data, categories));
    setInitialSkills(removeInitialSkill(data, initialSkills));
    closeDeleteSkillDialog(dialogName);
    setTouched(true);
  };
  const confirmCancel = (dialogName) => {
    dispatch(closeDialog(dialogName));
    setEditMode(false);
    setTouched(false);
  };

  const updateSkillItem = (key) => (editedItem) => {
    setCategories(
      updateCategorySkillItems(editedItem, categories, key, options)
    );
    const newInitialSkills = updateSkillItems(
      editedItem,
      initialSkills,
      key,
      options
    );
    setInitialSkills(newInitialSkills);
    const newEmployeMainTechs = newInitialSkills?.filter(
      (skill) => skill?.current_main_tech
    );
    setEmployeeMainTechs(newEmployeMainTechs);
    setTouched(true);
  };

  const onAddSkillsConfirm = (skills) => {
    const newSkills = [...initialSkills, ...skills];
    const categories = createCategoriesData(newSkills);
    openNewlyAddedCategories(categories);
    setInitialSkills(newSkills);
    setCategories(categories);
    dispatch(closeDialog(AddSkillsModal.NAME));
    setTouched(true);
  };
  const showAddSkillsModal = () =>
    dispatch(
      showDialog(AddSkillsModal, {
        onSubmit: onAddSkillsConfirm,
        initialSkills,
      })
    );

  const moveToQuestionary = () => {
    navigate(`/employees/${id}/skills-questionnaire`);
  };
  const onSubmitChanges = async () => {
    if (dataWasTouched) {
      const skills = prepareSkillsToAPI(initialSkills);
      await updateSkillsMutation({
        userId: id,
        skills,
        isQuestionnaire: false,
      });
    }
    setEditMode(false);
    setTouched(false);
  };
  const onCancel = () => {
    if (dataWasTouched) {
      return dispatch(
        showDialog(ConfirmationDialog, {
          dialogHeader: 'Are you sure you want to cancel editing?',
          dialogTitle: 'Changes you made will not be saved.',
          closeDialog: closeDeleteSkillDialog,
          confirmDialog: confirmCancel,
        })
      );
    }
    setEditMode(false);
  };
  const onEdit = () => setEditMode(true);

  const toggleList = (idx) =>
    handleOpenList({
      ...openedLists,
      [idx]: !openedLists[idx],
    });

  const skillsUpdatedDate = (modifiedCategories) => {
    const latestSkillUpdateDate = modifiedCategories
      .flat()
      .reduce((acc, item) => {
        if (acc === null) {
          return item.updated_at;
        }
        if (new Date(acc) < new Date(item.updated_at)) {
          return item.updated_at;
        }
        return acc;
      }, null);

    return (
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Alert
          severity='info'
          sx={{ padding: '0 15px' }}
          icon={<InfoOutlinedIcon fontSize={'small'} />}
        >
          Questionary updated{' '}
          {employee.questionnaire_updated_at
            ? formatDate(employee.questionnaire_updated_at)
            : EMPTY_VALUE}
        </Alert>
        <Alert
          severity='info'
          sx={{ padding: '0 15px' }}
          icon={<InfoOutlinedIcon fontSize={'small'} />}
        >
          Skills updated{' '}
          {latestSkillUpdateDate
            ? formatDate(latestSkillUpdateDate)
            : EMPTY_VALUE}
        </Alert>
      </Box>
    );
  };

  const lastSkillUpdateDate = useMemo(() => {
    return skillsUpdatedDate(
      categories
        .map(
          ({ employee_skill_assessments_list }) =>
            employee_skill_assessments_list
        )
        .flat()
    );
  }, [categories, employee]);

  const renderCategories = () => {
    const recSearch = recursiveSearch(
      categories,
      searchValue,
      'employee_skill_assessments_list',
      ['self_assessed_level', 'manager_assessed_level']
    );

    if (!recSearch.length) {
      return (
        <div className='list__no-item--border skill_items__empty'>No items</div>
      );
    }

    return recSearch.map((item, idx) => {
      return (
        <SkillsList
          key={idx}
          idx={idx}
          category={item.name}
          items={item.employee_skill_assessments_list}
          employeeMainTechs={employeeMainTechs}
          isOpen={openedLists[idx]}
          onToggle={(idx) => toggleList(idx)}
          isCurrentUser={isCurrentUser}
          isEditMode={isEditMode}
          updateSkillItem={updateSkillItem}
          confirmDeleteSkill={confirmDeleteSkill}
          scope={scope}
          zone={zone}
          options={options}
          header={idx === 0 ? lastSkillUpdateDate : null}
        />
      );
    });
  };

  const openNewlyAddedCategories = (newCategories) => {
    const reworkedIdx = newCategories.reduce((acc, item, idx) => {
      if (!acc.hasOwnProperty(idx)) {
        acc[idx] = true;
      }
      return acc;
    }, openedLists);
    handleOpenList(reworkedIdx);
  };

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={300}
      hideTracksWhenNotNeeded
      className='skill__wrapper_paddings'
    >
      <BasicEmployeeInfo
        buttonsTemplate={getButtonsTemplate(
          onSubmitChanges,
          onCancel,
          onEdit,
          isEditMode,
          canEditSupervisorInfo(zone) || isCurrentUser
        )}
      />
      <ListToolbar
        onSearchClick={searchSkills}
        searchPlaceholder='Search Skills'
        buttonsTemplate={getListToolbarButtons(
          showAddSkillsModal,
          moveToQuestionary,
          isCurrentUser,
          isEditMode
        )}
      />
      {renderCategories()}
    </Scrollbars>
  );
};

EmployeeSkills.propTypes = {
  isCurrentUser: PropTypes.bool.isRequired,
};
