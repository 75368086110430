import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { ILocationReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import TooltipRenderer from './ChartTooltip';

type DailyStars = {
  date: string;
  count: number;
};
type Series = {
  label: string;
  data: DailyStars[];
};

export function ChartAppLocation({
  data,
}: {
  data: ILocationReportResponse[];
}) {
  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.date,
      position: 'left',
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.count,
        formatters: {
          scale: (value: any) => {
            return `${Math.round(value)}`;
          },
        },
        stacked: true,
        position: 'bottom',
      },
    ],
    []
  );

  const dataResult = useMemo(() => {
    return data?.reduce((acc: Series[], elem: ILocationReportResponse) => {
      elem?.locations?.forEach((lock) => {
        if (!acc.find((el) => el.label === lock.location)) {
          acc.push({
            label: lock.location,
            data: data
              .map(({ date, locations }) => ({
                count:
                  locations.find((el) => el.location === lock.location)
                    ?.count || 0,
                date,
              }))
              .reverse(),
          });
        }
      });
      return acc;
    }, []);
  }, [data]);

  return dataResult?.length > 0 ? (
    <Chart
      options={{
        data: dataResult,
        primaryAxis,
        secondaryAxes,
        tooltip: {
          render: (tooltipItem) => <TooltipRenderer {...tooltipItem} />,
        },
      }}
    />
  ) : (
    <div>No data</div>
  );
}
