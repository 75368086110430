import { styled } from '@mui/system';

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: 'white',
  zIndex: 1,
  fontSize: 12,
}));
export const GroupItems = styled('ul')({
  padding: 0,
  fontSize: 14,
});
