import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: localStorage.getItem('userEmail'),
  employeeId: Number(localStorage.getItem('employeeId')),
  token: !!localStorage.getItem('AuthToken'),
  authToken: localStorage.getItem('AuthToken'), //todo: this one is unused. remove
  avatar: localStorage.getItem('avatar'),
  office: JSON.parse(localStorage.getItem('office')),
  hasSubordinates: JSON.parse(localStorage.getItem('hasSubordinates')),
  hasSupervised: JSON.parse(localStorage.getItem('hasSupervised')),
  permissions: JSON.parse(localStorage.getItem('permissions')),
  user_scope_access:
    JSON.parse(localStorage.getItem('user_scope_access')) || [],
  isLogoutByHand: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState, // todo: make it "lazy initializer " https://redux-toolkit.js.org/api/createSlice#initialstate
  reducers: {
    setAuthData: (state, action) => {
      state.email = action.payload.user.username;
      state.employeeId = action.payload.user.employee_id;
      state.token = !!action.payload.token; // todo: rename to isToken or isAuthenticated
      state.authToken = action.payload.token;
      state.avatar = action.payload.user.avatar_url;
      state.office = action.payload.user.office;
      state.hasSubordinates = action.payload.user.has_subordinates;
      state.hasSupervised = action.payload.user.has_supervised;
      state.permissions = action.payload.user.permissions;
      state.user_scope_access = action.payload.user.user_scope_access || [];
      state.isLogoutByHand = false;
    },
    deleteAuthData: (state) => {
      state.email = null;
      state.employeeId = null;
      state.token = null;
      state.authToken = null;
      state.avatar = null;
      state.office = null;
      state.hasSubordinates = null;
      state.hasSupervised = null;
      state.permissions = null;
      state.user_scope_access = null;
    },
    setFlagLogout: (state) => {
      state.isLogoutByHand = true;
    },
  },
});

export const { setAuthData, deleteAuthData, setFlagLogout } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthToken = (store) => store.authReducer.token;
export const selectIsLogoutByHand = (store) => store.authReducer.isLogoutByHand;
export const selectEmployeeId = (store) => store.authReducer.employeeId;
export const selectOffice = (store) => store.authReducer.office;

export const selectEmail = (store) => store.authReducer.email;
export const selectAvatar = (store) => store.authReducer.avatar;
export const selectHasSubordinates = (store) =>
  store.authReducer.hasSubordinates;
export const selectHasSupervised = (store) => store.authReducer.hasSupervised;
export const selectPermissions = (store) => store.authReducer.permissions;
export const selectAccessScope = (store) => store.authReducer.user_scope_access;
