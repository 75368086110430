export const phone = (
  <svg
    width='16'
    height='15'
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.675 10.3167C13.65 10.3167 12.6583 10.15 11.7333 9.85C11.4417 9.75 11.1167 9.825 10.8917 10.05L9.58333 11.6917C7.225 10.5667 5.01667 8.44167 3.84167 6L5.46667 4.61667C5.69167 4.38333 5.75833 4.05833 5.66667 3.76667C5.35833 2.84167 5.2 1.85 5.2 0.825C5.2 0.375 4.825 0 4.375 0H1.49167C1.04167 0 0.5 0.2 0.5 0.825C0.5 8.56667 6.94167 15 14.675 15C15.2667 15 15.5 14.475 15.5 14.0167V11.1417C15.5 10.6917 15.125 10.3167 14.675 10.3167Z'
      fill='#EF7530'
    />
  </svg>
);
