import { DateTime } from 'luxon';
import { Grid, Tooltip } from '@mui/material';
import { EMPTY_VALUE } from '../utils';
import TodayIcon from '@mui/icons-material/Today';
import { formatDate } from '../../../../../utils/formatDate';

const getYearsInCompany = (start_date_in_company) => {
  if (!start_date_in_company) {
    return EMPTY_VALUE;
  }
  const startDate = DateTime.fromISO(start_date_in_company);
  const currentDate = DateTime.now();
  const diff = currentDate.diff(startDate, ['years', 'months', 'days']);

  if (diff.years === 1) {
    return Math.floor(diff.years) + ' year with Akvelon';
  }
  if (diff.years > 1) {
    return `${Math.floor(diff.years)} years with Akvelon`;
  }
  if (diff.months === 1) {
    return Math.floor(diff.months) + ' month with Akvelon';
  }
  if (diff.months > 1) {
    return Math.floor(diff.months) + ' months with Akvelon';
  }
  return `${Math.floor(diff.days)} days with Akvelon`;
};

export const YearsInAkvelon = ({ startDate }) => {
  if (!startDate) {
    return null;
  }
  return (
    <Grid item container xs={'auto'} spacing={1}>
      <Grid item>
        <Tooltip title={`Start Date: ${formatDate(startDate)}`}>
          <TodayIcon fontSize={'small'} color={'primary'} />
        </Tooltip>
      </Grid>
      <Grid item>{getYearsInCompany(startDate)}</Grid>
    </Grid>
  );
};
