import classNames from 'classnames';

export const withCellWrapper = (WrappedComponent, additionalClassName = '') => {
  const Component = (props) => {
    const { column, config } = props;
    const columnSize = column.className ? column.className : 'small-col';
    const cellWithPermission =
      typeof config[column.Id].isHaveRights !== 'undefined';
    if (cellWithPermission && !config[column.Id].isHaveRights) {
      return null;
    }
    return (
      <div
        className={classNames(
          `c-list__item-column ${additionalClassName}`,
          columnSize
        )}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };

  return Component;
};
