import { ScopeType } from '../../../../utils/permissions/types';
import {
  canViewDCAndSubRadio,
  canViewSubRadio,
  canViewSupervisedRadio,
} from '../../../../utils/permissions/checkAccess';

export const getRadioButtonGroupOptions = (
  scope: ScopeType,
  hasSubordinates: boolean,
  hasAccessToHR: boolean,
  hasSupervised: boolean
) => {
  const defaultRadio = [{ label: 'All employees', value: 'all' }];

  if (canViewDCAndSubRadio(scope)) {
    if (hasSubordinates) {
      defaultRadio.push({ label: 'Subordinates', value: 'subordinates' });
    }
    defaultRadio.push({ label: 'Delivery Center', value: 'dc' });
  } else if (canViewSubRadio(scope) && hasSubordinates) {
    defaultRadio.push({ label: 'Subordinates', value: 'subordinates' });
  }

  if (hasAccessToHR) {
    defaultRadio.push({ label: 'HR', value: 'hr' });
  }

  if (canViewSupervisedRadio(scope) && hasSupervised) {
    defaultRadio.push({ label: 'Supervised', value: 'supervised' });
  }

  if (defaultRadio.length === 1) {
    return [];
  }

  return defaultRadio;
};
