export const archived = (
  <svg
    width='21'
    height='18'
    viewBox='0 0 21 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9167 0.75C7.36092 0.75 3.66675 4.44417 3.66675 9H0.916748L4.48258 12.5658L4.54675 12.6942L8.25008 9H5.50008C5.50008 5.4525 8.36925 2.58333 11.9167 2.58333C15.4642 2.58333 18.3334 5.4525 18.3334 9C18.3334 12.5475 15.4642 15.4167 11.9167 15.4167C10.1476 15.4167 8.54342 14.6925 7.38842 13.5283L6.08675 14.83C7.58091 16.3242 9.63425 17.25 11.9167 17.25C16.4726 17.25 20.1667 13.5558 20.1667 9C20.1667 4.44417 16.4726 0.75 11.9167 0.75ZM11.0001 5.33333V9.91667L14.9234 12.245L15.5834 11.1358L12.3751 9.22917V5.33333H11.0001Z'
      fill='#9B9B9B'
    />
  </svg>
);
