import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { ChecklistItemInterface } from '../../EmployeesPage/api/apiSlice.types';
import { TextField } from './components/TextField';
import { SliderField } from './components/SliderField';
import { CheckboxField } from './components/CheckboxField';
import './checklist.css';

interface Props {
  onChange?: (item: ChecklistItemInterface) => void;
  item: ChecklistItemInterface;
  disabled?: boolean;
}

const componentsMapper = {
  scale: SliderField,
  text: TextField,
  checkbox: CheckboxField,
} as any;

export const ChecklistItem: FC<Props> = ({ onChange, item, disabled }) => {
  const handleChange = (data: ChecklistItemInterface) => {
    if (onChange) {
      onChange({ ...item, ...data });
    }
  };

  const Input = componentsMapper[item.question_type];
  if (!Input) {
    return null;
  }

  return (
    <div className='checklist_item'>
      <Grid container spacing={2} alignItems='flex-start'>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>{item.question}</Typography>
        </Grid>
        <Grid container direction='row'>
          <Input disabled={disabled} item={item} onChange={handleChange} />
        </Grid>
      </Grid>
    </div>
  );
};
