import * as React from 'react';
import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Stack,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { ITask, OnUpdateTaskStatusParams } from '../../../api/apiSlice.types';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { AddTaskModal } from './AddTaskModal';
import {
  useAddTaskMutation,
  useEditTaskStatusMutation,
} from '../../../api/apiSlice';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

type IProps = {
  tasks?: ITask[];
  setTasks?: React.Dispatch<React.SetStateAction<ITask[]>>;
  isOwnProfile?: boolean;
  feedbackRisk?: string;
  visibility?: string;
};

export const TasksList = ({
  tasks,
  setTasks,
  isOwnProfile,
  feedbackRisk,
  visibility,
}: IProps) => {
  const dispatch = useDispatch();
  const { id, feedbackId } = useParams();

  const isCreate = !feedbackId;
  const isFeedbackResolved = feedbackRisk === 'resolved';

  const [addTaskMutation] = useAddTaskMutation();
  const [editTaskStatus] = useEditTaskStatusMutation();

  useEffect(() => {
    return () => {
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  const addTask = async (newTask: ITask) => {
    const newData = {
      due_date: newTask.due_date,
      short_summary: newTask.short_summary,
      owner_id: newTask.owner.id,
    };

    await addTaskMutation({
      employeeId: Number(id),
      feedbackId: Number(feedbackId),
      data: newData,
    }).unwrap();
  };

  const addTaskToList = (newTask: ITask) => {
    if (setTasks) {
      const newData = {
        due_date: newTask.due_date,
        short_summary: newTask.short_summary,
        owner: newTask.owner,
      };
      setTasks((prevState) => {
        return [...prevState, newData];
      });
    }
  };

  const onRemoveClick = (value: ITask) => {
    dispatch(
      // @ts-ignore
      showDialog(ConfirmationDialog, {
        dialogHeader: 'Delete Task',
        dialogTitle: 'Are you sure that you want to delete this task?',
        closeDialog: (dialogName: string) => dispatch(closeDialog(dialogName)),
        confirmDialog: (dialogName: string) => {
          dispatch(closeDialog(dialogName));
          setTasks?.((prevState) => {
            return prevState.filter((item) => !isEqual(item, value));
          });
        },
      })
    );
  };

  const switchTaskStatus = (
    currentStatus: string
  ): OnUpdateTaskStatusParams => {
    if (currentStatus === 'created') {
      return {
        status: 'finished',
      };
    }
    return {
      status: 'created',
    };
  };

  const onChangeStatus = debounce(async (taskData) => {
    await editTaskStatus({
      employeeId: Number(id),
      feedbackId: Number(feedbackId),
      taskId: taskData.id,
      data: switchTaskStatus(taskData.status),
    });
  }, 100);

  const columns = useMemo<GridColumns<ITask>>(
    () => [
      {
        field: 'short_summary',
        headerName: 'Short summary',
        width: 350,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title={`${params.row.short_summary}`} arrow>
            <Typography
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              fontSize={14}
            >
              {params.row.short_summary}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 130,
        sortable: false,
        valueFormatter: (params) => DateTime.fromISO(params.value).toISODate(),
      },
      {
        field: 'owner',
        headerName: 'Owner',
        width: 200,
        sortable: false,
        renderCell: (params) => (
          <Link
            to={`/employees/${params.row.owner?.id}`}
            style={{ textDecoration: 'none' }}
          >
            <Typography color='#1976D2' fontSize={14}>
              {params.row.owner?.name}
            </Typography>
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: !isCreate ? 'Status' : '',
        width: 80,
        sortable: false,
        renderCell: ({ row }) => (
          <Checkbox
            sx={{ visibility: isCreate ? 'hidden' : 'visible' }}
            checked={row.status === 'created' ? false : true}
            onChange={() => onChangeStatus(row)}
            disabled={isFeedbackResolved}
          />
        ),
      },
      ...(isCreate
        ? ([
            {
              type: 'actions',
              width: 50,
              renderCell: (params) => (
                <IconButton
                  onClick={() => {
                    onRemoveClick(params.row);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ] as GridColumns)
        : []),
    ],
    [tasks]
  );

  return (
    <section style={{ marginBottom: '24px' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <h1>Tasks </h1>
        {!isOwnProfile && (
          <AddTaskModal
            onSubmitTask={isCreate ? addTaskToList : addTask}
            showAddButton={!isFeedbackResolved}
            visibility={visibility}
          />
        )}
      </Stack>
      <DataGrid
        columns={columns}
        rows={tasks || []}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        autoHeight
        getRowId={(row) =>
          `${row.short_summary}${row.due_date}${row.id}${JSON.stringify(
            row.owner
          )}`
        }
      />
    </section>
  );
};
