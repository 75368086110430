import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DataTable } from './Table/Table';
import BasicEmployeeInfo from '../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import {
  useGetFeedbackFiltersQuery,
  useGetFeedbacksQuery,
} from '../../api/apiSlice';
import { selectEmployeeZone } from '../../slices/employeesSlice';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import { EmployeeFeedbackListItem } from '../../api/apiSlice.types';
import { DateTime } from 'luxon';
import { canCreateFeedback } from '../../../../utils/permissions/feedbacks';

export const Feedbacks = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const zone = useSelector(selectEmployeeZone);

  const { data: feedbacks, isLoading } = useGetFeedbacksQuery(Number(id));
  const { data: feedbackFilters, isLoading: isDictionaryLoading } =
    useGetFeedbackFiltersQuery();

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${id}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const getRows = (feedbacks: EmployeeFeedbackListItem[] | undefined) => {
    if (!feedbacks || !feedbackFilters) {
      return;
    }
    return feedbacks.map((feedback) => ({
      id: feedback.id,
      type: feedbackFilters?.type.find(
        (type: { id: number; name: string }) =>
          type.id === +feedback.feedback_type
      ).name, // todo: refactor
      risk: feedbackFilters?.risk.find(
        (risk: { id: string; name: string }) => risk.id === feedback?.risk_level
      ),
      editor: feedback.modified_by?.name,
      created: DateTime.fromISO(feedback.created),
      is_external: feedback?.is_external,
      feedback_date: feedback?.feedback_date,
    }));
  };

  const isAllowedToCreate = canCreateFeedback(zone);
  return (
    <>
      <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
        <BasicEmployeeInfo />
        <Box
          sx={{
            paddingRight: '36px',
            paddingLeft: '36px',
            paddingBottom: '64px',
          }}
        >
          {/*todo: create a common component if there will be more than 3 headers like this*/}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ mb: 2 }}
          >
            <Typography variant='h6'>Employee feedbacks</Typography>
            {isAllowedToCreate && (
              <Link to={'create'} state={{ pathname: location.pathname }}>
                <button className='button__main'>+ Add Feedback</button>
              </Link>
            )}
          </Stack>
          <DataTable
            rows={getRows(feedbacks)}
            isLoading={isLoading || isDictionaryLoading}
          />
        </Box>
      </Scrollbars>
    </>
  );
};
