import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Chip, Stack, Typography, Link as LinkMui } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  IGoalInState,
  OnSubmitCDPGoalParams,
} from '../../../api/apiSlice.types';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { AddGoal } from './AddGoal';
import {
  useAddGoalMutation,
  useDeleteGoalMutation,
} from '../../../api/apiSlice';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { EditGoal } from './EditGoal';
import { GoalCommentIndependent } from './GoalCommentIndependent';
import { GoalComment } from './GoalComment';
import { EditGoalForm } from './EditGoalForm';
import { ViewGoalModal } from './ViewGoalModal';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';

type IProps = {
  goals?: IGoalInState[];
  mentorId?: number;
  setGoals?: React.Dispatch<React.SetStateAction<IGoalInState[]>>;
  isOwnProfile?: boolean;
  onChange?: () => void;
};

export const GoalSection = ({
  goals = [],
  mentorId,
  setGoals,
  isOwnProfile,
  onChange,
}: IProps) => {
  const dispatch = useDispatch();
  const { cdpId } = useParams();

  const isCreate = !cdpId;

  const [addGoalMutation] = useAddGoalMutation();
  const [deleteGoalMutation] = useDeleteGoalMutation();

  useEffect(() => {
    return () => {
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  const addGoal = async (newGoal: OnSubmitCDPGoalParams) => {
    const newData = {
      short_summary: newGoal.short_summary,
      due_date: newGoal.due_date,
      mentor_id: newGoal.mentor.id,
      actions: newGoal.actions,
    };
    await addGoalMutation({ cdpId: Number(cdpId), data: newData }).unwrap();
  };

  const addGoalToList = (newGoal: OnSubmitCDPGoalParams) => {
    if (setGoals) {
      const newData = {
        short_summary: newGoal.short_summary,
        due_date: newGoal.due_date,
        mentor: { id: newGoal.mentor.id, name: newGoal.mentor.name },
        actions: newGoal.actions,
      };
      setGoals((prevState) => [...prevState, { ...newData, id: Date.now() }]);

      onChange?.();
    }
  };

  const onEditClick = (goalId: number) => {
    setGoalId(goalId);
    setIsEditGoalOpened(true);
  };

  const onRemoveClick = (goalId: number) => {
    dispatch(
      // @ts-ignore
      showDialog(ConfirmationDialog, {
        dialogHeader: 'Delete Goal',
        dialogTitle: 'Are you sure that you want to delete this record?',
        closeDialog: (dialogName: string) => dispatch(closeDialog(dialogName)),
        confirmDialog: (dialogName: string) => {
          dispatch(closeDialog(dialogName));
          if (setGoals) {
            setGoals((prevState) =>
              prevState.filter((item) => item.id !== goalId)
            );
          } else {
            deleteGoalMutation({ cdpId: Number(cdpId), goalId });
          }
        },
      })
    );
  };

  const saveCommentToStore = (comment: string, goalId: number) => {
    if (setGoals) {
      setGoals((prevState) =>
        prevState.map((goal) =>
          goal.id === goalId ? { ...goal, comment } : goal
        )
      );
    }
  };

  const [isCloseTrigger, setIsCloseTrigger] = useState<number | undefined>(
    undefined
  );

  const columns = useMemo<GridColumns<IGoalInState>>(
    () => [
      {
        field: 'short_summary',
        headerName: 'Short Summary',
        width: 350,
        sortable: false,
        renderCell: (params) => {
          return (
            <LinkMui
              onClick={() => {
                setShowViewGoal(params.row);
              }}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Typography color='#1976D2' fontSize={14}>
                {params.row.short_summary}
              </Typography>
            </LinkMui>
          );
        },
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 130,
        sortable: false,
        valueFormatter: (params) => {
          return DateTime.fromISO(params.value).toISODate();
        },
      },
      {
        field: 'mentor',
        headerName: 'Mentor',
        width: 200,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link
              to={`/employees/${params.row.mentor?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography color='#1976D2' fontSize={14}>
                {params.row.mentor?.name}
              </Typography>
            </Link>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 450,
        sortable: false,
      },
      ...(!isOwnProfile
        ? ([
            {
              field: 'comment',
              headerName: 'Comments',
              width: 130,
              sortable: false,
              renderCell: ({ row, value }) => {
                return isCreate ? (
                  <GoalComment
                    goalComment={value}
                    onSubmit={(comment) => saveCommentToStore(comment, row?.id)}
                    title='Add Comment'
                  />
                ) : (
                  <GoalCommentIndependent
                    goalId={row?.id}
                    cdpId={Number(cdpId)}
                    goalComment={value}
                    isCloseTrigger={isCloseTrigger}
                    setIsCloseTrigger={setIsCloseTrigger}
                  />
                );
              },
            },
          ] as GridColumns)
        : []),
      ...(!isOwnProfile
        ? ([
            {
              field: 'status',
              headerName: 'Status',
              width: 80,
              sortable: false,
              renderCell: ({ row }) => (
                <Chip
                  label={!row?.is_closed ? 'Open' : 'Closed'}
                  color='success'
                  size='small'
                  sx={{
                    backgroundColor: !row?.is_closed ? '#1CB18E' : '#D1D1D1',
                  }}
                />
              ),
            },
          ] as GridColumns)
        : []),
      ...(!isOwnProfile
        ? ([
            {
              field: 'actionMenu',
              type: 'actions',
              width: 50,
              getActions: (params: GridRowParams) =>
                !params?.row?.is_closed
                  ? [
                      <GridActionsCellItem
                        icon={<EditOutlinedIcon />}
                        onClick={() => onEditClick(params.row.id)}
                        label='Edit'
                        showInMenu
                      />,
                      goals?.length > 1 ? (
                        <GridActionsCellItem
                          icon={<DeleteOutlineIcon />}
                          onClick={() => onRemoveClick(params.row.id)}
                          label='Delete'
                          showInMenu
                        />
                      ) : (
                        <></>
                      ),
                      !isCreate && !params?.row?.is_closed ? (
                        <GridActionsCellItem
                          icon={<CloseIcon />}
                          onClick={() => setIsCloseTrigger(params?.row?.id)}
                          label='Close'
                          showInMenu
                        />
                      ) : (
                        <></>
                      ),
                    ]
                  : [],
            },
          ] as GridColumns)
        : []),
    ],
    [isCloseTrigger, goals]
  );

  const [isEditGoalOpened, setIsEditGoalOpened] = React.useState(false);
  const [goalId, setGoalId] = React.useState<number>();
  const [showViewGoal, setShowViewGoal] = React.useState<IGoalInState | null>(
    null
  );

  return (
    <section>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <h1>CDP Goals </h1>
        {!isOwnProfile && (
          <AddGoal
            mentorId={mentorId}
            onSubmitCDPGoal={isCreate ? addGoalToList : addGoal}
          />
        )}
      </Stack>
      <ViewGoalModal
        isOwnProfile={isOwnProfile}
        goal={showViewGoal}
        closeModal={setShowViewGoal}
      />
      <DataGrid
        columns={columns}
        rows={goals}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        autoHeight
      />
      {cdpId && goalId && (
        <EditGoal
          cdpId={Number(cdpId)}
          goalId={goalId}
          open={isEditGoalOpened}
          setOpen={setIsEditGoalOpened}
        />
      )}
      {isCreate && goals && setGoals && (
        <EditGoalForm
          goalData={goals?.find((goal) => goal.id === goalId)}
          handleSubmit={(values) => {
            setGoals((prevState) => {
              const newValues = {
                short_summary: values.shortSummary,
                due_date: values.dueDate.toISODate(),
                mentor: values.mentor,
                actions: values.actions,
              };
              return prevState.map((goal) => {
                if (goal.id === goalId) {
                  return { ...goal, ...newValues };
                }
                return goal;
              });
            });
          }}
          open={isEditGoalOpened}
          setOpen={setIsEditGoalOpened}
        />
      )}
    </section>
  );
};
