import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export const useActions = (actions, memoizationDeps = []) => {
  const dispatch = useDispatch();
  return useMemo(
    () => actions.map((a) => bindActionCreators(a, dispatch)),
    [dispatch, ...memoizationDeps]
  );
};
