import EventIcon from '@mui/icons-material/Event';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, IconButton } from '@mui/material';
import { useState } from 'react';
import { DateTime } from 'luxon';

interface IClearableDatePicker {
  value: DateTime | null;
  onChange: (date: DateTime | null) => void;
  maxDate?: DateTime | null;
  minDate?: DateTime | null;
  label: string;
}

// todo: move it to useDatePicker hook when it will be merged
export const ClearableDatePicker = ({
  value,
  onChange,
  label,
  maxDate,
  minDate,
}: IClearableDatePicker) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onClear = () => onChange(null);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  // todo: useDatePicker already have this validation
  const isValid = (date: DateTime | null) => {
    if (date === null) {
      return true;
    }
    if (!date.isValid) {
      return false;
    }
    if (minDate && date < minDate) {
      return false;
    }
    if (maxDate && date > maxDate) {
      return false;
    }
    return true;
  };

  const handleChange = (date: DateTime | null) => {
    if (isValid(date)) {
      onChange(date);
      setHasError(false);
      return;
    }
    onChange(date);
    setHasError(true);
  };

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={handleChange}
      inputFormat='yyyy-MM-dd'
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderInput={(startProps) => {
        return (
          <TextField
            {...startProps}
            error={hasError}
            InputProps={{
              endAdornment: (
                <div style={{ display: 'flex' }}>
                  <IconButton onClick={onClear}>
                    <ClearIcon />
                  </IconButton>
                  <IconButton onClick={toggleIsOpen}>
                    <EventIcon />
                  </IconButton>
                </div>
              ),
            }}
          />
        );
      }}
      // @ts-ignore
      maxDate={maxDate}
      // @ts-ignore
      minDate={minDate}
    />
  );
};
