import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  useGetBenchSummaryQuery,
  useGetExportBenchFileMutation,
} from '../EmployeesPage/api/apiSlice';
import { OfficeBenchSummary } from '../EmployeesPage/api/apiSlice.types';
import {
  resetComponent,
  setComponent,
} from '../../store/slices/header/headerSlice';
import Export from '../common/Export';

const TOTAL = 'Total Bench';

const columns = [
  {
    field: 'office_name',
    headerName: 'Dev Center',
    sortable: false,
    width: 150,
  },
  {
    field: 'total_count',
    headerName: 'Total Production',
    sortable: false,
    width: 130,
  },
  {
    field: 'billable_count',
    headerName: 'Total Billable',
    sortable: false,
    width: 110,
  },
  {
    field: 'billable_pct',
    headerName: 'Billable, %',
    sortable: false,
    width: 90,
  },
  {
    field: 'internal_count',
    headerName: 'Total Bench',
    sortable: false,
  },
  {
    field: 'internal_pct',
    headerName: 'Current Bench, %',
    sortable: false,
    width: 130,
  },
  {
    field: 'long_internal_count',
    headerName: 'Bench More than 3 Months',
    sortable: false,
    width: 200,
  },
  {
    field: 'long_internal_pct',
    headerName: 'Bench More than 3 Months, %',
    sortable: false,
    width: 210,
  },
  {
    field: 'invested_count',
    headerName: 'Invested',
    sortable: false,
    width: 80,
  },
  {
    field: 'invested_pct',
    headerName: 'Invested, %',
    sortable: false,
  },
];

export const BenchSummary = () => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const dispatch = useDispatch();

  const { data, isLoading } = useGetBenchSummaryQuery({
    not_bench_tags: checked ? undefined : 4,
  });

  const [downloadFile] = useGetExportBenchFileMutation();

  const [rows, setRows] = useState<OfficeBenchSummary[]>([]);

  useEffect(() => {
    dispatch(
      setComponent({
        component: 'Bench Summary',
        isTitle: true,
      })
    );

    return () => {
      dispatch(resetComponent());
    };
  }, []);

  useEffect(() => {
    if (data) {
      const total = { office_name: TOTAL, ...data?.total };
      const rowsWithTotal = [...data?.offices, total];
      setRows(rowsWithTotal);
    }
  }, [data]);
  const filter = !checked
    ? { tagsToExclude: [{ id: 4, name: 'Unpaid vacation' }] }
    : {};
  return (
    <Box px={3} pt={4}>
      <Box
        sx={{
          // todo: rewrite this style
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '8px',
        }}
      >
        <div>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} />}
            label='Show Unpaid'
          />
        </div>
        <Export onDownloadFile={() => downloadFile(filter)} />
      </Box>
      <Box
        sx={{
          '& .total-row': {
            backgroundColor: '#F1EDE9',
            '& [data-field="office_name"]': {
              fontWeight: 600,
            },
          },
        }}
      >
        <DataGrid
          columns={columns}
          rows={rows}
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          getRowId={(row) => row?.office_name}
          getRowClassName={(params) =>
            params?.id === TOTAL ? 'total-row' : ''
          }
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};
