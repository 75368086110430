import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useState } from 'react';
import { useGetBenchTagsQuery } from '../../../api/apiSlice';
import { IBenchTag } from '../../../api/apiSlice.types';

interface IBenchTagsEditProps {
  currentValue: IBenchTag[];
  onChange: (bench_tags: IBenchTag[]) => void;
}

export const BenchTagsEdit2 = ({
  currentValue,
  onChange,
}: IBenchTagsEditProps) => {
  const { data: benchTags } = useGetBenchTagsQuery();

  const [tags, setTags] = useState<IBenchTag[]>(currentValue);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      sx={{ width: 450 }}
      size='small'
      options={benchTags || []}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label='Tags' />}
      renderOption={(props, option, { selected }) => (
        <li style={{ padding: 2 }} {...props}>
          <Checkbox checked={selected} />
          {option.name}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={tags}
      onChange={(event: any, newValue: IBenchTag[]) => {
        setTags(newValue);
        onChange(newValue);
      }}
    />
  );
};
