import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider, Grid, Tooltip } from '@mui/material';
import { TabNav } from '../TabNav/TabNav';
import { Badge } from '../../../../common/badges/Badges';
import { formatEmptyValue } from '../../EmployeeProfile/utils';
import { countries } from '../../EmployeeProfile/ProfileInfo/countries';
import { useGetEmployeeQuery } from '../../../api/apiSlice';
import SkeletonComponent from '../../../../common/skeleton';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import './BasicEmployeeInfo.css';

interface Prop {
  withTabs: boolean;
  buttonsTemplate: React.ReactNode[];
}

const BasicEmployeeInfo = ({ withTabs, buttonsTemplate }: Prop) => {
  const { id } = useParams();

  const { data: employee, isLoading } = useGetEmployeeQuery(Number(id));

  const renderAvatarField = () => {
    if (employee) {
      return employee.avatar_url ? (
        <img
          src={employee.avatar_url}
          alt='Avatar'
          style={{ display: 'block' }}
        />
      ) : (
        employee.name && (
          <div className='profile-block__default-avatar'>
            <Badge item={employee.name} />
          </div>
        )
      );
    }
  };

  const getPhysicalLocationString = () => {
    if (employee) {
      const values = employee.employee_physical_location || {
        city: '',
        country: '',
      };
      const fullCountry = countries.find(
        (item) => item.code === values.country
      );
      const countryName = fullCountry?.name || values.country;
      const cityName = values.city || '';
      if (countryName && cityName) {
        return `${countryName}, ${cityName}`;
      }
      return `${countryName} ${cityName}`;
    }
  };

  const renderActions = () => (
    <div className='profile_info__actions_wrap'>
      {buttonsTemplate.map((item, index) => (
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </div>
  );

  return (
    <div
      className={classNames('profile_info', { profile_info__tabs: withTabs })}
    >
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>{withTabs && <TabNav />}</Grid>
        <Grid item>{renderActions()}</Grid>
      </Grid>
      <Grid container alignItems='center' direction='row'>
        <Grid item className='img_wrap'>
          <SkeletonComponent
            isLoading={isLoading}
            styles={{
              variant: 'circular',
              width: 64,
              height: 64,
            }}
            renderedValue={renderAvatarField()}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid alignItems='end' container direction='row' columnSpacing={8}>
            <Grid item md={'auto'}>
              <Grid
                container
                className='employee_name'
                direction='column'
                justifyContent='flex-end'
              >
                <Grid item>
                  <SkeletonComponent
                    isLoading={isLoading}
                    styles={{
                      variant: 'text',
                      width: 150,
                    }}
                    renderedValue={
                      <p style={{ marginTop: 0 }}>{employee?.name}</p>
                    }
                  />
                </Grid>
                <Grid item>
                  <SkeletonComponent
                    isLoading={isLoading}
                    styles={{
                      variant: 'text',
                      width: 150,
                    }}
                    renderedValue={<span>{employee?.title}</span>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={'auto'}>
              <Grid container columnSpacing={4}>
                <Grid item md={'auto'}>
                  <SkeletonComponent
                    isLoading={isLoading}
                    styles={{
                      variant: 'text',
                      width: 150,
                    }}
                    renderedValue={
                      <Grid item container spacing={1} xs='auto'>
                        <Grid item>
                          <Tooltip title='Akvelon email'>
                            <EmailIcon fontSize='small' color='primary' />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <span>{formatEmptyValue(employee?.email)}</span>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item md={'auto'}>
                  <SkeletonComponent
                    isLoading={isLoading}
                    styles={{
                      variant: 'text',
                      width: 150,
                    }}
                    renderedValue={
                      <Grid item container spacing={1} xs='auto'>
                        <Grid item>
                          <Tooltip title='Physical location'>
                            <LocationOnIcon fontSize='small' color='primary' />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <span>
                            {formatEmptyValue(getPhysicalLocationString())}
                          </span>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item md={'auto'}>
                  <SkeletonComponent
                    isLoading={isLoading}
                    styles={{ variant: 'text', width: 150 }}
                    renderedValue={
                      <Grid item container spacing={1} xs='auto'>
                        <Grid item>
                          <Tooltip title='Resource Manager'>
                            <ManageAccountsIcon
                              fontSize='small'
                              color='primary'
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <span>
                            {formatEmptyValue(employee?.manager?.name)}
                          </span>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ margin: '32px 0' }} />
    </div>
  );
};

BasicEmployeeInfo.defaultProps = {
  withTabs: true,
  buttonsTemplate: [],
};

BasicEmployeeInfo.propTypes = {
  withTabs: PropTypes.bool,
  buttonsTemplate: PropTypes.array,
};

export default BasicEmployeeInfo;
