import { FC, MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { getFilterName } from './getFilterName';

interface IProps {
  list: string[];
  onClick: (filterName: string) => void;
  buttonName: string;
}

export const AddButton: FC<IProps> = ({ list, onClick, buttonName }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (filterName: string) => {
    onClick(filterName);
    handleClose();
  };

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color={'inherit'}
      >
        + Add {buttonName}
      </Button>
      <Menu
        id={`basic-menu-${buttonName}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {list.map((filterName) => (
          <MenuItem
            key={filterName}
            onClick={() => handleOptionClick(filterName)}
          >
            {getFilterName(filterName)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
