import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { deleteAuthData } from '../../store/slices/auth/authSlice';
import './LoginPage.css';
import { LoginForm } from './LoginForm';
import { finishRequest } from '../../store/slices/loader/loaderSlice';
import { useGetVersionQuery } from '../EmployeesPage/api/apiSlice';

export const LoginPage = () => {
  const { data: version } = useGetVersionQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deleteAuthData());
    dispatch(finishRequest());
    localStorage.clear();
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <div className='content-wrapper logo-wrapper'>
          {/* todo: replace with SVG */}
          <img
            width='350px'
            src='https://akvelon.com/wp-content/uploads/2019/05/White-e1558101219868.png'
            alt='Logo'
          />
          <div className='version'>{version}</div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className='content-wrapper'>
          <LoginForm />
        </div>
      </Grid>
    </Grid>
  );
};
