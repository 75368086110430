export const checkMark = (
  <svg
    width='14'
    height='11'
    viewBox='0 0 14 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.75005 8.1502L1.60005 5.0002L0.550049 6.05019L4.75005 10.2502L13.75 1.2502L12.7 0.200195L4.75005 8.1502Z'
      fill='#4B4B4B'
    />
  </svg>
);
