import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import BasicEmployeeInfo from '../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import Probation from './Probation/Probation';
import BenchTags from './BenchTags/BenchTags';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import {
  selectEmployeeScope,
  selectEmployeeZone,
} from '../../slices/employeesSlice';
import './EmployeeOrg.css';
import { ScopeType, ZoneType } from '../../../../utils/permissions/types';
import { ResponsibilityAreas } from '../EmployeeProfile/ResponsibilityAreas';
import { ConnectedMembers } from '../EmployeeProfile/ConnectedMembers/ConnectedMembers';
import { EmployeeManagers } from '../EmployeeProfile/EmployeeManagers/EmployeeManagers';
import { Employment } from './Employment';
import { getCanEditOrgPage } from '../../../../utils/permissions/org';

export const EmployeeOrg = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const zone: ZoneType = useSelector(selectEmployeeZone);
  const scope: ScopeType = useSelector(selectEmployeeScope);

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${id}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const canEditOrg = getCanEditOrgPage(zone, scope);

  return (
    <Box overflow='auto'>
      <BasicEmployeeInfo
        buttonsTemplate={[
          canEditOrg && (
            <Link key={0} to={`/employees/${id}/org/edit`}>
              <button className='button__main'>Edit</button>
            </Link>
          ),
        ]}
      />
      <Box className='org_wrapper'>
        <Probation />
        <Divider sx={{ my: 4 }} />
        <ResponsibilityAreas />
        <Divider sx={{ my: 4 }} />
        <ConnectedMembers />
        <EmployeeManagers />
        <Divider sx={{ my: 4 }} />
        <BenchTags />
        <Divider sx={{ my: 4 }} />
        <Employment />
      </Box>
    </Box>
  );
};
