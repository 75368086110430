import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  closeDialog,
  showDialog,
} from '../../store/slices/modalDialog/modalDialogSlice';
import {
  useEditUserMutation,
  useGetManagerChangeRequestsQuery,
} from '../EmployeesPage/api/apiSlice';
import { IManagerChange } from '../EmployeesPage/api/apiSlice.types';
import { ConfirmationDialog } from '../common/modal-dialogs/ConfirmationDialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box } from '@mui/material';
import { DIRECTOR_ZONE } from '../../utils/const-variable';

const requestInitiated = 'request_initiated';
const requestSent = 'request_sent';

const payloadForApprove = { status_change_manager: 'true' };
const payloadForReject = {
  new_manager_for_employee_id: null,
  status_change_manager: null,
};

export const Requests = () => {
  const dispatch = useDispatch();

  const { data, isLoading, isError, isSuccess } =
    useGetManagerChangeRequestsQuery();
  const [editUser] = useEditUserMutation();

  useEffect(() => {
    return () => {
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  const doApprove = ({
    employeeId,
    answer,
  }: {
    employeeId: number;
    answer: boolean;
  }) => {
    editUser({
      id: employeeId,
      data: answer ? payloadForApprove : payloadForReject,
    });
  };

  // @ts-ignore
  const hasRightToChangeManager = data?.zone.includes(DIRECTOR_ZONE);

  const columns = useMemo<GridColumns<IManagerChange>>(
    () => [
      {
        field: 'employeeName',
        headerName: 'Employee',
        width: 200,
        sortable: false,
      },
      {
        field: 'managerName',
        headerName: 'Manager',
        width: 200,
        sortable: false,
      },
      {
        field: 'newManagerName',
        headerName: 'New Manager',
        width: 200,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        sortable: false,
        renderCell: (params) => {
          let message: string = '';

          if (params.value === requestInitiated) {
            message = 'Waiting for approval';
          } else {
            if (params.value === requestSent) {
              message = 'Waiting for sync';
            }
          }
          return message;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<CheckCircleOutlineIcon />}
            label='Approve'
            disabled={
              !hasRightToChangeManager || params.row.status !== requestInitiated
            }
            onClick={() =>
              dispatch(
                // @ts-ignore
                showDialog(ConfirmationDialog, {
                  dialogHeader: 'Manager change',
                  dialogTitle: `Approve manager change to ${params.row.newManagerName}?`,
                  closeDialog: (dialogName: string) =>
                    dispatch(closeDialog(dialogName)),
                  confirmDialog: () => {
                    doApprove({ employeeId: Number(params.id), answer: true });
                    dispatch(closeDialog(ConfirmationDialog.NAME));
                  },
                })
              )
            }
          />,
          <GridActionsCellItem
            icon={<CancelOutlinedIcon />}
            label='Reject'
            disabled={
              !hasRightToChangeManager || params.row.status !== requestInitiated
            }
            onClick={() =>
              dispatch(
                // @ts-ignore
                showDialog(ConfirmationDialog, {
                  dialogHeader: 'Manager change',
                  dialogTitle: `Reject manager change to ${params.row.newManagerName}?`,
                  closeDialog: (dialogName: string) =>
                    dispatch(closeDialog(dialogName)),
                  confirmDialog: () => {
                    doApprove({ employeeId: Number(params.id), answer: false });
                    dispatch(closeDialog(ConfirmationDialog.NAME));
                  },
                })
              )
            }
          />,
        ],
      },
    ],
    [hasRightToChangeManager]
  );

  return (
    <Box m={3} sx={{ width: 1000 }}>
      <h1>Manager Change Requests</h1>
      {isLoading && <div>Loading...</div>}
      {isError && <div>Error</div>}
      {isSuccess && (
        <div>
          <DataGrid
            columns={columns}
            rows={data.results}
            getRowId={(row) => row.employeeId}
            loading={isLoading}
            pageSize={50}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
          />
        </div>
      )}
    </Box>
  );
};
