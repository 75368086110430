import { Button } from '@mui/material';
import CustomIcon from '../icon';
import { Preloader } from '../preloader/preloader';
import { useState } from 'react';

interface IExport {
  onDownloadFile: () => Promise<any>;
}

const Export = ({ onDownloadFile }: IExport) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadHandler = async () => {
    try {
      setIsLoading(true);
      await onDownloadFile();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  return (
    <>
      {isLoading && (
        <Preloader
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            left: 0,
            bottom: 0,
          }}
        />
      )}

      <Button
        onClick={downloadHandler}
        sx={{
          textTransform: 'capitalize',
          color: 'inherit',
          fontSize: '16px',
          minWidth: '100px',
          height: '40px',
          marginLeft: '16px',
        }}
      >
        <CustomIcon
          iconName='export'
          style={{ marginRight: '12px', height: '16px' }}
        />
        Export
      </Button>
    </>
  );
};

export default Export;
