import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import BasicEmployeeInfo from '../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import {
  useGetEmployeeDetailsQuery,
  useGetEmployeesMinimalQuery,
  useGetMentorsMinimalQuery,
  useGetEmployeeOrgInfoQuery,
} from '../../api/apiSlice';
import { useDispatch } from 'react-redux';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import './EmployeeOrg.css';
import { EmployeeOrgEditForm } from './EmployeeOrgEditForm';
import { getBackfillOptions } from './utils/getBackfillOptions';
import { getOptions } from '../EmployeeProfile/ConnectedMembers/utils';
import { Box } from '@mui/material';

export const EmployeeOrgEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data: employee, isSuccess } = useGetEmployeeDetailsQuery(Number(id));
  const { data: employees } = useGetEmployeesMinimalQuery();
  const { data: mentors } = useGetMentorsMinimalQuery();
  const { data: employeeOrgInfo } = useGetEmployeeOrgInfoQuery(Number(id));

  useEffect(() => {
    dispatch(
      setComponent({
        component: { pathname: `/employees/${id}/`, text: 'Employee Details' },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const navigateToEmployeeOrg = () => {
    navigate(`/employees/${id}/org`);
  };

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <BasicEmployeeInfo />
      {isSuccess && employees && mentors && (
        <EmployeeOrgEditForm
          is_hr={employee.is_hr}
          is_key_resource={employee.is_key_resource}
          is_mentor={employee.is_mentor}
          is_nonprod={employee.is_nonprod}
          is_supplementary={employee.is_supplementary}
          is_team_lead={employee.is_team_lead}
          backfill={employee.backfill.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          backfillOptions={getBackfillOptions(employees, Number(id))}
          mentors={employee.mentors.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          mentorOptions={getOptions(mentors)}
          hrId={employee.hr?.id}
          navigateToEmployeeOrg={navigateToEmployeeOrg}
          benchTags={employeeOrgInfo?.bench_tags || []}
        />
      )}
    </Box>
  );
};
