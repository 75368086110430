import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../icon';

export const ListColumns = (props) => {
  const {
    setSortSettings,
    sortColumnName,
    sortDirection,
    columnsToDisplay,
    config,
  } = props;

  const renderGroupArrows = (column) => {
    const sortId = column.sortId || column.Id;

    const selectedUp =
      sortColumnName === sortId && sortDirection === 'Up'
        ? 'selected'
        : 'not-selected';

    const selectedDown =
      sortColumnName === sortId && sortDirection === 'Down'
        ? 'selected'
        : 'not-selected';

    return (
      <>
        <CustomIcon
          iconName={'arrow-up'}
          onClick={setSortSettings(sortId, 'Up')}
          className={`pointer ${selectedUp}`}
        />
        <CustomIcon
          iconName={'arrow-down'}
          onClick={setSortSettings(sortId, 'Down')}
          className={`pointer ${selectedDown}`}
        />
      </>
    );
  };

  return columnsToDisplay.map((column) => {
    if (
      typeof column.hasPermission !== 'undefined' &&
      !config[column.Id]?.isHaveRights
    ) {
      return null;
    }

    return column.isSelected || typeof column.isSelected === 'undefined' ? (
      <div
        key={column.Id}
        className={classNames(
          'c-list__header-col',
          column.className ? column.className : 'small-col',
          column.hasBorder
        )}
      >
        <div
          className={`c-list__header-name ${
            column.shiftName ? 'shift_up-header' : ''
          }`}
        >
          <span>{column.Name}</span>
          <div
            className={`c-list__arrows ${
              column.deleteArrow ? column.deleteArrow : ''
            }`}
          >
            {renderGroupArrows(column)}
          </div>
        </div>
      </div>
    ) : null;
  });
};

ListColumns.propTypes = {
  columnsToDisplay: PropTypes.array.isRequired,
  setSortSettings: PropTypes.func,
  sortColumnName: PropTypes.string,
  sortDirection: PropTypes.string,
};
