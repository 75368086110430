import { Grid, GridDirection, GridSpacing } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
  alignItems?: string;
  spacing?: GridSpacing;
  direction?: GridDirection;
  xs?: number;
  children: ReactNode;
  sx?: SxProps<Theme>;
}
// todo: obsolete. remove
export const GridContainerItem: FC<Props> = ({
  children,
  xs,
  direction = 'row',
  alignItems = 'center',
  spacing,
  sx,
}: Props) => (
  <Grid
    item
    container
    xs={xs || true}
    direction={direction}
    justifyContent='flex-start'
    alignItems={alignItems}
    spacing={spacing}
    sx={sx}
  >
    {children}
  </Grid>
);
