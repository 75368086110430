import { IRiskReportResponse } from '../../EmployeesPage/api/apiSlice.types';

export function transformDataStructureForTable(data: IRiskReportResponse[]) {
  return data.map((datum) => {
    const { date, risks, total } = datum;
    const newDatum = {
      date: '',
      risks: { danger: 0, warning: 0, resolved: 0, dismissed: 0 },
      total: 0,
    };
    newDatum.date = date;
    newDatum.total = total;
    const newRisks: {
      [key: string]: number;
      danger: number;
      warning: number;
      dismissed: number;
      resolved: number;
    } = {
      danger: 0,
      warning: 0,
      resolved: 0,
      dismissed: 0,
    };
    risks.forEach((risk) => {
      newRisks[risk.type] = risk.count;
    });
    newDatum.risks = newRisks;

    return newDatum;
  });
}

export function filterDataWithTypes(
  data: IRiskReportResponse[],
  types: string[]
) {
  return data.map((datum) => {
    const newDatum = { ...datum };
    const newRisks = [...datum.risks].filter((risk) =>
      types.includes(risk.type)
    );
    newDatum.risks = newRisks;
    return newDatum;
  });
}

export const getPercentages = (riskCount: number, total: number): string => {
  if (!riskCount || !total) {
    return `0%`;
  } else return `${((riskCount / total) * 100).toFixed(1)}%`;
};
