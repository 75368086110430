import * as React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import DatePickerValue from './DatePicker';
import ComboBox from './Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  useGetCDPQuery,
  useGetMentorsMinimalQuery,
} from '../../../api/apiSlice';
import { DateTime } from 'luxon';
import { OnSubmitCDPGoalParams } from '../../../api/apiSlice.types';

export const validationSchemaCDPGoal = Yup.object({
  shortSummary: Yup.string()
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  dueDate: Yup.date()
    .typeError('The value must be a date (YYYY-MM-DD)')
    .required('Required')
    .min(new Date(), 'Should be greater than today'),
  mentor: Yup.object().required('Required'),
  actions: Yup.string()
    .trim()
    .max(3000, 'Must be 3000 characters or less')
    .required('Required'),
});

interface IProps {
  mentorId?: number;
  onSubmitCDPGoal: (newGoal: OnSubmitCDPGoalParams) => void;
}

export const AddGoal = ({ mentorId, onSubmitCDPGoal }: IProps) => {
  const { cdpId } = useParams();

  const [open, setOpen] = React.useState(false);

  const { data: cdpData } = useGetCDPQuery(
    { cdpId: Number(cdpId) },
    { skip: isNaN(Number(cdpId)) }
  );
  const { data: mentorsData, isLoading: isMentorsLoading } =
    useGetMentorsMinimalQuery();

  const mentors = mentorsData
    ? mentorsData.map((item) => ({
        label: item.name,
        id: item.id,
      }))
    : [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: FormikValues) => {
    onSubmitCDPGoal({
      short_summary: values.shortSummary,
      mentor: { id: values.mentor.id, name: values.mentor.label },
      due_date: values.dueDate.toISODate(),
      actions: values.actions,
    });
    setOpen(false);
  };

  const initialValues = {
    shortSummary: '',
    mentor: mentors.find((item) => item.id === mentorId),
    dueDate: DateTime.now(),
    actions: '',
  };

  const onSubmit = (values: FormikValues) => {
    handleSubmit(values);
  };

  return (
    <div>
      <Button
        variant='contained'
        disabled={cdpData && cdpData?.status !== 'in_progress'}
        onClick={handleClickOpen}
      >
        + Add Goal
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 648, width: 648 } }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaCDPGoal}
        >
          <Form>
            <DialogTitle>Goal</DialogTitle>
            <DialogContent>
              <Field name='shortSummary'>
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    autoFocus
                    margin='dense'
                    label='Short Summary'
                    fullWidth
                    variant='outlined'
                  />
                )}
              </Field>
              <Stack direction='row' spacing={2}>
                <Field name='dueDate'>
                  {({ field, form, meta }: FieldProps) => (
                    <DatePickerValue
                      value={field.value}
                      setValue={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name='mentor'>
                  {({ field, form, meta }: FieldProps) => (
                    <ComboBox
                      options={mentors}
                      isLoading={isMentorsLoading}
                      value={field.value}
                      setValue={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : undefined}
                    />
                  )}
                </Field>
              </Stack>
              <Field name='actions'>
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    multiline
                    rows={4}
                    margin='dense'
                    label='Actions'
                    fullWidth
                    variant='outlined'
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button onClick={handleClose} color='inherit'>
                Cancel
              </Button>
              <Button type='submit' variant='contained'>
                Submit
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};
