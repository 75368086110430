export const checkGreenCircle = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM7.33341 13.1667L3.16675 9.00008L4.34175 7.82508L7.33341 10.8084L13.6584 4.48341L14.8334 5.66675L7.33341 13.1667Z'
      fill='#94CF78'
    />
  </svg>
);
