import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Chip } from '@mui/material';
import { CellRisk } from '../../../common/list/cells/CellRisk';
import { List } from '../../../common/list/List';
import { IFeedback } from '../../../EmployeesPage/api/apiSlice.types';

interface IProps {
  items: Array<IFeedback>;
  isFetching: boolean;
  onSortChange: (column: any, direction: string) => void;
  risks?: Array<{ id: string; name: string }>;
}
// todo: change column width. right now it's unreasonably small for some columns
const columnsToDisplay = [
  {
    Name: 'Feedback Type',
    Id: 'feedback_type',
    className: 'medium-col',
  },
  {
    Name: 'Employee',
    Id: 'employee',
    className: 'medium-col',
    isObject: true,
  },
  {
    Name: 'Position',
    Id: 'position',
    valueGetter: (row: IFeedback) => row.employee?.position,
    className: 'medium-col',
  },
  {
    Name: 'Manager',
    Id: 'manager',
    className: 'medium-col',
    valueGetter: (row: IFeedback) => row.employee?.manager?.name,
    isObject: true,
  },
  {
    Name: 'Updated By',
    Id: 'modified_by',
    className: 'medium-col',
    isObject: true,
  },
  {
    Name: 'Feedback date',
    Id: 'feedback_date',
    className: 'medium-col',
  },
  {
    Name: 'Risk level',
    Id: 'risk_level',
    className: 'medium-col',
  },
];

export const Table = ({ items, onSortChange, risks, isFetching }: IProps) => {
  const [column, setColumn] = useState('' as string);
  const [direction, setDirection] = useState('' as string);

  const renderRow = (defaultRowRender: React.ReactNode, rowItem: IFeedback) => (
    <Box
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={`/employees/${rowItem.employee.id}/feedbacks/${rowItem.id}`}
      state={{
        pathname: '/feedbacks',
      }}
    >
      {defaultRowRender}
    </Box>
  );

  const onSortClick = (column: string, sortDirection: string) => {
    setColumn(column);
    setDirection(sortDirection);
    onSortChange(column, sortDirection);
  };

  return (
    <List
      columnsToDisplay={columnsToDisplay}
      config={{
        risk_level: {
          risks,
          cell: (props: any) =>
            props?.item?.is_external ? (
              <Box px='10px'>
                <Chip label='External' variant='outlined' size='small' />
              </Box>
            ) : (
              <CellRisk {...props} />
            ),
        },
      }}
      items={items || []}
      isFetching={isFetching}
      sortColumnName={column}
      sortDirection={direction}
      setSortSettings={onSortClick}
      renderRow={renderRow}
    />
  );
};
