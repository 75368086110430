import { STATUS_COLOR } from './const-variable';

export const getStatusColor = (status) => {
  const statusColor = {
    Active: STATUS_COLOR.green,
    Prospective: STATUS_COLOR.blue,
    Removed: STATUS_COLOR.red,
    Draft: STATUS_COLOR.blue,
    Expired: STATUS_COLOR.grey,
    Completed: STATUS_COLOR.blue,
    Employee: STATUS_COLOR.lightRed,
    Contractor: STATUS_COLOR.yellow,
    Trainee: STATUS_COLOR.blue,
    Disabled: STATUS_COLOR.charcoal,
    'Admin Staff': STATUS_COLOR.violet,
    'On Hold': STATUS_COLOR.grey,
    'Former employee': STATUS_COLOR.emerald,
    'Early Active': STATUS_COLOR.emerald,
    'Early Active & Active': STATUS_COLOR.turquoise,
    'Late Active': STATUS_COLOR.mediumTurquoise,
    unknown: STATUS_COLOR.grey,

    Extended: STATUS_COLOR.green,
    warning: STATUS_COLOR.yellow,
    danger: STATUS_COLOR.red,
    low: STATUS_COLOR.green,
    resolved: STATUS_COLOR.blue,
    draft: STATUS_COLOR.blue,
    in_progress: STATUS_COLOR.green,
    finished: STATUS_COLOR.lightRed,
    canceled: STATUS_COLOR.red,
    Created: STATUS_COLOR.blue,
    Cancelled: STATUS_COLOR.red,

    Warning: STATUS_COLOR.yellow,
    Danger: STATUS_COLOR.red,
    Low: STATUS_COLOR.green,
    Resolved: STATUS_COLOR.blue,
    'In progress': STATUS_COLOR.green,
    'No risks': STATUS_COLOR.grey,

    'In Progress': STATUS_COLOR.green,
    Finished: STATUS_COLOR.lightRed,
    Canceled: STATUS_COLOR.red,

    Add: STATUS_COLOR.green,
    Delete: STATUS_COLOR.lightRed,
    Edit: STATUS_COLOR.blue,

    Scheduled: STATUS_COLOR.turquoise,
    'Re-scheduled': STATUS_COLOR.blue,
    Done: STATUS_COLOR.green,
    'Waiting for feedback': STATUS_COLOR.yellow,
    Approved: STATUS_COLOR.green,
    'Waiting for Approval': STATUS_COLOR.red,

    Initiated: STATUS_COLOR.red,
    Requested: STATUS_COLOR.yellow,
  };

  return statusColor[status] !== undefined
    ? statusColor[status]
    : 'transparent';
};

export const getProjectStatusColor = (status) => {
  if (!status) return '';
  const statusColor = {
    'Approve SOW/PO': STATUS_COLOR.red,
    'Approve new SOW/PO': STATUS_COLOR.red,
    Prospective: STATUS_COLOR.grey,
    Active: STATUS_COLOR.green,
    'Unblock Project': STATUS_COLOR.red,
    'Finish or Extend Project': STATUS_COLOR.red,
    Finished: STATUS_COLOR.grey,
    Draft: STATUS_COLOR.grey,
    'Complete the Phase': STATUS_COLOR.red,
    'Complete the Project': STATUS_COLOR.red,
    Removed: STATUS_COLOR.red,
  };

  if (!statusColor[status]) {
    const warningStatuses = [
      'Approve SOW/PO',
      'Unblock Project',
      'Finish or Extend Project',
      'Complete the Phase',
      'Approve new SOW/PO',
      'Complete the Project',
    ];
    return warningStatuses.some((item) => status.includes(item))
      ? STATUS_COLOR.red
      : STATUS_COLOR.green;
  }

  return statusColor[status];
};
