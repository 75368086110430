import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UNAUTHORIZED_STATUS } from './utils/const-variable';
import { setRedirectTo } from './components/LoginPage/slice/loginSlice';

const apiRoot = process.env.REACT_APP_HOST;
const isValidName = (fullName) => fullName.split('.').length >= 2;

const RedirectToEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  if (!isValidName(params.employee)) {
    navigate('/');
  }

  const axiosInstance = axios.create({
    baseURL: apiRoot,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('AuthToken') },
  });

  useEffect(() => {
    async function getUserByName() {
      try {
        const response = await axiosInstance({
          method: 'get',
          url: `/employees/user/${params.employee}/`,
        });
        processResponse(response);
      } catch (error) {
        if (error.response.status === UNAUTHORIZED_STATUS) {
          dispatch(setRedirectTo(`/employee/${params.employee}/`));
        }
        navigate('/');
      }
    }
    getUserByName();
  }, []);

  const processResponse = (response) => {
    if (!response?.data?.id) {
      navigate('/');
    }
    navigate(`/employees/${response.data.id}/`);
  };

  return null;
};

export default RedirectToEmployee;
