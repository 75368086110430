import { Grid } from '@mui/material';

interface IFeedbackCreateEditFormHeaderProps {
  isEditForm: boolean;

  onCancel: (event?: any) => void;

  onSubmit: (event?: any) => void;
}

export const FeedbackCreateEditFormHeader = ({
  isEditForm,
  onCancel,
  onSubmit,
}: IFeedbackCreateEditFormHeaderProps) => {
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      className='feedback_create__header'
    >
      <Grid item>
        <span className='feedback_create__title'>
          {isEditForm ? 'Edit Feedback:' : 'Add Feedback:'}
        </span>
      </Grid>
      <Grid item>
        <button className='button__secondary' onClick={onCancel}>
          Cancel
        </button>
        <button className='button__main' type='submit' onClick={onSubmit}>
          Submit
        </button>
      </Grid>
    </Grid>
  );
};
