import { useNavigate, useParams } from 'react-router-dom';
import { Ref, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { DetailsCreate } from './DetailsCreate';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import { onCancelHandler } from '../utils';
import {
  useAddEmployeeCDPMutation,
  useDeleteEmployeeCDPFileMutation,
  useUploadEmployeeCDPFileMutation,
} from '../../../api/apiSlice';
import {
  ICDPFileResponse,
  ICDPRequest,
  IGoalInState,
} from '../../../api/apiSlice.types';
import { FileUpload } from '../FileUpload';
import { GoalSection } from '../Goals/GoalSection';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import usePrompt from '../../../../common/Guards/usePrompt';

export const EmployeeCDPCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formRef: Ref<any> = useRef(null);
  const fileUploadRef: Ref<any> = useRef(null);

  const [addEmployeeCDP] = useAddEmployeeCDPMutation();
  const [, { isLoading: isUploadingFile }] = useUploadEmployeeCDPFileMutation({
    fixedCacheKey: 'shared-upload-cdp-file',
  });
  const [, { isLoading: isDeletingFile }] = useDeleteEmployeeCDPFileMutation({
    fixedCacheKey: 'shared-delete-cdp-file',
  });

  const [goals, setGoals] = useState<IGoalInState[]>([]);
  const [isTouchedForm, setIsTouchedForm] = useState<boolean>(false);
  const [CDPMentorId, setCDPMentorId] = useState<number>();

  usePrompt('Changes you made will not be saved.', isTouchedForm);

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: id ? `/employees/${id}/cdps` : `/cdps`, // If opened not from employee profile - redirect to CDP page from left menu
          text: 'CDP List',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  const onSubmit = () => formRef?.current?.handleSubmit();

  const onSubmitForm = async (data: ICDPRequest) => {
    setIsTouchedForm(false);

    if (Object.keys(data).length) {
      // Check if user selected any option and then cleared Select, entered text and then cleared textArea or entered only whitespaces
      Object.keys(data)?.forEach((key: string) => {
        // @ts-ignore
        if (typeof data?.[key] === 'string' && !data?.[key]?.trim()?.length) {
          // @ts-ignore
          delete data[key];
        }
      });

      const files: ICDPFileResponse[] =
        await fileUploadRef?.current?.getFiles();
      data.files_ids = files?.map((file) => file?.id);

      if (!goals.length) {
        return dispatch(
          // @ts-ignore
          showDialog(ConfirmationDialog, {
            dialogHeader: 'CDP',
            dialogTitle: 'You can not save CDP without any goal',
            closeDialog: (dialogName: string) =>
              dispatch(closeDialog(dialogName)),
            confirmDialog: (dialogName: string) =>
              dispatch(closeDialog(dialogName)),
            confirmButtonTitle: 'OK',
            isCloseButtonVisible: false,
          })
        );
      }

      const newCDP = { ...data };

      if (goals?.length) {
        const newGoals = goals.map((goal) => {
          return {
            short_summary: goal.short_summary,
            due_date: goal.due_date,
            mentor_id: goal.mentor.id,
            actions: goal.actions,
            comment: goal.comment,
          };
        });
        newCDP.cdp_goals = newGoals;
      }

      const CDP = await addEmployeeCDP({ data: newCDP }).unwrap();
      navigate(
        `/employees/${CDP?.employee?.id}/cdps/${CDP?.id}`,
        !id ? { state: { returnTo: '/cdps' } } : undefined
      );
    }
  };

  return (
    <Box overflow='auto' pb={3}>
      <Box pt={1} px={2.5}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h6' gutterBottom>
              CDP Details
            </Typography>
          </Grid>
          <Grid item>
            <button
              key={0}
              className='button__secondary'
              onClick={() => {
                setIsTouchedForm(false);

                onCancelHandler({
                  path: id ? `/employees/${id}/cdps` : `/cdps`,
                  dispatch,
                  navigate,
                  dialogHeader: 'Are you sure you want to cancel CDP creation?',
                });
              }}
            >
              Cancel
            </button>
            <button
              key={1}
              className='button__main'
              style={{ marginRight: 0 }}
              disabled={isUploadingFile || isDeletingFile}
              onClick={onSubmit}
            >
              Submit
            </button>
          </Grid>
        </Grid>

        <DetailsCreate
          onSubmit={onSubmitForm}
          ref={formRef}
          onTouchForm={() => setIsTouchedForm(true)}
          setCDPMentorId={setCDPMentorId}
        />

        <Box>
          <Typography color='#828282' fontSize={12} fontWeight='bold'>
            Attachments
          </Typography>
          <FileUpload
            ref={fileUploadRef}
            onChange={() => setIsTouchedForm(true)}
          />

          <Box>
            <Divider sx={{ my: 2 }} />
            <GoalSection
              goals={goals}
              setGoals={setGoals}
              mentorId={CDPMentorId}
              onChange={() => setIsTouchedForm(true)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
