import { FC } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from '../../../api/apiSlice';

const labelMap = {
  upcoming: 'Upcoming vacation',
  current: 'On vacation',
} as any;

export const Vacation: FC = () => {
  const { id } = useParams();
  const employeeId = Number(id);
  const { data: employee } = useGetEmployeeQuery(employeeId);

  if (!employee?.vacation) {
    return null;
  }

  const getTooltipTitle = () => {
    if (!employee.vacation) {
      return '';
    }
    const { type, start_date, end_date, status } = employee.vacation;
    if (status === 'upcoming') {
      return `Upcoming ${type} will be since ${start_date} by ${end_date}`;
    }
    return `On ${type} since ${start_date} by ${end_date}`;
  };

  return (
    <Tooltip title={getTooltipTitle()} placement='top'>
      <Chip
        label={labelMap[employee.vacation.status]}
        className='profile_vacation_label'
      />
    </Tooltip>
  );
};
