import CustomIcon from '../../icon';

export const Button = (props) => {
  const { title, onClick, iconName, className } = props;

  return (
    <div className={className} onClick={onClick}>
      {iconName && <CustomIcon iconName={iconName} />}
      <span>{title}</span>
    </div>
  );
};
