import {
  useGetBenchTagsQuery,
  useGetEmployeeFiltersQuery,
  useGetExportRiskFileMutation,
  useGetRiskReportMutation,
} from '../../EmployeesPage/api/apiSlice';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '../Filters/ClearIcon';
import { useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ChartAppRisk } from './ChartAppRisk';
import { useSelector } from 'react-redux';
import { selectOffice } from '../../../store/slices/auth/authSlice';
import StatusSelect from '../Filters/StatusSelect';
import {
  IBenchTag,
  IFilterReportParams,
  IRiskReportResponse,
} from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import { option } from '../../Bench/types';
import PeriodSelect from '../Filters/PeriodSelect';
import BasicTable from './BasicTable';
import { filterDataWithTypes, transformDataStructureForTable } from './utils';
import Export from '../../common/Export';

const senioritiesList = [
  { id: 'key_persons', name: 'Key persons' },
  { id: 'lead', name: 'Leads' },
  { id: 'senior', name: 'Seniors' },
  { id: 'middle', name: 'Middles' },
  { id: 'junior', name: 'Juniors' },
];
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const tagsInitialValues = { bench_tags: [], not_bench_tags: [] };

export const RiskReport = () => {
  const [getReport] = useGetRiskReportMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<{
    bench_tags: IBenchTag[];
    not_bench_tags: IBenchTag[];
  }>(tagsInitialValues);

  const [data, setData] = useState<IRiskReportResponse[]>([]);
  const { data: tags, isLoading } = useGetBenchTagsQuery();
  const [filter, setFilter] = useState({});

  const office = useSelector(selectOffice);

  const initialValues: any = {
    office: office.name,
    months: 6,
    manager: '',
    project: '',
    status: '',
    seniorities: '',
    ...selectedTags,
  };

  useEffect(() => {
    fetchReport(initialValues);
    setFilter(initialValues);
  }, []);

  const selectedTagsCount = useMemo(
    () => selectedTags.bench_tags.length + selectedTags.not_bench_tags.length,
    [selectedTags]
  );

  const {
    data: { offices, managers, projects } = {
      offices: [],
      managers: [],
      projects: [],
    },
  } = useGetEmployeeFiltersQuery();

  const fetchReport = async ({
    office,
    months,
    manager,
    project,
    status,
    seniorities,
    bench_tags,
    not_bench_tags,
  }: IFilterReportParams) => {
    try {
      setLoading(true);
      const response: any = await getReport({
        office,
        months,
        manager,
        project,
        status,
        seniorities,
        bench_tags,
        not_bench_tags,
      });
      setData(response?.data || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [downloadFile] = useGetExportRiskFileMutation();

  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Risk Trend Report</h1>
        <Export onDownloadFile={() => downloadFile(filter)} />
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          fetchReport(values);
          setFilter(values);
        }}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item width={240}>
                <Field name='office'>
                  {({ field }: any) => (
                    <TextField
                      disabled
                      select
                      label='Delivery Center'
                      size='small'
                      fullWidth
                      {...field}
                    >
                      {offices.map((item: any) => (
                        <MenuItem value={item.name} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='months'>
                  {({ field }: any) => (
                    <PeriodSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='project'>
                  {({ field }: FieldProps) => (
                    <TextField
                      select
                      label='Project'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {projects.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='manager'>
                  {({ field }: any) => (
                    <TextField
                      select
                      label='Manager'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {managers.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='status'>
                  {({ field }: any) => (
                    <StatusSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='seniorities'>
                  {({ field }: any) => (
                    <TextField
                      select
                      label='Seniority'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {senioritiesList.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>

              <Grid item>
                <Button
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={
                    <Box
                      sx={{
                        fontSize: 24,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ArrowDropDownIcon />
                    </Box>
                  }
                  variant={'outlined'}
                  color={'inherit'}
                  sx={{
                    width: '96px',
                    textTransform: 'none',
                    height: 40,
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      color:
                        selectedTags.bench_tags.length ||
                        selectedTags.not_bench_tags.length
                          ? '#000'
                          : 'inherit',
                    }}
                  >
                    {selectedTagsCount ? `${selectedTagsCount} tags` : 'Tags'}
                  </Typography>
                </Button>

                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <Box sx={{ p: 2 }} width={560}>
                    <Stack direction='row' spacing={2}>
                      <Field name='bench_tags'>
                        {({ field }: any) => (
                          <Autocomplete
                            size={'small'}
                            multiple
                            disableCloseOnSelect
                            loading={isLoading}
                            options={tags || []}
                            getOptionLabel={(option: IBenchTag) => option.name}
                            style={{ width: '50%' }}
                            limitTags={1}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Included Tags'
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                size='small'
                                fullWidth
                                {...field}
                              />
                            )}
                            value={selectedTags.bench_tags}
                            onChange={(event: any, newValue: option[]) => {
                              formik.setFieldValue(field.name, newValue);
                              setSelectedTags({
                                ...selectedTags,
                                bench_tags: newValue,
                              });
                            }}
                          />
                        )}
                      </Field>
                      <Field name='not_bench_tags'>
                        {({ field }: any) => (
                          <Autocomplete
                            size={'small'}
                            multiple
                            disableCloseOnSelect
                            loading={isLoading}
                            options={tags || []}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '50%' }}
                            limitTags={1}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Excluded Tags'
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                size='small'
                                fullWidth
                              />
                            )}
                            onChange={(event: any, newValue: option[]) => {
                              formik.setFieldValue(field.name, newValue);
                              setSelectedTags({
                                ...selectedTags,
                                not_bench_tags: newValue,
                              });
                            }}
                            value={selectedTags.not_bench_tags}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                          />
                        )}
                      </Field>
                    </Stack>
                  </Box>
                </Menu>
              </Grid>

              <Grid item>
                <Button
                  sx={{ height: '40px' }}
                  variant='contained'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  onClick={async () => {
                    const values = { ...initialValues, ...tagsInitialValues };
                    setSelectedTags(tagsInitialValues);
                    await formik.resetForm(values);
                    await fetchReport(values);
                    setFilter(initialValues);
                  }}
                  sx={{ height: '40px', marginLeft: '12px' }}
                  variant='outlined'
                  type='reset'
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {!!data?.length && (
        <Stack direction={'row'} spacing={2}>
          <Box width={400} pt={3}>
            <Box>
              <h3>Employees with risk still opened by the month</h3>
            </Box>
            <Box height={'90%'} position={'relative'}>
              <ChartAppRisk
                data={filterDataWithTypes(data, ['danger', 'warning'])}
                colors={['#ff6961', '#fdfd96']}
              />
            </Box>
          </Box>
          <Box width={450} pt={3}>
            <Box>
              <h3>Employees with Risk Closed in the previous month</h3>
            </Box>
            <Box height={'90%'}>
              <ChartAppRisk
                data={filterDataWithTypes(data, ['resolved', 'dismissed'])}
                colors={['#77dd77', '#444444']}
              />
            </Box>
          </Box>
          <Box width={600} pt={3}>
            <Box>
              <h3>Employees</h3>
            </Box>
            <BasicTable rows={transformDataStructureForTable(data)} />
          </Box>
        </Stack>
      )}
    </Box>
  );
};
