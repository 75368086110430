import { call, put, select, takeEvery } from 'redux-saga/effects';
import http from '../../../utils/http';
import history from '../../../utils/history';
import { setAuthData } from '../../../store/slices/auth/authSlice';
import {
  login,
  relogin,
  selectLoginRedirectTo,
  setLoginError,
  setRedirectTo,
  setShowLoginModal,
} from './loginSlice';
import { enqueueSnackbar } from 'notistack';
import { setUserDataToLocalStorage } from './setUserDataToLocalStorage';

export const RESTORE_URL_KEY = 'restoreUrl';

function* workLogin(action) {
  try {
    const responseData = yield call(http, {
      url: '/auth/login/',
      method: 'post',
      data: {
        username: action.payload.username,
        password: action.payload.password,
      },
    });

    // todo: remove the need of persist
    setUserDataToLocalStorage(responseData);

    yield put(setAuthData(responseData.data));

    const redirectTo = yield select(selectLoginRedirectTo);
    const url = new URL(window.location.href);
    if (redirectTo || url.searchParams.has(RESTORE_URL_KEY)) {
      history.push(redirectTo || url.searchParams.get(RESTORE_URL_KEY));
    } else {
      history.push('/'); // todo: remove route logic from here
    }
    yield put(setRedirectTo(null));
  } catch (error) {
    if (error?.response?.status === 429) {
      enqueueSnackbar(error?.response?.data?.detail || error, {
        variant: 'error',
      });
    }
    yield put(setLoginError());
  }
}

function* workRelogin(action) {
  try {
    const responseData = yield call(http, {
      url: '/auth/login/',
      method: 'post',
      data: {
        username: action.payload.username,
        password: action.payload.password,
      },
    });

    // todo: remove the need of persist
    setUserDataToLocalStorage(responseData);

    yield put(setAuthData(responseData.data));
    yield put(setShowLoginModal(false));
    yield put(setRedirectTo(null));
  } catch (error) {
    if (error?.response?.status === 429) {
      enqueueSnackbar(error?.response?.data?.detail || error, {
        variant: 'error',
      });
    }
    yield put(setLoginError());
  }
}

export default function* LoginRootSaga() {
  yield takeEvery(login, workLogin);
  yield takeEvery(relogin, workRelogin);
}
