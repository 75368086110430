import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useEditUserMutation } from '../../EmployeesPage/api/apiSlice';
import { RECRUITER_ZONE } from '../../../utils/const-variable';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { ZoneType } from '../../../utils/permissions/types';

interface IBenchCommentProps {
  benchComment?: string;
  employeeId?: number;
  zone?: ZoneType;
}

const BenchComment = ({
  employeeId,
  benchComment,
  zone,
}: IBenchCommentProps) => {
  const [editUser] = useEditUserMutation();

  const [comment, setComment] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const canEditComment = zone?.includes(RECRUITER_ZONE);

  const onSubmit = () => {
    if (employeeId && comment?.length) {
      editUser({
        id: employeeId,
        data: { bench_comment: comment },
      });
      onCancel();
    }
  };

  const onCancel = () => {
    setIsDialogOpen(false);
    setComment('');
  };

  return (
    <>
      <Dialog fullWidth maxWidth='sm' open={isDialogOpen} onClose={onCancel}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight={600}>Comment</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            maxRows={10}
            disabled={!canEditComment}
            defaultValue={benchComment}
            onChange={(e) => setComment(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Button
        variant='text'
        disabled={benchComment === null && !canEditComment}
        endIcon={benchComment !== null ? <OpenInFullIcon /> : undefined}
        onClick={() => setIsDialogOpen(true)}
        sx={{ color: '#1976D2', textTransform: 'none' }}
      >
        {benchComment !== null ? 'Read more' : 'Add comment'}
      </Button>
    </>
  );
};

export default BenchComment;
