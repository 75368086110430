import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getPercentages } from './utils';
import { styled } from '@mui/system';

const WithBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid #d7d7d7',
}));

export default function BasicTable({
  rows,
}: {
  rows: {
    date: string;
    risks: {
      danger: number;
      warning: number;
      resolved: number;
      dismissed: number;
    };
    total: number;
  }[];
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 320 }} aria-label='simple table' size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <WithBorder>Total</WithBorder>
            <WithBorder align='center' colSpan={2}>
              Danger
            </WithBorder>
            <WithBorder align='center' colSpan={2}>
              Warning
            </WithBorder>
            <WithBorder align='center'>Resolved</WithBorder>
            <WithBorder align='center'>Dismissed</WithBorder>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.date}>
              <TableCell component='th' scope='row'>
                {row.date}
              </TableCell>
              <WithBorder component='th' scope='row'>
                {row.total}
              </WithBorder>
              <WithBorder align='center'>{row.risks.danger}</WithBorder>
              <TableCell align='center'>
                {getPercentages(row.risks.danger, row.total)}
              </TableCell>
              <WithBorder align='center'>{row.risks.warning}</WithBorder>
              <TableCell align='center'>
                {getPercentages(row.risks.warning, row.total)}
              </TableCell>
              <WithBorder align='center'>{row.risks.resolved}</WithBorder>
              <WithBorder align='center'>{row.risks.dismissed}</WithBorder>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
