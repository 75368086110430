import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import EmployeesList from './components/EmployeesList/EmployeesList';
import { resetComponent } from '../../store/slices/header/headerSlice';
import { resetScopeZone } from './slices/employeesSlice';
import { EmployeePage } from './EmployeePage';

export const EmployeesPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetComponent);
      dispatch(resetScopeZone);
    };
  }, []);

  return (
    <Routes>
      <Route index element={<EmployeesList />} />
      <Route path=':id/*' element={<EmployeePage />} />
    </Routes>
  );
};
