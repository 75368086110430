import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  useChangeCDPStatusMutation,
  useGetCDPQuery,
  useGetCDPStatusesQuery,
} from '../../../api/apiSlice';
import { ICDPFileResponse } from '../../../api/apiSlice.types';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';
import { selectEmployeeZone } from '../../../slices/employeesSlice';
import './detail.css';
import { canEditCDP } from '../../../../../utils/permissions/checkAccess';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import { bytesToSize, onCancelHandler } from '../utils';
import { GoalSection } from '../Goals/GoalSection';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import { closeDialog } from '../../../../../store/slices/modalDialog/modalDialogSlice';
import ButtonGroup from '../../../../common/buttonGroup';
import DownloadIcon from '@mui/icons-material/Download';
import { EMPTY_VALUE, formatEmptyValue } from '../../EmployeeProfile/utils';

export const FieldItem = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <h4 className='fieldTitle'>{label}</h4>
      <div className='fieldValue'>{children}</div>
    </div>
  );
};

export const EmployeeCDPDetails = () => {
  const { id, cdpId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector(selectEmployeeId);
  const zone = useSelector(selectEmployeeZone);

  const { data, error } = useGetCDPQuery(
    { cdpId: Number(cdpId) },
    { skip: isNaN(Number(cdpId)) }
  );

  const isOwnProfile = data?.employee?.id
    ? data?.employee?.id === Number(userId)
    : Number(userId) === Number(id);

  const { data: CDPStatuses } = useGetCDPStatusesQuery();
  const [changeCDPStatus] = useChangeCDPStatusMutation();

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: state?.returnTo ? state.returnTo : `/employees/${id}/cdps`,
          text: 'CDP List',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  if (error && (error as any)?.status === 403) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {(error as any)?.data?.detail}
      </Box>
    );
  }

  return (
    <Box overflow='auto' pb={3}>
      <Grid container pl={4.5} pr={3} pt={1}>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Typography variant='h6' gutterBottom>
              CDP Details
            </Typography>

            <Box sx={{ display: 'inline-flex' }}>
              <ButtonGroup
                buttonList={[
                  {
                    text: 'Finish',
                    visibility:
                      data?.status === 'in_progress' &&
                      data?.mentor?.id === userId &&
                      !isOwnProfile,
                    disabled: data?.closed_goals !== data?.total_goals,
                    variant: 'outlined',
                    tooltip: {
                      title:
                        data?.closed_goals !== data?.total_goals &&
                        'All CDP goals must be closed',
                      placement: 'top',
                    },
                    onClick: () => {
                      onCancelHandler({
                        path: `/employees/${id}/cdps/${data?.id}`,
                        dispatch,
                        navigate,
                        dialogHeader: '',
                        dialogTitle:
                          'Are you sure you want to close CDP as finished?',
                        onConfirm: async () =>
                          await changeCDPStatus({
                            cdpId: Number(data?.id),
                            status: 'finish',
                          }),
                      });
                    },
                  },
                  {
                    text: 'Reject',
                    visibility:
                      data?.status === 'in_progress' &&
                      data?.mentor?.id === userId &&
                      !isOwnProfile,
                    variant: 'outlined',
                    onClick: () =>
                      onCancelHandler({
                        path: `/employees/${id}/cdps/${data?.id}`,
                        dispatch,
                        navigate,
                        dialogHeader: '',
                        dialogTitle:
                          'Are you sure you want to close CDP as rejected?',
                        onConfirm: async () =>
                          await changeCDPStatus({
                            cdpId: Number(data?.id),
                            status: 'reject',
                          }),
                      }),
                  },
                  {
                    text: 'Edit',
                    visibility: canEditCDP(zone) && !isOwnProfile,
                    variant: 'contained',
                    onClick: () =>
                      navigate(`/employees/${id}/cdps/${cdpId}/edit`),
                  },
                ]}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Grid container pl={4.5} pr={3}>
        <Grid item xs={3}>
          <FieldItem label='Short Summary'>
            <span>{formatEmptyValue(data?.short_summary)}</span>
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Employee'>
            {data?.employee?.id ? (
              <Link to={`/employees/${data?.employee?.id}/`}>
                <span>{formatEmptyValue(data?.employee?.name)}</span>
              </Link>
            ) : (
              EMPTY_VALUE
            )}
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Mentor'>
            {data?.mentor?.id ? (
              <Link to={`/employees/${data?.mentor?.id}/`}>
                <span>{formatEmptyValue(data?.mentor?.name)}</span>
              </Link>
            ) : (
              EMPTY_VALUE
            )}
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Goals Achieved'>
            <span>
              {data?.closed_goals}/{data?.total_goals}
            </span>
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Target Position'>
            <span>{formatEmptyValue(data?.position)}</span>
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Target Seniority'>
            <span>{formatEmptyValue(data?.seniority_level)}</span>
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='Due Date'>
            <span>{formatEmptyValue(data?.due_date)}</span>
          </FieldItem>
        </Grid>
        <Grid item xs={3}>
          <FieldItem label='CDP Status'>
            <span>
              {data?.status
                ? CDPStatuses?.map(
                    (status: { [key: string]: string }) => status[data?.status]
                  ) || data?.status
                : EMPTY_VALUE}
            </span>
          </FieldItem>
        </Grid>

        {data?.notes && (
          <Grid item xs={12}>
            <FieldItem label={isOwnProfile ? 'Additional materials' : 'Notes'}>
              <pre>{formatEmptyValue(data?.notes)}</pre>
            </FieldItem>
          </Grid>
        )}

        {data?.files && data?.files?.length > 0 && (
          <Grid item xs={12}>
            <FieldItem label='Attachments'>
              {data?.files?.map((file: ICDPFileResponse) => (
                <Box
                  key={file?.id}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <a download href={file?.file_url} title='Download'>
                    <DownloadIcon />
                  </a>
                  <Box ml={1}>
                    <Typography>{file?.file_name}</Typography>
                    <Typography color='#828282' fontSize={12}>
                      {bytesToSize(file?.file_size)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </FieldItem>
          </Grid>
        )}
      </Grid>
      <Box px={4}>
        <Divider sx={{ my: 2 }} />
        <GoalSection
          goals={data?.cdp_goals}
          mentorId={data?.mentor?.id}
          isOwnProfile={isOwnProfile}
        />
      </Box>
    </Box>
  );
};
