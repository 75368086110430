import React from 'react';
import PropTypes from 'prop-types';

export const CellImgButtonList = ({ config, item, column }) => {
  if (
    typeof config[column.Id].isHaveRights !== 'undefined' &&
    !config[column.Id].isHaveRights
  ) {
    return null;
  }
  return (
    <div className='cell-img-toggle-button__cont'>
      <div className='c-list__options-column'>
        <div className='c-list-item__options-column__button-container'>
          <div>{config[column.Id]?.render({ item })}</div>
        </div>
      </div>
    </div>
  );
};

CellImgButtonList.propTypes = {
  item: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
