import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Option } from './types';

interface IProps {
  options: Option[];
  isLoading: boolean;
  value?: Option;
  setValue: (value: Option) => void;
  error?: boolean;
  helperText?: string;
}

export default function ComboBox({
  options,
  isLoading,
  value,
  setValue,
  error,
  helperText,
}: IProps) {
  return (
    <Autocomplete
      disablePortal
      disableClearable
      id='combo-box-demo'
      options={options}
      sx={{ width: 300 }}
      value={value}
      onChange={(event: any, newValue: Option) => {
        setValue(newValue);
      }}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          margin='dense'
          id='name'
          label='Mentor'
          variant='outlined'
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
