import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

// todo: pass less props
export const renderRow = (
  defaultRowRender: JSX.Element,
  rowItem: { id: number }
) => (
  // todo: replace Box with Link
  <Box component={Link} sx={{ textDecoration: 'none' }} to={`${rowItem.id}`}>
    {defaultRowRender}
  </Box>
);
