import React from 'react';
import PropTypes from 'prop-types';
import CustomIcon from '../../icon';

export const CellButton = ({
  title,
  onClick,
  item,
  icon = '',
  canClick = true,
}) => {
  const handleOnClick = () => onClick(item.item);
  return (
    <div
      className={`c-list-item__options-column__option ${
        !canClick && 'disabled'
      }`}
      onClick={handleOnClick}
    >
      {icon && <CustomIcon iconName={icon} />}
      <span>{title}</span>
    </div>
  );
};

CellButton.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
