import { call, put, select, takeLatest } from 'redux-saga/effects';
import http from '../../../utils/http';
import {
  finishAllRequests,
  startRequest,
} from '../../../store/slices/loader/loaderSlice';
import { setEmployees } from './employeesListSlice';
import {
  loadEmployees,
  selectEmployeesReducer,
  setScopeZone,
} from './employeesSlice';
import qs from 'qs';
import { extractPermissionHeaders } from '../../../utils/extractAccessControlHeaders';
import { quickFiltersSearchString } from '../components/EmployeesList/quickFiltersSearchString';
import { categoryIdValueForNoCategoryId } from '../../Bench/const';

const SKILL_FILTERS = {
  beginner: 'skills_beginner',
  competent: 'skills_competent',
  expert: 'skills_expert',
  main_tech_skills: 'main_tech',
  interview_enabled_skills: 'skill_interviewers',
};

const mapSkillFilterParam = (key) => {
  return SKILL_FILTERS[key];
};

function* workLoadEmployees(action) {
  yield put(startRequest());

  const searchSettings = yield select(
    (store) => selectEmployeesReducer(store).searchSettings
  );
  const quickFilters /*: IQuickFilter[]*/ = yield select(
    (store) => selectEmployeesReducer(store).quickFilters
  );
  const employeePage /*: string*/ = yield select(
    (store) => selectEmployeesReducer(store).employeePage
  );

  const filters /*: Filters*/ = searchSettings.filters;

  const filterParams = {};

  if (filters && Object.keys(filters).length) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key].length) {
        filterParams[key] = filters[key].map((item) => item.id);
      }
    });
  }
  const skills /*: number[]*/ = yield select(
    (store) => selectEmployeesReducer(store).skills
  );
  const skillFilter = yield select(
    (store) => selectEmployeesReducer(store).skillFilter
  );
  const skillFilterParams = {};
  for (const key in skillFilter) {
    const param = mapSkillFilterParam(key);
    if (param) {
      if (param === SKILL_FILTERS.main_tech_skills) {
        skillFilterParams[param] = skillFilter[key].map((tech) => {
          let categoryId = '';
          if (!(tech.category.id === categoryIdValueForNoCategoryId)) {
            categoryId = tech.category.id.toString();
          }
          return `${tech.id}.${categoryId}`;
        });
      } else if (param === SKILL_FILTERS.interview_enabled_skills) {
        skillFilterParams[param] = skillFilter[key].map((skill) => skill.id);
      } else {
        skillFilterParams[param] = skillFilter[key];
      }
    }
  }
  const searchValue /*: string*/ = searchSettings.searchValue;

  const quickFiltersStr = quickFiltersSearchString(quickFilters, employeePage);

  const { offSet } = action.payload;

  const column = searchSettings.sortSettings.column;
  const sortDirection = searchSettings.sortSettings.sortDirection;

  const direction = sortDirection === 'Up' ? '' : '-';
  const ordering = `${direction + column}`;

  const queryStrings = qs.stringify(
    {
      search: searchValue || null,
      limit: 30,
      ordering: ordering === 'name' ? null : ordering,
      skills: skills.length ? skills.map((skill) => skill.id) : null,
      offset: offSet || null,
    },
    {
      encode: false,
      indices: false,
      arrayFormat: 'repeat',
      addQueryPrefix: true,
      skipNulls: true,
    }
  );

  const employees = yield call(http, {
    url: `/employees/${employeePage}/${queryStrings}${quickFiltersStr}`,
    method: 'get',
    params: { ...filterParams, ...skillFilterParams },
  });

  // todo: refactor this where it is called. and remove this
  employees.data.results.forEach((employee) => {
    employee.admin_manager = employee.manager;
  });

  const permissions = extractPermissionHeaders(employees);

  yield put(setScopeZone(permissions));
  yield put(setEmployees(employees.data));

  yield put(finishAllRequests());
}

export default function* EmployeesRootSaga() {
  yield takeLatest(loadEmployees, workLoadEmployees);
}
