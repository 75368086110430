import PropTypes from 'prop-types';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

const Cell = ({ item, column, config }) => {
  const clipBoardText = config[column.Id].valueSetter
    ? config[column.Id].valueSetter(item)
    : item[column.Id];
  return (
    <div className='c-list__item-column xs-col'>
      <Tooltip title={clipBoardText} arrow placement='top'>
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CopyToClipboard text={clipBoardText}>
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          </CopyToClipboard>
        </span>
      </Tooltip>
    </div>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellClipboard = withCellWrapper(Cell);
