export const files = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.00004 1.66675H11.6667L16.6667 6.66675V16.6667C16.6667 17.5834 15.9167
    18.3334 15 18.3334H4.99171C4.07504 18.3334 3.33337 17.5834 3.33337 16.6667L3.34171 3.33341C3.34171 2.41675 4.08337 1.66675
    5.00004 1.66675ZM5.00004 3.33341V16.6667H15V7.50008H10.8334V3.33341H5.00004ZM10.9208 10.3991L9.99996 8.33325L9.0791
    10.3991L6.82977 10.6365L8.50997 12.1507L8.04068 14.3633L9.99996 13.2333L11.9592 14.3633L11.4899 12.1507L13.1701
    10.6365L10.9208 10.3991Z'
      fill='#A5A5A5'
    />
  </svg>
);
