import {
  useGetEmployeeFiltersQuery,
  useGetExportLocationFileMutation,
  useGetLocationReportMutation,
} from '../../EmployeesPage/api/apiSlice';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import ClearIcon from '../Filters/ClearIcon';
import { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ChartAppLocation } from './ChartAppLocation';
import StatusSelect from '../Filters/StatusSelect';
import {
  IFilterReportParams,
  ILocationReportResponse,
} from '../../EmployeesPage/api/apiSlice.types';
import { Preloader } from '../../common/preloader/preloader';
import { useSelector } from 'react-redux';
import { selectOffice } from '../../../store/slices/auth/authSlice';
import Export from '../../common/Export';
import PeriodSelect from '../Filters/PeriodSelect';

export const LocationReport = () => {
  const [getReport] = useGetLocationReportMutation();
  const [downloadFile] = useGetExportLocationFileMutation();

  const [data, setData] = useState<ILocationReportResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState({});
  const office = useSelector(selectOffice);
  const initialValues: any = {
    office: office.name,
    months: 6,
    manager: '',
    project: '',
    status: '',
  };

  useEffect(() => {
    fetchReport(initialValues);
    setFilter(initialValues);
  }, []);

  const {
    data: { offices, managers, projects } = {
      offices: [],
      managers: [],
      projects: [],
    },
  } = useGetEmployeeFiltersQuery();

  const fetchReport = async ({
    office,
    months,
    manager,
    project,
    status,
  }: IFilterReportParams) => {
    try {
      setLoading(true);
      const response: any = await getReport({
        office,
        months,
        manager,
        project,
        status,
      });
      setData(response?.data || []);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  return (
    <Box py={2}>
      {loading && <Preloader />}
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <h1>Location Report</h1>
        <Export onDownloadFile={() => downloadFile(filter)} />
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          fetchReport(values);
          setFilter(values);
        }}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item width={240}>
                <Field name='office'>
                  {({ field }: any) => (
                    <TextField
                      disabled
                      select
                      label='Delivery Center'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {offices.map((item: any) => (
                        <MenuItem value={item.name} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='months'>
                  {({ field }: any) => (
                    <PeriodSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='project'>
                  {({ field }: FieldProps) => (
                    <TextField
                      select
                      label='Project'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {projects.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='manager'>
                  {({ field }: any) => (
                    <TextField
                      select
                      label='Manager'
                      size='small'
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <ClearIcon
                            field={field}
                            initialValues={initialValues}
                            onClick={() =>
                              formik.setFieldValue(
                                field.name,
                                initialValues[field.name]
                              )
                            }
                          />
                        ),
                      }}
                      {...field}
                    >
                      {managers.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item width={160}>
                <Field name='status'>
                  {({ field }: any) => (
                    <StatusSelect
                      field={field}
                      formik={formik}
                      initialValues={initialValues}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item>
                <Button
                  sx={{ height: '40px' }}
                  variant='contained'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  onClick={async () => {
                    await formik.resetForm(initialValues as any);
                    await fetchReport(initialValues);
                    setFilter(initialValues);
                  }}
                  sx={{ height: '40px', marginLeft: '12px' }}
                  variant='outlined'
                  type='reset'
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {!!data?.length && (
        <Box width={1000} height={550} pt={3}>
          <ChartAppLocation data={data} />
        </Box>
      )}
    </Box>
  );
};
