import { IQuickFilter } from './types';

export const quickFiltersSearchString = (
  quickFiltersArray: IQuickFilter[],
  employeePage: string
) =>
  quickFiltersArray.reduce((acc, item) => {
    acc = acc.concat(getConcatString(item, employeePage));
    return acc;
  }, '');

const getConcatString = (filter: IQuickFilter, employeePage: string) => {
  if (filter?.selected && filter?.showFor.includes(employeePage)) {
    return `&${filter.id}=${filter.selected}`;
  }
  return '';
};
