import { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Tooltip from '@mui/material/Tooltip';
import CustomIcon from '../../common/icon';
import './Sidebar.css';
import {
  selectAccessScope,
  selectPermissions,
} from '../../../store/slices/auth/authSlice';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import AssignmentIndIcon from '@mui/icons-material/AssignmentIndOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useGetVersionQuery } from '../../EmployeesPage/api/apiSlice';

const Sidebar = () => {
  const { data: version } = useGetVersionQuery();
  const accessScope = useSelector(selectAccessScope);
  const permissions = useSelector(selectPermissions);
  const hasAccessToFeedbacks =
    accessScope && accessScope.includes('feedback_access');
  const hasAccessToReport = permissions.includes(
    'employee.view_office_details'
  );
  const hasAccessToRequest = permissions.includes(
    'employee.view_office_details'
  );
  const hasAccessToBench = permissions.includes('employee.view_global_details');
  const anyScopePermissions = [
    'employee.view_manager_info',
    'employee.view_office_details',
    'employee.view_global_details',
    'employee.view_staff_details',
    'employee.view_supervised_details',
  ];
  const hasAccessToCDP = permissions.some((userPermission: string) =>
    anyScopePermissions.includes(userPermission)
  );

  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };
  // todo: replace type with react component
  const renderLink = (linkPath: string, linkName: string, icon: any) => {
    return (
      <NavLink to={`/${linkPath}`}>
        {({ isActive }) => (
          <div
            className={classNames('sidebar-row sidebar-option', {
              'selected-tab': isActive,
            })}
          >
            <Tooltip title={linkName} arrow placement='right'>
              <div className='sidebar-row-svg'>{icon}</div>
            </Tooltip>
            {isSidebarExpanded && (
              <div className='sidebar-row-text'>{linkName}</div>
            )}
          </div>
        )}
      </NavLink>
    );
  };

  return (
    <div
      className={
        isSidebarExpanded ? 'sidebar-cont expanded' : 'sidebar-cont collapsed'
      }
    >
      <Scrollbars
        className='scrollbars scrollbars__add-project'
        autoHide
        hideTracksWhenNotNeeded
        style={{ marginBottom: '-5px' }}
      >
        <div className='sidebar-container'>
          <div className='sidebar-row'>
            <div className='sidebar-row-svg' onClick={toggleSidebar}>
              <CustomIcon iconName='sidebar-menu' />
            </div>
            {isSidebarExpanded && (
              <Link to='/' className={'logo text-expanded'}>
                <CustomIcon iconName='sidebar-logo' />
              </Link>
            )}
          </div>
          {/*todo: replace with IconComponent*/}
          {renderLink(
            'employees',
            'Search Employees',
            <CustomIcon iconName={'search-magnifier'} />
          )}
          {hasAccessToFeedbacks &&
            renderLink(
              'feedbacks',
              'Feedbacks',
              <CustomIcon iconName={'sidebar-feedback'} />
            )}
          {hasAccessToReport &&
            renderLink(
              'report',
              'Reports',
              <DescriptionIcon sx={{ color: 'white' }} />
            )}
          {hasAccessToRequest &&
            renderLink(
              'requests',
              'Manager Changes',
              <SendIcon sx={{ color: 'white' }} />
            )}
          {hasAccessToBench &&
            renderLink(
              'bench',
              'Bench Management',
              <EventSeatIcon sx={{ color: 'white' }} />
            )}
          {hasAccessToBench &&
            renderLink(
              'bench-summary',
              'Bench Summary',
              <AssignmentIndIcon sx={{ color: 'white' }} />
            )}
          {hasAccessToCDP &&
            renderLink(
              'cdps',
              'CDP',
              <ContentPasteIcon sx={{ color: 'white' }} />
            )}
        </div>
        {/*  todo: we can change color by current color to highlight the active link*/}
      </Scrollbars>
      {/*todo: refactor*/}
      <div className='sidebar-footer'>
        <hr className='split-dashboard' />
        <span className='version-tools'>
          {isSidebarExpanded && `${version || '...'}`}
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
