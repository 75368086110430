import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';

interface IOnCancelHandler {
  path: string;
  dispatch: any;
  navigate: any;
  dialogHeader: string;
  dialogTitle?: string;
  onConfirm?: () => void;
}

export function onCancelHandler({
  path,
  dispatch,
  navigate,
  dialogHeader,
  dialogTitle,
  onConfirm,
}: IOnCancelHandler) {
  dispatch(
    // @ts-ignore
    showDialog(ConfirmationDialog, {
      dialogHeader,
      dialogTitle: dialogTitle || 'Changes you made will not be saved.',
      closeButtonTitle: 'No',
      confirmButtonTitle: 'Yes',
      closeDialog: (dialogName: string) => dispatch(closeDialog(dialogName)),
      confirmDialog: () => {
        dispatch(closeDialog(ConfirmationDialog.NAME));
        onConfirm?.();
        navigate(path);
      },
    })
  );
}

export const bytesToSize = (bytes: number) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    return bytes + ' ' + sizes[i];
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};
