export const DIALOGS = {};

export const registerModal = (Component, componentProps) => {
  const name = Component.NAME;

  if (!name || typeof name !== 'string') {
    console.error('Modal component should have unique name.');
  }
  if (DIALOGS[name]) {
    console.error(
      `Modal ${name} already registered \r\n please specify unique name for your dialog`
    );
  }
  DIALOGS[name] = <Component {...componentProps} key={name} />;
};

export const unregisterModal = (name) => {
  delete DIALOGS[name];
};
