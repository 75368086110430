import ReactInfiniteScroll from 'react-infinite-scroller';
import { debounce } from '../../../utils/debounce';

// todo: replace all infiniteScrolls by this one component
export const InfiniteScroll = (props) => (
  <ReactInfiniteScroll
    pageStart={1}
    loadMore={debounce(props.loadMore, 500)}
    hasMore={props.hasMore}
    useWindow={false}
    initialLoad={false}
    threshold={0}
    loader={<div key={0}>Loading ...</div>}
  >
    {props.children}
  </ReactInfiniteScroll>
);
