export const manager = (
  <svg
    width='20'
    height='17'
    viewBox='0 0 20 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z'
      fill='#EF7530'
    />
    <path
      d='M8.67 9.02C8.45 9.01 8.23 9 8 9C5.58 9 3.32 9.67 1.39 10.82C0.51 11.34 0 12.32 0 13.35V16H9.26C8.47 14.87 8 13.49 8 12C8 10.93 8.25 9.93 8.67 9.02Z'
      fill='#EF7530'
    />
    <path
      d='M18.7499 12C18.7499 11.78 18.7199 11.58 18.6899 11.37L19.8299 10.36L18.8299 8.63L17.3799 9.12C17.0599 8.85 16.6999 8.64 16.2999 8.49L15.9999 7H13.9999L13.6999 8.49C13.2999 8.64 12.9399 8.85 12.6199 9.12L11.1699 8.63L10.1699 10.36L11.3099 11.37C11.2799 11.58 11.2499 11.78 11.2499 12C11.2499 12.22 11.2799 12.42 11.3099 12.63L10.1699 13.64L11.1699 15.37L12.6199 14.88C12.9399 15.15 13.2999 15.36 13.6999 15.51L13.9999 17H15.9999L16.2999 15.51C16.6999 15.36 17.0599 15.15 17.3799 14.88L18.8299 15.37L19.8299 13.64L18.6899 12.63C18.7199 12.42 18.7499 12.22 18.7499 12ZM14.9999 14C13.8999 14 12.9999 13.1 12.9999 12C12.9999 10.9 13.8999 10 14.9999 10C16.0999 10 16.9999 10.9 16.9999 12C16.9999 13.1 16.0999 14 14.9999 14Z'
      fill='#EF7530'
    />
  </svg>
);
