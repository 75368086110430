import * as Yup from 'yup';
import FormDialog from '../../../../common/modal-dialogs/FormDialog';
import { TextField } from '../../../../common/formikFormControls/TextField';
import {
  DatePickerField,
  getFormattedDate,
} from '../../../../common/formikFormControls/DatePickerField';
import { DateTime } from 'luxon';
import { useEditEmployeeOrgInfoMutation } from '../../../api/apiSlice';

interface IProlongModal {
  initialDate: string;
  initialComment?: string;
  id: number;
}

const validationSchema = Yup.object().shape({
  probation_due_date: Yup.date()
    .typeError('Required')
    .required('Required')
    .when(
      'probation_due_date_init',
      (probation_due_date_init, schema) =>
        probation_due_date_init &&
        schema.min(
          probation_due_date_init.plus({ days: 1 }),
          `This field must be later than ${probation_due_date_init.toFormat(
            'dd-MM-yyyy'
          )}`
        )
    ),
  probation_prolongation_reason: Yup.string()
    .typeError('Required')
    .required('Required'),
});

const ProlongModal = ({ initialDate, initialComment, id }: IProlongModal) => {
  const [editOrgInfo] = useEditEmployeeOrgInfoMutation();

  const onSubmit = ({
    probation_due_date,
    probation_prolongation_reason,
  }: {
    probation_due_date: DateTime;
    probation_prolongation_reason: string;
  }) => {
    editOrgInfo({
      data: {
        probation_due_date: getFormattedDate(probation_due_date),
        probation_prolongation_reason,
      },
      id,
    }).then((data: any) => {
      if (data?.error && data?.error?.data?.probation_due_date) {
        alert(data?.error?.data?.probation_due_date);
      }
    });
  };

  return (
    <FormDialog
      name={'ProlongPeriod'}
      title={'Prolongate probation period'}
      validationSchema={validationSchema}
      initialValues={{
        probation_due_date_init: DateTime.fromISO(initialDate),
        probation_due_date: '',
        probation_prolongation_reason: initialComment,
      }}
      onSubmit={onSubmit}
      className='prolong_dialog'
      submitButtonContent='Confirm'
      width='xs'
    >
      <DatePickerField
        name='probation_due_date'
        placeholder={initialDate}
        label='Date'
        isRequired
        xs={12}
      />
      {/* @ts-ignore */}
      <TextField
        name='probation_prolongation_reason'
        placeholder='Indicate a reason'
        isRequired
        xs={12}
        multiline
        rows={4}
      />
    </FormDialog>
  );
};

ProlongModal.NAME = 'ProlongPeriod';

export default ProlongModal;
