import { ScopeType, ZoneType } from './types';
import { MANAGER_SCOPE, OFFICE_SCOPE, DIRECTOR_ZONE } from '../const-variable';

export const getCanViewSensitiveInfo = (scope?: ScopeType) => {
  if (!scope) {
    return false;
  }
  return scope.includes(OFFICE_SCOPE) || scope.includes(MANAGER_SCOPE);
};

export const getCanEditSensitiveInfo = (zone?: ZoneType) => {
  if (!zone) {
    return false;
  }
  return zone.includes(DIRECTOR_ZONE);
};
