import { ManagerForm } from './ManagerForm';
import { canViewManagerForm } from '../../../../../utils/permissions/checkAccess';
import { AkvelonContactInformationRow } from './AkvelonContactInformationRow';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tooltip from '@mui/material/Tooltip';
import { ContactLink } from '../ContactLink';
import { RowTitle } from '../RowTitle';
import PersonIcon from '@mui/icons-material/Person';
import { formatEmptyValue } from '../utils';
import { useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from '../../../api/apiSlice';

export const PersonalContacts = () => {
  const params = useParams();
  const { data: employee } = useGetEmployeeQuery(Number(params.id));
  const scope = employee?.scope;
  const hasAccess = canViewManagerForm(scope);

  return (
    <>
      <RowTitle style={{ marginTop: '10px' }}>
        Akvelon contact information:
      </RowTitle>
      <AkvelonContactInformationRow
        icon={
          <Tooltip title={'Delivery Center'}>
            <LocationCityIcon fontSize={'small'} color={'primary'} />
          </Tooltip>
        }
      >
        {formatEmptyValue(employee?.office?.name)}
      </AkvelonContactInformationRow>

      <AkvelonContactInformationRow
        icon={
          <Tooltip title={'Akvelon Email'}>
            <EmailIcon fontSize={'small'} color={'primary'} />
          </Tooltip>
        }
      >
        {employee.email}
      </AkvelonContactInformationRow>

      {!employee.no_linkedin && (
        <AkvelonContactInformationRow
          icon={
            <Tooltip title={'Linkedin link'}>
              <LinkedInIcon fontSize={'small'} color={'primary'} />
            </Tooltip>
          }
        >
          {employee.linkedin_url ? (
            <a
              href={`${employee.linkedin_url}`}
              target='_blank'
              rel='noreferrer'
            >
              LinkedIn profile
            </a>
          ) : (
            'Missing link'
          )}
        </AkvelonContactInformationRow>
      )}

      <AkvelonContactInformationRow
        icon={
          <Tooltip title={'Main Phone'}>
            <PhoneIcon fontSize={'small'} color={'primary'} />
          </Tooltip>
        }
      >
        {formatEmptyValue(employee.cell)}
      </AkvelonContactInformationRow>

      {employee.manager && (
        <ContactLink
          icon={
            <Tooltip title={'Resource Manager'}>
              <SendIcon fontSize={'small'} color={'primary'} />
            </Tooltip>
          }
          employee={employee.manager}
        />
      )}
      {hasAccess && <ManagerForm />}

      {employee.hr && (
        <ContactLink
          title={'HR: '}
          icon={
            <Tooltip title={'HR manager'}>
              <PersonIcon fontSize={'small'} color={'primary'} />
            </Tooltip>
          }
          employee={employee.hr}
        />
      )}
    </>
  );
};
