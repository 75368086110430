import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { relogin, selectLoginError } from '../../LoginPage/slice/loginSlice';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LoginDialog: FC<Props> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const error = useSelector(selectLoginError);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    // @ts-ignore
    username && password && dispatch(relogin({ username, password }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your username and password.
        </DialogContentText>
        {error && (
          <DialogContentText color={'red'}>
            Username or Password is incorrect
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin='dense'
          id='username'
          label='Username'
          type='text'
          fullWidth
          variant='standard'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          margin='dense'
          id='password'
          label='Password'
          type='password'
          fullWidth
          variant='standard'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleSubmit()}>Login</Button>
      </DialogActions>
    </Dialog>
  );
};
