import FormDialog from '../../../common/modal-dialogs/FormDialog';
import { Button } from '@mui/material';
import { TextField } from '../../../common/formikFormControls/TextField';
import { SelectField } from '../../../common/formikFormControls/SelectField';
import { FileField } from '../../../common/formikFormControls/FileField';
import * as Yup from 'yup';
import {
  useAddEmployeeCVMutation,
  useEditEmployeeCVMutation,
  useGetEmployeeFiltersQuery,
} from '../../api/apiSlice';
import { CvItem } from '../../api/apiSlice.types';
import './employeeCVsList.css';

interface Props {
  isNewCV: boolean;
  canEditCV: boolean;
  employeeId: string;
  data: CvItem;
}

export interface FormDataProps {
  comment: string;
  project?: string;
  role?: string;
  files: {
    file: any;
    name?: string;
  };
}

const MAX_SIZE = 10000000;
const MIN_SIZE = 1000;

const getOptions = (options: Array<{ name: string; id: number }>) =>
  options.map((item) => ({
    label: item.name,
    value: item.id,
  }));

const CVModal = ({ data, isNewCV, canEditCV, employeeId }: Props) => {
  const [editEmployeeCV] = useEditEmployeeCVMutation();
  const [addEmployeeCV] = useAddEmployeeCVMutation();
  const { data: feedbackFilters, isFetching: isFetchingFilters } =
    useGetEmployeeFiltersQuery();

  const submitCV = (values: FormDataProps) => {
    if (isNewCV) {
      addCV(values);
    } else {
      editCV(values);
    }
  };

  const addCV = async (values: any) => {
    const formData: any = new FormData();
    if (values.files) {
      const file = new File(
        [values.files.file],
        values.files.file.name.toLowerCase(),
        { type: values.files.file.type }
      );
      formData.append('file', file);
      if (values.files.name) {
        formData.append('file_name', values.files.name.toLowerCase());
      }
    }
    formData.append('project', values.project || '');
    formData.append('role', values.role || '');
    formData.append('comment', values.comment);
    formData.append('employee_id', employeeId);
    try {
      await addEmployeeCV(formData).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const editCV = async (values: FormDataProps) => {
    const formData: any = new FormData();
    if (values.files.file.size) {
      const file = new File(
        [values.files.file],
        values.files.file.name.toLowerCase(),
        { type: values.files.file.type }
      );
      formData.append('file', file);
      if (values.files.name) {
        formData.append('file_name', values.files.name.toLowerCase());
      }
    }
    formData.append('project', values.project || '');
    formData.append('role', values.role || '');
    formData.append('comment', values.comment);
    formData.append('employee_id', employeeId);
    try {
      await editEmployeeCV({ formData, cvId: data.id }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const renderActionButtons = (args: {
    submitForm: () => void;
    onClose: () => void;
    isValid: boolean;
    isSubmitting: boolean;
  }) => {
    const { submitForm, onClose, isValid, isSubmitting } = args;
    return (
      <div className='cv_modal_action_buttons'>
        <Button disabled={!isValid} onClick={submitForm} className='submit_btn'>
          SAVE CHANGES
        </Button>
        <Button
          disabled={isSubmitting}
          onClick={onClose}
          className='cancel_btn'
        >
          CANCEL
        </Button>
      </div>
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      comment: Yup.string().required('Notes is a required field'),
      files: !data.file_url
        ? Yup.object()
            .required('You need to provide a file')
            .test(
              'fileSize',
              'The file is too large',
              (value: any) => value?.file.size <= MAX_SIZE
            )
            .test(
              'fileSize',
              'The file is too small',
              (value: any) => value?.file.size >= MIN_SIZE
            )
        : Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              'fileSize',
              'The file is too large',
              (value) => !value.name || value?.file.size <= MAX_SIZE
            )
            .test(
              'fileSize',
              'The file is too small',
              (value) => !value.name || value?.file.size >= MIN_SIZE
            ),
    });

  const initialValues = {
    project: data.project || '',
    role: data.role || '',
    comment: data.comment || '',
    files: {
      file: {
        file_url: data.file_url,
      },
    },
  };

  return (
    <FormDialog
      name={'Add/Edit CV'}
      title={isNewCV ? 'Adding new CV' : 'Edit CV'}
      validationSchema={validationSchema()}
      initialValues={isNewCV ? {} : initialValues}
      onSubmit={submitCV}
      className='add_new_CV_dialog'
      actionButtons={renderActionButtons}
    >
      {/* @ts-ignore */}
      <SelectField
        name='project'
        placeholder='Project name'
        xs={6}
        options={getOptions(feedbackFilters?.projects || [])}
        isRequired
        getValue={(item) => item?.label}
        isLoading={isFetchingFilters}
        isCreatable
      />
      {/* @ts-ignore */}
      <SelectField
        name='role'
        placeholder='Position'
        xs={6}
        options={getOptions(feedbackFilters?.positions || [])}
        isRequired
        getValue={(item) => item?.label}
        isLoading={isFetchingFilters}
      />
      {/* @ts-ignore */}
      <TextField
        name='comment'
        placeholder='Add some notes'
        isRequired
        xs={12}
        multiline
        rows={4}
        isSubmitting={!canEditCV}
      />
      {/* @ts-ignore */}
      <FileField
        name='files'
        accept='.docx, .pdf'
        xs={12}
        isNewFile={isNewCV}
        isRequired
      />
    </FormDialog>
  );
};

CVModal.NAME = 'Add/Edit CV';

export default CVModal;
