import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RouteTabs from '../../../../common/tabs/tabs';
import './TabNav.css';
import { useGetEmployeeQuery } from '../../../api/apiSlice';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';
import {
  canViewCDP,
  canViewSkills,
  hasAccessToCV,
  hasAccessToOrg,
} from '../../../../../utils/permissions/checkAccess';
import { hasAccessToFeedbacks } from '../../../../../utils/permissions/feedbacks';
import { ScopeType } from '../../../../../utils/permissions/types';

export const TabNav = () => {
  const { id } = useParams();
  const userId = useSelector(selectEmployeeId);
  const { data: employee } = useGetEmployeeQuery(Number(id));
  const isOwnProfile = employee?.id === userId;

  // @ts-ignore
  const scope = employee?.scope || ('' as ScopeType);

  return (
    <RouteTabs
      className='profile-block__tabs'
      linkList={[
        {
          name: 'General Info',
          access: true,
          link: `/employees/${id}/`,
        },
        {
          name: 'CV',
          access: hasAccessToCV(scope) && !isOwnProfile,
          link: `/employees/${id}/cvs`,
        },
        {
          name: 'Skills',
          access: canViewSkills(scope, isOwnProfile),
          link: `/employees/${id}/skills`,
        },
        {
          name: 'Feedbacks',
          access: hasAccessToFeedbacks(scope) && !isOwnProfile,
          link: `/employees/${id}/feedbacks`,
        },
        {
          name: 'CDP',
          access: canViewCDP(scope) && !isOwnProfile,
          link: `/employees/${id}/cdps`,
        },
        {
          name: 'CDP',
          access: isOwnProfile,
          link: `/employees/${id}/cdps`,
        },
        {
          name: 'Project History',
          access: true,
          link: `/employees/${id}/projects`,
        },
        {
          name: 'Organizational info',
          access: hasAccessToOrg(scope) && !isOwnProfile,
          link: `/employees/${id}/org`,
        },
      ]}
    />
  );
};
