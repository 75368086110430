export const columnsToDisplayCVList = [
  {
    Name: '',
    Id: 'cv_status',
    className: 'smallest-col cv-status-col',
    deleteArrow: 'delete-arrow',
  },
  {
    Name: 'CV Created',
    Id: 'created_date',
    className: 'small-col',
    deleteArrow: 'delete-arrow',
    isDate: true,
  },
  {
    Name: 'Created By',
    Id: 'creator',
    className: 'big-col',
    deleteArrow: 'delete-arrow',
    isObject: true,
  },
  {
    Name: 'Projects',
    Id: 'project',
    className: 'big-col',
    deleteArrow: 'delete-arrow',
  },
  {
    Name: 'Role',
    Id: 'role',
    className: 'big-col',
    deleteArrow: 'delete-arrow',
  },
  {
    Name: 'CV reviewed',
    Id: 'reviewed_date',
    className: 'small-col',
    deleteArrow: 'delete-arrow',
  },
  {
    Name: 'Reviewed by',
    Id: 'reviewer',
    className: 'custom-col',
    deleteArrow: 'delete-arrow',
    isObject: true,
  },
  {
    Name: 'File',
    Id: 'file_url',
    className: 'xs-col cv-file',
    isSelected: true,
    deleteArrow: 'delete-arrow',
  },
  {
    Name: 'Operation',
    Id: 'operation',
    className: 'small-col operation',
    isSelected: true,
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
  {
    Name: 'CV Notes',
    Id: 'comment',
    className: 'small-col notes',
    isSelected: true,
    deleteArrow: 'delete-arrow',
  },
  {
    Name: '',
    Id: 'action',
    className: 'action-col',
    deleteArrow: 'delete-arrow',
    hasPermission: true,
  },
];
