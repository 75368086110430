import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  useEditEmployeeOrgInfoMutation,
  useGetBenchTagsQuery,
} from '../../EmployeesPage/api/apiSlice';
import { ITagsState } from '../types';

interface IProps {
  tags: ITagsState;
  open: boolean;
  setTagsToEdit: (value: ITagsState) => void;
  setIsOpenDialog: (value: boolean) => void;
}

export const TagsDialog = ({
  open,
  tags,
  setTagsToEdit,
  setIsOpenDialog,
}: IProps) => {
  const [editOrgInfo] = useEditEmployeeOrgInfoMutation();
  const { data, isLoading } = useGetBenchTagsQuery();

  const handleSubmit = () => {
    editOrgInfo({
      id: Number(tags.id),
      data: { bench_tags: tags.tags },
    });
    setIsOpenDialog(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      sx={{ '& .MuiDialog-paper': { width: '500px' } }}
    >
      <DialogTitle>
        {tags.tags.length > 0 ? 'Editing Tags' : 'Adding Tags'}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ paddingTop: '8px' }}
          fullWidth={true}
          multiple
          disableCloseOnSelect
          loading={isLoading}
          options={data || []}
          value={tags.tags}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField sx={{}} {...params} label='Tags' />
          )}
          renderOption={(props, option, data) => {
            return (
              <li {...props}>
                <Checkbox checked={data.selected} />
                {option.name}
              </li>
            );
          }}
          onChange={(_e, value) => setTagsToEdit({ id: tags.id, tags: value })}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsOpenDialog(false)}
          sx={{ color: '#797979' }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
