import { FeedbackOutlined as FeedbackOutlinedIcon } from '@mui/icons-material';

import { redCross } from './red-cross';
import { greenTick } from './green-tick';
import { headerArrow } from './header-arrow';
import { dots } from './three-dots';
import { trash } from './trash';
import { plusOrange } from './plus-orange';
import { cross } from './cross';
import { arrowLeftDetails } from './arrow-left-details';
import { exportIcon } from './export';
import { sidebarVacation } from './sidebar-vacation';
import { editPencil } from './edit-pencil';
import { dropdownTriangle } from './dropdown-triangle';
import { sidebarMenu } from './sidebar-menu';
import { sidebarLogo } from './sidebar-logo';
import { sidebarDashboard } from './sidebar-dashboard';
import { sidebarReports } from './sidebar-reports';
import { sidebarEmployees } from './sidebar-employees';
import { sidebarClients } from './sidebar-clients';
import { sidebarPO } from './sidebar-po';
import { sidebarManageUser } from './sidebar-manageuser';
import { sidebarSettings } from './sidebar-settings';
import { sidebarHierarchy } from './sidebar-hierarchy';
import { listToolbarSettings } from './list-toolbar-settings';
import { questionMark } from './question-mark';
import { crossSmall } from './cross-small';
import { arrowHistory } from './arrow-history';
import { pencil } from './pencil';
import { personGroup } from './person-group';
import { searchMagnifier } from './search-magnifier';
import { deleteSearchbox } from './delete-searchbox';
import { filterSettingsOff } from './filters-settings-off';
import { filtersSettingsOn } from './filters-settings-on';
import { configIconOff } from './configuration-icon-off';
import { configIconOn } from './configuration-icon-on';
import { confirmDialogCross } from './confirmation-dialog-cross';
import { confirmDialogQuestion } from './confirmation-dialog-question';
import { adminLookups } from './admin-lookups';
import { files } from './files';
import { redClock } from './red-clock';
import { arrowLeft } from './arrow-left';
import { arrowRight } from './arrow-right';
import { arrowUp } from './arrow-up';
import { arrowDown } from './arrow-down';
import { revert } from './revert';
import { merge } from './merge';
import { accept } from './accept';
import { location } from './location';
import { birthDate } from './birth-date';
import { calendarAkvelon } from './calendar-akvelon';
import { portfolio } from './portfolio';
import { manager } from './manager';
import { house } from './house';
import { email } from './email';
import { phone } from './phone';
import { report } from './report';
import { skype } from './skype';
import { file } from './file';
import { certificateLink } from './certificate-link';
import { plus } from './plus';
import { unconfirmed } from './unconfirmed';
import { confirmed } from './confirmed';
import { otherContacts } from './other-contacts';
import { checkMark } from './check-mark';
import { crossXS } from './cross-xs';
import { emptyPhoto } from './empty-photo';
import { add } from './add';
import { blueArrowRight } from './blue-arrow-right';
import { blueArrowDown } from './blue-arrow-down';
import { checkGreenCircle } from './check-green-circle';
import { warningYellowTriangle } from './warning-yellow-triangle';
import { archived } from './archived';
import { upload } from './upload';
import { questionary } from './questionary';
import { skillUncofirmed } from './skill-unconfirmed';
import { infoIcon } from './info-icon';
import { brilliant } from './brilliant';

export default {
  'green-tick': greenTick,
  'red-cross': redCross,
  'header-arrow': headerArrow,
  'three-dots': dots,
  trash: trash,
  'plus-orange': plusOrange,
  cross: cross,
  'arrow-left-details': arrowLeftDetails,
  export: exportIcon,
  'sidebar-vacation': sidebarVacation,
  'edit-pencil': editPencil,
  'dropdown-triangle': dropdownTriangle,
  'sidebar-menu': sidebarMenu,
  'sidebar-logo': sidebarLogo,
  'sidebar-dashboard': sidebarDashboard,
  'sidebar-reports': sidebarReports,
  'sidebar-employees': sidebarEmployees,
  'sidebar-clients': sidebarClients,
  'sidebar-po': sidebarPO,
  'sidebar-manageuser': sidebarManageUser,
  'sidebar-feedback': <FeedbackOutlinedIcon style={{ color: 'white' }} />,
  'sidebar-settings': sidebarSettings,
  'sidebar-hierarchy': sidebarHierarchy,
  'list-toolbar-settings': listToolbarSettings,
  'question-mark': questionMark,
  'cross-small': crossSmall,
  'arrow-history': arrowHistory,
  pencil: pencil,
  'person-group': personGroup,
  'search-magnifier': searchMagnifier,
  'delete-searchbox': deleteSearchbox,
  'filters-settings-off': filterSettingsOff,
  'filters-settings-on': filtersSettingsOn,
  'configuration-icon-off': configIconOff,
  'configuration-icon-on': configIconOn,
  'confirmation-dialog-cross': confirmDialogCross,
  'confirmation-dialog-question': confirmDialogQuestion,
  'admin-lookups': adminLookups,
  files: files,
  'red-clock': redClock,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  'arrow-down': arrowDown,
  revert: revert,
  merge: merge,
  accept: accept,
  location: location,
  'birth-date': birthDate,
  'calendar-akvelon': calendarAkvelon,
  portfolio: portfolio,
  manager: manager,
  house: house,
  email: email,
  phone: phone,
  report: report,
  skype: skype,
  file: file,
  'certificate-link': certificateLink,
  plus: plus,
  unconfirmed: unconfirmed,
  confirmed: confirmed,
  'other-contacts': otherContacts,
  'check-mark': checkMark,
  'cross-xs': crossXS,
  'empty-photo': emptyPhoto,
  add: add,
  'blue-arrow-right': blueArrowRight,
  'blue-arrow-down': blueArrowDown,
  'check-green-circle': checkGreenCircle,
  'warning-yellow-triangle': warningYellowTriangle,
  archived: archived,
  upload: upload,
  questionary: questionary,
  'skill-unconfirmed': skillUncofirmed,
  'info-icon': infoIcon,
  brilliant: brilliant,
};
