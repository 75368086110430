import { Provider } from 'react-redux';
import store from './store/store';
import Router from './Router';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { theme } from './styles';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Interceptor } from './Interceptor';
import './components/common/formikFormControls/FormControl.css'; // TODO: this is wrong. this import does not belong here
import './styles/app.css';
import './styles/dialogs.css';
import './styles/redux-form.css'; // todo: review
import ToastProvider from './ToastProvider';

const App = () => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <ToastProvider>
      <ThemeProvider theme={theme}>
        <div className='app'>
          <CssBaseline />
          <Provider store={store}>
            <Interceptor>
              <Router />
            </Interceptor>
          </Provider>
        </div>
      </ThemeProvider>
    </ToastProvider>
  </LocalizationProvider>
);

export default App;
