import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { keyboardKeys, muiSizes } from '../../../utils/const-variable';
import { CustomFormFieldset } from '../formikFormControls/CustomFormFieldset';
import { closeDialog as closeModalDialog } from '../../../store/slices/modalDialog/modalDialogSlice';
import './form-dialog.css';

const isEmptyArray = (array) => !array.length;

const getList = (array, className = '') => (
  <ul className={className}>
    {array.map((item) => (
      <li>{item}</li>
    ))}
  </ul>
);

const FormDialog = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef();

  const closeDialog = () => {
    const { name, updateData } = props;
    dispatch(closeModalDialog(name));

    updateData && updateData();
  };

  const renderWarnings = () => {
    const { warnings } = props;
    if (isEmptyArray(warnings)) {
      return null;
    }
    return getList(warnings, 'formik_warnings');
  };

  const renderComments = () => {
    const { comments } = props;
    if (isEmptyArray(comments)) {
      return null;
    }
    return getList(comments);
  };

  const {
    name,
    title,
    children,
    submitButtonContent,
    closeOnEscape,
    submitOnEnter,
    onClose,
    onDelete,
    onSubmit,
    initialValues,
    enableReinitialize,
    validationSchema,
    validateOnMount,
    validateOnChange,
    validateOnBlur,
    validate,
    width,
    isActionsRequired,
    isNeededToCloseDialog = true,
    fullHeight,
    className = '',
    actionButtons,
    onTouchForm,
    footer,
  } = props;

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby={`${name}-title`}
      fullWidth={!!width}
      maxWidth={width}
      onKeyDown={(event) => {
        if (event.keyCode === keyboardKeys.Escape && closeOnEscape) {
          closeDialog();
        }
        if (event.keyCode === keyboardKeys.Enter && submitOnEnter) {
          formRef.current.handleSubmit();
        }
      }}
      className={className}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues || {}}
        enableReinitialize={enableReinitialize}
        validateOnMount={!validationSchema && validateOnMount}
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        validate={!validationSchema && validate}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
          isNeededToCloseDialog && closeDialog();
        }}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          isSubmitting,
          errors,
          isValid,
          touched,
          setFieldTouched,
        }) => (
          <>
            <DialogTitle className='dialog_title' id={`${name}-title`}>
              {title}
            </DialogTitle>
            <DialogContent
              className='dialog_content'
              dividers
              sx={{ height: fullHeight ? '100vh' : undefined }}
            >
              <Form>
                <CustomFormFieldset
                  formProps={{
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    setFieldTouched,
                  }}
                  onTouchForm={onTouchForm}
                >
                  {children}
                </CustomFormFieldset>
              </Form>
              {renderWarnings()}
              {renderComments()}
              {footer}
            </DialogContent>
            {isActionsRequired && (
              <DialogActions className='dialog_actions'>
                {actionButtons ? (
                  actionButtons({
                    submitForm,
                    isSubmitting,
                    isValid,
                    onClose: closeDialog,
                  })
                ) : (
                  <>
                    {onDelete && (
                      <Button className='cancel_btn' onClick={onDelete}>
                        Delete
                      </Button>
                    )}
                    <Button
                      disabled={isSubmitting}
                      onClick={closeDialog}
                      className='cancel_btn'
                    >
                      Cancel
                    </Button>
                    {onSubmit && (
                      <Button
                        disabled={!isValid}
                        onClick={submitForm}
                        className='submit_btn'
                      >
                        {submitButtonContent}
                      </Button>
                    )}
                  </>
                )}
              </DialogActions>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
};

FormDialog.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButtonContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.func,
  ]),
  initialValues: PropTypes.object,
  closeOnEscape: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  validateOnMount: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validate: PropTypes.func,
  validationSchema: PropTypes.object,
  width: PropTypes.string,
  isActionsRequired: PropTypes.bool,
  fullHeight: PropTypes.bool,
  warnings: PropTypes.array,
  comments: PropTypes.array,
  onTouchForm: PropTypes.func,
  className: PropTypes.string,
  actionButtons: PropTypes.func,
};

FormDialog.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  submitButtonContent: 'Submit',
  initialValues: {},
  closeOnEscape: true,
  submitOnEnter: true,
  enableReinitialize: false,
  validateOnMount: false,
  validateOnChange: true,
  validateOnBlur: false,
  validate: undefined,
  validationSchema: undefined,
  width: muiSizes.sm,
  isActionsRequired: true,
  fullHeight: false,
  warnings: [],
  comments: [],
};

export default FormDialog;
