import { Route, Routes } from 'react-router-dom';
import { FeedbacksList } from './components/FeedbacksList/FeedbacksList';
import { FeedbackCreate } from './components/FeedbackCreate/FeedbackCreate';

export const Feedbacks = () => {
  return (
    <Routes>
      <Route index element={<FeedbacksList />} />
      <Route path='/create' element={<FeedbackCreate />} />
    </Routes>
  );
};
