import { FC } from 'react';
import { ChecklistItem } from './ChecklistItem';
import { Box, Typography } from '@mui/material';
import './checklist.css';
import {
  ChecklistItemInterface,
  ChecklistInterface,
} from '../../EmployeesPage/api/apiSlice.types';

interface Props {
  onChange?: (checklistItem: ChecklistItemInterface) => void;
  checklist: ChecklistInterface;
  disabled?: boolean;
}

export const Checklist: FC<Props> = ({
  checklist,
  onChange,
  disabled = false,
}) => {
  return (
    <Box>
      <Typography variant='subtitle2'>{checklist.name}</Typography>
      {checklist.items?.length && (
        <Box component='ol'>
          {checklist.items.map((question: ChecklistItemInterface) => (
            <Box
              component='li'
              sx={{ mb: 2 }}
              key={`${question.question}${question.order}`}
            >
              <ChecklistItem
                item={question}
                onChange={onChange}
                disabled={disabled}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
