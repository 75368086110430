import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ item, column, config }) => {
  const render = config[column.Id].render;
  return (
    <Stack direction='row' alignItems='center'>
      {render(item)}
    </Stack>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellActions = withCellWrapper(Cell, 'without_padding');
