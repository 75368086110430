export const email = (
  <svg
    width='16'
    height='13'
    viewBox='0 0 16 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 0.5H2C1.175 0.5 0.5075 1.175 0.5075 2L0.5 11C0.5 11.825 1.175 12.5 2 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5ZM14 3.5L8 7.25L2 3.5V2L8 5.75L14 2V3.5Z'
      fill='#EF7530'
    />
  </svg>
);
