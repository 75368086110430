import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { FilterConstructor } from './FilterConstructor';
import { FC, useEffect, useState } from 'react';
import { SearchField } from '../../../common/list-toolbar/components/search-field';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSkillFilter,
  selectFilters,
  selectSkillFilter,
  selectSkills,
} from '../../slices/employeesSlice';
import { FiltersOptions } from './types';
import { SkillFilterPanel, SkillRow } from './SkillFilterPanel';
import { SkillFilter } from '../../slices/employeeSlice.types';

type Props = {
  searchEmployees: ({
    filters,
    skills,
    searchValue,
    skillFilter,
  }: {
    filters: FiltersOptions;
    skills: { id: number; name: string }[];
    searchValue: string;
    skillFilter: SkillFilter;
  }) => void;
  reset: () => void;
  searchValuePassed: string;
  isVisibleFilterTag: boolean;
};

const SearchBox: FC<Props> = (props) => {
  const { searchEmployees, reset, searchValuePassed, isVisibleFilterTag } =
    props;

  const [searchValue, setSearchValue] = useState<string>(searchValuePassed);
  const skillsInStore = useSelector(selectSkills);
  const [skills, setSkills] =
    useState<{ id: number; name: string }[]>(skillsInStore);
  const skillFilterInStore = useSelector(selectSkillFilter);
  const [skillFilter, setSkillFilter] =
    useState<SkillFilter>(skillFilterInStore);
  const filtersFromStore = useSelector(selectFilters);
  const [filters, setFilters] = useState<FiltersOptions>(filtersFromStore);

  const [isSearchRequired, setIsSearchRequired] = useState(false);

  const dispatch = useDispatch();

  // for search by text reset
  useEffect(() => {
    if (isSearchRequired) {
      searchEmployees1();
      setIsSearchRequired(false);
    }
  }, [isSearchRequired]);

  const searchEmployees1 = () => {
    searchEmployees({
      filters,
      skills,
      searchValue,
      skillFilter,
    });
  };

  const resetFilter = () => {
    reset();
    setSearchValue('');
    // @ts-ignore
    setFilters([]);
    setSkills([]);
    setSkillRows([]);
    setSkillFilter({});
    dispatch(resetSkillFilter());
  };

  const isSearchDataEmpty = () => {
    return (
      !skills.length &&
      !Object.keys(filters).length &&
      !searchValue.length &&
      !skillFilter.beginner &&
      !skillFilter.competent &&
      !skillFilter.expert &&
      !skillFilter.main_tech_skills &&
      !skillFilter.interview_enabled_skills
    );
  };

  const handleClear = () => {
    setSearchValue('');
    setIsSearchRequired(true);
  };

  const [skillRows, setSkillRows] = useState<SkillRow[]>([]);

  return (
    <Card className='search-box'>
      <SearchField
        isWide
        searchValue={searchValue}
        searchPlaceholder='Search by name'
        onSearchClick={searchEmployees1}
        handleChange={setSearchValue}
        handleClear={handleClear}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box pt={2}>
            <div>
              <Box mb={2}>
                <Typography variant={'body2'}>Search by skills</Typography>
              </Box>
              <SkillFilterPanel
                setSkillFilter={setSkillFilter}
                skillRows={skillRows}
                setSkillRows={setSkillRows}
                skillFilter={skillFilter}
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box pt={2}>
            <FilterConstructor
              filters={filters}
              setFilters={setFilters}
              isVisibleFilterTag={isVisibleFilterTag}
            />
          </Box>
        </Grid>
      </Grid>

      <div className='search-box_footer'>
        <Stack direction='row' spacing={2}>
          <Button onClick={resetFilter}>Reset</Button>
          <Button
            variant={'contained'}
            onClick={searchEmployees1}
            disabled={isSearchDataEmpty()}
          >
            Apply
          </Button>
        </Stack>
      </div>
    </Card>
  );
};

export default SearchBox;
