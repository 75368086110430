import { DateTime } from 'luxon';
import { EMPTY_VALUE } from '../utils';

export const getTimeInProfession = (year: number) => {
  if (!year) {
    return EMPTY_VALUE;
  }
  const date = DateTime.fromISO(`${year}`);
  const diffYears = DateTime.now().diff(date, ['years', 'months']).toObject();
  if (diffYears.years === 1) {
    return Math.floor(diffYears.years) + ' year in profession';
  }
  if (diffYears.years && diffYears.years > 1) {
    return Math.floor(diffYears.years) + ' years in profession';
  }
  if (diffYears.months && diffYears.months === 1) {
    return Math.floor(diffYears.months) + ' month in profession';
  }
  return Math.floor(diffYears.months || 0) + ' months in profession';
};
