import { Avatar } from './utils';
import Typography from '@mui/material/Typography';
import { Grid, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../../api/apiSlice';

export const Backfills = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const backfills = employee?.backfill || [];

  return (
    <Grid item container xs={3} alignItems='center'>
      <span style={{ paddingRight: '8px' }}>Backfilled by</span>
      {backfills.length === 0 ? (
        <Typography fontSize={'small'} color={'gray.light'}>
          none
        </Typography>
      ) : null}
      {backfills.map((item) => (
        <Tooltip
          arrow
          className='profile_block__members_default_avatar'
          key={item.id}
          title={item.name}
        >
          <div>
            <Avatar item={item} />
          </div>
        </Tooltip>
      ))}
    </Grid>
  );
};
