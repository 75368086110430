import './style.css';
import {
  resetComponent as resetComponentAction,
  setComponent as setComponentAction,
} from '../../../../../store/slices/header/headerSlice';
import {
  closeDialog as closeDialogAction,
  showDialog as showDialogAction,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import { useActions } from '../../../../../hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { SkillsQuestionnaireList } from './SkillsList';
import { SkillsQuestionnaireCategories } from './Categories';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from '../../../../common/Guards/usePrompt';
import {
  useGetSkillCategoriesQuery,
  useUpdateSkillsMutation,
} from '../../../api/apiSlice';

export const SkillsQuestionnaire = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [updatedSkills, setUpdatedSkills] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data: skills = [], isSuccess } = useGetSkillCategoriesQuery();
  const [updateSkills] = useUpdateSkillsMutation();

  const [setComponent, resetComponent, showDialog, closeDialog] = useActions([
    setComponentAction,
    resetComponentAction,
    showDialogAction,
    closeDialogAction,
  ]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setComponent({
      component: {
        pathname: `/employees/${id}/skills`,
        text: 'Skills',
      },
      isLink: true,
    });

    return () => {
      resetComponent();
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setCategories(
        skills
          .filter((skillCategory) => skillCategory.skills?.length > 0)
          .map((skillCategory) => ({
            name: skillCategory.name,
            id: skillCategory.id,
          }))
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSubmitted) goToSkills();
  }, [isSubmitted]);

  useEffect(() => {
    if (categories.length) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  const handleLevelUpdate = (data) => {
    setUpdatedSkills((prevUpdatedSkills) => {
      if (!data.self_assessed_level && !data.id) {
        return prevUpdatedSkills.filter(
          (updatedSkill) => updatedSkill.skill.id !== data.skill.id
        );
      }
      if (
        prevUpdatedSkills.some(
          (updatedSkill) =>
            updatedSkill.skill.id === data.skill.id &&
            updatedSkill.skill_category.id === data.skill_category.id
        )
      ) {
        return prevUpdatedSkills.map((updatedSkill) =>
          updatedSkill.skill.id === data.skill.id &&
          updatedSkill.skill_category.id === data.skill_category.id
            ? data
            : updatedSkill
        );
      }
      return [...prevUpdatedSkills, data];
    });
  };

  const goToSkills = () => navigate(`/employees/${id}/skills`);

  const resetUpdatedSkills = () => {
    setUpdatedSkills([]);
    setIsSubmitted(true);
  };

  const closeDialogCallback = useCallback(
    (dialogName) => closeDialog(dialogName),
    [closeDialog]
  );

  const handleCancel = () => {
    if (updatedSkills.length) {
      showDialog(ConfirmationDialog, {
        dialogHeader: 'Are you sure you want to cancel editing?',
        dialogTitle: 'Changes you made will not be saved.',
        closeDialog: closeDialogCallback,
        confirmDialog: () => {
          resetUpdatedSkills();
          closeDialogCallback(ConfirmationDialog.NAME);
        },
      });
    } else {
      resetUpdatedSkills();
    }
  };

  const onSubmit = () => {
    if (updatedSkills.length) {
      updateSkills({ userId: id, skills: updatedSkills }).then(
        resetUpdatedSkills
      );
    }
  };

  const handleNext = () => {
    const currentIndex = categories.indexOf(selectedCategory);
    const index =
      currentIndex + 1 <= categories.length - 1
        ? currentIndex + 1
        : categories.length - 1;
    setSelectedCategory(categories[index]);
  };
  const handleBack = () => {
    const currentIndex = categories.indexOf(selectedCategory);
    const index = currentIndex - 1 >= 0 ? currentIndex - 1 : 0;
    setSelectedCategory(categories[index]);
  };

  const filteredSkills = useMemo(
    () =>
      (
        skills.find((category) => category?.id === selectedCategory?.id)
          ?.skills || []
      )
        .filter((skill) => skill.approved)
        .sort((a, b) => {
          const nameA = a?.name?.toUpperCase();
          const nameB = b?.name?.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    [skills, selectedCategory]
  );

  usePrompt('Changes you made will not be saved', !!updatedSkills.length);

  return (
    <div className='skills-questionnaire'>
      <Grid
        style={{ padding: '18px 36px 0' }}
        container
        justifyContent='space-between'
        alignItems='center'
      >
        <h5 className='skills__heading'>Skills Questionnaire</h5>
        <div>
          <button className='cancel__btn' onClick={handleCancel}>
            CANCEL
          </button>
          <button className='button__main' onClick={onSubmit}>
            SUBMIT
          </button>
        </div>
      </Grid>
      <Divider style={{ marginTop: 18 }} />
      <Grid container style={{ paddingLeft: '36px', height: '100%' }}>
        <SkillsQuestionnaireList
          currentCategory={selectedCategory}
          currentCategoryIndex={categories.indexOf(selectedCategory)}
          handleUpdate={handleLevelUpdate}
          updatedSkills={updatedSkills}
          skills={filteredSkills}
        />
        <SkillsQuestionnaireCategories
          currentCategory={selectedCategory}
          categories={categories}
          onClick={setSelectedCategory}
        />
      </Grid>
      <div className='skills-questionnaire__actions'>
        {categories.indexOf(selectedCategory) > 0 && (
          <button className='secondary__btn' onClick={handleBack}>
            Back
          </button>
        )}
        {categories.indexOf(selectedCategory) < categories.length - 1 && (
          <button className='button__main' onClick={handleNext}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};
