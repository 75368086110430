import { DictionaryElement, Item2 } from '../apiSlice.types';

export const dictionaryToItem = (item: DictionaryElement): Item2 => {
  const property = Object.keys(item)[0];
  const propertyValue = item[property];
  return {
    id: property,
    name: propertyValue,
  };
};
