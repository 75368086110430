import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFormattedDate } from '../../formikFormControls/DatePickerField';
import './ListItem.css';

export const ListItem = (props) => {
  const {
    item,
    columnsToDisplay,
    config,
    standardCursor,
    readMoreKey,
    readMoreIdArr,
  } = props;

  const renderListCell = (column, item) => {
    const { Id, isDate, isObject, isArray, isFullDate, valueGetter } = column;
    if (valueGetter) {
      return valueGetter(item);
    }
    if (isDate) {
      return item[Id] && getFormattedDate(item[Id]);
    }
    if (isFullDate) {
      return item[Id] && getFormattedDate(item[Id], 'yyyy-MM-dd HH:mm');
    }
    if (isObject) {
      return item[Id] && item[Id].name;
    }
    if (item[Id] && isArray) {
      return item[Id].map((item) => item.name || item).join(', ');
    }
    return !!item[Id] ? (
      <span title={item[Id]}>{item[Id]}</span>
    ) : column.id === 'Action' ? null : (
      '———'
    );
  };

  const renderCustomCell = (CustomCell, column) => {
    return (
      <CustomCell
        {...props}
        column={column}
        key={(item.id || JSON.stringify(item)) + Math.random()}
      />
    );
  };

  const withRenderRow = (defaultRowRender) => {
    if (!props.renderRow) {
      return defaultRowRender;
    }
    return props.renderRow(defaultRowRender, props.item);
  };

  return withRenderRow(
    <>
      <div
        className={classNames('c-list__item-cont', {
          cursor_auto: standardCursor,
          'highlight-item': item.id === 0,
          'second-highlight-item': item.id === null,
          archived: item.is_archived,
        })}
        id={item.id && item.id}
      >
        {columnsToDisplay.map((column) => {
          return column.isSelected ||
            typeof column.isSelected === 'undefined' ? (
            config && config[column.Id] ? (
              renderCustomCell(config[column.Id].cell, column)
            ) : (
              <div
                key={column.Id}
                className={classNames(
                  'c-list__item-column',
                  column.className ? column.className : 'small-col',
                  {
                    array_column:
                      column.isArray &&
                      renderListCell(column, item).length >= 20,
                  },
                  { dismissed: item.employee_status === 'dismissed' }
                )}
              >
                <span
                  className='col-span'
                  style={
                    column.Id !== 'Status' && {
                      WebkitBoxOrient: 'vertical',
                    }
                  }
                  title={renderListCell(column, item)}
                >
                  {renderListCell(column, item)}
                </span>
              </div>
            )
          ) : null;
        })}
      </div>
      {readMoreIdArr &&
        !!readMoreIdArr.length &&
        readMoreIdArr.includes(item.id) && (
          <div className='read-more-row'>
            <p>{item[readMoreKey]}</p>
          </div>
        )}
    </>
  );
};

ListItem.propTypes = {
  item: PropTypes.object,
  columnsToDisplay: PropTypes.array,
  editSpan: PropTypes.string,
  showDeleteSpan: PropTypes.string,
  renderRow: PropTypes.func,
};
