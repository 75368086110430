import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  employees: [],
};

const employeesListSlice = createSlice({
  name: 'employeesList',
  initialState,
  reducers: {
    setEmployees: (state, { payload }) => {
      state.count = payload.count;
      state.employees = [...state.employees, ...payload.results];
    },
    clearEmployees: (state) => {
      state.count = initialState.count;
      state.employees = initialState.employees;
    },
  },
});

export const { setEmployees, clearEmployees } = employeesListSlice.actions;

//#region Selectors
export const selectEmployeesListReducer = (store) => store.employeesListReducer;
export const selectEmployeesListEmployees = (store) =>
  store.employeesListReducer.employees;
//#endregion

export default employeesListSlice.reducer;
