import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: false,
  redirectTo: null,
  showLoginModal: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state) => {
      state.error = false;
      state.showLoginModal = false;
    },
    setLoginError: (state) => {
      state.error = true;
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    relogin: (state) => {
      state.error = false;
    },
  },
});

export const {
  login,
  setLoginError,
  setRedirectTo,
  setShowLoginModal,
  relogin,
} = loginSlice.actions;

export const selectLoginError = (state) => state.loginReducer.error;
export const selectLoginRedirectTo = (state) => state.loginReducer.redirectTo;
export const selectShowLoginModal = (state) =>
  state.loginReducer.showLoginModal;

export default loginSlice.reducer;
