import { useSelector } from 'react-redux';
import { Chip, Grid, IconButton, Skeleton, Tooltip } from '@mui/material';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Badge } from '../../../../common/badges/Badges';
import PropTypes from 'prop-types';
import { YearsInAkvelon } from './YearsInAkvelon';
import { Vacation } from './Vacation';
import { selectIsLoading } from '../../../../../store/slices/loader/loaderSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { YearsInProfession } from './YearsInProfession';
import { Location } from './Location';
import {
  useGetEmployeeQuery,
  useGetLocationHistoryQuery,
} from '../../../api/apiSlice';
import { useParams } from 'react-router-dom';
import { canViewLocationHistory } from '../../../../../utils/permissions/checkAccess';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';

const IN_PROGRESS_STATUS = 'in_progress';
const FAILED_STATUS = 'failed';

export const ProfileInfo = () => {
  const { id } = useParams();
  const employeeId = Number(id);
  const userId = useSelector(selectEmployeeId);
  const isOwnProfile = userId === employeeId;

  const { data: employee } = useGetEmployeeQuery(employeeId);

  const hasAccess = () => {
    if (employee?.scope && !isOwnProfile) {
      return canViewLocationHistory(employee?.scope);
    }
    return false;
  };

  const { data: locationHistory } = useGetLocationHistoryQuery(employeeId, {
    skip: !hasAccess(),
  });

  const isLoading = useSelector(selectIsLoading);

  const AvatarField = () =>
    employee?.avatar_url ? (
      <img
        src={employee.avatar_url}
        alt='Avatar'
        className='profile-block__avatar'
      />
    ) : employee?.name ? (
      <div className='profile-block__default-avatar'>
        <Badge item={employee.name} />
      </div>
    ) : null;

  const ProbationLabel = () => {
    if (employee?.probation_status === IN_PROGRESS_STATUS) {
      return (
        <Tooltip title={`Ends ${employee.probation_due_date}`} placement='top'>
          <Chip
            label='Probation period'
            className='profile_probation__in_progress'
          />
        </Tooltip>
      );
    }
    if (employee?.probation_status === FAILED_STATUS) {
      return (
        <Chip
          label='Probation period failed'
          className='profile_probation__failed'
        />
      );
    }
    return null;
  };

  const CopyLinkButton = () => (
    <Tooltip
      title={`${window.location.origin}/employee/${employee?.username}`}
      placement='top'
    >
      <span>
        <CopyToClipboard
          text={`${window.location.origin}/employee/${employee?.username}`}
        >
          <IconButton>
            <ContentCopyIcon />
          </IconButton>
        </CopyToClipboard>
      </span>
    </Tooltip>
  );

  const EmployeeName = () => (
    <Grid item container alignItems={'center'}>
      <Grid item>
        <span className='profile-block__name'>{employee?.name}</span>
      </Grid>
      <Grid item container xs={'auto'} columnSpacing={1} alignItems='center'>
        <Grid item>
          <CopyLinkButton />
        </Grid>
        <Grid item>
          <Vacation />
        </Grid>
        <Grid item>
          <ProbationLabel />
        </Grid>
      </Grid>
    </Grid>
  );

  const getLocationTitle = () => {
    let locationTitle = [];
    if (employee?.employee_physical_location?.country) {
      locationTitle.push(employee.employee_physical_location.country);
    }
    if (employee?.employee_physical_location?.city) {
      locationTitle.push(`${employee.employee_physical_location.city}`);
    }
    return locationTitle.join(', ');
  };

  const locationTitle = getLocationTitle();

  return (
    <Grid
      container
      pb={2}
      direction='column'
      rowSpacing={3}
      position={'relative'}
    >
      <Grid item container>
        <Grid item xs={2}>
          {!isLoading ? (
            <AvatarField />
          ) : (
            <Skeleton variant='circular' width={90} height={90} />
          )}
        </Grid>
        <Grid item container direction='column' xs={10} rowSpacing={2}>
          {!isLoading ? <EmployeeName /> : <Skeleton width={250} height={49} />}
          {!isLoading ? (
            <Grid item xs={2}>
              <span className='profile-block__position'>{employee?.title}</span>
            </Grid>
          ) : (
            <Skeleton width={250} />
          )}
        </Grid>
      </Grid>
      {!isLoading && (
        <Grid item container xs={12} columnSpacing={5} rowSpacing={1}>
          <Location
            title={locationTitle}
            showTooltip={hasAccess()}
            locationHistory={locationHistory}
          />
          <YearsInAkvelon startDate={employee?.start_date_in_company} />
          <YearsInProfession startYear={employee?.year_start_in_profession} />
        </Grid>
      )}
    </Grid>
  );
};

ProfileInfo.propTypes = {
  employee: PropTypes.object.isRequired,
};
