import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactNode, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import {
  useEditFeedbackMutation,
  useGetEmployeeFiltersQuery,
  useGetFeedbackFiltersQuery,
  useGetFeedbackQuery,
} from '../../api/apiSlice';
import { Checklist } from '../../../common/checklist/Checklist';
import { getFormattedDate } from '../../../common/formikFormControls/DatePickerField';
import './Feedbacks.css';
import classNames from 'classnames';
import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import ResolveFeedbackModal from './ResolveFeedbackModal';
import { selectEmployeeZone } from '../../slices/employeesSlice';
import { FeedbackFormDataInterface } from '../../api/apiSlice.types';
import { canResolveOrEditFeedback } from '../../../../utils/permissions/feedbacks';
import { EMPTY_VALUE, formatEmptyValue } from '../EmployeeProfile/utils';
import { TasksList } from './Tasks/TasksList';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';

const RESOLVED_RISK_LEVEL = 'resolved';
const EXCLUDED_RISKS = ['resolved', 'unknown'];

export const FeedbackPage = () => {
  const dispatch = useDispatch();
  const { id, feedbackId } = useParams();
  const { state } = useLocation();
  const { data: employeeFilters } = useGetEmployeeFiltersQuery();
  const positions = employeeFilters?.positions;

  const zone = useSelector(selectEmployeeZone);

  useEffect(() => {
    const pathname = state ? state.pathname : `/employees/${id}/feedbacks`;
    dispatch(
      setComponent({
        component: {
          pathname,
          text: 'Feedbacks',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
      dispatch(closeDialog(ResolveFeedbackModal.NAME));
    };
  }, []);

  // @ts-ignore
  const { data: filters } = useGetFeedbackFiltersQuery();
  const {
    data: feedback,
    isFetching,
    isSuccess,
    isError,
  } = useGetFeedbackQuery({
    employeeId: Number(id),
    feedbackId: Number(feedbackId),
  });
  const [editFeedback] = useEditFeedbackMutation();

  if (isError) {
    return (
      <Box mx={3}>
        <h2>Feedback not found</h2>
      </Box>
    );
  }

  const InfoField = ({
    label,
    text,
  }: {
    label: string;
    text: string | ReactNode;
  }) => (
    <div>
      <h4>{label}</h4>
      <p>{text}</p>
    </div>
  );

  const LinkField = ({
    label,
    props,
  }: {
    label: string;
    props: { id: number; name: string };
  }) => (
    <div className='feedback_page__content__link_field'>
      <h4>{label}</h4>
      {props ? (
        <Link to={`/employees/${props.id}/`}>
          <span>{formatEmptyValue(props.name)}</span>
        </Link>
      ) : (
        EMPTY_VALUE
      )}
    </div>
  );

  const RiskField = ({ status, text }: { status: string; text: string }) => (
    <div className='feedback_page__risk'>
      <h4>Risk</h4>
      <span
        className={classNames({
          // todo: should have default class. and others just changing color
          risk_no_riks: status === 'unknown',
          risk_danger: status === 'danger',
          risk_warning: status === 'warning',
          risk_resolved: status === 'resolved',
        })}
      >
        {text}
      </span>
    </div>
  );
  const isAllTasksFinished = () => {
    if (!feedback || !feedback.feedback_tasks.length) return true;
    return feedback?.feedback_tasks.some((task) => task.status !== 'finished');
  };

  const onResolveClick = () => {
    isAllTasksFinished()
      ? dispatch(
          // @ts-ignore
          showDialog(ConfirmationDialog, {
            dialogHeader: 'Resolve Feedback',
            dialogTitle:
              'You can not resolve this feedback while you have unfinished tasks',
            closeDialog: (dialogName: string) =>
              dispatch(closeDialog(dialogName)),
            confirmDialog: (dialogName: string) =>
              dispatch(closeDialog(dialogName)),
            confirmButtonTitle: 'OK',
            isCloseButtonVisible: false,
          })
        )
      : dispatch(
          // @ts-ignore
          showDialog(ResolveFeedbackModal, {
            initialDate: DateTime.now(),
            initialComment: '',
            confirmResolveFeedback,
          })
        );
  };

  const confirmResolveFeedback = async (
    resolution_date: string,
    resolution_comment: string
  ) => {
    try {
      const data: Partial<FeedbackFormDataInterface> = {
        id: feedback?.id,
        risk_level: RESOLVED_RISK_LEVEL,
        resolution_date,
      };
      if (resolution_comment) {
        data.resolution_comment = resolution_comment;
      }
      await editFeedback({
        id: Number(id),
        // @ts-ignore
        feedback: data,
      }).unwrap();
      dispatch(closeDialog(ResolveFeedbackModal.NAME));
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const header = (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      className='feedback_create__header'
    >
      <Grid item>
        <span className='feedback_create__title'>Feedback details</span>
      </Grid>
      {canResolveOrEditFeedback(zone) && (
        <Grid item>
          {feedback &&
            !feedback?.is_external &&
            !EXCLUDED_RISKS.includes(feedback.risk_level) && (
              <button className='button__main' onClick={onResolveClick}>
                Resolve
              </button>
            )}
          <Link to={'edit'} state={state}>
            <button className='button__main'>Edit</button>
          </Link>
        </Grid>
      )}
    </Grid>
  );

  const currentPosition = positions?.find(
    (position: { id: number; name: string }) =>
      position.id === feedback?.position_id
  );

  const currentRisk = filters?.risk.find(
    (risk: { id: string; name: string }) => risk.id === feedback?.risk_level
  );
  const currentType = filters?.type.find(
    (type: { id: number; name: string }) => type.id === feedback?.feedback_type
  );

  const content = isFetching ? (
    <div className='loader_wrapper'>
      <CircularProgress />
    </div>
  ) : isSuccess ? (
    <article>
      <Grid container alignItems='flex-start'>
        <Grid
          item
          md={8}
          xs={8}
          flexDirection='column'
          className='feedback_page__content'
        >
          <h2>{feedback.title}</h2>
          <Grid container>
            <Grid item md={3}>
              <InfoField label='Feedback type' text={currentType?.name} />
            </Grid>
            <Grid item md={3}>
              <LinkField label='Employee' props={feedback.employee} />
            </Grid>
            <Grid item md={3}>
              <InfoField
                label='Position'
                text={currentPosition?.name || feedback.employee.title}
              />
            </Grid>
            <Grid item md={3}>
              <LinkField label='Manager' props={feedback?.manager} />
            </Grid>
            <Grid item md={3}>
              <LinkField label='Reviewer' props={feedback.modified_by} />
            </Grid>
            {!feedback?.is_external && (
              <Grid item md={3}>
                <RiskField status={currentRisk?.id} text={currentRisk?.name} />
              </Grid>
            )}
            <Grid item md={3}>
              <InfoField
                label='Feedback date'
                text={getFormattedDate(feedback.feedback_date)}
              />
            </Grid>
            <Grid item md={3}>
              <InfoField
                label='Modified date'
                text={getFormattedDate(feedback.modified)}
              />
            </Grid>
            {feedback?.employee_comments && (
              <Grid item md={12}>
                <InfoField
                  label='Employee Feedback'
                  text={feedback.employee_comments}
                />
              </Grid>
            )}
            {feedback?.risk_level === 'resolved' && (
              <>
                <Grid item md={3}>
                  <InfoField
                    label='Resolution comment'
                    text={feedback?.resolution_comment ?? EMPTY_VALUE}
                  />
                </Grid>
                <Grid item md={3}>
                  <InfoField
                    label='Resolution date'
                    text={feedback?.resolution_date}
                  />
                </Grid>
              </>
            )}
            <Grid item md={12}>
              <InfoField
                label='General Feedback'
                text={feedback.reporter_comments}
              />
            </Grid>
            {feedback?.employee_plans_goals && (
              <Grid item md={12}>
                <InfoField
                  label='Employee Plans and Goals'
                  text={feedback?.employee_plans_goals}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {feedback.checklist && (
          <Grid item xs={4} className='feedback_page__checklist'>
            <Checklist checklist={feedback.checklist} disabled />
          </Grid>
        )}
        {feedback.feedback_tasks && (
          <Grid item xs={12} sx={{ padding: '0 36px' }}>
            <Divider sx={{ my: 2 }} />
            <TasksList
              tasks={feedback.feedback_tasks}
              feedbackRisk={feedback?.risk_level}
            ></TasksList>
          </Grid>
        )}
      </Grid>
    </article>
  ) : null;

  return (
    <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
      {header}
      <Divider />
      {content}
    </Scrollbars>
  );
};
