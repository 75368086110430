import { useEditGoalMutation } from '../../../api/apiSlice';
import { GoalComment } from './GoalComment';

interface IGoalCommentProps {
  goalComment?: string;
  cdpId?: number;
  goalId?: number;
  isCloseTrigger?: number;
  setIsCloseTrigger?: (val: number | undefined) => void;
}

export const GoalCommentIndependent = ({
  cdpId,
  goalComment,
  goalId,
  isCloseTrigger,
  setIsCloseTrigger,
}: IGoalCommentProps) => {
  const [editGoal] = useEditGoalMutation();

  const onSubmit = async (comment: string) => {
    if (cdpId && goalId) {
      await editGoal({
        cdpId,
        goalId,
        data: { comment, ...(isCloseTrigger ? { is_closed: true } : {}) },
      });
    }
  };

  return (
    <GoalComment
      goalComment={goalComment}
      onSubmit={onSubmit}
      isCloseTrigger={isCloseTrigger}
      setIsCloseTrigger={setIsCloseTrigger}
      goalId={goalId}
      title={
        isCloseTrigger
          ? 'Close Goal'
          : goalComment
          ? 'Edit Comment'
          : 'Add Comment'
      }
    />
  );
};
