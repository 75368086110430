import * as React from 'react';
import { FormikValues } from 'formik';
import { useEditGoalMutation, useGetGoalQuery } from '../../../api/apiSlice';
import { EditGoalForm } from './EditGoalForm';

interface IProps {
  cdpId: number;
  goalId: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditGoal = ({ cdpId, goalId, open, setOpen }: IProps) => {
  const { data: goalData } = useGetGoalQuery({
    cdpId,
    goalId,
  });

  const [editGoalMutation] = useEditGoalMutation();

  const handleSubmit = async (values: FormikValues) => {
    const newData = {
      short_summary: values.shortSummary,
      mentor_id: values.mentor.id,
      due_date: values.dueDate.toISODate(),
      actions: values.actions,
    };
    await editGoalMutation({ cdpId, goalId, data: newData });
  };

  return (
    <EditGoalForm
      goalData={goalData}
      handleSubmit={handleSubmit}
      open={open}
      setOpen={setOpen}
    />
  );
};
