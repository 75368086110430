import { MenuItem, TextField } from '@mui/material';
import ClearIcon from './ClearIcon';

const STATUS_ITEMS = {
  prod: 'Production',
  'non-prod': 'Non Production',
  bench: 'Bench',
  'non-bench': 'Non Bench',
};

export default function StatusSelect({ field, formik, initialValues }: any) {
  return (
    <TextField
      select
      label='Status'
      size='small'
      fullWidth
      InputProps={{
        endAdornment: (
          <ClearIcon
            field={field}
            initialValues={initialValues}
            onClick={() =>
              formik.setFieldValue(field.name, initialValues[field.name])
            }
          />
        ),
      }}
      {...field}
    >
      {Object.entries(STATUS_ITEMS).map(([value, label]: any) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
