import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

const getErrorMsg = (response: {
  data: any;
  status?: number;
  originalStatus?: number;
}): string[] => {
  // case for dev and stg, do not show big HTML
  if (
    (`${response?.status}`.startsWith('50') && !response?.data?.detail) ||
    (`${response?.originalStatus}`.startsWith('50') && !response?.data?.detail)
  ) {
    return ['Server error occurred'];
  }
  return response?.data?.detail
    ? [response?.data?.detail]
    : Object.entries(response?.data || {})?.reduce(
        (acc: string[], [key, value]) => {
          if (key) {
            acc.push(
              `${isNaN(+key) ? key + ': ' : ''}${
                Array.isArray(value) ? value.join('\n') : value
              }`
            );
          }
          return acc;
        },
        []
      );
};

export const rtkQueryErrorMessages: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // Case when data looks like this:
    // {"status": 400, "data": {"comment": ["This field may not be blank."]}}
    // or {"status": 400, "data": ["Employee can not be mentor of himself."]}
    // or {"status": 400, "data": {"detail": "Some error info message"}}
    const errorMsg = getErrorMsg(action?.payload);
    errorMsg &&
      errorMsg.forEach((msg) => enqueueSnackbar(msg, { variant: 'error' }));
  }
  return next(action);
};
