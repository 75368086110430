import { useGetMentorsMinimalQuery } from '../../../api/apiSlice';
import { DateTime } from 'luxon';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import DatePickerValue from './DatePicker';
import ComboBox from './Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import * as React from 'react';
import { validationSchemaCDPGoal } from './AddGoal';

interface IProps {
  goalData?: any; // todo: add type
  handleSubmit: (values: FormikValues) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditGoalForm = ({
  goalData,
  handleSubmit,
  open,
  setOpen,
}: IProps) => {
  const { data: mentorsData, isLoading: isMentorsLoading } =
    useGetMentorsMinimalQuery();

  const mentors = mentorsData
    ? mentorsData.map((item) => ({
        label: item.name,
        id: item.id,
      }))
    : [];

  const initialValues = {
    shortSummary: goalData?.short_summary || '',
    mentor: goalData?.mentor?.id
      ? mentors.find((item) => item.id === goalData?.mentor.id)
      : goalData?.mentor_id
      ? mentors.find((item) => item.id === goalData.mentor_id)
      : null,
    dueDate: goalData?.due_date
      ? DateTime.fromISO(goalData.due_date)
      : DateTime.now(),
    actions: goalData?.actions || '',
  };

  const onSubmit = (values: FormikValues) => {
    handleSubmit({
      ...values,
      mentor: { id: values.mentor.id, name: values.mentor.label },
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { maxWidth: 648, width: 648 } }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaCDPGoal}
          enableReinitialize
        >
          <Form>
            <DialogTitle>Edit Goal</DialogTitle>
            <DialogContent>
              <Field name='shortSummary'>
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    autoFocus
                    margin='dense'
                    label='Short Summary'
                    fullWidth
                    variant='outlined'
                  />
                )}
              </Field>
              <Stack direction='row' spacing={2}>
                <Field name='dueDate'>
                  {({ field, form, meta }: FieldProps) => (
                    <DatePickerValue
                      value={field.value}
                      setValue={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : undefined}
                    />
                  )}
                </Field>
                <Field name='mentor'>
                  {({ field, form, meta }: FieldProps) => (
                    <ComboBox
                      options={mentors}
                      isLoading={isMentorsLoading}
                      value={field.value}
                      setValue={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : undefined}
                    />
                  )}
                </Field>
              </Stack>
              <Field name='actions'>
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    multiline
                    rows={4}
                    margin='dense'
                    label='Actions'
                    fullWidth
                    variant='outlined'
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button onClick={handleClose} color='inherit'>
                Cancel
              </Button>
              <Button type='submit' variant='contained'>
                Submit
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};
