import PropTypes from 'prop-types';
import { RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';

const RadioButtonGroup = ({
  isRow,
  handleChange,
  selectedValue,
  options,
  className,
  disabled,
}) => {
  return (
    <FormControl>
      <RadioGroup
        style={{ flexDirection: 'row' }}
        row={isRow}
        onChange={handleChange}
        value={selectedValue}
        data-testid='radiogroup'
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option.value}
            control={
              <Radio
                disabled={disabled}
                data-testid={`radiogroup-item-${option.label}`}
              />
            }
            label={option.label}
            className={className}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonGroup.propTypes = {
  isRow: PropTypes.bool,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RadioButtonGroup;
