import {
  VIEW_DISMISSED_EMPLOYEES_IN_OFFICE,
  VIEW_SUPPLEMENTARY_EMPLOYEES,
} from '../../../../utils/const-variable';

export const quickFilters = [
  {
    id: 'risk',
    name: 'High risk',
    selected: '',
    showFor: ['dc', 'hr', 'subordinates'],
    isShown: true,
  },
  {
    id: 'probation',
    name: 'Probation period',
    selected: '',
    showFor: ['dc', 'hr', 'subordinates', 'supervised'],
    isShown: true,
  },
  {
    id: 'key',
    name: 'Key resources',
    selected: '',
    showFor: ['dc', 'hr', 'subordinates'],
    isShown: true,
  },
  {
    id: 'mydc',
    name: 'Show my DC',
    selected: '',
    showFor: ['all'],
    isShown: false,
  },
  {
    id: 'dismissed',
    name: 'Dismissed',
    selected: '',
    showFor: ['dc', 'hr', 'subordinates'],
    permission: VIEW_DISMISSED_EMPLOYEES_IN_OFFICE,
    isShown: false,
  },
  {
    id: 'vacation',
    name: 'Vacation',
    selected: '',
    showFor: ['all', 'dc', 'hr', 'subordinates', 'supervised'],
    isShown: false,
  },
  {
    id: 'direct',
    name: 'Direct subordinates only',
    selected: '',
    showFor: ['subordinates'],
    isShown: false,
  },
  {
    id: 'supplementary',
    name: 'Supplementary',
    selected: '',
    showFor: ['dc'],
    permission: VIEW_SUPPLEMENTARY_EMPLOYEES,
    isShown: false,
  },
];
