export const headerArrow = (
  <svg
    width='12'
    height='5'
    viewBox='0 0 12 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 0L5.97518 5L11.9504 0H0Z' fill='#4B4B4B' />
  </svg>
);
