import { Stack, Chip } from '@mui/material';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import PropTypes from 'prop-types';

const relationLabels = {
  mentor_relation: 'Mentee',
  team_lead_relation: 'Team',
  project_manager_relation: 'Project',
};

const Cell = ({ item, column }) => {
  return (
    <Stack direction='row' spacing={0.5}>
      {column?.fields?.map(
        (field) =>
          item?.[field?.Id] && (
            <Chip
              key={field?.Id}
              label={relationLabels?.[field?.Id] || ''}
              size='small'
            />
          )
      )}
    </Stack>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellRelation = withCellWrapper(Cell);
