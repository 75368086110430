import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ item, column }) => {
  const { Id, spareId } = column;
  const position = item[Id]?.name || item[spareId];
  return (
    <span
      className={classNames('col-span', {
        dismissed: item.employee_status === 'dismissed',
      })}
      title={position}
    >
      {position}
    </span>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellPosition = withCellWrapper(Cell);
