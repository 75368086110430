import { Skill } from '../apiSlice.types';
import { categoryIdValueForNoCategoryId } from '../../../Bench/const';

export function getSkillWithCategoryOptions(
  skillWithMultipleCategoriesList: Skill[]
): Skill[] {
  const skillWithOneCategoryOptions: Skill[] = [];
  if (Array.isArray(skillWithMultipleCategoriesList)) {
    skillWithMultipleCategoriesList.forEach((skill) => {
      if (skill.categories) {
        skill.categories.forEach((category) => {
          skillWithOneCategoryOptions.push({
            ...skill,
            category: { id: category.id, name: category.name },
          });
        });
      } else {
        skillWithOneCategoryOptions.push({
          ...skill,
          category: { id: categoryIdValueForNoCategoryId, name: 'No Category' },
        });
      }
    });
    skillWithOneCategoryOptions.sort((a, b) => a.category.id - b.category.id);
    return skillWithOneCategoryOptions;
  }
  return skillWithMultipleCategoriesList;
}
