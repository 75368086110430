/* eslint-disable */
import * as React from 'react';
import { TextField, Checkbox, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './grouped-select.css';
import {
  getOptionsWithGroupAndUID,
  removeExtraUID,
  convertValues,
} from './utils';

const DEFAULT_STYLES = {
  width: '100%',
  maxHeight: 100,
  overflow: 'auto',
  overflowX: 'hidden',
};

// todo: fix the performance of this component, big leak of memory
// seems due to getOptionsWithGroupAndUID function
export class GroupedSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.value || [],
      uniqueOptions:
        getOptionsWithGroupAndUID(props.options, props.groupedKey) || {},
    };
  }

  componentDidUpdate(prevProps) {
    const { options, groupedKey, value, idKey } = this.props;
    if (prevProps.options !== options || prevProps.value !== value) {
      const uniqueOptions = getOptionsWithGroupAndUID(options, groupedKey);
      const sortedOptionsWithGroup = uniqueOptions?.sort(
        (a, b) =>
          a?._group?.localeCompare(b?._group) || a?.name?.localeCompare(b?.name)
      );

      this.setState({
        uniqueOptions: sortedOptionsWithGroup || {},
        selected: convertValues(uniqueOptions, value, idKey),
      });
    }
  }

  handleChange = (...args) => {
    const { selected } = this.state;
    const { onChange, idKey } = this.props;
    const item = args[3] && args[3].option;

    if (!item) {
      this.setState({
        selected: [],
      });
      onChange([]);
      return;
    }
    const alreadyChosenItem = selected.find(
      (opt) => opt[idKey] === item[idKey]
    );
    if (alreadyChosenItem) {
      const filteredOptions = selected.filter(
        (opt) => opt[idKey] !== alreadyChosenItem[idKey]
      );
      this.setState({ selected: filteredOptions });
      onChange && onChange(removeExtraUID(filteredOptions));
      return;
    }

    this.setState({ selected: [...selected, item] });
    onChange && onChange(removeExtraUID([...selected, item]));
  };

  renderTags = (tagValue) =>
    tagValue.map((option) => (
      <span key={option.uid}>{option.name}, &nbsp;</span>
    ));

  render() {
    const {
      label,
      className,
      autoFocus,
      renderValueName,
      sx,
      groupedKey,
      idKey,
      preventCustomTag,
    } = this.props;
    const { uniqueOptions, selected } = this.state;

    return (
      <div className={classNames(className, 'grouped-select_wrapper')}>
        <Autocomplete
          autoFocus={autoFocus}
          multiple
          disableCloseOnSelect
          disableListWrap
          options={uniqueOptions}
          groupBy={(option) => (groupedKey ? option._group : null)}
          value={selected}
          getOptionLabel={(option) => option[renderValueName]}
          sx={sx || DEFAULT_STYLES}
          renderInput={(params) => (
            <TextField {...params} label={label} variant='standard' />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                className={selected ? 'grouped-checkbox' : ''}
                checked={selected}
              />
              {option[renderValueName]}
            </li>
          )}
          renderTags={preventCustomTag ? undefined : this.renderTags}
          onChange={this.handleChange}
          isOptionEqualToValue={(option, value) =>
            option[idKey] === value[idKey]
          }
          ChipProps={{ className: 'grouped_select__option' }}
        />
      </div>
    );
  }
}

GroupedSelect.defaultProps = {
  autoFocus: true,
  className: '',
  renderValueName: 'name',
  idKey: 'uid',
  preventCustomTag: false,
};

GroupedSelect.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  groupedKey: PropTypes.string,
  onChange: PropTypes.func,
  renderValueName: PropTypes.string,
  idKey: PropTypes.string,
  preventCustomTag: PropTypes.bool,
};
