export const sidebarLogo = (
  <svg
    width='120'
    height='16'
    viewBox='0 0 120 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.59141e-07 15.3616L6.61986 0.0593109H9.68907L16.4293 15.3616H13.3601L11.4343 11.2098H6.43932L7.40221
    8.71877H10.652L8.12437 2.96557L3.00903 15.3616H4.59141e-07ZM20.4614 0.0593109V15.3616H23.3501V0.0593109H20.4614ZM30.1505
    0.0593109L24.2528 6.40562V8.24427L30.5717 15.4209H34.5436L26.7803 7.23598L33.6409 0.0593109H30.1505ZM53.1996
    0.0593109L46.3992 15.3616H43.33L36.7101 0.0593109H40.1404L44.9549 11.803L49.7693 0.0593109H53.1996ZM72.5176
    0.0593109V15.3616H81.6048L82.6279 12.9299H75.4062V0.0593109H72.5176ZM88.345 7.71047C88.345 11.2692 89.9699 13.0485
    93.1595 13.0485C96.2889 13.0485 97.8536 11.2692 97.8536 7.71047C97.8536 4.2111 96.2889 2.43176 93.1595 2.43176C89.9097
    2.43176 88.345 4.15179 88.345 7.71047ZM85.336 7.76978C85.336 2.55039 87.9238 4.5251e-07 93.1595 4.5251e-07C98.2748
    4.5251e-07 100.863 2.6097 100.863 7.76978C100.863 12.9299 98.2748 15.5396 93.1595 15.5396C88.1645 15.4803 85.5767 12.9299
    85.336 7.76978ZM105.677 15.3616V0.0593109H108.566L117.232 11.0319V0.0593109H120V15.3616H117.111L108.445
    4.15179V15.3616H105.677Z'
      fill='white'
    />
    <path
      d='M67.5226 13.6416C67.643 13.8195 67.7633 13.9975 67.7633 14.2347C67.7633 14.4719 67.7633 14.6499 67.643 14.8278C67.5226
    15.0651 67.4023 15.243 67.1615 15.3023C66.9208 15.4209 66.7403 15.4209 66.4995 15.4209H52.2368C52.0562 15.3616 51.8757 15.3023
    51.7553 15.243C51.6349 15.1244 51.5146 15.0057 51.3942 14.8871C51.334 14.7092 51.2739 14.4719 51.2739 14.2347C51.2739 13.9975
    51.3942 13.8195 51.5146 13.6416C51.7553 13.345 51.996 13.2264 52.3571 13.2857L66.0783 13.2264C66.3792 13.2264 66.6199 13.2264
    66.8606 13.2264C67.1615 13.2857 67.4022 13.4637 67.5226 13.6416Z'
      fill='#D86A43'
    />
    <path
      d='M66.6199 0.0592952C66.8004 0.118607 66.9809 0.177917 67.1615 0.237229C67.342 0.29654 67.4624 0.415164 67.5827
    0.593098C67.6429 0.711721 67.7031 0.889654 67.7633 1.06759C67.8235 1.24552 67.7633 1.42346 67.7031 1.60139C67.6429 1.77933
    67.5226 1.95726 67.342 2.07588C67.1615 2.1945 66.9809 2.31313 66.8004 2.31313L59.7593 2.25381C59.5787 2.25381 59.3982 2.25381
    59.1575 2.25381C58.9769 2.25381 58.7362 2.25381 58.5557 2.25381C58.3751 2.25381 58.1946 2.1945 58.014 2.13519C57.8335 2.07588
    57.7131 1.89795 57.5928 1.72001C57.5326 1.60139 57.4724 1.42346 57.4724 1.24552C57.4724 1.06759 57.4724 0.889654 57.5326
    0.771032C57.5928 0.593098 57.7733 0.415164 57.8937 0.296541C58.0742 0.177919 58.2548 0.118607 58.4353 0.0592952H64.6339H66.6199Z'
      fill='#D86A43'
    />
    <path
      d='M66.7402 6.70219C67.1013 6.76151 67.4022 6.88013 67.5828 7.236C67.7031 7.35462 67.7633 7.53255 67.7633 7.71049C67.7633
    7.88842 67.7633 8.06636 67.7031 8.18498C67.6429 8.36291 67.5828 8.48153 67.4022 8.60016C67.2819 8.71878 67.1013 8.8374 66.9208
    8.8374H66.4995H55.4263C55.2458 8.77809 55.005 8.71878 54.8847 8.54084C54.7041 8.36291 54.5838 8.18498 54.5838 8.00704C54.5236
    7.82911 54.5236 7.59187 54.5838 7.41393C54.644 7.236 54.7643 7.05806 54.9449 6.88013C55.1254 6.76151 55.3059 6.64288 55.6068
    6.64288L66.7402 6.70219Z'
      fill='#D86A43'
    />
  </svg>
);
