import classNames from 'classnames';
import { Field } from 'formik';
import React from 'react';
import InnerTextField from '../form-items/input/text-field';

const renderTextInput = ({
  field,
  form,
  isSubmitting,
  error,
  className,
  ...props
}) => (
  <InnerTextField
    customLabel
    fullWidth
    disabled={isSubmitting}
    formClassName='form-control'
    inputClassName={classNames(className, {
      'form-control__input__error': error,
    })}
    {...field}
    {...props}
  />
);

export const TextField = function ({
  name,
  setFieldValue,
  onChange,
  ...props
}) {
  return (
    <Field
      className=''
      name={name}
      component={renderTextInput}
      onChange={(e) => {
        if (e.target) {
          setFieldValue(name, e.target.value);
          if (onChange) {
            onChange(e.target.value, name);
          }
        }
      }}
      {...props}
    />
  );
};
