import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';
import {
  useGetEmployeeDetailsQuery,
  useGetEmployeeQuery,
} from '../../../api/apiSlice';
import { useParams } from 'react-router-dom';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import { useEffect } from 'react';
import { EditEmployeeGeneralInfo } from './EditEmployeeGeneralInfo';

export const EditEmployeeGeneralInfoPage = () => {
  const { id } = useParams();
  const userId = useSelector(selectEmployeeId);
  const isOwnProfile = userId === Number(id);

  // todo: make it a component or something
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${id}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  return isOwnProfile ? <EditOwnGeneralInfo /> : <EditOthersGeneralInfo />;
};

const EditOthersGeneralInfo = () => {
  const { id } = useParams();
  const { data } = useGetEmployeeDetailsQuery(Number(id));
  return <EditEmployeeGeneralInfo employee={data} />;
};

const EditOwnGeneralInfo = () => {
  const { id } = useParams();
  const { data } = useGetEmployeeQuery(Number(id));
  return <EditEmployeeGeneralInfo employee={data} />;
};
