import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { Project } from '../../../api/apiSlice.types';
import { getAssignmentType } from '../utils';
interface Props {
  projects: Project[];
  isLoading: boolean;
}

const columns = [
  {
    headerName: 'Name',
    field: 'name',
    sortable: false,
    flex: 2,
    minWidth: 350,
  },
  {
    headerName: 'Position',
    field: 'role',
    flex: 2,
    sortable: false,
    minWidth: 350,
  },
  {
    // DataGrid uses toLocaleDateString() for displaying dates and doesn't allow configuration mechanism, that might be tricky and limits application from defining the common date format for all the site
    headerName: 'Start date',
    field: 'start_date',
    sortable: false,
    flex: 1,
  },
  {
    // DataGrid uses toLocaleDateString() for displaying dates and doesn't allow configuration mechanism, that might be tricky and limits application from defining the common date format for all the site
    headerName: 'End date',
    field: 'end_date',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Employee Assignment',
    field: 'assignment_type',
    sortable: false,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      getAssignmentType(params?.row?.assignment_type),
  },
];

export const ProjectsTable: FC<Props> = ({ projects, isLoading }) => (
  <DataGrid
    columns={columns}
    rows={projects}
    loading={isLoading}
    getRowId={(row) =>
      `${row.name}${row.role}${row.status}${row.project.id}${row.start_date}${row.end_date}`
    }
    pageSize={50}
    autoHeight
    disableColumnMenu
    disableSelectionOnClick
    hideFooter
  />
);
