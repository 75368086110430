import { useState } from 'react';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import PropTypes from 'prop-types';
import { Badge } from '../../badges/Badges';
import classNames from 'classnames';

export const DefaultAvatar = ({ item, className = 'cell_avatar-badge' }) => (
  <div className={className} id='badge'>
    <Badge item={item.name} />
  </div>
);

const Cell = ({ item, column }) => {
  const [isImageBroken, setIsImageBroken] = useState(false);
  return (
    <span
      className={classNames(
        'col-span',
        item.employee_status === 'dismissed' && 'dismissed'
      )}
      style={{ WebkitBoxOrient: 'vertical' }}
    >
      {!isImageBroken && item[column.Id] ? (
        <img
          src={item[column.Id]}
          alt={column.Name}
          className='cell_avatar'
          onError={() => setIsImageBroken(true)}
        />
      ) : (
        <DefaultAvatar item={item} />
      )}
    </span>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellAvatar = withCellWrapper(Cell);
