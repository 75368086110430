export const arrowDown = (
  <svg
    width='10'
    height='5'
    viewBox='0 0 10 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M10 0L5 5L0 0H10Z' fill='#797979' />
  </svg>
);
