import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions } from '../../../../../hooks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  resetComponent as resetComponentAction,
  setComponent as setComponentAction,
} from '../../../../../store/slices/header/headerSlice';
import { useGetProjectsQuery } from '../../../api/apiSlice';
import BasicEmployeeInfo from '../../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import { ProjectsTable } from './Table';
import { Box } from '@mui/material';

export const Projects = () => {
  const { id } = useParams();
  const [setComponent, resetComponent] = useActions([
    setComponentAction,
    resetComponentAction,
  ]);
  const { data = [], isLoading } = useGetProjectsQuery(Number(id));

  useEffect(() => {
    setComponent({
      component: {
        pathname: `/employees/${id}/`,
        text: 'Employee Details',
      },
      isLink: true,
    });
    return () => {
      resetComponent();
    };
  }, []);

  return (
    <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
      <BasicEmployeeInfo />
      <Box padding={4.5}>
        <ProjectsTable projects={data} isLoading={isLoading} />
      </Box>
    </Scrollbars>
  );
};
