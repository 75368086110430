export const warningYellowTriangle = (
  <svg
    width='20'
    height='17'
    viewBox='0 0 20 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.833252 16.5001H19.1666L9.99992 0.666748L0.833252 16.5001ZM10.8333 14.0001H9.16659V12.3334H10.8333V14.0001ZM10.8333 10.6667H9.16659V7.33341H10.8333V10.6667Z'
      fill='#FCAB40'
    />
  </svg>
);
