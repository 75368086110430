export const setUserDataToLocalStorage = (responseData) => {
  localStorage.setItem('AuthToken', responseData.data.token);
  localStorage.setItem('RefreshToken', responseData.data.refresh);
  localStorage.setItem('userEmail', responseData.data.user.username);
  localStorage.setItem('employeeId', responseData.data.user.employee_id);
  if (responseData.data.user.avatar_url) {
    localStorage.setItem('avatar', responseData.data.user.avatar_url);
  }
  localStorage.setItem('office', JSON.stringify(responseData.data.user.office));
  localStorage.setItem(
    'hasSubordinates',
    JSON.stringify(responseData.data.user.has_subordinates)
  );
  localStorage.setItem(
    'hasSupervised',
    JSON.stringify(responseData.data.user.has_supervised)
  );
  localStorage.setItem(
    'permissions',
    JSON.stringify(responseData.data.user.permissions)
  );
  if (responseData.data.user.user_scope_access) {
    localStorage.setItem(
      'user_scope_access',
      JSON.stringify(responseData.data.user.user_scope_access)
    );
  }
};
