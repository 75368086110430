import React, { FC, useEffect } from 'react';
import { SkillFilter } from './SkillFilter';
import {
  useGetEmployeeFiltersQuery,
  useGetSkillLevelsQuery,
} from '../../api/apiSlice';
import { SkillFilter as SkillFilterType } from '../../slices/employeeSlice.types';

export interface Skill {
  id: number;
  name: string;
}

export interface SkillRow {
  skills: Skill[];
  level: { id: number; name: string };
}

type Props = {
  setSkillFilter: React.Dispatch<React.SetStateAction<SkillFilterType>>;
  skillRows: SkillRow[];
  setSkillRows: React.Dispatch<React.SetStateAction<SkillRow[]>>;
  skillFilter: SkillFilterType;
};

export const SkillFilterPanel: FC<Props> = ({
  setSkillFilter,
  skillRows,
  setSkillRows,
  skillFilter,
}) => {
  const { data: employeeFilters, isLoading: isSkillsLoading } =
    useGetEmployeeFiltersQuery();
  const skillOptionsData = employeeFilters?.skills;

  const interviewerOptionsData = employeeFilters?.interview_enabled_skills;

  const { data: levelOptions = [], isLoading: isLevelsLoading } =
    useGetSkillLevelsQuery();

  useEffect(() => {
    setSkillFilter((prevState) => ({
      ...(prevState?.interview_enabled_skills && {
        interview_enabled_skills: prevState?.interview_enabled_skills,
      }),
      ...(prevState.main_tech_skills && {
        main_tech_skills: prevState.main_tech_skills,
      }),
      beginner: skillRows
        .filter((skillRow) => skillRow.level?.id === 1)
        .map((skillRow) => skillRow.skills.map((skill) => skill.id))
        .reduce((acc, val) => acc.concat(val), []),
      competent: skillRows
        .filter((skillRow) => skillRow.level?.id === 2)
        .map((skillRow) => skillRow.skills.map((skill) => skill.id))
        .reduce((acc, val) => acc.concat(val), []),
      expert: skillRows
        .filter((skillRow) => skillRow.level?.id === 3)
        .map((skillRow) => skillRow.skills.map((skill) => skill.id))
        .reduce((acc, val) => acc.concat(val), []),
    }));
  }, [skillRows]);

  return skillOptionsData ? (
    <SkillFilter
      isSkillsLoading={isSkillsLoading}
      skillOptions={skillOptionsData}
      interviewOptions={interviewerOptionsData}
      skillRows={skillRows}
      setSkillRows={setSkillRows}
      isLevelsLoading={isLevelsLoading}
      levelOptions={levelOptions}
      setSkillFilter={setSkillFilter}
      skillFilter={skillFilter}
    />
  ) : (
    <p>loading...</p>
  );
};
