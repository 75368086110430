import classNames from 'classnames';
import { List } from '../../../common/list/List';
import { columnsToDisplay } from './config';
import { CellImgButtonList } from '../../../common/list/cells/CellImgButtonList';
import { CellSelect } from '../../../common/list/cells/CellSelect';
import { CellMainTechnology } from '../../../common/list/cells/CellMainTechnology';
import {
  canEditSupervisorInfo,
  canViewInterviewer,
  canViewMainTechs,
  canViewSelfAndManagerSkills,
} from '../../../../utils/permissions/checkAccess';
import { FC, ReactNode } from 'react';
import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { UserSkill } from '../../api/apiSlice.types';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { ScopeType, ZoneType } from '../../../../utils/permissions/types';
import { CellInterviewer } from '../../../common/list/cells/CellInterviewer';

const MAX_MAIN_TECHNOLOGIES: number = 3;

interface ISkillsListProps {
  category: string;
  items: UserSkill[];
  employeeMainTechs: UserSkill[];
  idx: number;
  onToggle: (idx: number) => void;
  isOpen: boolean;
  isCurrentUser: boolean;
  isEditMode: boolean;
  updateSkillItem: (key: string) => Function;
  confirmDeleteSkill: (data: any) => Function;
  scope: ScopeType;
  zone: ZoneType;
  options: any[];
  header?: ReactNode;
}

export const SkillsList: FC<ISkillsListProps> = ({
  items,
  employeeMainTechs,
  category,
  idx,
  onToggle,
  isOpen,
  isCurrentUser,
  isEditMode,
  updateSkillItem,
  confirmDeleteSkill,
  scope,
  zone,
  options,
  header,
}) => {
  const dispatch = useDispatch();

  if (!items.length) {
    return null;
  }

  const closeDeleteSkillDialog = (dialogName: string) =>
    dispatch(closeDialog(dialogName));

  const setShowDeleteModal = (item: any) =>
    dispatch(
      // @ts-ignore
      showDialog(ConfirmationDialog, {
        dialogHeader: 'Delete skill',
        dialogTitle: 'Are you sure to delete this skill?',
        closeDialog: closeDeleteSkillDialog,
        confirmDialog: confirmDeleteSkill(item),
      })
    );

  return (
    <section className='skill__list-wrapper'>
      <Box
        component={'header'}
        className='skill__list-category__header'
        sx={{ display: 'flex', minHeight: '36px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {category}
          <ArrowRightIcon
            fontSize={'small'}
            className={classNames({
              'skill__list-category-icon__rotate': isOpen,
            })}
            onClick={() => onToggle(idx)}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {header}
        </Box>
      </Box>
      <div
        className={classNames('skills__list-container', {
          'skills__list-container__hided': !isOpen,
        })}
      >
        <List
          columnsToDisplay={columnsToDisplay}
          config={{
            action: {
              cell: CellImgButtonList,
              isHaveRights: isCurrentUser && isEditMode,
              render: (item: any) => [
                <IconButton
                  key={item.item.id}
                  className='empty-btn'
                  title='Delete skill'
                  size={'small'}
                  onClick={() => setShowDeleteModal(item.item)}
                >
                  <DeleteIcon fontSize={'inherit'} />
                </IconButton>,
              ],
            },
            self_assessed_level: {
              cell: CellSelect,
              valueSetter: updateSkillItem('self_assessed_level'),
              isHaveRights: isCurrentUser || canViewSelfAndManagerSkills(scope),
              disabled: !isCurrentUser || !isEditMode,
              options,
              withIcon: true,
            },
            manager_assessed_level: {
              cell: CellSelect,
              valueSetter: updateSkillItem('manager_assessed_level'),
              isHaveRights:
                !isCurrentUser && canViewSelfAndManagerSkills(scope),
              disabled: !canEditSupervisorInfo(zone) || !isEditMode,
              options,
            },
            current_main_tech: {
              cell: CellMainTechnology,
              valueSetter: updateSkillItem('current_main_tech'),
              isHaveRights: !isCurrentUser && canViewMainTechs(scope),
              disabled: !canEditSupervisorInfo(zone) || !isEditMode,
              withTooltip:
                isEditMode && employeeMainTechs?.length >= MAX_MAIN_TECHNOLOGIES
                  ? true
                  : false,
            },
            interview_enabled: {
              cell: CellInterviewer,
              valueSetter: updateSkillItem('interview_enabled'),
              isHaveRights: !isCurrentUser && canViewInterviewer(scope),
              disabled: !canEditSupervisorInfo(zone) || !isEditMode,
            },
          }}
          // @ts-ignore
          rootClassName={'skill__list__align'}
          items={items}
          standardCursor
        />
      </div>
    </section>
  );
};
