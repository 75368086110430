export const NO_ITEMS_TITLE = 'No items';

////////////////////////////////////////////
//              ERROR STATUSES            //
////////////////////////////////////////////
export const UNAUTHORIZED_STATUS = 401;

////////////////////////////////////////////
//             COLORS STATUS              //
////////////////////////////////////////////
export const STATUS_COLOR = {
  red: '#D54144',
  green: '#27AE60',
  grey: '#BDBDBD',
  blue: '#56CCF2',
  lightRed: 'rgb(249, 142, 101)',
  accent: '#F26939',
  charcoal: '#464646',
  violet: '#AA66C3',
  yellow: '#EBAA16',
  emerald: '#4DCFA9',
  turquoise: '#3089A7',
  mediumTurquoise: '#1A6267',
};

////////////////////////////////////////////////////
//             CHECKLIST ITEM VALUES              //
////////////////////////////////////////////////////
export const MAX_CHECKLIST_ITEM_VALUE = 10;
export const MIN_CHECKLIST_ITEM_VALUE = 0;
export const CHECKLIST_ITEM_VALUE_STEP = 1;

//////////////////////////////////////////////
//                  SIZES                  //
/////////////////////////////////////////////
export const muiSizes = { xs: 'xs', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' };

//////////////////////////////////////////////
//           CROSS BROWSER VARS             //
/////////////////////////////////////////////
export const keyboardKeys = {
  Enter: 13,
  Escape: 27,
};

//////////////////////////////////////////////
//           PERMISSIONS                    //
/////////////////////////////////////////////
export const VIEW_DISMISSED_EMPLOYEES_IN_OFFICE =
  'employee.view_dismissed_employees_in_office';
export const VIEW_SUPPLEMENTARY_EMPLOYEES =
  'employee.view_supplementary_employees';

export const OFFICE_SCOPE = 'OFFICE';
export const MANAGER_SCOPE = 'MANAGER';
export const GLOBAL_SCOPE = 'GLOBAL';
export const SUPERVISOR_SCOPE = 'SUPERVISOR';
export const STAFF_SCOPE = 'STAFF';

export const SUPERVISOR_ZONE = 'SUPERVISOR';
export const HR_ZONE = 'HR';
export const ORG_ZONE = 'ORG';
export const RECRUITER_ZONE = 'RECRUITER';
export const DIRECTOR_ZONE = 'DIRECTOR';
