import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import {
  useGetBenchTagsQuery,
  useGetCountriesQuery,
  useGetEmployeeFiltersQuery,
} from '../../api/apiSlice';
import { FiltersOptions } from './types';
import { getFilterName } from './getFilterName';
import CloseIcon from '@mui/icons-material/Close';
import { ComboBox } from './FilterComboBox';
import { AddButton } from './AddButton';

interface IProps {
  filters: FiltersOptions;
  setFilters: Dispatch<SetStateAction<FiltersOptions>>;
  isVisibleFilterTag: boolean;
}

export const FilterConstructor: FC<IProps> = ({
  filters,
  setFilters,
  isVisibleFilterTag,
}) => {
  const { data: employeeFilters, isLoading: isEmployeeFiltersLoading } =
    useGetEmployeeFiltersQuery();
  const { data: countries, isLoading: isCountriesLoading } =
    useGetCountriesQuery();
  const { data: tags, isLoading: isTagsLoading } = useGetBenchTagsQuery();

  const filtersOptions: {
    [key: string]: { id: number | string; name: string }[];
  } = {
    positions: employeeFilters?.positions || [],
    projects: employeeFilters?.projects || [],
    employee_status: employeeFilters?.employee_status || [],
    offices: employeeFilters?.offices || [],
    managers: employeeFilters?.managers || [],
    country: countries || [],
    not_country: countries || [],
    ...(isVisibleFilterTag && { bench_tags: tags || [] }),
    ...(isVisibleFilterTag && { not_bench_tags: tags || [] }),
  };

  const setFilter = (
    key: string,
    value: { id: number | string; name: string }[]
  ) => {
    setFilters((filters) => ({
      ...filters,
      [key]: value,
    }));
  };

  const getFilterInputs = () => {
    const filterInputs = [];

    for (let key in filters) {
      filterInputs.push(
        <Stack direction='row' spacing={1} key={key}>
          <ComboBox
            name={getFilterName(key)}
            options={filtersOptions[key]}
            isLoading={
              isCountriesLoading || isEmployeeFiltersLoading || isTagsLoading
            }
            value={filters[key]}
            setValue={(value) => setFilter(key, value)}
          />
          <div>
            <IconButton
              onClick={() =>
                setFilters((filters) => {
                  const newFilters = { ...filters };
                  delete newFilters[key];
                  return newFilters;
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Stack>
      );
    }

    return filterInputs;
  };

  const list = Object.keys(filtersOptions).filter(
    (filterOptionName) => !filters[filterOptionName]
  );

  const onClick = (filterName: string) => {
    setFilters((filters) => ({
      ...filters,
      [filterName]: [],
    }));
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant={'body2'}>Search by filters</Typography>
      </Box>
      {!!Object.keys(filters).length && (
        <Box mb={2}>
          <Stack spacing={2}>{getFilterInputs()}</Stack>
        </Box>
      )}
      <div>
        {!!list.length && (
          <AddButton list={list} onClick={onClick} buttonName={'filter'} />
        )}
      </div>
    </div>
  );
};
