import React from 'react';
import { Field } from 'formik';

import { GroupedSelect } from '../form-items/grouped-select/grouped-select';

export const AutocompleteField = function ({
  name,
  setFieldValue,
  onChange,
  ...props
}) {
  return (
    <Field
      className=''
      name={name}
      labelId={`field-${name}_label-id`}
      id={`field-${name}`}
      component={GroupedSelect}
      onChange={(value) => {
        setFieldValue(name, value);
        if (onChange) {
          onChange(value);
        }
      }}
      {...props}
    />
  );
};
