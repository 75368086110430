export const dropdownTriangle = (
  <svg
    width='5'
    height='8'
    viewBox='0 0 5 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 8L5 4L-3.49691e-07 0L0 8Z' fill='#4B4B4B' />
  </svg>
);
