import PropTypes from 'prop-types';
import TextFieldComponent from './text-field';

export const LoginTextField = (props) => {
  const {
    name,
    type,
    value,
    placeholder,
    inputClassName,
    clearClassName,
    onChange,
  } = props;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <TextFieldComponent
      name={name}
      value={value ? value : ''}
      placeholder={placeholder}
      type={type}
      fullWidth={true}
      inputClassName={inputClassName}
      onChange={handleChange}
      clearClassName={clearClassName}
      variant='standard'
    />
  );
};

LoginTextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
};
