import { useSelector } from 'react-redux';
import { selectEmployeeScope } from '../../../slices/employeesSlice';
import { canViewOrgInfo } from '../../../../../utils/permissions/checkAccess';
import { PendingManager } from './PendingManager';
import { useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from '../../../api/apiSlice';

export const ManagerForm = () => {
  const params = useParams();
  const { data: employee } = useGetEmployeeQuery(Number(params.id));
  const scope = useSelector(selectEmployeeScope);

  return (
    <>
      {canViewOrgInfo(scope) && employee.new_manager_for_employee ? (
        <PendingManager
          newManagerId={employee.new_manager_for_employee.id}
          newManagerName={employee.new_manager_for_employee.name}
        />
      ) : null}
    </>
  );
};
