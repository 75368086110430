import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomIcon from '../icon';
import './customMenu.css';

export const CustomMenu = (props) => {
  const { buttonContent, children } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='menu_container' onClick={handleToggle}>
      {buttonContent}
      <div
        className={classNames('menu_arrow-icon', {
          'menu_arrow-icon_expanded': isExpanded,
        })}
      >
        <CustomIcon iconName='header-arrow' />
      </div>
      <div
        className={classNames('menu_menu', {
          menu_menu_expanded: isExpanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

CustomMenu.propTypes = {
  buttonContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
};
