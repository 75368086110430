export const crossSmall = (
  <svg
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.54'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1L9 0L5 4L1 0L0 1L4 5L0 9L1 10L5 6L9 10L10 9L6 5L10 1Z'
      fill='white'
    />
  </svg>
);
