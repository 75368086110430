import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
// @ts-ignore
import { Mutex } from 'async-mutex';
import { UNAUTHORIZED_STATUS } from '../../../utils/const-variable';
import { setShowLoginModal } from '../../LoginPage/slice/loginSlice';

const baseUrl = process.env.REACT_APP_HOST;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('AuthToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if ((result.error as any)?.status === UNAUTHORIZED_STATUS) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const refreshToken = localStorage.getItem('RefreshToken');
      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/token_refresh/',
            method: 'POST',
            body: { refresh: refreshToken },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // @ts-ignore
          localStorage.setItem('AuthToken', refreshResult.data.access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          console.log('refresh token expired');
          api.dispatch(setShowLoginModal(true));
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
