import { useEffect, useState, useMemo, RefObject } from 'react';

export const useIsInViewport = (
  ref: RefObject<any>,
  parentRef: RefObject<any>
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        {
          root: parentRef.current,
          threshold: 1.0,
        }
      ),
    []
  );

  useEffect(() => {
    if (ref.current && parentRef.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref.current, parentRef.current, observer]);

  return isIntersecting;
};
