import { Grid } from '@mui/material';
import { renderRowTitle } from './utils';
import DescriptionIcon from '@mui/icons-material/Description';

export const Certificates = ({ certificates }) => (
  <Grid container className='profile_block__delimiter' direction='column'>
    <Grid item xs={6} mb={2}>
      <span className='profile_block__title'>Certificates</span>
    </Grid>
    <Grid item container xs={12}>
      <Grid item container>
        {certificates.map((certificate, index) => (
          <Grid item container gap={1} key={index} xs={4}>
            <Grid item>
              <DescriptionIcon color='disabled' />
            </Grid>
            <Grid item xs={4}>
              {renderRowTitle(certificate.certificate_name)}
              <a
                download
                href={certificate.file_url}
                style={{ paddingRight: '4px' }}
                rel='noreferrer'
                target='_blank'
              >
                Download
              </a>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);
