import classNames from 'classnames';
import { Field } from 'formik';
import CustomIcon from '../icon';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';

const RenderInputFile = ({
  error,
  accept,
  disabled,
  handleChangeForm,
  file,
  setFile,
}) => {
  const onChangeFile = (event) => {
    const newFile = {
      ...file,
      [event.target.name]: event.target.files[0],
      name: event.target.files[0].name,
    };
    setFile(newFile);
    handleChangeForm && handleChangeForm(newFile);
    // reset input, to prevent choosing same file
    event.target.value = null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFile = {
        ...file,
        file: acceptedFiles[0],
        name: acceptedFiles[0].name,
      };
      setFile(newFile);
      handleChangeForm && handleChangeForm(newFile);
    },
  });

  return (
    <div
      {...getRootProps({
        className: `file__field--holder dropzone ${
          isDragActive ? 'active' : ''
          // todo: refactor this class
        } ${error ? 'error' : ''}`,
      })}
    >
      <input
        {...getInputProps()}
        type='file'
        onChange={onChangeFile}
        id='file'
        name='file'
        accept={accept}
        className='form-control__file-input'
        disabled={disabled}
      />

      <label htmlFor='file'>
        <CustomIcon iconName='upload' />
        <p>
          <span>Click to upload</span> or drag and drop
        </p>
      </label>
    </div>
  );
};

const RenderFileInput = (props) => {
  const [file1, setFile1] = useState({ file: null, name: '' });

  const {
    handleChangeForm,
    isNewFile,
    error,
    isSubmitting,
    isRequired,
    label,
    accept,
    field,
  } = props;

  const deleteFile = () => {
    handleChangeForm(undefined);
    setFile1({
      name: '',
      file: null,
    });
  };

  const getFileSize = (file) => {
    const { size } = file;
    return (size / 1000).toFixed();
  };

  const file = field?.value?.file;
  const fileName =
    file?.file_url?.split('/')[file.file_url.split('/').length - 1] || '';

  return (
    <div>
      {label && (
        <label className='redux-form__label'>
          <span>{label}</span>
          {isRequired && <span className='redux-form__required'> *</span>}
        </label>
      )}
      <RenderInputFile
        disabled={isSubmitting}
        accept={accept}
        handleChangeForm={handleChangeForm}
        file={file1}
        setFile={setFile1}
        error={error}
      />
      <div
        className={classNames('form_files-row form_files-container_cv', {
          'margin-left-5px': !isNewFile,
        })}
      >
        {file && (file?.name || file?.file_url) && (
          <div className='redux_form-filename-cdp_edit'>
            <div className='cv__file-name-width' title={file?.name}>
              <CustomIcon iconName='upload' />
              <div>
                <span className='file_name'>{file?.name || fileName}</span>
                {file.size && (
                  <span className='file_size'>{getFileSize(file)}kb</span>
                )}
              </div>
            </div>
            {!isNewFile && (
              <a download href={file?.file_url} title='download'>
                <CustomIcon
                  iconName='files'
                  className='redux_form-filename_icon-download'
                />
              </a>
            )}
            {isNewFile && (
              <button onClick={deleteFile} type='button'>
                <CustomIcon iconName='cross' />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const FileField = function ({
  name,
  setFieldValue,
  accept,
  xs,
  isNewFile,
  isRequired,
}) {
  return (
    <Field
      name={name}
      component={RenderFileInput}
      handleChangeForm={(value) => {
        setFieldValue(name, value);
      }}
      accept={accept}
      xs={xs}
      isNewFile={isNewFile}
      isRequired={isRequired}
    />
  );
};
