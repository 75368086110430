export const redClock = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.99992 0.333252C3.33325 0.333252 0.333252 3.33325 0.333252 6.99992C0.333252
    10.6666 3.33325 13.6666 6.99992 13.6666C10.6666 13.6666 13.6666 10.6666 13.6666
    6.99992C13.6666 3.33325 10.6666 0.333252 6.99992 0.333252ZM9.79992 9.79992L6.33325
    7.66659V3.66659H7.33325V7.13325L10.3333 8.93325L9.79992 9.79992Z'
      fill='#C00000'
    />
  </svg>
);
