import React from 'react';
import icons from './icons';
import Box from '@mui/material/Box';

interface Props {
  iconName: keyof typeof icons;
  className?: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
}

const CustomIcon: React.FC<Props> = ({
  iconName,
  className,
  onClick,
  id,
  style = {},
  ...props
}) => {
  if (!iconName) {
    return null;
  }
  return (
    <Box
      component='i'
      id={id}
      sx={{ display: 'inline-flex', ...style }}
      className={className}
      onClick={onClick}
      {...props}
    >
      {/* @ts-ignore */}
      {icons[iconName]}
    </Box>
  );
};

export default CustomIcon;
