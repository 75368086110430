import { MenuItem, TextField } from '@mui/material';
import ClearIcon from './ClearIcon';

export default function PeriodSelect({ field, formik, initialValues }: any) {
  return (
    <TextField
      select
      label={'Period'}
      size='small'
      fullWidth
      InputProps={{
        endAdornment: (
          <ClearIcon
            field={field}
            initialValues={initialValues}
            onClick={() =>
              formik.setFieldValue(field.name, initialValues[field.name])
            }
          />
        ),
      }}
      {...field}
    >
      {[3, 6, 12].map((item: any) => (
        <MenuItem value={item} key={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
}
