import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import { Grid } from '@mui/material';

export const CustomFormFieldset = ({
  children,
  formProps: {
    values,
    setFieldValue,
    isSubmitting,
    errors,
    touched, // todo: remove. not used
    setFieldTouched,
  },
  onTouchForm,
}) => {
  return (
    <Grid container spacing={2}>
      {Children.map(children, (child) => {
        if (!child) {
          return;
        }
        const { name, xs, ...rest } = child.props;

        const error = errors && errors[name];

        const setValue = (name, value) => {
          setFieldValue(name, value);
          setFieldTouched(name, true, false);
          onTouchForm && onTouchForm(name, value);
        };

        const props1 = {
          ...rest,
          name,
          // error,
          setFieldValue: setValue,
          isSubmitting,
          values,
        };

        return (
          <Grid item xs={xs || 3}>
            {cloneElement({
              ...child,
              props: props1,
            })}
            {error && <span className='error'>{error}</span>}
          </Grid>
        );
      })}
    </Grid>
  );
};

CustomFormFieldset.propTypes = {
  onTouchForm: PropTypes.func,
};
