import { Grid, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IEmployeeLocationHistory } from '../../../api/apiSlice.types';
import { LocationHistory } from './LocationHistory';

interface ILocationProps {
  title: string;
  showTooltip: boolean;
  locationHistory: IEmployeeLocationHistory[] | undefined;
}

export const Location = ({
  title,
  showTooltip,
  locationHistory,
}: ILocationProps) => {
  return (
    <Grid item container spacing={1} xs={'auto'}>
      <Grid item>
        <Tooltip title='Physical location'>
          <LocationOnIcon fontSize={'small'} color={'primary'} />
        </Tooltip>
      </Grid>
      <Grid item>
        <span>{title}</span>
      </Grid>
      {showTooltip && locationHistory?.length && (
        <Grid item>
          <LocationHistory locationHistory={locationHistory} />
        </Grid>
      )}
    </Grid>
  );
};
