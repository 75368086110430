import { useSelector } from 'react-redux';
import { selectEmployeeId } from '../../store/slices/auth/authSlice';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from './api/apiSlice';
import { EmployeeProfile } from './components/EmployeeProfile/EmployeeProfile';
import { SkillsQuestionnaire } from './components/EmployeeProfile/Questionnaire/Questionnaire';
import { EmployeeFeedbacks } from './components/EmployeeFeedbacks/EmployeeFeedbacks';
import { CvList } from './components/EmployeeCVs/EmployeeCvsList';
import { EmployeeSkills } from './components/EmployeeSkills/EmployeeSkills';
import { Projects } from './components/EmployeeProfile/Projects';
import { EmployeeOrg } from './components/EmployeeOrg/EmployeeOrg';
import { EmployeeOrgEdit } from './components/EmployeeOrg/EmployeeOrgEdit';
import {
  canEditOrgInfo,
  canViewCDP,
  canViewSkills,
  hasAccessToCV,
  hasAccessToOrg,
} from '../../utils/permissions/checkAccess';
import { ScopeType, ZoneType } from '../../utils/permissions/types';
import EmployeeCDP from './components/EmployeeCDP';
import { hasAccessToFeedbacks } from '../../utils/permissions/feedbacks';
import { EditEmployeeGeneralInfoPage } from './components/EmployeeGeneralInfo/Edit/EditEmployeeGeneralInfoPage';

export const EmployeePage = () => {
  const userId = useSelector(selectEmployeeId);

  const { id } = useParams();
  const { data: employee } = useGetEmployeeQuery(Number(id));

  const scope = employee?.scope || ('' as ScopeType);
  const zone = employee?.zone || ('' as ZoneType);

  const isOwnProfile = userId === employee?.id;

  return (
    <Routes>
      <Route index element={<EmployeeProfile isCurrentUser={isOwnProfile} />} />
      <Route path='/edit' element={<EditEmployeeGeneralInfoPage />} />
      {hasAccessToCV(scope) && !isOwnProfile && (
        <Route path='/cvs' element={<CvList />} />
      )}
      {isOwnProfile && (
        <Route path='/skills-questionnaire' element={<SkillsQuestionnaire />} />
      )}
      {canViewSkills(scope, isOwnProfile) && (
        <Route
          path='/skills'
          element={<EmployeeSkills isCurrentUser={isOwnProfile} />}
        />
      )}
      {hasAccessToFeedbacks(scope) && !isOwnProfile && (
        <Route path='/feedbacks/*' element={<EmployeeFeedbacks />} />
      )}
      <Route path='/projects' element={<Projects />} />
      {(canViewCDP(scope) || isOwnProfile) && (
        <Route path='/cdps/*' element={<EmployeeCDP zone={zone} />} />
      )}
      {hasAccessToOrg(scope) && !isOwnProfile && (
        <>
          <Route path='/org' element={<EmployeeOrg />} />
          <Route path='/org/edit' element={<EmployeeOrgEdit />} />
        </>
      )}
      {hasAccessToOrg(scope) && !isOwnProfile && canEditOrgInfo(zone) && (
        <Route path='/org/edit' element={<EmployeeOrgEdit />} />
      )}
    </Routes>
  );
};
