import { getFormattedDate } from '../components/common/formikFormControls/DatePickerField';

const objectFilter = (filtrationObject, value, excludedKeys = []) => {
  for (const key in filtrationObject) {
    if (key === 'EndDate' || key === 'StartDate') {
      if (getFormattedDate(filtrationObject[key]).includes(value)) {
        return filtrationObject;
      }
    }
    const keys = ['id', ...excludedKeys];
    if (!keys.includes(key)) {
      if (typeof filtrationObject[key] !== 'object') {
        if (
          filtrationObject[key]
            .toString()
            .toUpperCase()
            .includes(value.toUpperCase())
        ) {
          return filtrationObject;
        }
      } else {
        const deepResult = objectFilter(
          filtrationObject[key],
          value,
          excludedKeys
        );
        if (deepResult) {
          return deepResult;
        }
      }
    }
  }
};

export const recursiveSearch = (
  incomingData,
  value,
  propertyName,
  excludedKeys
) => {
  return incomingData
    .filter((item) => {
      return objectFilter(item, value);
    })
    .map((item) => {
      if (
        propertyName &&
        item[propertyName] &&
        Array.isArray(item[propertyName])
      ) {
        return {
          ...item,
          [propertyName]: item[propertyName]
            .filter((innerArrayItem) =>
              objectFilter(innerArrayItem, value, excludedKeys)
            )
            .sort((a, b) => {
              const nameA = a?.skill?.name?.toUpperCase();
              const nameB = b?.skill?.name?.toUpperCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            }),
        };
      }
      return item;
    })
    .filter((searchItem) => searchItem[propertyName].length > 0)
    .sort((a, b) => {
      const nameA = a?.name?.toUpperCase();
      const nameB = b?.name?.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
};
