import { FC } from 'react';
import { canApproveNewManager } from '../../../../../utils/permissions/checkAccess';
import {
  useGetEmployeeDetailsQuery,
  useGetEmployeeFiltersQuery,
} from '../../../api/apiSlice';
import { ManagerSelectBox } from './ManagerSelectBox';
import { PendingManager } from './PendingManager';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

type StatusType = {
  status_change_manager?: 'true' | null;
  new_manager_for_employee_id?: number | null;
};

interface IProps {
  setData: (data: StatusType) => void;
  currentValue: StatusType | {};
}

export const ManagerForm: FC<IProps> = ({ setData, currentValue }) => {
  const { id } = useParams();
  const employeeId = Number(id);
  const { data: employee } = useGetEmployeeDetailsQuery(employeeId);
  const { data: employeeFilters } = useGetEmployeeFiltersQuery();

  const scope = employee?.scope;
  const zone = employee?.zone;

  const managers = employeeFilters?.managers || [];

  const hasRightToApproveNewManager =
    scope && zone && canApproveNewManager(scope, zone);

  const approveNewManager = () => {
    setData({ status_change_manager: 'true' });
  };
  const rejectNewManager = () => {
    setData({
      new_manager_for_employee_id: null,
      status_change_manager: null,
    });
  };
  const requestNewManager = (newManagerId: number) => {
    setData({
      new_manager_for_employee_id: newManagerId,
    });
  };
  const assignNewManager = (newManagerId: number) => {
    setData({
      new_manager_for_employee_id: newManagerId,
      status_change_manager: 'true',
    });
  };
  const handleOnManagerSelect = (newManagerId: number) => {
    if (hasRightToApproveNewManager) {
      assignNewManager(newManagerId);
    } else {
      requestNewManager(newManagerId);
    }
  };

  return (
    <>
      {employee?.new_manager_for_employee ? (
        <PendingManager
          newManagerId={employee.new_manager_for_employee.id}
          newManagerName={employee.new_manager_for_employee.name}
          operationStatus={employee.status_change_manager}
          isEdit={true}
          canEdit={true}
          rejectNewManager={rejectNewManager}
          hasRightToApproveNewManager={hasRightToApproveNewManager}
          approveNewManager={approveNewManager}
          statusChangeManager={employee.status_change_manager}
          currentValue={currentValue}
          // statusChangeManager={data.status_change_manager}
        />
      ) : (
        <Grid item container>
          <ManagerSelectBox
            currentManager={employee?.manager}
            managers={managers}
            onChange={handleOnManagerSelect}
          />
        </Grid>
      )}
    </>
  );
};
