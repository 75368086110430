import * as Yup from 'yup';
import FormDialog from '../../../../../common/modal-dialogs/FormDialog';
import { TextField } from '../../../../../common/formikFormControls/TextField';
import { Grid, Button, Typography, IconButton } from '@mui/material';
import { Field } from 'formik';
import { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';

interface ICertificateModal {
  title: string;
  initialCertificateName: string;
  certificateId?: number;
  editCertificate?: ({
    certificate_name,
    certificateId,
  }: {
    certificate_name: string;
    certificateId: number | undefined;
  }) => void | undefined;
  confirmCertificate?: (values: {
    certificate_name: string;
    file: File;
  }) => void | undefined;
}

const validationSchema = (type: string) => {
  const schema: any = {
    certificate_name: Yup.string().required('This is a required field'),
  };
  if (type === 'Add') {
    schema.file = Yup.mixed()
      .nullable()
      .required('You need to provide a file')
      .test('fileSize', 'The file is too large', (value: any) => {
        return value?.size / 1024 / 1024 < 10;
      });
  }
  return Yup.object().shape(schema);
};

const getFileSize = (fileSize: number) => {
  if (fileSize / 1000 / 1000 < 1) {
    return `${(fileSize / 1000).toFixed()} Kb`;
  } else {
    return `${(fileSize / 1000 / 1000).toFixed(1)} MB`;
  }
};

const FileInput = (props: any) => {
  const { setFieldValue, setFile, field, accept } = props;
  const { name } = field;

  return (
    <div style={{ paddingTop: '20px' }}>
      <Button
        component='label'
        variant='outlined'
        sx={{
          background: '#F1EDE9',
          borderColor: '#D1D1D1',
          color: '#000',
        }}
        fullWidth
      >
        Upload file
        <input
          {...{
            name,
            accept,
            type: 'file',
            hidden: true,
            onChange: (e) => {
              setFile({
                name: e.target.files?.[0]?.name,
                size: e.target.files?.[0]?.size,
              });
              setFieldValue(field.name, e.target.files?.[0]);
            },
          }}
        />
      </Button>
    </div>
  );
};

const RenderFile = (props: any) => {
  const { file, setFile, setFieldValue } = props;
  return (
    <Grid container spacing={2} marginTop={'0'}>
      <Grid item>
        <DescriptionIcon color='primary' />
      </Grid>
      <Grid item>
        <Typography>{file.name}</Typography>
        <Typography>{file.size && getFileSize(file.size)}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          aria-label='clear'
          sx={{ p: 0.5 }}
          onClick={() => {
            setFile({ name: '', size: null });
            setFieldValue('file', null);
          }}
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const CertificateModal = ({
  title,
  initialCertificateName,
  certificateId,
  confirmCertificate,
  editCertificate,
}: ICertificateModal) => {
  const [file, setFile] = useState({ name: '', size: null });

  const onSubmit = (values: { certificate_name: string; file: File }) => {
    confirmCertificate && confirmCertificate(values);
    editCertificate &&
      editCertificate({
        certificate_name: values.certificate_name,
        certificateId: certificateId,
      });
  };

  const UploadFile = (props: any) => {
    return (
      <Grid item>
        <Field
          component={FileInput}
          accept='.png, .pdf, .jpg'
          setFile={setFile}
          {...props}
        />
        <Grid
          item
          style={{
            textAlign: 'center',
          }}
        >
          <Typography sx={{ color: '#797979', fontSize: '14px' }}>
            PDF, PNG orJPG (max 10MB)
          </Typography>
        </Grid>
        {file.name && <RenderFile {...{ file, setFile }} {...props} xs={12} />}
      </Grid>
    );
  };

  return (
    <FormDialog
      name={'CertificateModal'}
      title={`${title} certificate`}
      validationSchema={validationSchema(title)}
      initialValues={{
        certificate_name: initialCertificateName || '',
        file: null,
      }}
      onSubmit={onSubmit}
      submitButtonContent={title === 'Add' ? 'Upload' : 'Save changes'}
      width='xs'
    >
      {/* @ts-ignore */}
      <TextField
        name='certificate_name'
        placeholder='Certificate name here'
        label='Certificate name'
        xs={12}
        isRequired
      />
      {title === 'Add' && <UploadFile xs={12} name='file' />}
    </FormDialog>
  );
};

CertificateModal.NAME = 'CertificateModal';

export default CertificateModal;
