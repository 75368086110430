import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#F26939',
      main: '#F26939',
      dark: '#F26939',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
    },
    gray: {
      light: '#C4C4C4',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const buttonStyles = {
  fontWeight: 400,
  padding: '6px 16px',
  '&.MuiButton-root': {
    color: 'black',
  },
};
