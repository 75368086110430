export const location = (
  <svg
    width='10'
    height='14'
    viewBox='0 0 10 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.99992 0.333313C2.41992 0.333313 0.333252 2.41998 0.333252 4.99998C0.333252 8.49998 4.99992 13.6666 4.99992 13.6666C4.99992 13.6666 9.66658 8.49998 9.66658 4.99998C9.66658 2.41998 7.57992 0.333313 4.99992 0.333313ZM4.99992 6.66665C4.07992 6.66665 3.33325 5.91998 3.33325 4.99998C3.33325 4.07998 4.07992 3.33331 4.99992 3.33331C5.91992 3.33331 6.66658 4.07998 6.66658 4.99998C6.66658 5.91998 5.91992 6.66665 4.99992 6.66665Z'
      fill='#EF7530'
    />
  </svg>
);
