import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid';
import { Options, SkillRow } from '../../../components/Bench/types';
import {
  Office,
  Skill,
} from '../../../components/EmployeesPage/api/apiSlice.types';
import { setAuthData, deleteAuthData } from '../auth/authSlice';

interface InitialState {
  page: number;
  sortModel: GridSortModel;
  offices: Options;
  positions: Options;
  seniorities: Options;
  mainTechValues: Skill[];
  tags: Options;
  tagsToExclude: Options;
  skillRows: SkillRow[];
  isIncludeUnconfirmed: boolean;
}

const BELLEVUE_OFFICE: string = 'Bellevue';
// @ts-ignore
const office: Office | null = JSON.parse(localStorage.getItem('office'));
const initialState: InitialState = {
  page: 0,
  sortModel: [],
  // If user is not from Bellevue DC - set user's office from localStorage as default. Retains value on page reload.
  offices: office !== null && office?.name !== BELLEVUE_OFFICE ? [office] : [],
  positions: [],
  seniorities: [],
  mainTechValues: [],
  tags: [],
  tagsToExclude: [{ id: 4, name: 'Unpaid vacation' }],
  skillRows: [{ skills: [], level: null }],
  isIncludeUnconfirmed: false,
};

export const benchSlice = createSlice({
  name: 'bench',
  initialState: initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetPage: (state) => {
      state.page = initialState.page;
    },
    setSortModel: (state, action: PayloadAction<GridSortModel>) => {
      state.sortModel = action.payload;
    },
    resetSortModel: (state) => {
      state.sortModel = initialState.sortModel;
    },
    setOffices: (state, action: PayloadAction<Options>) => {
      state.offices = action.payload;
    },
    resetOffices: (state) => {
      state.offices = initialState.offices;
    },
    setPositions: (state, action: PayloadAction<Options>) => {
      state.positions = action.payload;
    },
    resetPositions: (state) => {
      state.positions = initialState.positions;
    },
    setSeniorities: (state, action: PayloadAction<Options>) => {
      state.seniorities = action.payload;
    },
    resetSeniorities: (state) => {
      state.seniorities = initialState.seniorities;
    },
    setMainTechValues: (state, action: PayloadAction<Skill[]>) => {
      state.mainTechValues = action.payload;
    },
    resetMainTechValues: (state) => {
      state.mainTechValues = initialState.mainTechValues;
    },
    setTags: (state, action: PayloadAction<Options>) => {
      state.tags = action.payload;
    },
    resetTags: (state) => {
      state.tags = initialState.tags;
    },
    setTagsToExclude: (state, action: PayloadAction<Options>) => {
      state.tagsToExclude = action.payload;
    },
    resetTagsToExclude: (state) => {
      state.tagsToExclude = initialState.tagsToExclude;
    },
    setSkillRows: (state, action: PayloadAction<SkillRow[]>) => {
      state.skillRows = action.payload;
    },
    resetSkillRows: (state) => {
      state.skillRows = initialState.skillRows;
    },
    setIsIncludeUnconfirmed: (state, action: PayloadAction<boolean>) => {
      state.isIncludeUnconfirmed = action.payload;
    },
    resetIsIncludeUnconfirmed: (state) => {
      state.isIncludeUnconfirmed = initialState.isIncludeUnconfirmed;
    },
  },
  extraReducers: (builder) => {
    builder
      // If user is not logged in, there is no office in localStorage and store is
      // already initialized with empty offices[]. Fill default office on login.
      .addCase(setAuthData, (state, action) => {
        const office = action?.payload?.user?.office;
        if (
          office?.name !== BELLEVUE_OFFICE &&
          !state?.offices?.some((e) => e.name === office?.name)
        ) {
          state.offices = [...state.offices, office];
        }
      })
      // Clean up offices on logout otherwise previous logged user's office will be also
      // displayed as default in Bench Management grid without page reload.
      .addCase(deleteAuthData, () => {
        return { ...initialState, offices: [] };
      });
  },
});

type State = { bench: InitialState };

// selectors
export const selectPage = (state: State) => state.bench.page;
export const selectSortModel = (state: State) => state.bench.sortModel;
export const selectOffices = (state: State) => state.bench.offices;
export const selectPositions = (state: State) => state.bench.positions;
export const selectSeniorities = (state: State) => state.bench.seniorities;
export const selectMainTechValues = (state: State) =>
  state.bench.mainTechValues;
export const selectTags = (state: State) => state.bench.tags;
export const selectTagsToExclude = (state: State) => state.bench.tagsToExclude;
export const selectSkillRows = (state: State) => state.bench.skillRows;
export const selectIsIncludeUnconfirmed = (state: State) =>
  state.bench.isIncludeUnconfirmed;

export const {
  setPage,
  resetPage,
  setSortModel,
  resetSortModel,
  setOffices,
  resetOffices,
  setPositions,
  resetPositions,
  setSeniorities,
  resetSeniorities,
  setMainTechValues,
  resetMainTechValues,
  setTags,
  resetTags,
  setTagsToExclude,
  resetTagsToExclude,
  setSkillRows,
  resetSkillRows,
  setIsIncludeUnconfirmed,
  resetIsIncludeUnconfirmed,
} = benchSlice.actions;

export default benchSlice.reducer;
