import * as Yup from 'yup';
import FormDialog from '../../../common/modal-dialogs/FormDialog';
import { TextField } from '../../../common/formikFormControls/TextField';
import { DatePickerField } from '../../../common/formikFormControls/DatePickerField';
import { DateTime } from 'luxon';
import { Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

interface IResolveFeedbackModal {
  initialDate: DateTime;
  initialComment?: string;
  confirmResolveFeedback: (
    resolution_date: string,
    resolution_comment: string
  ) => void;
}

const validationSchema = Yup.object().shape({
  resolution_date: Yup.date()
    .typeError('Date must be in the format YYYY-MM-DD')
    .required('Date must be in the format YYYY-MM-DD')
    .max(
      DateTime.now().endOf('day'),
      `Date cannot be later than ${DateTime.now().toFormat('yyyy-MM-dd')}`
    ),
});

const ResolveFeedbackModal = ({
  initialDate,
  initialComment,
  confirmResolveFeedback,
}: IResolveFeedbackModal) => {
  const onSubmit = ({
    resolution_date,
    resolution_comment,
  }: {
    resolution_date: DateTime;
    resolution_comment: string;
  }) => {
    const stringDate = resolution_date.toFormat('yyyy-MM-dd');

    confirmResolveFeedback(stringDate, resolution_comment);
  };

  return (
    <FormDialog
      name={'ResolveFeedbackModal'}
      title={'Do you want to resolve this feedback?'}
      validationSchema={validationSchema}
      initialValues={{
        resolution_date: initialDate,
        resolution_comment: initialComment,
      }}
      onSubmit={onSubmit}
      className='resolve_feedback_dialog'
      submitButtonContent='Confirm'
      width='xs'
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <HelpIcon color='disabled' style={{ fontSize: 48 }} />
        <span>Changes you made will not be saved.</span>
      </Grid>
      <DatePickerField
        name='resolution_date'
        value={initialDate}
        label='Resolution Date'
        maxDate={DateTime.now()}
        xs={12}
      />
      {/* @ts-ignore */}
      <TextField
        name='resolution_comment'
        placeholder='Resolution Comment'
        label='Resolution Comment'
        xs={12}
        multiline
      />
    </FormDialog>
  );
};

ResolveFeedbackModal.NAME = 'ResolveFeedbackModal';

export default ResolveFeedbackModal;
