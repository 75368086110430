import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import { Preloader } from '../common/preloader/preloader';
import {
  useGetEnglishLevelsQuery,
  useGetPositionsQuery,
  useGetSenioritiesQuery,
} from '../EmployeesPage/api/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowLoginModal,
  setShowLoginModal,
} from '../LoginPage/slice/loginSlice';
import { LoginDialog } from '../common/modal-dialogs/LoginDialog';

export const Layout = () => {
  // preload data for all pages
  useGetSenioritiesQuery();
  useGetPositionsQuery();
  useGetEnglishLevelsQuery();

  const showLoginModal = useSelector(selectShowLoginModal);
  const dispatch = useDispatch();

  return (
    <div className='page'>
      <Sidebar />
      <div className='page-content'>
        <Header />
        <Suspense fallback={<Preloader />}>
          <Outlet />
        </Suspense>
        <LoginDialog
          open={showLoginModal}
          setOpen={(isOpen) => dispatch(setShowLoginModal(isOpen))}
        />
      </div>
    </div>
  );
};
