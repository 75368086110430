import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import {
  useDeleteEmployeeCDPFileMutation,
  useUploadEmployeeCDPFileMutation,
} from '../../api/apiSlice';
import { ICDPFileResponse } from '../../api/apiSlice.types';
import { bytesToSize } from './utils';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const MAX_FILES = 5;
const MAX_FILE_SIZE = 10;

export const FileUpload = forwardRef(
  (
    {
      initialFiles = [],
      isEdit = false,
      onChange,
    }: {
      initialFiles?: ICDPFileResponse[];
      isEdit?: boolean;
      onChange?: () => void;
    },
    ref
  ) => {
    const maxAllowedSize = MAX_FILE_SIZE * 1024 * 1024; // Maximum allowed size 10 MB in bytes

    const [files, setFiles] = useState<ICDPFileResponse[]>([]);
    const [isFileSizeError, setIsFileSizeError] = useState(false);

    const fileInputRef: Ref<HTMLInputElement> = useRef(null);

    const [uploadEmployeeCDPFile, { isLoading: isUploadingFile }] =
      useUploadEmployeeCDPFileMutation({
        fixedCacheKey: 'shared-upload-cdp-file',
      });
    const [deleteEmployeeCDPFile, { isLoading: isDeletingFile }] =
      useDeleteEmployeeCDPFileMutation({
        fixedCacheKey: 'shared-delete-cdp-file',
      });

    useImperativeHandle(ref, () => ({
      getFiles: () => files,
    }));

    useEffect(() => {
      if (initialFiles?.length) {
        setFiles(initialFiles);
      }
    }, [initialFiles]);

    const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0];
      setIsFileSizeError(false);

      onChange?.();

      if (file) {
        if (file?.size > maxAllowedSize) {
          setIsFileSizeError(true);
          return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_name', file?.name);

        const uploadedFile = await uploadEmployeeCDPFile({ formData }).unwrap();
        if (uploadedFile) {
          setFiles([...files, uploadedFile]);
        }

        event.target.value = ''; // To select same file again
      }
    };

    const deleteFile = async (fileId: number) => {
      onChange?.();

      if (fileId) {
        if (!isEdit) {
          await deleteEmployeeCDPFile({ fileId }).unwrap();
        }

        const filteredFiles = files.filter((item) => item?.id !== fileId);
        setFiles(filteredFiles);
      }
    };

    return (
      <Box mt={1}>
        <Box mt={1} mb={1}>
          {files.map((file) => (
            <Box key={file?.id} sx={{ display: 'flex' }}>
              <Box mr={1}>
                <Typography>{file?.file_name}</Typography>
                <Typography color='#828282' fontSize={12}>
                  {bytesToSize(file?.file_size)}
                </Typography>
              </Box>
              <IconButton onClick={() => deleteFile(file?.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>

        {isFileSizeError && (
          <Box
            mb={1}
            sx={{ display: 'flex', alignItems: 'center', color: '#FF0000' }}
          >
            <ErrorOutlineIcon />
            <Typography fontSize={14} ml={0.5}>
              File size is more than 10 MB
            </Typography>
          </Box>
        )}

        <Button
          variant='outlined'
          startIcon={
            isUploadingFile || isDeletingFile ? (
              <CircularProgress size={20} />
            ) : (
              <FilePresentIcon />
            )
          }
          sx={{
            background: '#f2f2f2',
            color: '#797979',
            borderColor: '#dfdfdf',
            marginBottom: 1,
          }}
          disabled={
            isUploadingFile || isDeletingFile || files.length >= MAX_FILES
          }
          onClick={() => fileInputRef?.current?.click()}
        >
          Upload files
        </Button>
        <Typography color='#828282' fontSize={12}>
          Maximum {MAX_FILES} files can be uploaded. Maximum size of each file
          is {MAX_FILE_SIZE} MB.
        </Typography>

        <label>
          <input
            style={{ display: 'none' }}
            type='file'
            ref={fileInputRef}
            onChange={uploadFile}
          />
        </label>
      </Box>
    );
  }
);
