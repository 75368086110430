import loginReducer from '../../components/LoginPage/slice/loginSlice';
import authReducer from '../slices/auth/authSlice';
import employeesReducer from '../../components/EmployeesPage/slices/employeesSlice';
import employeesListReducer from '../../components/EmployeesPage/slices/employeesListSlice';
import headerReducer from '../slices/header/headerSlice';
import loaderReducer from '../slices/loader/loaderSlice';
import modalDialogsReducers from '../slices/modalDialog/modalDialogSlice';
import { apiSlice } from '../../components/EmployeesPage/api/apiSlice';
import feedbacksReducer from '../../components/Feedbacks/slices/feedbacksSlice';
import benchReducer from '../slices/bench/benchSlice';

// todo: change state slice names. without word reducer in it. ex `loaderReducer` --> `loader`
export const rootReducer = {
  loaderReducer,
  loginReducer,
  authReducer,
  employeesReducer,
  employeesListReducer,
  modalDialogsReducers,
  headerReducer,
  feedbacksReducer,
  bench: benchReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
};
