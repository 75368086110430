import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { IRiskReportResponse } from '../../EmployeesPage/api/apiSlice.types';
import { defaultColors } from '../utils';
import TooltipRenderer from './ChartTooltip';

type DailyStars = {
  risk_date: string;
  count: number;
  total: number;
};
type Series = {
  label: string;
  data: DailyStars[];
};

export function ChartAppRisk({
  data,
  colors,
}: {
  data: IRiskReportResponse[];
  colors: string[];
}) {
  const primaryAxis = useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.risk_date,
      position: 'left',
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.count,
        formatters: {
          scale: (value: any) => {
            return `${Math.round(value)}`;
          },
        },
        stacked: true,
        position: 'bottom',
      },
    ],
    []
  );

  const dataResult: Series[] = useMemo(() => {
    return data.reduce((acc: Series[], elem: IRiskReportResponse) => {
      elem.risks.forEach((risk) => {
        const capitalizedLabel =
          risk.type.charAt(0).toUpperCase() + risk.type.slice(1);

        if (acc.length === 0) {
          acc.push({ label: capitalizedLabel, data: [] });
        }

        const foundItem = acc.find((el) => el?.label === capitalizedLabel);

        if (foundItem) {
          foundItem?.data?.unshift({
            count: risk.count,
            risk_date: elem.date,
            total: elem.total,
          });
        } else {
          acc.push({
            label: capitalizedLabel,
            data: [
              { count: risk.count, risk_date: elem.date, total: elem.total },
            ],
          });
        }
      });
      return acc;
    }, []);
  }, [data]);

  return dataResult?.length > 0 ? (
    <Chart
      options={{
        defaultColors: colors || defaultColors,
        data: dataResult,
        primaryAxis,
        secondaryAxes,
        tooltip: {
          render: (tooltipItem) => <TooltipRenderer {...tooltipItem} />,
        },
      }}
    />
  ) : (
    <div>No data</div>
  );
}
