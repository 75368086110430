import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { IGoalInState } from '../../../api/apiSlice.types';
import React from 'react';
import { FieldItem } from '../Detail/EmployeeCDPDetails';
import { formatEmptyValue } from '../../EmployeeProfile/utils';

interface IProps {
  goal: IGoalInState | null;
  closeModal: (goal: IGoalInState | null) => void;
  isOwnProfile?: boolean;
}

export const ViewGoalModal = ({ goal, closeModal, isOwnProfile }: IProps) => {
  const statusField = () => {
    return (
      <Chip
        label={!goal?.is_closed ? 'Open' : 'Closed'}
        color='success'
        size='small'
        sx={{ backgroundColor: !goal?.is_closed ? '#1CB18E' : '#D1D1D1' }}
      />
    );
  };

  return (
    <Dialog
      open={!!goal}
      maxWidth='md'
      onClose={() => closeModal(null)}
      sx={{ '& .MuiDialog-paper': { width: '800px' } }}
    >
      <DialogTitle>View Goal: {goal?.short_summary}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Divider />
        <Grid container>
          <Grid item xs={6}>
            <FieldItem label='Short Summary'>
              <span>{formatEmptyValue(goal?.short_summary)}</span>
            </FieldItem>
          </Grid>
          <Grid item xs={6}>
            <FieldItem label='Due Date'>
              <span>{formatEmptyValue(goal?.due_date)}</span>
            </FieldItem>
          </Grid>
          <Grid item xs={6}>
            <FieldItem label='Mentor'>
              <span>{formatEmptyValue(goal?.mentor?.name)}</span>
            </FieldItem>
          </Grid>
          <Grid item xs={6}>
            <FieldItem label='Status'>
              <span>{statusField()}</span>
            </FieldItem>
          </Grid>
          <Grid item xs={12}>
            <FieldItem label='Actions'>
              <pre>{formatEmptyValue(goal?.actions)}</pre>
            </FieldItem>
          </Grid>
          {!isOwnProfile && (
            <Grid item xs={12}>
              <FieldItem label='Comments'>
                <pre>{formatEmptyValue(goal?.comment)}</pre>
              </FieldItem>
            </Grid>
          )}
        </Grid>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModal(null)} sx={{ color: '#000000' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
