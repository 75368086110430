import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import CustomIcon from '../../icon';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ item }) => {
  const { is_archived, is_reviewed } = item;

  const getStatusIcon = () =>
    is_archived
      ? 'archived'
      : is_reviewed
      ? 'check-green-circle'
      : 'warning-yellow-triangle';

  const getStatusText = () =>
    is_archived ? 'Archived' : is_reviewed ? 'Active' : 'Needs review';

  return (
    <Tooltip title={getStatusText()} placement='top'>
      <span>
        <CustomIcon iconName={getStatusIcon()} />
      </span>
    </Tooltip>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
};

export const CellCvStatus = withCellWrapper(Cell);
