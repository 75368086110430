import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import { IBenchTag, IEmployeePatchRequest } from '../../api/apiSlice.types';
import {
  useEditEmployeeDetailsMutation,
  useEditEmployeeOrgInfoMutation,
  useGetEmployeeDetailsQuery,
} from '../../api/apiSlice';
import { useParams } from 'react-router-dom';
import { Section } from './Section';
import { FieldWithLabel } from './FieldWithLabel';
import { ManagerForm } from '../EmployeeProfile/Contacts/ManagerForm2';
import { HRManagerAutocompleteField } from '../EmployeeProfile/Contacts/HRManagerAutocompleteField2';
import { EmploymentEdit } from './EmploymentEdit';
import { BenchTagsEdit2 } from './BenchTags/BenchTagsEdit2';
import { ProbationEdit } from './Probation/ProbationEdit';
import {
  canEditOrgInfo,
  canEditSupervisorInfo,
  canEditBenchTags,
  hasAnyScope,
} from '../../../../utils/permissions/checkAccess';
import {
  getCanViewSensitiveInfo,
  getCanEditSensitiveInfo,
} from '../../../../utils/permissions/sensitiveInfo';
import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';
import { useDispatch } from 'react-redux';

interface EmployeeOrgEditFormProps {
  is_hr: boolean;
  is_key_resource: boolean;
  is_mentor: boolean;
  is_nonprod: boolean;
  is_supplementary: boolean;
  is_team_lead: boolean;
  backfill: { value: number; label: string }[];
  backfillOptions: { value: number; label: string }[];
  mentors: { value: number; label: string }[];
  mentorOptions: { value: number; label: string }[];
  hrId?: number;
  benchTags: IBenchTag[];
  navigateToEmployeeOrg: () => void;
}

interface IInitialValues {
  hr: boolean;
  keyResource: boolean;
  mentor: boolean;
  nonProduction: boolean;
  supplementary: boolean;
  teamLead: boolean;
  backfill: { value: number; label: string }[];
  mentors: { value: number; label: string }[];
  mentorOptions: { value: number; label: string }[];
  employeeManager: {
    status_change_manager?: 'true' | null;
    new_manager_for_employee_id?: number | null;
  };
  hrId?: number;
  benchTags: IBenchTag[];
}

interface IPatchData extends IEmployeePatchRequest {
  bench_tags?: IBenchTag[];
}

export const EmployeeOrgEditForm = (props: EmployeeOrgEditFormProps) => {
  const [editEmployeeDetails] = useEditEmployeeDetailsMutation();
  const [editEmployeeOrgInfo] = useEditEmployeeOrgInfoMutation();

  const dispatch = useDispatch();

  const { id } = useParams();
  const employeeId = Number(id);
  const { data: employee } = useGetEmployeeDetailsQuery(employeeId);
  const scope = employee?.scope;
  const zone = employee?.zone;

  const initialValues: IInitialValues = {
    hr: props.is_hr,
    keyResource: props.is_key_resource,
    mentor: props.is_mentor,
    nonProduction: props.is_nonprod,
    supplementary: props.is_supplementary,
    teamLead: props.is_team_lead,
    backfill: props.backfill,
    mentors: props.mentors,
    mentorOptions: props.mentorOptions,
    employeeManager: {},
    hrId: props.hrId,
    benchTags: props.benchTags,
  };

  const sendPatchRequest = async (patchData: IPatchData) => {
    // todo: refactor try/catch blocks
    if (patchData.bench_tags) {
      try {
        await editEmployeeOrgInfo({
          id: Number(id),
          data: patchData,
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
    try {
      await editEmployeeDetails({
        employeeId: Number(id),
        data: patchData,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelHandler = (dirty: boolean) => {
    if (dirty) {
      return dispatch(
        // @ts-ignore
        showDialog(ConfirmationDialog, {
          dialogHeader: 'Are you sure you want to cancel editing?',
          dialogTitle: 'Changes you made will not be saved.',
          closeDialog: (dialogName: string) =>
            dispatch(closeDialog(dialogName)),
          confirmDialog: (dialogName: string) => {
            dispatch(closeDialog(dialogName));
            props.navigateToEmployeeOrg();
          },
        })
      );
    }
    props.navigateToEmployeeOrg();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const newManagerId =
          values?.employeeManager?.new_manager_for_employee_id;
        const newManagerStatus = values?.employeeManager?.status_change_manager;
        const patchData: IPatchData = {
          is_hr: values.hr,
          is_key_resource: values.keyResource,
          is_mentor: values.mentor,
          is_nonprod: values.hr ? true : values.nonProduction,
          is_supplementary: values.supplementary,
          is_team_lead: values.teamLead,
          backfill_ids: values.backfill?.map((item) => item.value),
          mentors_ids: values.mentors?.map((item) => item.value),
          ...(newManagerStatus || newManagerStatus === null
            ? { status_change_manager: newManagerStatus }
            : {}),
          ...(newManagerId || newManagerId === null
            ? { new_manager_for_employee_id: newManagerId }
            : {}),
        };

        if (zone && canEditBenchTags(zone)) {
          patchData.bench_tags = values.benchTags;
        }

        if (zone && canEditOrgInfo(zone) && values.hrId) {
          patchData.hr_id = values.hrId;
        }

        sendPatchRequest(patchData).then(() => {
          props.navigateToEmployeeOrg();
        });
      }}
    >
      {(formik) => (
        <Form>
          {zone && canEditOrgInfo(zone) && (
            <Section title='Probation'>
              <ProbationEdit />
            </Section>
          )}

          {zone &&
            (canEditOrgInfo(zone) ||
              (scope &&
                getCanViewSensitiveInfo(scope) &&
                getCanEditSensitiveInfo(zone))) && (
              <Section title='Employee attributes'>
                {canEditOrgInfo(zone) && (
                  <>
                    <Field name={'teamLead'}>
                      {({ field }: FieldProps) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox checked={field.value} />}
                          label={'Team Lead'}
                          componentsProps={{ typography: { fontSize: 14 } }}
                        />
                      )}
                    </Field>
                    <Field name={'mentor'}>
                      {({ field }: FieldProps) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox checked={field.value} />}
                          label={'Mentor'}
                          componentsProps={{ typography: { fontSize: 14 } }}
                        />
                      )}
                    </Field>
                  </>
                )}

                {scope &&
                  zone &&
                  getCanViewSensitiveInfo(scope) &&
                  getCanEditSensitiveInfo(zone) && (
                    <>
                      <Field name={'keyResource'}>
                        {({ field }: FieldProps) => (
                          <FormControlLabel
                            {...field}
                            control={<Checkbox checked={field.value} />}
                            label={'Key Resource'}
                            componentsProps={{ typography: { fontSize: 14 } }}
                          />
                        )}
                      </Field>
                      <Field name={'hr'}>
                        {({ field }: FieldProps) => (
                          <FormControlLabel
                            {...field}
                            control={<Checkbox checked={field.value} />}
                            label={'HR'}
                            componentsProps={{ typography: { fontSize: 14 } }}
                          />
                        )}
                      </Field>
                      <Field name={'nonProduction'}>
                        {({ field }: FieldProps) => (
                          <FormControlLabel
                            {...field}
                            control={<Checkbox checked={field.value} />}
                            label={'Non-Production'}
                            componentsProps={{ typography: { fontSize: 14 } }}
                          />
                        )}
                      </Field>
                      <Field name={'supplementary'}>
                        {({ field }: FieldProps) => (
                          <FormControlLabel
                            {...field}
                            control={<Checkbox checked={field.value} />}
                            label={'Supplementary'}
                            componentsProps={{ typography: { fontSize: 14 } }}
                          />
                        )}
                      </Field>
                    </>
                  )}

                {formik?.initialValues?.hr
                  ? !formik?.values?.hr && (
                      <Box sx={{ display: 'flex' }}>
                        <Alert severity='info'>
                          The employee will be unassigned from all his/her HR
                          subordinates
                        </Alert>
                      </Box>
                    )
                  : formik?.values?.hr &&
                    !formik?.values?.nonProduction && (
                      <Box sx={{ display: 'flex' }}>
                        <Alert severity='info'>
                          The employee's status will be changed to
                          Non-Production
                        </Alert>
                      </Box>
                    )}
              </Section>
            )}

          {zone && canEditSupervisorInfo(zone) && (
            <Section title='Connected members'>
              <Stack direction='row' spacing={3}>
                <FieldWithLabel title=''>
                  <Field name={'backfill'}>
                    {({ field, form }: FieldProps) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        sx={{ width: 450 }}
                        size='small'
                        options={props.backfillOptions || []}
                        renderInput={(params) => (
                          <TextField {...params} label='Backfilled by' />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li style={{ padding: 2 }} {...props}>
                            <Checkbox checked={selected} />
                            {option.label}
                          </li>
                        )}
                        value={field.value}
                        onChange={(
                          event: any,
                          newValue: { value: number; label: string }[]
                        ) => {
                          form.setFieldValue(field.name, newValue);
                          form.setFieldTouched(field.name, true);
                        }}
                        limitTags={5}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                      />
                    )}
                  </Field>
                </FieldWithLabel>

                <FieldWithLabel title=''>
                  <Field name={'mentors'}>
                    {({ field, form }: FieldProps) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        sx={{ width: 450 }}
                        size='small'
                        options={props.mentorOptions || []}
                        renderInput={(params) => (
                          <TextField {...params} label='Mentored by' />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li style={{ padding: 2 }} {...props}>
                            <Checkbox checked={selected} />
                            {option.label}
                          </li>
                        )}
                        value={field.value}
                        onChange={(
                          event: any,
                          newValue: { value: number; label: string }[]
                        ) => {
                          form.setFieldValue(field.name, newValue);
                          form.setFieldTouched(field.name, true);
                        }}
                        limitTags={5}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                      />
                    )}
                  </Field>
                </FieldWithLabel>
              </Stack>
            </Section>
          )}

          {zone && canEditOrgInfo(zone) && (
            <Section>
              <Stack direction='row' spacing={3}>
                {scope && hasAnyScope(scope) && (
                  <FieldWithLabel title=''>
                    <Field name='employeeManager'>
                      {({ field, form }: FieldProps) => (
                        <ManagerForm
                          currentValue={field.value}
                          setData={(newValue: {}) => {
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }}
                        />
                      )}
                    </Field>
                  </FieldWithLabel>
                )}
                <FieldWithLabel title=''>
                  <Field name='hrId'>
                    {({ field, form }: FieldProps) => (
                      <HRManagerAutocompleteField
                        setData={(newValue: number) => {
                          form.setFieldValue(field.name, newValue);
                          form.setFieldTouched(field.name, true);
                        }}
                      />
                    )}
                  </Field>
                </FieldWithLabel>
              </Stack>
            </Section>
          )}

          {zone && canEditBenchTags(zone) && (
            <Section title='Tags'>
              <Field name='benchTags'>
                {({ field, form }: FieldProps) => (
                  <BenchTagsEdit2
                    currentValue={field.value}
                    onChange={(newValue) => {
                      form.setFieldValue(field.name, newValue);
                      form.setFieldTouched(field.name, true);
                    }}
                  />
                )}
              </Field>
            </Section>
          )}

          {zone && canEditOrgInfo(zone) && (
            <Section title='Employment'>
              <EmploymentEdit />
            </Section>
          )}

          <Stack m={5} direction='row' spacing={3}>
            <Button
              variant='outlined'
              disabled={formik.isSubmitting}
              onClick={() => cancelHandler(formik?.dirty)}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type={'submit'}
              disabled={
                !formik.dirty ||
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.values
              }
            >
              Save changes
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
