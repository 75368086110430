import { Route, Routes } from 'react-router-dom';
import { Feedbacks } from './Feedbacks';
import { FeedbackPage } from './FeedbackPage';
import FeedbackCreateEditForm from './FeedbackCreateEdit/FeedbackCreateEditForm';

export const EmployeeFeedbacks = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Feedbacks />} />
      <Route path={'/create'} element={<FeedbackCreateEditForm />} />
      <Route path={'/:feedbackId'} element={<FeedbackPage />} />
      <Route path={'/:feedbackId/edit'} element={<FeedbackCreateEditForm />} />
    </Routes>
  );
};
