import classNames from 'classnames';
import { Field } from 'formik';
import MultiSuggestBox from '../form-items/multi-value-suggestbox/multi-value-suggestbox';

const renderSelectInput = ({
  field,
  options,
  onAdd,
  isSubmitting,
  error,
  className,
  disabled,
  label,
  isRequired,
  isMulti,
  placeholder,
  isLoading,
  isClearable,
  isCreatable,
}) => {
  const selectedValue = options.find(
    (x) => field.value && (field.value === x.value || field.value === x.label)
  );

  return (
    <div className='multi-box-wrapper'>
      <MultiSuggestBox
        className={classNames(className, {
          'form-control__input__error': error,
        })}
        options={options}
        handleValue={isMulti ? field.value : selectedValue}
        isDisabled={isSubmitting || disabled}
        onAdd={(selected) => {
          if (selected) {
            onAdd(selected);
          } else {
            onAdd(null);
          }
        }}
        label={label}
        isRequired={isRequired}
        isMulti={isMulti}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={isClearable}
        isCreatable={isCreatable}
      />
    </div>
  );
};

export const SelectField = function ({
  name,
  setFieldValue,
  onChange,
  getValue = (value) => value,
  label,
  className,
  xs,
  isRequired,
  isMulti,
  options,
  placeholder,
  isLoading,
  isClearable,
  disabled,
  isCreatable,
}) {
  return (
    <Field
      className={className}
      name={name}
      labelId={`field-${name}_label-id`}
      id={`field-${name}`}
      component={renderSelectInput}
      onAdd={(value) => {
        setFieldValue(name, getValue(value) || '');
        if (onChange) {
          onChange(getValue(value));
        }
      }}
      label={label}
      isRequired={isRequired}
      isMulti={isMulti}
      placeholder={placeholder}
      isLoading={isLoading}
      isClearable={isClearable}
      disabled={disabled}
      options={options}
      xs={xs}
      isCreatable={isCreatable}
    />
  );
};
