import { FC, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface IProps {
  name: string;
  options: { id: number | string; name: string }[];
  isLoading: boolean;
  value: { id: number; name: string }[];
  setValue: (value: { id: number | string; name: string }[]) => void;
}

export const ComboBox: FC<IProps> = ({
  name,
  options,
  isLoading,
  value,
  setValue,
}) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <div>
      <Autocomplete
        multiple
        size={'small'}
        value={value}
        onChange={(
          event: any,
          newValue: { id: number | string; name: string }[]
        ) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        getOptionLabel={(option) => option.name}
        sx={{ minWidth: 300 }}
        renderInput={(params) => <TextField {...params} label={name} />}
        loading={isLoading}
        disableCloseOnSelect
        limitTags={3}
      />
    </div>
  );
};
