import { Preloader } from './components/common/preloader/preloader';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import history from './utils/history';
import { DIALOGS } from './components/common/modal-dialogs';
import { LoginPage } from './components/LoginPage/LoginPage';
import { EmployeesPage } from './components/EmployeesPage/EmployeesPage';
import { Feedbacks } from './components/Feedbacks/Feedbacks';
import { Layout } from './components/Layout/Layout';
import {
  selectAuthToken,
  selectIsLogoutByHand,
} from './store/slices/auth/authSlice';
import { selectIsLoading } from './store/slices/loader/loaderSlice';
import { selectModalDialogConfig } from './store/slices/modalDialog/modalDialogSlice';
import RedirectToEmployee from './RedirectToEmployee';
import Report from './components/Report';
import { Requests } from './components/Requests/Requests';
import { Bench } from './components/Bench/Bench';
import { BenchSummary } from './components/BenchSummary/BenchSummary';
import { CDPList } from './components/CDP/CDPList';
import { EmployeeCDPCreate } from './components/EmployeesPage/components/EmployeeCDP/Create/EmployeeCDPCreate';
import { RESTORE_URL_KEY } from './components/LoginPage/slice/loginSaga';

const Router = () => {
  const isLoggedIn = useSelector(selectAuthToken); // todo: it have only one usage
  const isLogoutByHand = useSelector(selectIsLogoutByHand);
  const dialogSettings = useSelector(selectModalDialogConfig);
  const isLoading = useSelector(selectIsLoading);

  const CustomDialog = dialogSettings.dialogName.map((name: string) => {
    const dialogs = DIALOGS as any;
    return dialogs[name];
  }); // todo: create component for this

  const restoreUrlStr = isLogoutByHand
    ? ''
    : `?${RESTORE_URL_KEY}=` + window.location.pathname;

  return (
    // todo: change HistoryRouter to Router when all navigation will be handled by React Router and navigate API, not by history in saga
    // @ts-ignore
    <HistoryRouter history={history}>
      {dialogSettings.dialogName && CustomDialog}

      {/* todo: Preloader should hold its state*/}
      {isLoading > 0 && <Preloader />}

      {isLoggedIn ? (
        <Routes>
          <Route path='/login' element={<LoginPage />} />

          <Route path={'/'} element={<Layout />}>
            <Route index element={<Navigate replace to={`employees`} />} />
            <Route path='employees/*' element={<EmployeesPage />} />
            <Route path='employee/:employee' element={<RedirectToEmployee />} />
            <Route path='feedbacks/*' element={<Feedbacks />} />
            <Route path='report/*' element={<Report />} />
            <Route path='requests' element={<Requests />} />
            <Route path='bench' element={<Bench />} />
            <Route path='bench-summary' element={<BenchSummary />} />
            <Route path='cdps' element={<CDPList />} />
            <Route path='cdps/create' element={<EmployeeCDPCreate />} />

            <Route path='*' element={<Navigate replace to='/' />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path='employee/:employee' element={<RedirectToEmployee />} />
          <Route path='/login' element={<LoginPage />} />
          <Route
            path='*'
            element={<Navigate replace to={`/login${restoreUrlStr}`} />}
          />
        </Routes>
      )}
    </HistoryRouter>
  );
};

export default Router;
