import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

interface IProps {
  title: string;
  children: React.ReactNode;
}

export const FieldWithLabel: FC<IProps> = ({ title, children }) => (
  <Stack spacing={1}>
    <Typography variant='body2' color='#797979'>
      {title}
    </Typography>
    {children}
  </Stack>
);
