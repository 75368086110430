import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  DataGrid,
  gridClasses,
  GridColumns,
  GridSortModel,
} from '@mui/x-data-grid';
import { useGetCDPListQuery } from '../EmployeesPage/api/apiSlice';
import {
  IEmployeeCDPListResponse,
  IPaginationParams,
} from '../EmployeesPage/api/apiSlice.types';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  resetComponent,
  setComponent,
} from '../../store/slices/header/headerSlice';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonGroup from '../common/buttonGroup';
import { canEditCDP } from '../../utils/permissions/checkAccess';

const LIMIT = 50;
const DEFAULT_PARAMS = { limit: LIMIT, offset: 0, ordering: undefined };

export const CDPList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [params, setParams] = useState<IPaginationParams>({
    ...DEFAULT_PARAMS,
    search: searchValue,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const { data, isFetching } = useGetCDPListQuery(params);
  const zone = data?.zone || '';

  useEffect(() => {
    dispatch(
      setComponent({
        component: 'CDP',
        isTitle: true,
      })
    );

    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const columns = useMemo<GridColumns<IEmployeeCDPListResponse>>(
    () => [
      {
        field: 'employee',
        headerName: 'Employee',
        width: 250,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link
              to={`/employees/${params?.row?.employee?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography color='#1976D2' fontSize={14}>
                {params.row?.employee?.name}
              </Typography>
            </Link>
          );
        },
      },
      {
        field: 'mentor',
        headerName: 'Mentor',
        width: 250,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link
              to={`/employees/${params.row.mentor?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography color='#1976D2' fontSize={14}>
                {params.row.mentor?.name}
              </Typography>
            </Link>
          );
        },
      },
      {
        field: 'modified_date',
        headerName: 'Last Updated',
        width: 140,
        valueFormatter: (params) =>
          DateTime.fromISO(params.value).toISODate() || '',
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 160,
        renderCell: (params) => {
          const dueDate = DateTime.fromISO(params.value);
          const value = dueDate.toISODate() || '';
          const tomorrow = DateTime.fromISO(
            // @ts-ignore todo: fix it
            DateTime.now().plus({ days: 1 }).toISODate()
          );
          const isDueDateTomorrow =
            dueDate.toMillis() - tomorrow.toMillis() <= 0;

          return (
            <Stack direction='row' spacing={2}>
              <Typography fontSize={14}>{value}</Typography>
              {isDueDateTomorrow && (
                <Tooltip title='Due date expiration'>
                  <WarningIcon fontSize='small' color='warning' />
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'goals_achieved',
        headerName: 'Goals Achieved',
        width: 120,
        sortable: false,
        valueGetter: (params) =>
          `${params.row.closed_goals}/${params.row.total_goals}` || '',
      },
    ],
    []
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSortModel([]);
      setParams({ ...DEFAULT_PARAMS, search: searchValue });
    }
  };

  return (
    <Box px={3} overflow='auto'>
      <Stack direction='row' justifyContent='space-between' pt={4} pb={4}>
        <TextField
          size='small'
          placeholder='Search by employee or mentor...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchValue ? (
              <IconButton
                size='small'
                onClick={() => {
                  setSortModel([]);
                  setSearchValue('');
                  setParams({ ...DEFAULT_PARAMS, search: '' });
                }}
              >
                <ClearIcon />
              </IconButton>
            ) : undefined,
          }}
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(e?.currentTarget?.value)
          }
          onBlur={() => {
            setSortModel([]);
            setParams({ ...DEFAULT_PARAMS, search: searchValue });
          }}
          onKeyDown={onKeyDown}
          sx={{ width: 500 }}
        />
        <ButtonGroup
          buttonList={[
            {
              text: '+ Create',
              variant: 'contained',
              color: 'primary',
              onClick: () => navigate('/cdps/create'),
              visibility: canEditCDP(zone),
            },
          ]}
        />
      </Stack>

      <Box>
        <DataGrid
          rows={data?.results || []}
          columns={columns}
          loading={isFetching}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          autoHeight
          paginationMode='server'
          rowCount={data?.count || 0}
          pageSize={params?.limit}
          rowsPerPageOptions={[]}
          // @ts-ignore
          page={params?.offset / params?.limit}
          onPageChange={(page: number) =>
            setParams({ ...params, offset: LIMIT * page })
          }
          sortingMode='server'
          sortModel={sortModel}
          onSortModelChange={(sortModel: GridSortModel) => {
            setSortModel(sortModel);

            if (sortModel.length) {
              const { field, sort } = sortModel[0];
              setParams({
                ...params,
                offset: 0,
                ordering: sort === 'desc' ? '-' + field : field,
              });
            } else {
              setParams({ ...params, offset: 0, ordering: undefined });
            }
          }}
          sx={() => ({
            // Scrolling inside grid content
            [`.${gridClasses.virtualScroller}`]: {
              maxHeight: '650px !important',
              overflow: 'auto !important',
            },
          })}
        />
      </Box>
    </Box>
  );
};
