import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  useGetBenchFiltersQuery,
  useGetSkillLevelsQuery,
  useGetSkillsQuery,
} from '../EmployeesPage/api/apiSlice';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Menu,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BenchSkillFilter } from './BenchSkillFilter';
import { Option, option, SkillRow } from './types';
import { FilterChips } from './FilterChips';
import { SkillFilterChips } from './SkillFilterChips';
import { GroupHeader, GroupItems } from './OptionsGroup';
import { Skill } from '../EmployeesPage/api/apiSlice.types';
import { FilterChip } from './FilterChip';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const BenchFilters = ({
  offices,
  setOffices,
  positions,
  setPositions,
  seniorities,
  setSeniorities,
  mainTechValues,
  setMainTechValues,
  tags,
  setTags,
  tagsToExclude,
  setTagsToExclude,
  skillRows,
  setSkillRows,
  isIncludeUnconfirmed,
  setIsIncludeUnconfirmed,
}: {
  offices: Option[];
  setOffices: React.Dispatch<React.SetStateAction<Option[]>>;
  positions: Option[];
  setPositions: React.Dispatch<React.SetStateAction<Option[]>>;
  seniorities: Option[];
  setSeniorities: React.Dispatch<React.SetStateAction<Option[]>>;
  mainTechValues: Skill[];
  setMainTechValues: React.Dispatch<React.SetStateAction<Skill[]>>;
  tags: Option[];
  setTags: React.Dispatch<React.SetStateAction<Option[]>>;
  tagsToExclude: Option[];
  setTagsToExclude: React.Dispatch<React.SetStateAction<Option[]>>;
  skillRows: SkillRow[];
  setSkillRows: React.Dispatch<React.SetStateAction<SkillRow[]>>;
  isIncludeUnconfirmed: boolean;
  setIsIncludeUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: benchFilters, isLoading: isBenchFiltersLoading } =
    useGetBenchFiltersQuery();

  const officeOptions = benchFilters?.offices || [];
  const tagOptions = benchFilters?.bench_tags || [];
  const positionOptions = benchFilters?.positions || [];
  const seniorityOptions = benchFilters?.seniorities || [];
  const mainTechOptions = benchFilters?.main_tech_skills || [];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: skillOptionsData, isLoading: isSkillsLoading } =
    useGetSkillsQuery();

  const { data: levelOptions = [], isLoading: isLevelsLoading } =
    useGetSkillLevelsQuery();

  return (
    <Stack spacing={1} mb={1.5}>
      <Stack direction='row' spacing={1}>
        <Autocomplete
          size={'small'}
          multiple
          disableCloseOnSelect
          loading={isBenchFiltersLoading}
          options={officeOptions}
          getOptionLabel={(option) => option.name}
          style={{ width: 240 }}
          limitTags={1}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              label='Delivery Center'
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              InputProps={{ endAdornment: params.InputProps.endAdornment }}
              size='small'
              fullWidth
            />
          )}
          value={offices}
          onChange={(event: any, newValue: option[]) => setOffices(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />

        <BenchSkillFilter
          isSkillsLoading={isSkillsLoading}
          skillOptions={skillOptionsData || []}
          isLevelsLoading={isLevelsLoading}
          levelOptions={levelOptions}
          skillRows={skillRows}
          setSkillRows={setSkillRows}
          isIncludeUnconfirmed={isIncludeUnconfirmed}
          setIsIncludeUnconfirmed={setIsIncludeUnconfirmed}
        />

        <div>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={
              <Box sx={{ fontSize: 24, display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownIcon />
              </Box>
            }
            variant={'outlined'}
            color={'inherit'}
            sx={{
              textTransform: 'none',
              height: 40,
              color: 'rgba(0, 0, 0, 0.6)',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Tags
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Box sx={{ p: 2 }} width={560}>
              <Stack direction='row' spacing={2}>
                <Autocomplete
                  size={'small'}
                  multiple
                  disableCloseOnSelect
                  loading={isBenchFiltersLoading}
                  options={tagOptions}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 240 }}
                  limitTags={1}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label='Included Tags'
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      InputProps={{
                        endAdornment: params.InputProps.endAdornment,
                      }}
                      size='small'
                      fullWidth
                    />
                  )}
                  value={tags}
                  onChange={(event: any, newValue: option[]) => {
                    setTags(newValue);
                  }}
                />

                <Autocomplete
                  size={'small'}
                  multiple
                  disableCloseOnSelect
                  loading={isBenchFiltersLoading}
                  options={tagOptions}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 240 }}
                  limitTags={1}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label='Excluded Tags'
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      InputProps={{
                        endAdornment: params.InputProps.endAdornment,
                      }}
                      size='small'
                      fullWidth
                    />
                  )}
                  value={tagsToExclude}
                  onChange={(event: any, newValue: option[]) => {
                    setTagsToExclude(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Stack>
            </Box>
          </Menu>
        </div>

        <Autocomplete
          size={'small'}
          multiple
          disableCloseOnSelect
          loading={isBenchFiltersLoading}
          options={mainTechOptions}
          getOptionLabel={(option) => option.name}
          style={{ width: 240 }}
          limitTags={1}
          isOptionEqualToValue={(option, value) => {
            return (
              option?.id === value?.id &&
              option?.category?.id === value?.category?.id
            );
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option?.name + option?.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              label='Main Tech'
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              InputProps={{ endAdornment: params.InputProps.endAdornment }}
              size='small'
              fullWidth
            />
          )}
          value={mainTechValues}
          onChange={(event: any, newValue: Skill[]) => {
            setMainTechValues(newValue);
          }}
          groupBy={(option) => option.category.name}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />

        <Autocomplete
          size={'small'}
          multiple
          disableCloseOnSelect
          loading={isBenchFiltersLoading}
          options={positionOptions}
          getOptionLabel={(option) => option.name}
          style={{ width: 240 }}
          limitTags={1}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              label='Position'
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              InputProps={{ endAdornment: params.InputProps.endAdornment }}
              size='small'
              fullWidth
            />
          )}
          value={positions}
          onChange={(event: any, newValue: option[]) => {
            setPositions(newValue);
          }}
        />
        <Autocomplete
          size={'small'}
          multiple
          disableCloseOnSelect
          loading={isBenchFiltersLoading}
          options={seniorityOptions}
          getOptionLabel={(option) => option.name}
          style={{ width: 240 }}
          limitTags={1}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              label='Seniority level'
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              InputProps={{ endAdornment: params.InputProps.endAdornment }}
              size='small'
              fullWidth
            />
          )}
          value={seniorities}
          onChange={(event: any, newValue: option[]) => {
            setSeniorities(newValue);
          }}
        />
      </Stack>

      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FilterChips values={offices} setValues={setOffices} />
        {isIncludeUnconfirmed && (
          <Box sx={{ mr: 1, mb: 0.5 }} key='unconfimed'>
            <FilterChip
              label='Include unconfirmed'
              onDelete={() => {
                setIsIncludeUnconfirmed(false);
              }}
            />
          </Box>
        )}
        {skillRows.map((skillRow, skillRowIndex) => {
          const prefix = skillRow.level?.name;
          return (
            <SkillFilterChips
              key={skillRowIndex + `${skillRow.level?.name}`}
              values={skillRow.skills}
              // remove the skill and level pair
              setValues={(skill) => {
                const deleteFromPrev = (
                  preSkillRows: SkillRow[],
                  skill: Option
                ) => {
                  // create deep copy of preSkillRows
                  const newSkillRows = preSkillRows.map((preSkillRow) => {
                    return {
                      level: preSkillRow.level,
                      skills: [...preSkillRow.skills],
                    };
                  });

                  let currentRowSkills = newSkillRows[skillRowIndex].skills;
                  // is this the last
                  if (currentRowSkills.length === 1) {
                    // remove the row
                    newSkillRows.splice(skillRowIndex, 1);
                  } else {
                    // remove the skill from the row
                    newSkillRows[skillRowIndex].skills =
                      currentRowSkills.filter(
                        (currentRowSkill) =>
                          currentRowSkill?.id !== skill?.id ||
                          (currentRowSkill.id === skill?.id &&
                            currentRowSkill?.category?.id !==
                              skill?.category?.id)
                      );
                  }

                  return newSkillRows;
                };
                setSkillRows(deleteFromPrev(skillRows, skill));
              }}
              prefix={prefix}
            />
          );
        })}
        <FilterChips values={tags} setValues={setTags} />
        <FilterChips
          values={tagsToExclude}
          setValues={setTagsToExclude}
          isExcluded
          prefix='Not'
        />
        <FilterChips
          values={mainTechValues}
          setValues={setMainTechValues}
          prefix='Main'
        />
        <FilterChips values={positions} setValues={setPositions} />
        <FilterChips values={seniorities} setValues={setSeniorities} />
      </Box>
    </Stack>
  );
};
