import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { Badge } from '../../../../common/badges/Badges';
import TextField from '@mui/material/TextField';

export const ManagerSelectBox = ({ managers, onChange, currentManager }) => (
  <Autocomplete
    disablePortal
    options={managers}
    defaultValue={currentManager || null}
    getOptionLabel={(option) => option.name}
    renderOption={({ className, ...rest }, option) => (
      <Grid
        {...rest}
        style={{ cursor: 'pointer' }}
        container
        direction='row'
        alignItems='center'
      >
        <Grid item>
          {option.avatar_url ? (
            <img
              src={option.avatar_url}
              alt='Avatar'
              className='manager-option__avatar'
            />
          ) : (
            option.name && (
              <div className='manager-option__avatar'>
                <Badge item={option.name} />
              </div>
            )
          )}
        </Grid>
        <Grid item>{option.name}</Grid>
      </Grid>
    )}
    sx={{ width: 300 }}
    renderInput={(params) => (
      <TextField {...params} size='small' label='Request New RM' />
    )}
    onChange={(_e, value) => onChange(value?.id || null)}
    disableClearable
  />
);
