import axios from 'axios';
import qs from 'qs';
import history from './history';
import { UNAUTHORIZED_STATUS } from './const-variable';

const apiRoot = process.env.REACT_APP_HOST;

let updateTokenRequest = null;

const refreshToken = () => {
  if (updateTokenRequest) {
    return updateTokenRequest;
  }
  const refresh = localStorage.getItem('RefreshToken');
  updateTokenRequest = axios({
    url: apiRoot + '/auth/token_refresh/',
    method: 'post',
    timeout: 5000,
    data: {
      refresh,
    },
  });
  return updateTokenRequest;
};

const reRequest = (request) => {
  const handleToken = localStorage.getItem('AuthToken');
  const config = {
    url: request.url.replace(apiRoot, ''),
    method: request.method,
    handleToken: handleToken,
  };
  if (request.data) {
    config['data'] = request.data;
  }
  if (request.params) {
    config['params'] = request.params;
  }
  return http(config)
    .then((res) => {
      updateTokenRequest = null;
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export default function http({
  method,
  url,
  data,
  params,
  handleToken,
  isFile,
  accept,
}) {
  const token = handleToken ? handleToken : localStorage.getItem('AuthToken');
  const config = {
    method: method.toLowerCase(),
    url: apiRoot + url,
    params,
    paramsSerializer: function (p) {
      return qs.stringify(p, { arrayFormat: 'repeat' });
    },
  };

  if (data) {
    config['data'] = data;
  }

  if (token) {
    config['headers'] = {
      Accept: accept || 'application/json',
      'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
      Authorization: 'Bearer ' + token,
    };
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response.status === UNAUTHORIZED_STATUS &&
        error.config &&
        !error.config.__isRetryRequest &&
        !error.config.url.includes('/token_refresh/')
      ) {
        return refreshToken()
          .then((response) => {
            localStorage.setItem('AuthToken', response.data.access);
            return response;
          })
          .then(() => {
            return reRequest(error.config);
          })
          .catch(() => {
            updateTokenRequest = null;
            history.push('/login'); //todo: remove the history push.
          });
      }
      throw error;
    }
  );

  return axios(config);
}
