import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import { ActiveProjects } from './ActiveProjects/ActiveProjects';
import { Skills } from './Skills';
import { Certificates } from './Certificates';
import { GridWrap } from './utils';
import { TabNav } from '../CommonComponents/TabNav/TabNav';
import './employee-profile.css';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import { PersonalContacts } from './Contacts/PersonalContacts';
import { getFullLevelString } from './Contacts/getFullLevelString';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {
  useGetEmployeeCertificatesQuery,
  useGetEmployeeQuery,
  useGetEnglishLevelsQuery,
} from '../../api/apiSlice';
import { EditActions } from './EditActions';

interface IProps {
  isCurrentUser: boolean;
}

export const EmployeeProfile: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { data: employee } = useGetEmployeeQuery(Number(params.id));

  const { data: certificates } = useGetEmployeeCertificatesQuery(
    Number(params.id)
  );

  const scope = employee?.scope;

  const { data: englishLevelsData, isLoading: isLoadingEnglishLevels } =
    useGetEnglishLevelsQuery();

  useEffect(() => {
    dispatch(
      setComponent({
        component: `Employee details`,
        isTitle: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  return employee ? (
    <Scrollbars autoHide autoHideTimeout={300}>
      <div className='empolyee_profile_wrapper'>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <TabNav />
          </Grid>
          <Grid item>
            <EditActions scope={scope} isCurrentUser={props.isCurrentUser} />
          </Grid>
        </Grid>

        <Grid container>
          <GridWrap xs={8}>
            <ProfileInfo employee={employee} />
            <ActiveProjects
              projects={employee.projects}
              currentUserId={employee.id}
            />
            {!!employee.skills.length && <Skills skills={employee.skills} />}
            {!!certificates?.length && (
              <Certificates certificates={certificates} />
            )}
          </GridWrap>

          <GridWrap xs={4}>
            <Grid container direction='column' spacing={2}>
              <PersonalContacts />
              {!isLoadingEnglishLevels && employee.english_level && (
                <Grid item>
                  <Grid item mb={2}>
                    <Typography fontSize={18} fontWeight={700} color='#222222'>
                      English Level
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color='#4b4b4b'>
                      {getFullLevelString(
                        englishLevelsData?.find(
                          (level) => level.title.id === employee?.english_level
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {employee.employee_contacts?.length > 0 && (
                <Grid item container direction={'column'}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    color='#222222'
                    mb={2}
                  >
                    Personal contacts:
                  </Typography>
                  <Stack spacing={2}>
                    {employee.employee_contacts.map((contact, index) => (
                      <Stack
                        direction='row'
                        spacing={1}
                        key={`${contact}${index}`}
                      >
                        <PermContactCalendarIcon
                          fontSize={'small'}
                          color={'primary'}
                        />
                        <Typography color='#4b4b4b'>{`${contact.name}: ${contact.contact}`}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </GridWrap>
        </Grid>
      </div>
    </Scrollbars>
  ) : null;
};
//todo: hideTracksWhenNotNeeded is not real API. remove
