import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

interface IGoalCommentProps {
  goalComment?: string;
  onSubmit: (comment: string) => void;
  setIsCloseTrigger?: (val: number | undefined) => void;
  isCloseTrigger?: number;
  goalId?: number;
  title?: string;
}

export const GoalComment = ({
  goalComment,
  onSubmit,
  isCloseTrigger,
  setIsCloseTrigger,
  goalId,
  title = 'Comment',
}: IGoalCommentProps) => {
  const [comment, setComment] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isCloseTrigger && goalId && isCloseTrigger === goalId) {
      setIsDialogOpen(!!isCloseTrigger);
    }
  }, [isCloseTrigger]);

  const onSubmitHandler = async () => {
    if (comment.trim()) {
      await onSubmit(comment.trim());
      onCancelHandler();
    }
  };

  const onCancelHandler = () => {
    setIsDialogOpen(false);
    setIsCloseTrigger && setIsCloseTrigger(undefined);
    setComment('');
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={isDialogOpen}
        onClose={onCancelHandler}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight={600}>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            defaultValue={goalComment}
            onChange={(e) => setComment(e.currentTarget.value)}
            placeholder='Please enter your comment'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelHandler}>Cancel</Button>
          <Button onClick={onSubmitHandler}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Button
        variant='text'
        endIcon={goalComment ? <OpenInFullIcon /> : undefined}
        onClick={() => setIsDialogOpen(true)}
        sx={{ color: '#1976D2', textTransform: 'none', padding: 0 }}
      >
        {goalComment ? 'Read More' : 'Add Comment'}
      </Button>
    </>
  );
};
