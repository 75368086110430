import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Project } from '../../types/types';
import { ProjectCard } from './ProjectCard';
import { useIsInViewport } from './useIsInViewport';
import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const ACTIVE_STATUS = 'Active';
const MIN_ASSIGNMENT = 0;
// const BILLABLE = 'Billable';
const CARD_WIDTH = 320;

export const filterProject = ({
  status,
  assignment,
}: {
  status: string;
  assignment: string;
  assignment_type: string;
}) => {
  return status === ACTIVE_STATUS && +assignment > MIN_ASSIGNMENT;
};

interface Props {
  projects: Project[];
  currentUserId: number;
}

export const ActiveProjects = ({ projects, currentUserId }: Props) => {
  const swiperRef = useRef(null);
  const firstSlideRef = useRef(null);
  const lastSlideRef = useRef(null);
  const [isControlsVisible, setVisible] = useState<boolean>(false);
  const isInViewportFirstSlide = useIsInViewport(firstSlideRef, swiperRef);
  const isInViewportLastSlide = useIsInViewport(lastSlideRef, swiperRef);

  const activeProjects = projects.filter(filterProject);

  useEffect(() => {
    checkIfNeedRenderControls();
  }, [swiperRef.current, activeProjects]);

  const checkIfNeedRenderControls = () => {
    if (swiperRef.current && !!activeProjects.length) {
      // @ts-ignore
      const swiperWidth = swiperRef.current.clientWidth;
      if (activeProjects.length > swiperWidth / CARD_WIDTH) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      return;
    }
    setVisible(false);
  };

  const setRef = (idx: number) => {
    if (idx === 0) {
      return firstSlideRef;
    }
    if (idx === activeProjects.length - 1) {
      return lastSlideRef;
    }
  };

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    // @ts-ignore
    swiperRef.current.swiper.slideNext();
  }, []);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    // @ts-ignore
    swiperRef.current.swiper.slidePrev();
  }, []);

  return !!activeProjects.length ? (
    <Grid
      container
      className='profile_block__delimiter'
      direction='column'
      py={3}
    >
      <Grid
        item
        container
        pb={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <span className='profile_block__title'>Active Projects</span>
        </Grid>
        {isControlsVisible && (
          <Grid item>
            <Grid container columnSpacing={2}>
              <Grid item>
                <IconButton
                  onClick={handlePrev}
                  disabled={isInViewportFirstSlide}
                  color={'primary'}
                >
                  <ArrowBackIosRoundedIcon fontSize={'small'} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleNext}
                  disabled={isInViewportLastSlide}
                  color={'primary'}
                >
                  <ArrowForwardIosRoundedIcon fontSize={'small'} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Swiper
          slidesPerView='auto'
          spaceBetween={16}
          pagination={{
            clickable: true,
          }}
          // @ts-ignore
          ref={swiperRef}
        >
          {activeProjects.map((project, index) => (
            <SwiperSlide
              key={index}
              style={{ width: CARD_WIDTH }}
              // @ts-ignore
              ref={setRef(index)}
            >
              <ProjectCard project={project} currentUserId={currentUserId} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  ) : null;
};
