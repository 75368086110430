import { FC, PropsWithChildren, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import IconPencil from '@mui/icons-material/Edit';
import IconTrash from '@mui/icons-material/Delete';
import { Grid, Typography } from '@mui/material';

interface IAkvelonContactInformationRowProps {
  icon?: ReactNode | null;
  isEdit?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  tooltip?: string;
}

export const AkvelonContactInformationRow: FC<
  PropsWithChildren<IAkvelonContactInformationRowProps>
> = ({ icon, isEdit, onClick, onRemove, children }) => {
  return (
    <Grid item container>
      {icon && (
        <span style={{ display: 'inline-flex', paddingRight: '8px' }}>
          {icon}
        </span>
      )}
      <Typography color={'#4b4b4b'}>{children}</Typography>
      {isEdit && (
        <>
          <IconButton
            aria-label='edit'
            onClick={onClick}
            size={'small'}
            sx={{
              marginLeft: '8px',
              color: 'gray.light',
            }}
          >
            <IconPencil fontSize={'inherit'} />
          </IconButton>
          {typeof onRemove === 'function' ? (
            <IconButton
              aria-label='remove'
              onClick={onRemove}
              size={'small'}
              sx={{
                color: 'gray.light',
              }}
            >
              <IconTrash fontSize={'inherit'} />
            </IconButton>
          ) : null}
        </>
      )}
    </Grid>
  );
};
