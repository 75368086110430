import React, { FC } from 'react';
import { ScopeType } from '../../../../utils/permissions/types';
import { canEditEmployee } from '../../../../utils/permissions/checkAccess';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface IProps {
  scope?: ScopeType;
  isCurrentUser: boolean;
}

export const EditActions: FC<IProps> = (props) => {
  const isCanEditEmployee = props.scope && canEditEmployee(props.scope);

  if (!isCanEditEmployee && !props.isCurrentUser) {
    return null;
  }
  return (
    <div>
      <Box mt={2} mr={3}>
        <Link to={'edit'} style={{ textDecoration: 'none' }}>
          <Button variant='contained' size='small'>
            Edit
          </Button>
        </Link>
      </Box>
    </div>
  );
};
