import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../../../store/slices/auth/authSlice';
import { Checkbox } from '../../../common/form-items/checkbox/checkbox';
import { Box } from '@mui/material';
import QuickFiltersPopover from './QuickFiltersPopover';

import { IQuickFilter } from './types';

interface IProps {
  quickFilters: IQuickFilter[];
  employeePage: string;
  handleChangeQuickFilters: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const QuickFilters: FC<IProps> = ({
  quickFilters,
  employeePage,
  handleChangeQuickFilters,
}) => {
  const MAX_VISIBLE_QUICK_FILTERS = 3;
  const visibleCheckboxes: JSX.Element[] = [];
  const hiddenCheckboxes: JSX.Element[] = [];

  const permissions = useSelector(selectPermissions);

  const renderCheckbox = (
    checkbox: IQuickFilter,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  ) => (
    <Checkbox
      key={checkbox?.id}
      name={checkbox?.name}
      onChange={onChange}
      checked={!!checkbox?.selected}
    />
  );

  const gridQuickFilters = quickFilters?.filter((filter) => {
    if (filter.showFor.includes(employeePage)) {
      if (
        !(
          permissions &&
          filter?.permission &&
          !permissions?.includes(filter?.permission)
        )
      ) {
        return true;
      }
    }
  });

  if (gridQuickFilters?.length <= MAX_VISIBLE_QUICK_FILTERS) {
    gridQuickFilters.map((filter) =>
      visibleCheckboxes.push(renderCheckbox(filter, handleChangeQuickFilters))
    );
  } else {
    gridQuickFilters.map((filter) => {
      if (filter.isShown) {
        visibleCheckboxes.push(
          renderCheckbox(filter, handleChangeQuickFilters)
        );
      } else {
        hiddenCheckboxes.push(renderCheckbox(filter, handleChangeQuickFilters));
      }
    });
  }

  return (
    <Box ml={6} sx={{ display: 'flex', alignItems: 'center' }}>
      {visibleCheckboxes}
      {!!hiddenCheckboxes?.length && (
        <QuickFiltersPopover checkboxes={hiddenCheckboxes} />
      )}
    </Box>
  );
};
