import { Box, Grid } from '@mui/material';
import { Backfills } from './Backfills';
import { Mentors2 } from './Mentors2';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../../api/apiSlice';

export const ConnectedMembers = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const backfills = employee?.backfill;
  const mentors = employee?.mentors;
  const isBackfills = !!backfills?.length;
  const isMentors = !!mentors?.length;
  const isAnyConnectedMembers = isBackfills || isMentors;

  return (
    <div>
      <Box mb={2}>
        <span className='profile_block__title'>Connected members</span>
      </Box>
      {isAnyConnectedMembers && (
        <Grid container alignItems='flex-start'>
          {isBackfills && <Backfills />}
          {isMentors && <Mentors2 />}
        </Grid>
      )}
    </div>
  );
};
