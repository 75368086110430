import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import { CellAvatar } from './CellAvatar';
import { CellClipboard } from './CellClipboard';

const cellComponents = {
  avatar_url: (props, column) => <CellAvatar {...props} column={column} />,
  name: (props, column) => (
    <div
      className={classNames(
        props?.item?.employee_status === 'dismissed' && 'dismissed'
      )}
      key={props.item[column.Id] + Math.random()}
    >
      {props.item[column.Id]}
    </div>
  ),
  username: (props, column) => <CellClipboard {...props} column={column} />,
};

const Cell = (props) =>
  props.column.fields.map(
    (field) =>
      cellComponents[field.Id] &&
      cellComponents[field.Id](
        { ...props, key: field.Id + props.idItem },
        field
      )
  );

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellGroupedFields = withCellWrapper(Cell);
