import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.css';

export const Checkbox = (props) => {
  const { checked, name, onChange, disabled = false } = props;

  return (
    <div>
      <label
        className={`checkbox-cont-list-item configuration-option check_role recurrence-checkbox ${
          disabled ? 'checkbox-disabled' : ''
        }`}
      >
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
        <span className='configuration-option__checkbox__container'>
          <span className='configuration-option__checkbox' />
        </span>
        <span className='checklist_item-name'>{name}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

// todo: remove css class recurrence-checkbox. looks like it doesn't exist
// todo: remove extra div tag
// todo: fix css chaos
