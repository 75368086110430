import MUIClearIcon from '@mui/icons-material/Clear';

export default function ClearIcon({ field, initialValues, onClick }: any) {
  const styles = {
    display:
      field?.value && field?.value !== initialValues[field.name]
        ? 'block'
        : 'none',
    cursor: 'pointer',
    fontSize: '21px',
    position: 'absolute',
    right: '26px',
    color: 'rgba(0, 0, 0, 0.54)',
  };

  return <MUIClearIcon sx={styles} onClick={onClick} />;
}
