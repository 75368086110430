import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../api/apiSlice';

const DISMISSED_STATUS = 'dismissed';

const textForActive = 'Employee is currently active';
const textForDismissed = 'Employee is currently dismissed';

export const Employment = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const isDismissed = employee?.employee_status === DISMISSED_STATUS;

  const text = isDismissed ? textForDismissed : textForActive;

  return (
    <div>
      <Box mb={2}>
        <span className='profile_block__title'>Employment</span>
      </Box>
      {employee && <Typography fontSize={14}>{text}</Typography>}
    </div>
  );
};
