import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetSkillLevelsQuery,
  useGetUserSkillsQuery,
} from '../../../api/apiSlice';
import { SkillOption } from './SkillOption';

export const SkillsQuestionnaireList = ({
  currentCategory,
  currentCategoryIndex,
  handleUpdate,
  skills,
  updatedSkills,
}) => {
  const { id } = useParams();
  const { data: userSkills = [], isSuccess } = useGetUserSkillsQuery(id);
  const { data: options = [] } = useGetSkillLevelsQuery();
  const [availableSkills, setAvailableSkills] = useState([]);

  useEffect(() => {
    if (currentCategory) {
      const mergedSkills = skills.map((skill) => {
        const userSkill = userSkills.find(
          (user_skill) =>
            user_skill.skill.id === skill.id &&
            user_skill.skill_category.id === currentCategory.id
        );
        const updatedSkill = updatedSkills.find(
          (updated_skill) =>
            updated_skill.skill.id === skill.id &&
            updated_skill.skill_category.id === currentCategory.id
        );
        return {
          id: userSkill?.id || null,
          skill: {
            id: skill.id,
            name: skill.name,
          },
          skill_category: currentCategory,
          self_assessed_level:
            updatedSkill?.self_assessed_level ||
            userSkill?.self_assessed_level ||
            null,
        };
      });
      setAvailableSkills(mergedSkills);
    }
  }, [currentCategory, isSuccess]);

  return (
    <div className='skills-questionnaire__list'>
      {currentCategory && (
        <h6>
          {currentCategoryIndex + 1}. {currentCategory.name}
        </h6>
      )}
      {availableSkills.map((skillOption) => (
        <SkillOption
          options={options}
          skillOption={skillOption}
          handleChange={handleUpdate}
          key={skillOption.skill.id}
        />
      ))}
    </div>
  );
};
