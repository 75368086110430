import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select as MaterialSelect } from '@mui/material';
import './select.css';

const renderObjectItem = (item, i) => (
  <MenuItem key={i} value={item.id} componentname={item.name}>
    {item.name}
  </MenuItem>
);

const renderOptions = (options) =>
  options.map((item, i) => renderObjectItem(item, i));

const Select = (props) => {
  const {
    value,
    formClassName,
    inputProps,
    selectOptions,
    disabled,
    customId = '',
    onChange,
  } = props;

  const selectValue = !!value || value === 0 ? value : 'none';

  const newProps = {
    ...inputProps,
    id: inputProps?.id + customId,
  };

  return (
    <FormControl className={formClassName}>
      <MaterialSelect
        value={selectValue}
        id={inputProps?.id + customId}
        onChange={onChange}
        inputProps={newProps}
        disabled={disabled}
        className={disabled ? 'placeholder--is-disabled' : ''}
      >
        {renderOptions(selectOptions)}
      </MaterialSelect>
    </FormControl>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formClassName: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  selectOptions: PropTypes.array,
  disabled: PropTypes.bool,
};

export default Select;
