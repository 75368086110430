export const sidebarHierarchy = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill='white'
      d='M 3.421875 14.472656 C 3.707031 14.472656 3.9375 14.242188 3.9375 13.957031 L 3.9375 12.703125 L 11.484375
    12.703125 L 11.484375 13.957031 C 11.484375 14.242188 11.714844 14.472656 12 14.472656 C 12.285156 14.472656
    12.515625 14.242188 12.515625 13.957031 L 12.515625 12.703125 L 20.0625 12.703125 L 20.0625 13.957031 C 20.0625
    14.242188 20.292969 14.472656 20.578125 14.472656 C 20.863281 14.472656 21.097656 14.242188 21.097656 13.957031
    L 21.097656 12.1875 C 21.097656 11.902344 20.863281 11.671875 20.578125 11.671875 L 12.515625 11.671875 L 12.515625
    10.453125 C 12.515625 10.167969 12.285156 9.9375 12 9.9375 C 11.714844 9.9375 11.484375 10.167969 11.484375
    10.453125 L 11.484375 11.671875 L 3.421875 11.671875 C 3.136719 11.671875 2.902344 11.902344 2.902344 12.1875
    L 2.902344 13.957031 C 2.902344 14.242188 3.136719 14.472656 3.421875 14.472656 Z M 3.421875 14.472656 '
    />
    <path
      fill='white'
      d='M 9.742188 17.355469 C 9.742188 18.601562 10.753906 19.613281 12 19.613281 C 13.246094 19.613281 14.257812
    18.601562 14.257812 17.355469 C 14.257812 16.109375 13.246094 15.09375 12 15.09375 C 10.753906 15.09375 9.742188
    16.109375 9.742188 17.355469 Z M 13.226562 17.355469 C 13.226562 18.03125 12.675781 18.582031 12 18.582031 C
    11.324219 18.582031 10.773438 18.03125 10.773438 17.355469 C 10.773438 16.679688 11.324219 16.128906 12 16.128906
    C 12.675781 16.128906 13.226562 16.679688 13.226562 17.355469 Z M 13.226562 17.355469 '
    />
    <path
      fill='white'
      d='M 13.726562 20.054688 L 10.273438 20.054688 C 9.339844 20.054688 8.578125 20.8125 8.578125 21.75 L 8.578125
    23.023438 C 8.578125 23.308594 8.8125 23.539062 9.097656 23.539062 L 14.902344 23.539062 C 15.1875 23.539062
    15.421875 23.308594 15.421875 23.023438 L 15.421875 21.75 C 15.421875 20.8125 14.660156 20.054688 13.726562
    20.054688 Z M 9.613281 21.75 C 9.613281 21.382812 9.910156 21.085938 10.273438 21.085938 L 11.484375 21.085938
    L 11.484375 22.503906 L 9.613281 22.503906 Z M 14.386719 22.503906 L 12.515625 22.503906 L 12.515625 21.085938
    L 13.726562 21.085938 C 14.089844 21.085938 14.386719 21.382812 14.386719 21.75 Z M 14.386719 22.503906 '
    />
    <path
      fill='white'
      d='M 12 4.980469 C 13.246094 4.980469 14.257812 3.964844 14.257812 2.71875 C 14.257812 1.476562 13.246094 0.460938
    12 0.460938 C 10.753906 0.460938 9.742188 1.476562 9.742188 2.71875 C 9.742188 3.964844 10.753906 4.980469
    12 4.980469 Z M 12 1.496094 C 12.675781 1.496094 13.226562 2.042969 13.226562 2.71875 C 13.226562 3.398438
    12.675781 3.945312 12 3.945312 C 11.324219 3.945312 10.773438 3.398438 10.773438 2.71875 C 10.773438 2.042969
    11.324219 1.496094 12 1.496094 Z M 12 1.496094 '
    />
    <path
      fill='white'
      d='M 9.097656 8.90625 L 14.902344 8.90625 C 15.1875 8.90625 15.421875 8.671875 15.421875 8.386719 L 15.421875
    7.113281 C 15.421875 6.179688 14.660156 5.417969 13.726562 5.417969 L 10.273438 5.417969 C 9.339844 5.417969
    8.578125 6.179688 8.578125 7.113281 L 8.578125 8.386719 C 8.578125 8.671875 8.8125 8.90625 9.097656 8.90625 Z
    M 14.386719 7.113281 L 14.386719 7.871094 L 12.515625 7.871094 L 12.515625 6.453125 L 13.726562 6.453125 C
    14.089844 6.453125 14.386719 6.75 14.386719 7.113281 Z M 9.613281 7.113281 C 9.613281 6.75 9.910156 6.453125
    10.273438 6.453125 L 11.484375 6.453125 L 11.484375 7.871094 L 9.613281 7.871094 Z M 9.613281 7.113281 '
    />
    <path
      fill='white'
      d='M 18.320312 17.355469 C 18.320312 18.601562 19.335938 19.613281 20.578125 19.613281 C 21.824219 19.613281
    22.839844 18.601562 22.839844 17.355469 C 22.839844 16.109375 21.824219 15.09375 20.578125 15.09375 C
    19.335938 15.09375 18.320312 16.109375 18.320312 17.355469 Z M 21.804688 17.355469 C 21.804688 18.03125
    21.253906 18.582031 20.578125 18.582031 C 19.902344 18.582031 19.355469 18.03125 19.355469 17.355469 C
    19.355469 16.679688 19.902344 16.128906 20.578125 16.128906 C 21.253906 16.128906 21.804688 16.679688
    21.804688 17.355469 Z M 21.804688 17.355469 '
    />
    <path
      fill='white'
      d='M 22.304688 20.054688 L 18.855469 20.054688 C 17.917969 20.054688 17.160156 20.8125 17.160156 21.75
    L 17.160156 23.023438 C 17.160156 23.308594 17.390625 23.539062 17.675781 23.539062 L 23.484375 23.539062
    C 23.769531 23.539062 24 23.308594 24 23.023438 L 24 21.75 C 24 20.8125 23.238281 20.054688 22.304688
    20.054688 Z M 18.191406 21.75 C 18.191406 21.382812 18.488281 21.085938 18.855469 21.085938 L 20.0625
    21.085938 L 20.0625 22.503906 L 18.191406 22.503906 Z M 22.96875 22.503906 L 21.097656 22.503906 L 21.097656
    21.085938 L 22.304688 21.085938 C 22.671875 21.085938 22.96875 21.382812 22.96875 21.75 Z M 22.96875 22.503906 '
    />
    <path
      fill='white'
      d='M 3.421875 19.613281 C 4.664062 19.613281 5.679688 18.601562 5.679688 17.355469 C 5.679688 16.109375
    4.664062 15.09375 3.421875 15.09375 C 2.175781 15.09375 1.160156 16.109375 1.160156 17.355469 C 1.160156
    18.601562 2.175781 19.613281 3.421875 19.613281 Z M 3.421875 16.128906 C 4.097656 16.128906 4.648438
    16.679688 4.648438 17.355469 C 4.648438 18.03125 4.097656 18.582031 3.421875 18.582031 C 2.746094 18.582031
    2.195312 18.03125 2.195312 17.355469 C 2.195312 16.679688 2.746094 16.128906 3.421875 16.128906 Z M 3.421875 16.128906 '
    />
    <path
      fill='white'
      d='M 5.144531 20.054688 L 1.695312 20.054688 C 0.761719 20.054688 0 20.8125 0 21.75 L 0 23.023438 C 0
    23.308594 0.230469 23.539062 0.515625 23.539062 L 6.324219 23.539062 C 6.609375 23.539062 6.839844 23.308594
    6.839844 23.023438 L 6.839844 21.75 C 6.839844 20.8125 6.082031 20.054688 5.144531 20.054688 Z M 1.03125
    21.75 C 1.03125 21.382812 1.328125 21.085938 1.695312 21.085938 L 2.902344 21.085938 L 2.902344 22.503906
    L 1.03125 22.503906 Z M 5.808594 22.503906 L 3.9375 22.503906 L 3.9375 21.085938 L 5.144531 21.085938 C 5.511719
    21.085938 5.808594 21.382812 5.808594 21.75 Z M 5.808594 22.503906 '
    />
  </svg>
);
