export const SkillsQuestionnaireCategories = ({
  categories,
  currentCategory,
  onClick,
}) => {
  return (
    <ol className='skills-questionnaire__categories__list'>
      {categories.map((category) => (
        <li
          key={category.id}
          className={category.id === currentCategory?.id ? 'active' : ''}
          onClick={() => onClick(category)}
        >
          {category.name}
        </li>
      ))}
    </ol>
  );
};
