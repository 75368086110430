import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';
//todo: make a common component
export const renderRiskCell = (params: GridRenderCellParams) => {
  const risk = params.value.risk;
  if (!risk) return;

  let color = '#fff';
  let backgroundColor = '#9b9b9b';

  if (risk.id === 'danger') {
    color = '#fff';
    backgroundColor = '#d32f2f';
  }
  if (risk.id === 'warning') {
    color = '#fff';
    backgroundColor = '#ED6C03';
  }
  if (risk.id === 'resolved') {
    color = 'rgba(0, 0, 0, 0.87)';
    backgroundColor = 'rgba(0, 0, 0, 0.08)';
  }

  // @ts-ignore
  return (
    <Chip
      label={risk.name}
      size={'small'}
      sx={{ backgroundColor: backgroundColor, color: color }}
    />
  );
};
