import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import {
  closeDialog,
  showDialog,
} from '../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../modal-dialogs/ConfirmationDialog';

function useBlocker(confirmation, when = false) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }
    const unblock = navigator.block((naviProps) => {
      const context = {
        ...naviProps,
        retry: () => {
          unblock();
          naviProps.retry();
        },
      };
      confirmation(context);
    });
    return unblock;
  }, [navigator, confirmation, when]);
}

export default function usePrompt(message, when = false) {
  const dispatch = useDispatch();

  const showConfirmation = useCallback(
    (context) => {
      dispatch(
        showDialog(ConfirmationDialog, {
          dialogHeader: 'Do you want to leave this page?',
          dialogTitle: message,
          closeDialog: (name) => dispatch(closeDialog(name)),
          confirmDialog: (name) => {
            dispatch(closeDialog(name));
            context.retry();
          },
        })
      );
    },
    [message]
  );

  useBlocker(showConfirmation, when);
}

// create a component that uses the hook
export const PromptOnReroute = ({ message, when }) => {
  usePrompt(message, when);
  return <></>;
};
