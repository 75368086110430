export const add = (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3155_23139)'>
      <rect
        x='2'
        y='2'
        width='32'
        height='32'
        rx='16'
        fill='white'
        fillOpacity='0.01'
      />
      <rect x='2' y='2' width='32' height='32' rx='16' fill='#BDBDBD' />
    </g>
    <rect
      x='1'
      y='1'
      width='34'
      height='34'
      rx='17'
      stroke='white'
      strokeWidth='2'
    />
    <g clipPath='url(#clip1_3155_23139)'>
      <path d='M25 19H19V25H17V19H11V17H17V11H19V17H25V19Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_3155_23139'>
        <rect x='2' y='2' width='32' height='32' rx='16' fill='white' />
      </clipPath>
      <clipPath id='clip1_3155_23139'>
        <rect width='24' height='24' fill='white' transform='translate(6 6)' />
      </clipPath>
    </defs>
  </svg>
);
