import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ListItem } from './ListItem/ListItem';
import { NO_ITEMS_TITLE } from '../../../utils/const-variable';
import { ListColumns } from './ListColumns';
import './List.css';

export const List = (props) => {
  const {
    rootClassName,
    items,
    columnsToDisplay,
    sortColumnName,
    sortDirection,
    config,
    standardCursor,
    readMoreKey,
    readMoreIdArr,
    renderRow,
  } = props;

  const setSortSettings = (column, direction) => () => {
    const { setSortSettings } = props;
    setSortSettings && setSortSettings(column, direction);
  };

  return (
    <div
      className={classNames(rootClassName, {
        list__is_fetching: props.isFetching,
      })}
    >
      <div>
        {!(items && items.length) ? (
          <div className='list__no-item--border'>{NO_ITEMS_TITLE}</div>
        ) : null}
      </div>

      <div>
        {items && !!items.length && (
          <>
            <div className={classNames('c-list__header table_header')}>
              <ListColumns
                columnsToDisplay={columnsToDisplay}
                setSortSettings={setSortSettings}
                sortColumnName={sortColumnName}
                sortDirection={sortDirection}
                config={config}
              />
            </div>
            {items.map((item, i) => (
              <ListItem
                key={i}
                item={item}
                columnsToDisplay={columnsToDisplay}
                config={config}
                standardCursor={standardCursor}
                readMoreKey={readMoreKey}
                readMoreIdArr={readMoreIdArr}
                renderRow={renderRow}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  items: PropTypes.array,
  columnsToDisplay: PropTypes.array,
  sortColumnName: PropTypes.string,
  sortDirection: PropTypes.string,
  setSortSettings: PropTypes.func,
  config: PropTypes.object,
  isFetching: PropTypes.bool,
  renderRow: PropTypes.func,
};
