import { DefaultAvatar } from '../../../../common/list/cells/CellAvatar';

interface AvatarProps {
  item: { name: string; avatar_url: null | string };
}

interface IncomeOption {
  name: string;
  id: number;
  avatar_url: null | string;
}

interface OutcomeOption {
  label: string;
  value: number;
  avatar_url: null | string;
}

export const Avatar = ({ item }: AvatarProps) => {
  if (item.avatar_url) {
    return (
      <img
        src={item.avatar_url}
        alt={item.name}
        className='profile_block__members_avatar'
      />
    );
  }
  return (
    <DefaultAvatar
      item={item}
      className='profile_block__members_default_avatar'
    />
  );
};

export const getOptions = (
  options: Array<IncomeOption>
): Array<OutcomeOption> =>
  options.map((item) => ({
    label: item.name,
    value: item.id,
    avatar_url: item.avatar_url,
  }));
