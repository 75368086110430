import {
  DIRECTOR_ZONE,
  GLOBAL_SCOPE,
  HR_ZONE,
  MANAGER_SCOPE,
  OFFICE_SCOPE,
  ORG_ZONE,
  RECRUITER_ZONE,
  STAFF_SCOPE,
  SUPERVISOR_SCOPE,
  SUPERVISOR_ZONE,
} from '../const-variable';
import { ScopeType, ZoneType } from './types';

export const hasAnyScope = (scope: ScopeType) =>
  scope.includes(STAFF_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(GLOBAL_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const hasAnyZone = (zone: ZoneType) =>
  zone.includes(ORG_ZONE) ||
  zone.includes(RECRUITER_ZONE) ||
  zone.includes(SUPERVISOR_ZONE) ||
  zone.includes(HR_ZONE) ||
  zone.includes(DIRECTOR_ZONE);

// route access
export const hasAccessToCV = (scope: ScopeType) =>
  scope.includes(STAFF_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(GLOBAL_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const canViewSkills = (scope: ScopeType, isOwnProfile: boolean) =>
  isOwnProfile ||
  scope.includes(STAFF_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(GLOBAL_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const hasAccessToOrg = (scope: ScopeType) => !!scope;

// employee CDP
export const canViewCDP = (scope: ScopeType): boolean => hasAnyScope(scope);

export const canEditCDP = (zone: ZoneType | ''): boolean =>
  zone.includes(SUPERVISOR_ZONE);

// employee cv
export const canEditCV = (zone: ZoneType) => zone.includes(RECRUITER_ZONE);

// employee org
export const canEditProbation = (zone: ZoneType) => zone.includes(ORG_ZONE);

export const canEditBenchTags = (zone: ZoneType) =>
  zone.includes(RECRUITER_ZONE);

// employee skills
export const canViewSelfAndManagerSkills = (scope: ScopeType) =>
  hasAnyScope(scope);

export const canViewMainTechs = (scope: ScopeType) => hasAnyScope(scope);

export const canViewInterviewer = (scope: ScopeType) => hasAnyScope(scope);

// employees list
export const canViewRiskColumn = (scope: ScopeType) =>
  scope.includes(OFFICE_SCOPE) || scope.includes(MANAGER_SCOPE);

export const canViewDCAndSubRadio = (scope: ScopeType) =>
  scope.includes(OFFICE_SCOPE);

export const canViewSubRadio = (scope: ScopeType) =>
  scope.includes(MANAGER_SCOPE);

export const canViewSupervisedRadio = (scope: ScopeType) =>
  scope.includes(SUPERVISOR_SCOPE);

// employee profile
export const canApproveNewManager = (scope: ScopeType, zone: ZoneType) =>
  scope.includes(OFFICE_SCOPE) && zone.includes(DIRECTOR_ZONE);

export const canViewManagerForm = (scope: ScopeType) => canViewOrgInfo(scope);

export const canViewOrgInfo = (scope: ScopeType) =>
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(GLOBAL_SCOPE) ||
  scope.includes(STAFF_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const canEditOrgInfo = (zone: ZoneType) => zone.includes(ORG_ZONE);

export const canEditSupervisorInfo = (zone: ZoneType) =>
  zone.includes(SUPERVISOR_ZONE);

// todo: check if this is correct. why it checks only for a scope? why it doesn't check for a zone?
// should be any scope except Global
export const canEditEmployee = (scope: ScopeType) =>
  scope.includes(STAFF_SCOPE) ||
  scope.includes(OFFICE_SCOPE) ||
  scope.includes(MANAGER_SCOPE) ||
  scope.includes(SUPERVISOR_SCOPE);

export const canViewLocationHistory = (scope: ScopeType) =>
  scope.includes(OFFICE_SCOPE);
