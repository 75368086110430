import { createAction, createSlice } from '@reduxjs/toolkit';
import { deleteAuthData } from '../../../store/slices/auth/authSlice';
import { apiSlice } from '../api/apiSlice';
import { loadState } from '../../../store/localStorage';
import { quickFilters } from './utils/quickFilters';

const persistedState = loadState()?.employeesReducer;
const storedQuickFilters = persistedState?.quickFilters;
const employeePage = persistedState?.employeePage;

const initialState = {
  count: 0,
  columnsToDisplay: [
    {
      Name: 'Name',
      className: 'biggest-col grouped-col',
      Id: 'grouped_fields',
      sortId: 'name',
      fields: [
        {
          Id: 'avatar_url',
          deleteArrow: 'delete-arrow',
          className: 'avatar-col',
          necessary: true,
        },
        {
          Id: 'name',
          className: 'medium-col',
          necessary: true,
        },
        {
          Id: 'username',
          className: 'xs-col email-col',
          isSelected: true,
          necessary: true,
          deleteArrow: 'delete-arrow',
        },
      ],
    },
    {
      Name: 'Status',
      Id: 'employee_status',
      className: 'l-col',
      isSelected: true,
      deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Main Tech',
      Id: 'employee_main_tech',
      className: 'custom-col',
      isSelected: true,
      deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Position',
      Id: 'employee_position',
      isObject: true,
      className: 'biggest-col',
      isSelected: true,
      spareId: 'title',
      deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Seniority',
      Id: 'employee_seniority',
      isObject: true,
      className: 'xs-col',
      isSelected: true,
      deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Manager',
      Id: 'admin_manager',
      isObject: true,
      className: 'biggest-col',
      deleteArrow: 'delete-arrow',
      ExportName: 'manager',
      isSelected: true,
    },
    {
      Name: 'Delivery Center',
      Id: 'office',
      isBool: true,
      className: 'medium-col',
      isSelected: true,
      isObject: true,
      sortId: 'office__name',
    },
    {
      Name: 'Risk',
      Id: 'risk_level',
      className: 'xs-col',
      isSelected: false,
      hasPermission: true,
      deleteArrow: 'delete-arrow',
    },
    {
      Name: 'Relation',
      Id: 'grouped_relation',
      className: 'grouped-col',
      hasPermission: true,
      deleteArrow: 'delete-arrow',
      fields: [
        { Id: 'mentor_relation' },
        { Id: 'team_lead_relation' },
        { Id: 'project_manager_relation' },
      ],
    },
  ],
  quickFilters: storedQuickFilters || quickFilters,
  searchSettings: {
    // todo: destructure searchSettings object
    searchValue: '',
    filters: {},
    sortSettings: {
      column: 'name', // sortField: 'name',
      sortDirection: 'Up', // sortDirection: 'asc',
    },
  },
  employeePage: employeePage || 'all',
  scope: '',
  zone: '',
  skills: [],
  skillFilter: {
    beginner: [],
    competent: [],
    expert: [],
    main_tech: [],
    skill_interviewers: [],
  },
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    changeColumnsToDisplay: (state, action) => {
      state.columnsToDisplay = action.payload;
    },
    setQuickFilters: (state, action) => {
      state.quickFilters = action.payload;
    },
    clearQuickFilters: (state) => {
      state.quickFilters = quickFilters;
    },
    setSearchValue: (state, action) => {
      state.searchSettings.searchValue = action.payload;
    },
    resetSearchValue: (state) => {
      state.searchSettings.searchValue =
        initialState.searchSettings.searchValue;
    },
    setFilters: (state, action) => {
      state.searchSettings.filters = action.payload;
    },
    resetFilters: (state) => {
      // todo: check how it can be done better
      // this is the same as initialState.searchSettings.filters
      // it triggers useEffect in EmployeesPage component to load employees
      // because it is a new object
      state.searchSettings.filters = {};
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
    resetSkills: (state) => {
      state.skills = initialState.skills;
    },
    setSortSettings: (state, action) => {
      state.searchSettings.sortSettings = action.payload;
    },
    resetSortSettings: (state) => {
      state.searchSettings.sortSettings =
        initialState.searchSettings.sortSettings;
    },
    setEmployeePage: (state, action) => {
      state.employeePage = action.payload;
    },
    setScopeZone: (state, action) => {
      state.scope = action.payload.scope;
      state.zone = action.payload.zone;
    },
    resetScopeZone: (state) => {
      state.scope = '';
      state.zone = '';
    },
    setSkillFilter: (state, action) => {
      state.skillFilter = action.payload;
    },
    resetSkillFilter: (state) => {
      state.skillFilter = initialState.skillFilter;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteAuthData, () => {
        return {
          ...initialState,
          quickFilters: [...quickFilters],
          employeePage: 'all',
        };
      })
      .addMatcher(
        apiSlice.endpoints.getEmployee.matchFulfilled,
        (state, { payload }) => {
          state.scope = payload.scope;
          state.zone = payload.zone;
        }
      );
  },
});

export const {
  changeColumnsToDisplay,
  setQuickFilters,
  clearQuickFilters,
  setEmployeePage,
  setScopeZone,
  resetScopeZone,
  setSearchValue,
  setFilters,
  setSkills,
  setSortSettings,
  resetSearchValue,
  resetFilters,
  resetSkills,
  resetSortSettings,
  setSkillFilter,
  resetSkillFilter,
} = employeesSlice.actions;

export default employeesSlice.reducer;

export const selectEmployeesReducer = (store) => store.employeesReducer;
export const selectEmployeeScope = (store) => store.employeesReducer.scope;
export const selectEmployeeZone = (store) => store.employeesReducer.zone;
export const selectSkills = (store) => store.employeesReducer.skills;
export const selectFilters = (store) =>
  store.employeesReducer.searchSettings.filters;
export const selectSkillFilter = (store) => store.employeesReducer.skillFilter;

export const loadEmployees = createAction(
  'loadEmployees',
  ({ offSet = 0 }) => ({
    payload: {
      offSet,
    },
  })
);
