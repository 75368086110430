import { Ref, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  useEditEmployeeCDPMutation,
  useGetCDPQuery,
} from '../../../api/apiSlice';
import ButtonGroup from '../../../../common/buttonGroup';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import { DetailsCreate, ICDPFormValues } from '../Create/DetailsCreate';
import { ICDPFileResponse, ICDPRequest } from '../../../api/apiSlice.types';
import { onCancelHandler } from '../utils';
import { FileUpload } from '../FileUpload';
import { GoalSection } from '../Goals/GoalSection';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import { closeDialog } from '../../../../../store/slices/modalDialog/modalDialogSlice';

export const EmployeeCDPEdit = () => {
  const { id, cdpId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formRef: Ref<any> = useRef(null);
  const fileUploadRef: Ref<any> = useRef(null);

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${id}/cdps/${cdpId}`,
          text: 'CDP',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
      dispatch(closeDialog(ConfirmationDialog.NAME));
    };
  }, []);

  const { data } = useGetCDPQuery(
    { cdpId: Number(cdpId) },
    {
      // todo: seems unnecessary. Check if it can be removed
      selectFromResult: ({ data }): { data: ICDPFormValues | {} } => {
        return {
          data: data
            ? {
                employee_id: data?.employee?.id || '',
                mentor_id: data?.mentor?.id || '',
                notes: data?.notes || '',
                position: data?.position || undefined,
                seniority_level: data?.seniority_level || undefined,
                short_summary: data?.short_summary || '',
                files: data?.files || [],
                cdp_goals: data?.cdp_goals || [],
              }
            : {},
        };
      },
    }
  );

  const [editData] = useState(data);

  const [editEmployeeCDP] = useEditEmployeeCDPMutation();
  const onSubmit = () => formRef?.current?.handleSubmit();

  const onSubmitForm = async (
    data: ICDPRequest & { files?: ICDPFileResponse[] }
  ) => {
    if (Object.keys(data).length) {
      const files: ICDPFileResponse[] =
        await fileUploadRef?.current?.getFiles();
      data.files_ids = files?.map((file) => file?.id);
      // @ts-ignore
      delete data?.files;

      const CDP = await editEmployeeCDP({
        data,
        cdpId: Number(cdpId),
      }).unwrap();
      navigate(`/employees/${id}/cdps/${CDP.id}`);
    }
  };

  return (
    <Box overflow='auto' pb={3}>
      <Grid container pl={4.5} pr={3} pt={1}>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Typography variant='h6' gutterBottom>
              CDP Edit Details
            </Typography>
            <ButtonGroup
              buttonList={[
                {
                  sx: { color: '#797979' },
                  text: 'Cancel',
                  onClick: () =>
                    onCancelHandler({
                      path: `/employees/${id}/cdps/${cdpId}`,
                      dispatch,
                      navigate,
                      dialogHeader: 'Are you sure you want to cancel CDP edit?',
                    }),
                },
                {
                  variant: 'contained',
                  text: 'Submit',
                  onClick: onSubmit,
                },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container pl={4.5} pr={3}>
        <Grid item xs={12}>
          <DetailsCreate
            onSubmit={onSubmitForm}
            ref={formRef}
            initialValues={editData}
          />
          <Box>
            <Typography color='#828282' fontSize={12} fontWeight='bold'>
              Attachments
            </Typography>
            <FileUpload
              ref={fileUploadRef}
              // @ts-ignore
              initialFiles={data?.files}
              isEdit={true}
            />
            <Box>
              <Divider sx={{ my: 2 }} />
              <GoalSection
                // @ts-ignore
                goals={data?.cdp_goals}
                // @ts-ignore
                mentorId={data?.mentor_id}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
