import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import './confirmation-dialog.css';

const DIALOG_NAME = 'confirmationDialog';

export const ConfirmationDialog = ({
  closeButtonTitle = 'Cancel',
  confirmButtonTitle = 'Confirm',
  dialogHeader = 'Confirm',
  dialogTitle,
  confirmDialog,
  closeDialog,
  isCloseButtonVisible = true,
}) => {
  const handleOnClose = () => closeDialog(DIALOG_NAME);

  return (
    <Dialog open onClose={handleOnClose} className='dialog-width'>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span className='show-dialog-title'>{dialogHeader}</span>
        <IconButton onClick={handleOnClose} style={{ padding: 0 }}>
          <CloseIcon color='action' />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {dialogTitle && (
          <DialogContentText
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <HelpIcon color='disabled' style={{ fontSize: 48 }} />
            <span className='show-dialog-content'>{dialogTitle}</span>
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions style={{ padding: '0px 24px 16px 24px' }}>
        {isCloseButtonVisible && (
          <Button
            variant='text'
            onClick={handleOnClose}
            sx={{ color: '#797979' }}
          >
            {closeButtonTitle}
          </Button>
        )}
        {confirmDialog && (
          <Button
            variant='contained'
            onClick={() => confirmDialog(DIALOG_NAME)}
          >
            {confirmButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.NAME = DIALOG_NAME;

ConfirmationDialog.propTypes = {
  dialogHeader: PropTypes.string,
  dialogTitle: PropTypes.string,
  closeButtonTitle: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  closeDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  isCloseButtonVisible: PropTypes.bool,
};
