import { LoginTextField } from '../common/form-items/input/login-text-field';
import { Button } from '@mui/material';
import styles from './LoginForm.module.css';
import { login, selectLoginError } from './slice/loginSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const error = useSelector(selectLoginError);
  const dispatch = useDispatch();

  const handleOnSubmit = (event) => {
    event.preventDefault();
    username && password && dispatch(login({ username, password }));
  };

  return (
    <form className={styles.form} onSubmit={handleOnSubmit}>
      <h1>Sign In</h1>
      <h2>to Akvelon Employee Profile</h2>
      <h3>Enter your details below.</h3>
      <LoginTextField
        name='username'
        placeholder='Username'
        type='text'
        value={username}
        inputClassName={styles.input}
        fullWidth
        onChange={setUsername}
        clearClassName={styles.clearInput}
      />
      <LoginTextField
        name='password'
        placeholder='Password'
        type='password'
        value={password}
        inputClassName={styles.input}
        fullWidth
        onChange={setPassword}
        clearClassName={styles.clearInput}
      />
      <div className={styles.error}>
        {error && <span>Username or Password is incorrect</span>}
      </div>
      <Button fullWidth variant='outlined' type={'submit'}>
        Login
      </Button>
    </form>
  );
};
