import PropTypes from 'prop-types';
import { withCellWrapper } from '../../HOC/withCellWrapper';
import { Tooltip } from '@mui/material';

const Cell = ({ item }) => {
  const data = item.main_technologies
    .map((stack) => stack.name)
    .sort((a, b) => {
      return a.length - b.length;
    })
    .join(', ');

  return (
    <Tooltip title={data} placement='bottom'>
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {data}
      </span>
    </Tooltip>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const CellMainTech = withCellWrapper(Cell);
