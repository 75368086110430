import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatDate } from '../../../../../utils/formatDate';
import { IEmployeeLocationHistory } from '../../../api/apiSlice.types';

interface ILocationHistoryProps {
  locationHistory: IEmployeeLocationHistory[] | undefined;
}

export const LocationHistory = ({ locationHistory }: ILocationHistoryProps) => {
  return (
    <Tooltip
      title={
        <div style={{ width: '200px' }}>
          <Typography color='inherit' textAlign={'center'}>
            Location history
          </Typography>
          <div>
            <List dense>
              {locationHistory?.map((location: any, index: any) => {
                return (
                  <ListItem
                    key={`${location.location}${index}`}
                    secondaryAction={formatDate(location.history_date)}
                  >
                    <ListItemText primary={location.location} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      }
    >
      <InfoOutlinedIcon fontSize={'small'} color='disabled' />
    </Tooltip>
  );
};
