import {
  finishAllRequests,
  startRequest,
} from '../../../store/slices/loader/loaderSlice';
import { callGetApi } from '../../../store/commonThunks';

export const getAllSkills = () => async (dispatch) => {
  dispatch(startRequest());
  const thunk = await dispatch(
    callGetApi({
      url: '/skills/all/',
    })
  );
  dispatch(finishAllRequests());
  return thunk.data;
};
