import { FC } from 'react';
import { Grid, TextField as MaterialTextField } from '@mui/material';
import { ChecklistItemInterface } from '../../../EmployeesPage/api/apiSlice.types';

interface Props {
  disabled?: boolean;
  onChange: (data: { text_answer: string }) => void;
  item: ChecklistItemInterface;
}

export const TextField: FC<Props> = ({ disabled, item, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange({ text_answer: event.target.value });

  return (
    <Grid item xs>
      <MaterialTextField
        disabled={disabled}
        value={item.text_answer}
        multiline
        onChange={handleChange}
        fullWidth
        className='step__form--event-big-block'
      />
    </Grid>
  );
};
