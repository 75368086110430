export const sidebarVacation = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24 12L21 12M3 12L0 12M12 3L12 0M12 24L12 21M20.485 20.485L18.364
    18.364M5.636 5.636L3.515 3.515M20.485 3.515L18.364 5.636M5.636
    18.364L3.515 20.485M12 6A6 6 0 1 0 12 18 6 6 0 1 0 12 6z'
      fill='#ffffff'
      stroke='#ffffff'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
  </svg>
);
