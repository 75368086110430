import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const CellProjectsList = ({ column, item }) => {
  const activeProjects = item[column.Id].filter(
    (item) => item?.status === 'Active'
  );
  const renderValue = activeProjects.map((item) => item.name).join(', ');
  return (
    <div
      className={classNames(
        'c-list__item-column',
        { array_column: renderValue.length >= 20 },
        column.className ? column.className : 'small-col',
        item.employee_status === 'dismissed' && 'dismissed'
      )}
      title={renderValue}
    >
      <span className='col-span'>{renderValue ? renderValue : '———'}</span>
    </div>
  );
};

CellProjectsList.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
