import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RiskReport } from './RiskReport/RiskReport';
import { LocationReport } from './LocationReport/LocationReport';
import { AttritionReport } from './AttritionReport/AttritionReport';
import RouteTabs from '../common/tabs/tabs';
import { CDPReport } from './CDPReport/CDPReport';

export default function ReportTabs() {
  return (
    <Box className='page-cont' px={3} sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <RouteTabs
              className='profile-block__tabs'
              linkList={[
                {
                  name: 'Risk report',
                  access: true,
                  link: `/report/risk`,
                },
                {
                  name: 'Location report',
                  access: true,
                  link: `/report/location`,
                },
                {
                  name: 'Attrition report',
                  access: true,
                  link: `/report/attrition`,
                },
                {
                  name: 'CDP report',
                  access: true,
                  link: `/report/cdp`,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
      <Routes>
        <Route path='/' element={<Navigate to={'/report/risk'} />} />
        <Route path='/risk' element={<RiskReport />} />
        <Route path='/location' element={<LocationReport />} />
        <Route path='/attrition' element={<AttritionReport />} />
        <Route path='/cdp' element={<CDPReport />} />
      </Routes>
    </Box>
  );
}
