import { useDispatch } from 'react-redux';
import { DismissModal } from './DismissModal';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { useActivateEmployeeMutation } from '../../../api/apiSlice';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { FC, useEffect } from 'react';

interface Props {
  isDismissed: boolean;
}

export const Actions: FC<Props> = ({ isDismissed }) => {
  const dispatch = useDispatch();

  const [activateEmployee] = useActivateEmployeeMutation();

  const { id: userIdString } = useParams();

  const userId = Number(userIdString);

  useEffect(() => {
    return () => {
      dispatch(closeDialog(DismissModal.NAME));
    };
  }, []);

  const openDismissModal = () => {
    // @ts-ignore
    dispatch(showDialog(DismissModal, { userId }));
  };

  const activateEmployeeClick = async () => {
    await activateEmployee({ userId: userId }).unwrap();
  };

  const actions = () => {
    if (isDismissed) {
      return (
        <Button variant='outlined' onClick={activateEmployeeClick}>
          Activate Employee
        </Button>
      );
    }

    return (
      <Button variant='outlined' onClick={openDismissModal}>
        Dismiss Employee
      </Button>
    );
  };

  return <div>{actions()}</div>;
};
