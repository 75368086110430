import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Chip, IconButton, Popover, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ITag, ITagsState } from '../types';

export interface ITagsListProps {
  tags: ITag[];
  employeeId: number;
  setTagsToEdit: (obj: ITagsState) => void;
  setIsOpenDialog: (value: boolean) => void;
  canEditTags: boolean;
}

function useDimensions(targetRef: any, tags: ITag[]) {
  const getDimensions = () => {
    let childWidth: number = 0;
    let childCount: number = 0;
    const wrapperWidth = targetRef.current ? targetRef.current.offsetWidth : 0;
    const plusWidth =
      targetRef.current && targetRef.current.children[1]
        ? targetRef.current.children[1].offsetWidth
        : 0;
    if (targetRef?.current?.firstChild?.childNodes?.length > 0) {
      for (let el of targetRef?.current?.firstChild?.childNodes) {
        if (
          wrapperWidth > 0 &&
          childWidth + el.offsetWidth < wrapperWidth - plusWidth
        ) {
          childCount++;
          childWidth += el.offsetWidth + 8;
        } else {
          break;
        }
      }
    }
    return {
      wrapperWidth,
      childWidth,
      childCount,
    };
  };

  const [dimensions, setDimensions] = useState(getDimensions);
  const handleResize = () => {
    setDimensions(getDimensions());
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tags]);
  useLayoutEffect(() => {
    handleResize();
  }, [tags]);
  return dimensions;
}

const Tag = ({
  employeeId,
  tags,
  setTagsToEdit,
  setIsOpenDialog,
  canEditTags,
}: ITagsListProps) => {
  const targetRef: any = useRef();
  const size = useDimensions(targetRef, tags);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Stack
        ref={targetRef}
        direction='row'
        sx={{ width: `100%`, overflow: 'hidden' }}
      >
        <Stack
          direction='row'
          spacing={1}
          sx={{
            width: size.childWidth ? `${size.childWidth}px` : '100%',
            overflow: 'hidden',
          }}
        >
          {tags.map((tag) => (
            <Chip key={tag.id} label={tag.name} />
          ))}
        </Stack>
        {tags.length > size.childCount && (
          <>
            <Chip
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{ color: '#9B9B9B' }}
              label={`+${tags.length - size.childCount}`}
            />
            <Popover
              id='mouse-over-popover'
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Stack direction='row' sx={{ padding: '8px' }} spacing={1}>
                {tags.map(
                  (tag, idx) =>
                    idx + 1 > size.childCount && (
                      <Chip key={tag.id} label={tag.name} />
                    )
                )}
              </Stack>
            </Popover>
          </>
        )}
      </Stack>
      {canEditTags && (
        <IconButton
          size='small'
          aria-label='edit'
          onClick={() => {
            setTagsToEdit({ id: employeeId, tags });
            setIsOpenDialog(true);
          }}
          sx={{
            color: '#797979',
            marginLeft: '10px',
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </>
  );
};

export const TagsList = ({
  employeeId,
  tags,
  setTagsToEdit,
  setIsOpenDialog,
  canEditTags,
}: ITagsListProps) => {
  return tags.length > 0 ? (
    <Tag
      tags={tags}
      setTagsToEdit={setTagsToEdit}
      setIsOpenDialog={setIsOpenDialog}
      employeeId={employeeId}
      canEditTags={canEditTags}
    />
  ) : (
    <Button
      disabled={!canEditTags}
      sx={{ color: '#1976D2', textTransform: 'capitalize', fontWeight: 400 }}
      onClick={() => {
        setTagsToEdit({ id: employeeId, tags });
        setIsOpenDialog(true);
      }}
    >
      Add tags
    </Button>
  );
};
