import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Badge } from '../../../../common/badges/Badges';
import { FC } from 'react';
import { IEmployee } from '../../../api/apiSlice.types';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type MinimalEmployee = Pick<IEmployee, 'id' | 'name' | 'username'> & {
  avatar_url: string | null;
};

interface IUserAutocompleteFieldProps {
  options: MinimalEmployee[];
  onChange: (value: MinimalEmployee | null) => void;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  defaultValue?: MinimalEmployee | null;
}

export const UserAutocompleteField: FC<IUserAutocompleteFieldProps> = (
  props
) => {
  return (
    <Autocomplete
      loading={props.isLoading}
      disablePortal
      options={props.options}
      getOptionLabel={(option) => option.name}
      renderOption={(renderOptionProps, option) => {
        const { className, ...rest } = renderOptionProps;
        return (
          // @ts-ignore
          <Grid
            {...rest}
            sx={{ cursor: 'pointer' }}
            container
            direction='row'
            alignItems='center'
          >
            <Grid item>
              {option.avatar_url ? (
                <img
                  src={option.avatar_url}
                  alt='Avatar'
                  className='manager-option__avatar'
                />
              ) : (
                option.name && (
                  <div className='manager-option__avatar'>
                    <Badge item={option.name} />
                  </div>
                )
              )}
            </Grid>
            <Grid item>{option.name}</Grid>
          </Grid>
        );
      }}
      sx={Object.assign({}, { width: 300 }, props.sx)}
      renderInput={(params) => (
        <TextField {...params} size='small' label='HR Manager' />
      )}
      onChange={(e, value: MinimalEmployee | null) => props.onChange(value)}
      defaultValue={props?.defaultValue || undefined}
      disableClearable
    />
  );
};
