import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../store/slices/modalDialog/modalDialogSlice';
import { DocViewer } from './DocViewer';
import './DocViewer.css';

interface Props {
  cvId: number;
  fileURL: string;
}

export const ModalView: FC<Props> & { NAME: string } = ({ cvId, fileURL }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog('FilePreviewModal'));

  return (
    <Dialog open className='modal_viewer__wrapper' onClose={onClose}>
      <div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DocViewer cvId={cvId} fileURL={fileURL} />
    </Dialog>
  );
};

ModalView.NAME = 'FilePreviewModal';
