import { IEmployee } from '../../../api/apiSlice.types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';
import {
  useGetEnglishLevelsQuery,
  useGetPositionsQuery,
  useGetSenioritiesQuery,
  useGetEmployeeCertificatesQuery,
} from '../../../api/apiSlice';
import { EditEmployeeGeneralInfoForm } from './EditEmployeeGeneralInfoForm';
import { getFullLevelString } from './utils/getFullLevelString';

interface IProps {
  employee?: IEmployee;
}

export const EditEmployeeGeneralInfo = ({ employee }: IProps) => {
  const { id } = useParams();
  const userId = useSelector(selectEmployeeId);
  const isOwnProfile = userId === Number(id);

  const { data: senioritiesData = [], isLoading: isLoadingSenioritiesData } =
    useGetSenioritiesQuery();

  const { data: englishLevelsData, isLoading: isLoadingEnglishLevels } =
    useGetEnglishLevelsQuery();

  const { data: positionsData = [], isLoading: isLoadingPositionsData } =
    useGetPositionsQuery();

  const { data: certificates = [] } = useGetEmployeeCertificatesQuery(
    Number(id)
  );

  const positions = positionsData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const seniorities = senioritiesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const englishLevels = englishLevelsData?.map((item) => ({
    value: item.title.id,
    label: getFullLevelString(item),
  }));

  const showPreloader =
    isLoadingPositionsData &&
    isLoadingSenioritiesData &&
    isLoadingEnglishLevels;
  return showPreloader ? (
    <div>Loading...</div>
  ) : employee && englishLevels ? (
    <EditEmployeeGeneralInfoForm
      // todo: pass only required fields not the entire object
      employee={employee}
      showTitle={!isOwnProfile}
      positions={positions}
      seniorities={seniorities}
      englishLevels={englishLevels}
      certificates={certificates}
    />
  ) : null;
};
