import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Scrollbars } from 'react-custom-scrollbars-2';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

import { List } from '../../../common/list/List';
import { CellCvFile } from '../../../common/list/cells/CellCvFile';
import { CellReadMore } from '../../../common/list/cells/CellReadMore';
import { CellButtonList } from '../../../common/list/cells/CellButtonList';
import { CellButton } from '../../../common/list/cells/CellButton';
import { CellActions } from '../../../common/list/cells/CellActions';
import { CellCvStatus } from '../../../common/list/cells/CellCvStatus';

import {
  closeDialog,
  showDialog,
} from '../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../common/modal-dialogs/ConfirmationDialog';
import { selectEmployeeZone } from '../../slices/employeesSlice';
import {
  resetComponent,
  setComponent,
} from '../../../../store/slices/header/headerSlice';
import { columnsToDisplayCVList } from './config';
import CVModal from './cv-modal';
import BasicEmployeeInfo from '../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import './employeeCVsList.css';
import {
  useDeleteEmployeeCVMutation,
  useEditEmployeeCVMutation,
  useGetCvsQuery,
} from '../../api/apiSlice';
import { canEditCV } from '../../../../utils/permissions/checkAccess';

export const CvList = () => {
  const [readMoreIdArr, setReadMoreIdArr] = useState([]);

  const dispatch = useDispatch();
  const { id: employeeId } = useParams();

  // todo: use isLoading
  const { data: employeeCvs } = useGetCvsQuery(Number(employeeId));
  const [deleteEmployeeCV] = useDeleteEmployeeCVMutation();
  const [editEmployeeCV] = useEditEmployeeCVMutation();
  const zone = useSelector(selectEmployeeZone);

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${employeeId}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, [employeeId]);

  const canChangeEntity = canEditCV(zone);

  const showCVModal = () => {
    const cvModalProps = {
      canEditCV: canChangeEntity,
      data: {},
      isNewCV: true,
      employeeId,
    };
    dispatch(showDialog(CVModal, cvModalProps));
  };

  const handleEditCV = (id) => {
    if (!canChangeEntity) {
      return;
    }
    const currentCV = employeeCvs.find((item) => item.id === id);
    const cvModalProps = {
      canEditCV: canChangeEntity,
      data: currentCV,
      isNewCV: false,
      employeeId,
    };
    dispatch(showDialog(CVModal, cvModalProps));
  };

  const toggleDialog = (id) => {
    const dialogProps = {
      dialogHeader: 'Delete',
      dialogTitle: 'Are you sure to delete this CV?',
      confirmButtonTitle: 'Delete',
      confirmDialog: (dialogName) => deleteCV(dialogName, id),
      closeDialog: closeConfirmationDialog,
    };
    dispatch(showDialog(ConfirmationDialog, dialogProps));
  };

  const closeConfirmationDialog = (dialogName) => {
    dispatch(closeDialog(dialogName));
  };

  const deleteCV = async (dialogName, id) => {
    try {
      await deleteEmployeeCV(id).unwrap();
    } catch (error) {
      console.log(error);
    }
    closeConfirmationDialog(dialogName);
  };

  const toggleDialogChangeStatus = (cvId, statusParam) => {
    const dialogProps = {
      dialogHeader: 'To Archive',
      dialogTitle: 'Are you sure you want to archive this CV?',
      closeButtonTitle: 'Cancel',
      confirmButtonTitle: 'Submit',
      confirmDialog: (dialogName) =>
        changeCVStatus(dialogName, cvId, statusParam),
      closeDialog: closeConfirmationDialog,
    };
    dispatch(showDialog(ConfirmationDialog, dialogProps));
  };

  const changeCVStatus = async (dialogName, cvId, statusParam) => {
    const formData = new FormData();
    formData.append(statusParam, true);
    try {
      await editEmployeeCV({ formData, cvId }).unwrap();
    } catch (error) {
      console.log(error);
    }
    dialogName && closeConfirmationDialog(dialogName);
  };

  const openCVNotes = (id) => {
    if (readMoreIdArr.includes(id)) {
      setReadMoreIdArr(readMoreIdArr.filter((itemId) => itemId !== id));
      return;
    }
    setReadMoreIdArr([...readMoreIdArr, id]);
  };

  // todo: Change list to mui data grid
  return (
    <Scrollbars autoHide autoHideTimeout={300} hideTracksWhenNotNeeded>
      <BasicEmployeeInfo />
      <div className='page-container_for-scroll employeeCVsPage'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ mb: 2 }}
        >
          <Typography variant='h6'>Employee's CV</Typography>
          {canChangeEntity && (
            <Button
              variant={'contained'}
              disableElevation
              onClick={showCVModal}
            >
              + Add CV
            </Button>
          )}
        </Stack>
        <List
          rootClassName='relative-list'
          columnsToDisplay={columnsToDisplayCVList}
          items={employeeCvs}
          dontShowOptions
          readMoreKey='comment'
          readMoreIdArr={readMoreIdArr}
          config={{
            file_url: { cell: CellCvFile },
            comment: {
              cell: CellReadMore,
              onClick: openCVNotes,
              readMoreIdArr: readMoreIdArr,
            },
            operation: {
              cell: CellActions,
              isHaveRights: canChangeEntity,
              render: (item) => [
                <Tooltip
                  title='Approve'
                  placement='top'
                  key={item.id + Math.random()}
                >
                  <IconButton
                    onClick={() =>
                      changeCVStatus(undefined, item.id, 'is_reviewed')
                    }
                  >
                    <TaskOutlinedIcon />
                  </IconButton>
                </Tooltip>,
                <Tooltip
                  title='Archive'
                  placement='top'
                  key={item.id + Math.random()}
                >
                  <IconButton
                    onClick={() =>
                      toggleDialogChangeStatus(item.id, 'is_archived')
                    }
                  >
                    <ArchiveOutlinedIcon />
                  </IconButton>
                </Tooltip>,
              ],
            },
            action: {
              cell: CellButtonList,
              isHaveRights: canChangeEntity,
              render: (item) => [
                <CellButton
                  onClick={() => handleEditCV(item.item.id)}
                  title='Edit'
                  item={item}
                  icon='pencil'
                  key={item.item.id + Math.random()}
                />,
                <CellButton
                  onClick={() => toggleDialog(item.item.id)}
                  title='Remove CV'
                  item={item}
                  icon='trash'
                  key={item.item.id + Math.random()}
                />,
              ],
            },
            cv_status: {
              cell: CellCvStatus,
            },
          }}
        />
      </div>
    </Scrollbars>
  );
};
