import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import FormDialog from '../../../common/modal-dialogs/FormDialog';
import { AutocompleteField } from '../../../common/formikFormControls/AutocompleteField';
import restructureObjects from '../../../../utils/restructureObjects';
import { getAllSkills } from '../../slices/employeeThunk';
import { getUnusedSkills } from './utils';

const validationSchema = Yup.object().shape({
  skills: Yup.array()
    .min(1, 'skills field must have at least 1 item')
    .of(Yup.object())
    .required(''),
});

export const AddSkillsModal = ({ onSubmit, initialSkills }) => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    loadAllSkills();
  }, []);

  const loadAllSkills = async () => {
    const skills = await dispatch(getAllSkills());
    const restructured = restructureObjects(skills);
    setOptions(getUnusedSkills(restructured, initialSkills));
  };

  const getInitCategories = (id) =>
    options.find((item) => item.id === id).categories;

  const prepareSkill = (skill) => ({
    manager_assessed_level: null,
    self_assessed_level: null,
    id: skill.id,
    skill: {
      ...skill,
      categories: getInitCategories(skill.id),
    },
    skill_category: skill.categories,
    isTouched: true,
    isNew: true,
  });

  const confirmCreateEventModal = async ({ skills }) =>
    onSubmit(skills.map(prepareSkill));

  return (
    <FormDialog
      name='addSkills'
      title='Adding Skills'
      validationSchema={validationSchema}
      onSubmit={confirmCreateEventModal}
      isNeededToCloseDialog={false}
      className='add_skill_modal'
    >
      <AutocompleteField
        name='skills'
        label='Skills'
        xs={12}
        isRequired
        isMulti
        options={options}
        groupedKey='categories'
        preventCustomTag
      />
    </FormDialog>
  );
};

AddSkillsModal.NAME = 'addSkills';
AddSkillsModal.propTypes = {
  onSubmit: PropTypes.func,
};
