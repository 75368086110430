export const otherContacts = (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 1.5H11.25V0H9.75V1.5H3.75V0H2.25V1.5H1.5C0.6675 1.5 0 2.175 0 3V13.5C0 14.325 0.6675 15 1.5 15H12C12.825 15 13.5 14.325 13.5 13.5V3C13.5 2.175 12.825 1.5 12 1.5ZM6.75 3.75C7.995 3.75 9 4.755 9 6C9 7.245 7.995 8.25 6.75 8.25C5.505 8.25 4.5 7.245 4.5 6C4.5 4.755 5.505 3.75 6.75 3.75ZM11.25 12.75H2.25V12C2.25 10.5 5.25 9.675 6.75 9.675C8.25 9.675 11.25 10.5 11.25 12V12.75Z'
      fill='#EF7530'
    />
  </svg>
);
