import { useDispatch } from 'react-redux';
import { deleteAuthData } from './store/slices/auth/authSlice';
import history from './utils/history';
import './styles/error-page.css';

export const ErrorPage = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    // do extra cleaning of storage
    dispatch(deleteAuthData());
    localStorage.clear();
    history.go('/login');
  };

  return (
    <div className='error_page__wrapper'>
      <h1>Oops!</h1>
      <p>Looks like something went wrong and an error has occured.</p>
      <button className='button__main' onClick={onClick}>
        Back to the safety
      </button>
    </div>
  );
};
