import classNames from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Grid } from '@mui/material';
import {
  useEditEmployeeOrgInfoMutation,
  useGetEmployeeOrgInfoQuery,
  useGetProbationStatusesQuery,
} from '../../../api/apiSlice';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import ProlongModal from './ProlongModal';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import { canEditProbation } from '../../../../../utils/permissions/checkAccess';
import { selectEmployeeZone } from '../../../slices/employeesSlice';
import { ZoneType } from '../../../../../utils/permissions/types';

const FINISHED_STATUS = 'finished';
const INPROGRESS_STATUS = 'in_progress';
const FAILED_STATUS = 'failed';

const ProbationButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => (
  <Button variant='outlined' onClick={onClick}>
    {title}
  </Button>
);

export const ProbationEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data: probationData } = useGetEmployeeOrgInfoQuery(Number(id));
  const { data: statuses } = useGetProbationStatusesQuery();

  const [editOrgInfo] = useEditEmployeeOrgInfoMutation();

  const zone: ZoneType = useSelector(selectEmployeeZone);

  useEffect(() => {
    return () => {
      dispatch(closeDialog(ConfirmationDialog.NAME));
      dispatch(closeDialog(ProlongModal.NAME));
    };
  }, []);

  const closeConfirmationDialog = (dialogName: string) => {
    dispatch(closeDialog(dialogName));
  };

  const finishProbation = () =>
    editOrgInfo({
      id: Number(id),
      data: { probation_status: FINISHED_STATUS },
    });

  const failProbation = () =>
    editOrgInfo({
      id: Number(id),
      data: { probation_status: FAILED_STATUS },
    });

  const onFinish = () => {
    const dialogProps = {
      dialogHeader: 'Finish',
      dialogTitle:
        'Are you sure you want to close probation period as Finished?',
      confirmButtonTitle: 'Yes',
      closeButtonTitle: 'No',
      confirmDialog: (dialogName: string) => {
        finishProbation();
        dispatch(closeDialog(dialogName));
      },
      closeDialog: closeConfirmationDialog,
    };
    // @ts-ignore
    dispatch(showDialog(ConfirmationDialog, dialogProps));
  };

  const onFail = () => {
    const dialogProps = {
      dialogHeader: 'Fail',
      dialogTitle: 'Are you sure you want to close probation period as Failed?',
      confirmButtonTitle: 'Yes',
      closeButtonTitle: 'No',
      confirmDialog: (dialogName: string) => {
        failProbation();
        dispatch(closeDialog(dialogName));
      },
      closeDialog: closeConfirmationDialog,
    };
    // @ts-ignore
    dispatch(showDialog(ConfirmationDialog, dialogProps));
  };

  const onProlong = () => {
    dispatch(
      // @ts-ignore
      showDialog(ProlongModal, {
        initialDate: probationData?.probation_due_date,
        initialComment: probationData?.probation_prolongation_reason,
        id,
      })
    );
  };

  const currentStatus =
    probationData?.probation_status &&
    statuses?.find((status) => {
      if (status.status[probationData?.probation_status]) {
        return status;
      }
    });

  const renderedStatus = currentStatus
    ? currentStatus.status[probationData?.probation_status]
    : '';
  const isInProgress = probationData?.probation_status === INPROGRESS_STATUS;

  return (
    <>
      <Grid container style={{ paddingTop: '18px' }} alignItems='center'>
        <Grid item>
          <Chip
            label={renderedStatus}
            className={classNames({
              finished_status:
                probationData?.probation_status === FINISHED_STATUS,
              in_progress_status:
                probationData?.probation_status === INPROGRESS_STATUS,
              failed_status: probationData?.probation_status === FAILED_STATUS,
            })}
          />
        </Grid>
        <Grid item>
          <span className='org_date'>
            {isInProgress ? 'Due date: ' : ''}
            {probationData?.probation_due_date}
          </span>
        </Grid>
      </Grid>

      {isInProgress && canEditProbation(zone) && (
        <Grid container style={{ paddingTop: '40px' }} spacing={1}>
          <Grid item>
            <ProbationButton title='Finish' onClick={onFinish} />
          </Grid>
          <Grid item>
            <ProbationButton title='Prolong' onClick={onProlong} />
          </Grid>
          <Grid item>
            <ProbationButton title='Fail' onClick={onFail} />
          </Grid>
        </Grid>
      )}

      {probationData?.probation_prolongation_reason && isInProgress && (
        <Grid container direction='column'>
          <Grid item style={{ paddingTop: '34px' }}>
            <span className='org_prolongation_title'>Prolongation reason</span>
          </Grid>
          <Grid item style={{ paddingTop: '4px' }}>
            <span className='org_prolongation_reason'>
              {probationData.probation_prolongation_reason}
            </span>
          </Grid>
        </Grid>
      )}
    </>
  );
};
