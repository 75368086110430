import { DateTime } from 'luxon';
import FormDialog from '../../../../common/modal-dialogs/FormDialog';
import {
  DatePickerField,
  getFormattedDate,
} from '../../../../common/formikFormControls/DatePickerField';
import { SelectField } from '../../../../common/formikFormControls/SelectField';
import Alert from '@mui/material/Alert';
import {
  useEditEmployeeDetailsMutation,
  useGetDismissReasonsQuery,
} from '../../../api/apiSlice';

interface IDismissEmployeeData {
  dismissed_date: string;
  dismissed_reason: string;
}

export const DismissModal = ({ userId }: { userId: number }) => {
  const [editEmployeeDetails] = useEditEmployeeDetailsMutation();
  const { data: reasons = [] } = useGetDismissReasonsQuery();

  const sendRequest = async ({
    dismissed_date,
    dismissed_reason,
  }: IDismissEmployeeData) => {
    const patchData = { dismissed_date, dismissed_reason };
    try {
      await editEmployeeDetails({
        employeeId: userId,
        data: patchData,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDialog = ({
    dismissed_date,
    dismissed_reason = '',
  }: IDismissEmployeeData) => {
    sendRequest({
      dismissed_date: getFormattedDate(dismissed_date),
      dismissed_reason,
    }).then((r) => {
      console.log(r);
    });
  };

  const validate = ({
    dismissed_date,
    dismissed_reason,
  }: {
    dismissed_date: DateTime;
    dismissed_reason: number;
  }) => {
    const errors: { dismissed_date?: string; dismissed_reason?: string } = {};

    if (!dismissed_date) {
      errors.dismissed_date = 'Required';
    }
    if (dismissed_date && !dismissed_date?.isValid) {
      errors.dismissed_date = 'Invalid date';
    }
    if (!dismissed_reason) {
      errors.dismissed_reason = 'Required';
    }

    return errors;
  };

  return (
    <FormDialog
      name='DismissModal'
      title='Dismiss Employee'
      validate={validate}
      validateOnBlur
      validateOnMount={false}
      onSubmit={confirmDialog}
      submitButtonContent='Dismiss'
      // @ts-ignore // todo: fix after FormDialog will be typed
      footer={
        <Alert severity={'error'} sx={{ my: '15px' }}>
          This action will make changes in Theodolite that cannot be reverted
          automatically
        </Alert>
      }
    >
      {/*@ts-ignore // todo: fix after DatePickerField will be typed*/}
      <DatePickerField name='dismissed_date' label='Date' isRequired xs={6} />
      {/*@ts-ignore // todo: fix after SelectField will be typed*/}
      <SelectField
        name='dismissed_reason'
        label='Dismissed Reason'
        options={reasons}
        getValue={(option) => option?.value}
        isRequired
        xs={12}
      />
    </FormDialog>
  );
};

DismissModal.NAME = 'DismissModal';
