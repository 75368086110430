import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './multi-value-suggestbox.css';
import { useEffect, useState } from 'react';

const MultiSuggestBox = (props) => {
  const {
    options,
    placeholder,
    onAdd,
    handleValue,
    suggestValue,
    handleChangeSuggestBox,
    handleInputChange,
    onBlur,
    label,
    isRequired,
    isMulti,
    isDisabled,
    autoFocus,
    className,
    name,
    isLoading,
    isClearable = true,
    defaultValue,
    components,
    isCreatable,
  } = props;

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [optionsState, setOptionsState] = useState(options);
  const [placeholderState, setPlaceholderState] = useState(placeholder);
  const [isAddRequestedFlag, setIsAddRequestedFlag] = useState(false);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    if (handleValue === null) {
      setValue(null);
    }
  }, [handleValue]);

  useEffect(() => {
    if (isAddRequestedFlag) {
      onAdd(value);
      setIsAddRequestedFlag(false);
    }
  }, [isAddRequestedFlag]);

  const handleChange = (value) => {
    if (suggestValue) {
      handleChangeSuggestBox && handleChangeSuggestBox(value);
      onAdd(value);
    } else {
      setValue(value);
      if (onAdd) {
        setIsAddRequestedFlag(true);
      }
    }
  };

  const handleInputChange1 = (inputValue) => {
    setInputValue(inputValue);
    handleInputChange && handleInputChange(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    const lastPosition = event.target.value.length;
    switch (event.key) {
      case 'Home':
        event.target.setSelectionRange(0, 0);
        break;
      case 'End':
        event.target.setSelectionRange(lastPosition, lastPosition);
        break;
      default:
        break;
    }
  };

  const onFocus = () => {
    setPlaceholderState('');
  };

  const onBlur1 = () => {
    onBlur && onBlur();
    setPlaceholderState(placeholder);
  };

  return (
    <div
      className={`multi-value-suggestbox__container ${
        className ? className : ''
      }`}
    >
      {label ? (
        <div className='multi-value-suggestbox__label'>
          <label className='form_field__label'>
            <span>{label}</span>
            {isRequired && <span className='redux-form__required'> *</span>}
          </label>
        </div>
      ) : null}

      <SelectComponent
        classNamePrefix={'multi-value-suggestbox'}
        inputValue={inputValue}
        isClearable={isClearable}
        isMulti={isMulti}
        onChange={handleChange} // Handle change events on the select
        onInputChange={handleInputChange1} // Handle change events on the input
        onKeyDown={handleKeyDown}
        options={optionsState}
        placeholder={placeholderState}
        value={suggestValue || handleValue || value}
        isDisabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur1}
        autoFocus={autoFocus}
        isLoading={isLoading}
        name={name}
        isSearchable={true}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 10000 }) }}
        menuPortalTarget={document.body}
        menuPosition={'absolute'}
        menuPlacement={'bottom'}
        closeMenuOnSelect={true}
        inputProps={{}}
        defaultValue={defaultValue}
        components={components}
      />
    </div>
  );
};

export default MultiSuggestBox;
