export const arrowLeftDetails = (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000155878 6L6.00016 1.23266e-07L7.41016 1.41Z'
      fill='#F2994A'
    />
  </svg>
);
