import BasicEmployeeInfo from '../../CommonComponents/BasicEmployeeInfo/BasicEmployeeInfo';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useGetEmployeeCDPListQuery } from '../../../api/apiSlice';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IEmployeeCDPListResponse } from '../../../api/apiSlice.types';
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import WarningIcon from '@mui/icons-material/Warning';
import { canEditCDP } from '../../../../../utils/permissions/checkAccess';
import {
  resetComponent,
  setComponent,
} from '../../../../../store/slices/header/headerSlice';
import { selectEmployeeId } from '../../../../../store/slices/auth/authSlice';

export const CDPList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const userId = useSelector(selectEmployeeId);

  const employeeId = Number(id);
  const isOwnProfile = employeeId === Number(userId);

  const { data: employeeCDPListResponse, isLoading: employeeCDPListIsLoading } =
    useGetEmployeeCDPListQuery({ employeeId });
  const employeeCDPList = employeeCDPListResponse?.results || [];
  const zone = employeeCDPListResponse?.zone || '';

  useEffect(() => {
    dispatch(
      setComponent({
        component: {
          pathname: `/employees/${employeeId}/`,
          text: 'Employee Details',
        },
        isLink: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, [employeeId]);

  const columns = useMemo<GridColumns<IEmployeeCDPListResponse>>(
    () => [
      {
        field: 'short_summary',
        headerName: 'Short Summary',
        width: 400,
        sortable: false,
        renderCell: (params) => {
          return (
            <Box sx={{ overflow: 'hidden' }}>
              <Link
                to={`/employees/${employeeId}/cdps/${params.row.id}`}
                style={{ textDecoration: 'none' }}
              >
                <Tooltip title={params.value}>
                  <Typography color='#1976D2' fontSize={14} noWrap>
                    {params.value}
                  </Typography>
                </Tooltip>
              </Link>
            </Box>
          );
        },
      },
      {
        field: 'mentor',
        headerName: 'Mentor',
        width: 200,
        sortable: false,
        renderCell: (params) => {
          return (
            <Link
              to={`/employees/${params.row.mentor?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography color='#1976D2' fontSize={14}>
                {params.row.mentor?.name}
              </Typography>
            </Link>
          );
        },
      },
      {
        field: 'modified_date',
        headerName: 'Last Updated',
        width: 150,
        sortable: false,
        valueFormatter: (params) => {
          return DateTime.fromISO(params.value).toISODate() || '';
        },
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        width: 150,
        sortable: false,
        renderCell: (params) => {
          const dueDate = DateTime.fromISO(params.value);
          const value = dueDate.toISODate() || '';
          const tomorrow = DateTime.fromISO(
            // @ts-ignore todo: fix it
            DateTime.now().plus({ days: 1 }).toISODate()
          );
          const isDueDateTomorrow =
            dueDate.toMillis() - tomorrow.toMillis() <= 0;
          return (
            <Stack direction='row' spacing={2}>
              <Typography fontSize={14}>{value}</Typography>
              {isDueDateTomorrow && params?.row?.status === 'In Progress' && (
                <Tooltip title='Due date expiration'>
                  <WarningIcon fontSize='small' color='warning' />
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
      {
        field: 'goals_achieved',
        headerName: 'Goals Achieved',
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          return `${params.row.closed_goals}/${params.row.total_goals}` || '';
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        sortable: false,
        renderCell: (params) => {
          let backgroundColor;
          switch (params.value) {
            case 'In Progress':
              backgroundColor = '#1CB18E';
              break;
            case 'Rejected':
              backgroundColor = '#9B9B9B';
              break;
            case 'Finished':
              backgroundColor = '#D1D1D1';
              break;
          }

          return (
            <Chip
              label={params.value}
              color='success'
              size='small'
              sx={{ backgroundColor: backgroundColor }}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <BasicEmployeeInfo
        buttonsTemplate={[
          canEditCDP(zone) && !isOwnProfile && (
            <Link
              to={`/employees/${employeeId}/cdps/create`}
              style={{ textDecoration: 'none' }}
            >
              <Button variant='contained' color='primary'>
                + Create
              </Button>
            </Link>
          ),
        ]}
      />
      <Box px={4}>
        <DataGrid
          rows={employeeCDPList}
          columns={columns}
          loading={employeeCDPListIsLoading}
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
          autoHeight
        />
      </Box>
    </div>
  );
};
