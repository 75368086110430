import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import CustomIcon from '../../../../common/icon';
import { DeliveryManager, Project } from '../../types/types';
import { EMPTY_VALUE, formatEmptyValue, getAssignmentType } from '../utils';
import './activeProject.css';

interface Props {
  project: Project;
  currentUserId: number;
}

const WithTooltip = ({
  children,
  isRenderTooltip,
  title,
  componentsProps,
}: {
  children: any;
  isRenderTooltip: boolean;
  title: string | Array<ReactNode>;
  componentsProps?: any;
}) => {
  if (!isRenderTooltip) {
    return children;
  }
  return (
    <Tooltip title={title} placement='right' componentsProps={componentsProps}>
      {children}
    </Tooltip>
  );
};

const getLink = (
  user: DeliveryManager,
  teamLeadIsCurrentUser?: boolean
): ReactNode => (
  <Link
    key={user.id}
    className='project_card__link'
    to={`/employees/${user.id}`}
  >
    {user.name}
    {teamLeadIsCurrentUser && <CustomIcon iconName='brilliant' />}
  </Link>
);

const geManagersLink = (deliveryManagers: Array<DeliveryManager>) =>
  deliveryManagers.map((manager) => getLink(manager));

const WrapTypography = ({
  fieldName,
  children,
}: {
  fieldName: string;
  children?: ReactNode;
}) => (
  <Typography sx={{ paddingBottom: '6px' }}>
    <Typography
      sx={{ paddingRight: '6px' }}
      color='text.secondary'
      component='span'
    >
      {fieldName}
    </Typography>
    {children}
  </Typography>
);

const getTeamLead = (project: Project, currentUserId: number) => {
  if (project?.team?.lead) {
    const isUserTeamLead = project.team.lead.id === currentUserId;
    return (
      <WithTooltip
        isRenderTooltip={isUserTeamLead}
        title='This employee is a Team Lead'
      >
        {getLink(
          {
            ...project.team.lead,
            username: project.team.lead.name,
          },
          isUserTeamLead
        )}
      </WithTooltip>
    );
  }
  return EMPTY_VALUE;
};

export const ProjectCard: React.FC<Props> = ({ project, currentUserId }) => {
  const managers = geManagersLink(project.project.delivery_managers);
  const otherManagers = managers.filter((manager, idx) => idx !== 0 && manager);
  const firstManager = managers[0];
  return (
    <Card sx={{ borderRadius: '4px' }}>
      <CardContent sx={{ padding: '18px 24px' }}>
        <Typography className='active_project__title' title={project.name}>
          {project.name}
        </Typography>
        <WrapTypography fieldName='Manager:'>
          {!!managers.length ? (
            <WithTooltip
              title={otherManagers}
              isRenderTooltip={!!otherManagers.length}
              componentsProps={{
                tooltip: { className: 'project_card__tooltip' },
              }}
            >
              <Typography component='span'>
                {firstManager}
                {!!otherManagers.length ? ' ...' : ''}
              </Typography>
            </WithTooltip>
          ) : (
            '---'
          )}
        </WrapTypography>
        <WrapTypography fieldName='Team:'>
          <Typography component='span'>
            {formatEmptyValue(project?.team?.name)}
          </Typography>
        </WrapTypography>
        <WrapTypography fieldName='Team Lead:'>
          <Typography component='span'>
            {getTeamLead(project, currentUserId)}
          </Typography>
        </WrapTypography>
        <WrapTypography fieldName='Employee Assignment:'>
          <Typography component='span'>
            {getAssignmentType(project?.assignment_type)}
          </Typography>
        </WrapTypography>
      </CardContent>
    </Card>
  );
};
