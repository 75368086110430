import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Select from 'react-select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { countries as countriesData } from '../../EmployeeProfile/ProfileInfo/countries';
import { useEditEmployeeDetailsMutation } from '../../../api/apiSlice';
import {
  EmployeeContact,
  ICertificateResponse,
  IEmployee,
  IEmployeePatchRequest,
} from '../../../api/apiSlice.types';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { selectStyles } from '../../../selectStyles';
import { useNavigate } from 'react-router-dom';
import { PromptOnReroute } from '../../../../common/Guards/usePrompt';
import { useEffect, useState } from 'react';
import {
  closeDialog,
  showDialog,
} from '../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../common/modal-dialogs/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { Certificates } from './Certificates/Certificates';

const linkedinRegExp = /^https?:\/\/(www|[a-z]{2}).linkedin.com\/(.*)/gi;

const countries = countriesData.map((country) => ({
  value: country.code,
  label: country.name,
}));

interface Props {
  employee: IEmployee;
  showTitle: boolean;
  positions: { value: number; label: string }[];
  seniorities: { value: number; label: string }[];
  englishLevels: { value: string; label: string }[];
  certificates: ICertificateResponse[];
}

interface IValues {
  position?: { value: number; label: string };
  seniority?: { value: number; label: string };
  country?: { value: string; label: string };
  city: string;
  dateOfBirth: DateTime | null;
  dateOfStart: DateTime | null;
  englishLevel?: { value: string; label: string } | null;
  contacts: EmployeeContact[];
  linkedin_url?: string | null;
  no_linkedin: boolean;
}

export const EditEmployeeGeneralInfoForm = ({
  employee,
  showTitle,
  positions,
  seniorities,
  englishLevels,
  certificates,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nextPageRoute = `/employees/${employee.id}`;

  const [editEmployeeDetails] = useEditEmployeeDetailsMutation();

  const [removedContactIds, setRemovedContactIds] = useState<number[]>([]);

  const [isFinished, setIsFinished] = useState<boolean>(false);

  useEffect(() => {
    if (isFinished) {
      navigate(nextPageRoute);
    }
  }, [isFinished]);

  const initialValues: IValues = {
    position: positions.find(
      (position) => position.value === employee.employee_position?.id
    ),
    seniority: seniorities.find(
      (seniority) => seniority.value === employee.employee_seniority?.id
    ),
    country: countries.find(
      (country) => country.label === employee.employee_physical_location.country
    ) || {
      value: employee.employee_physical_location.country,
      label: employee.employee_physical_location.country,
    },
    city: employee.employee_physical_location.city || '',
    dateOfBirth: employee.date_of_birth
      ? DateTime.fromISO(employee.date_of_birth)
      : null,
    dateOfStart: employee.year_start_in_profession
      ? DateTime.fromISO(String(employee.year_start_in_profession))
      : null,
    englishLevel: englishLevels.find(
      (level) => level.value === employee.english_level
    ),
    contacts: employee.employee_contacts.length
      ? employee.employee_contacts
      : [],
    linkedin_url: employee.linkedin_url || null,
    no_linkedin: employee.no_linkedin || false,
  };

  const validationSchema = Yup.object().shape({
    city: Yup.string().trim().min(2, 'Too Short').max(50, 'Too Long'),
    contacts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .min(2, 'Too Short')
          .max(50, 'Too Long')
          .required('Required'),
        contact: Yup.string()
          .trim()
          .min(2, 'Too Short')
          .max(50, 'Too Long')
          .required('Required'),
      })
    ),
    linkedin_url: Yup.string()
      .trim()
      .max(2000, 'Too Long')
      .matches(linkedinRegExp, 'Link must start with https://www.linkedin.com/')
      .nullable(),
  });

  const sendPatchRequest = async (patchData: IEmployeePatchRequest) => {
    try {
      await editEmployeeDetails({
        employeeId: employee.id,
        data: patchData,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelClick = (formik: FormikProps<IValues>) => {
    const config = {
      dialogHeader: 'Do you want to cancel?',
      dialogTitle: 'Changes you made will not be saved.',
      closeDialog: (name: string) => dispatch(closeDialog(name)),
      confirmDialog: (name: string) => {
        dispatch(closeDialog(name));
        formik.resetForm();
        setIsFinished(true);
      },
    };
    // @ts-ignore
    dispatch(showDialog(ConfirmationDialog, config));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const patchData: IEmployeePatchRequest = {
          employee_position_id: values.position?.value,
          employee_seniority_id: values.seniority?.value,
          employee_physical_location: {
            country: values.country?.label,
            city: values.city || null,
          },
          // @ts-ignore todo: fix it
          date_of_birth: values.dateOfBirth
            ? values.dateOfBirth.toISODate()
            : undefined,
          year_start_in_profession: values.dateOfStart
            ? values.dateOfStart.year
            : undefined,
          english_level: values.englishLevel?.value,
          employee_contacts: values.contacts.filter(
            (contact) => contact.id || (contact.name && contact.contact)
          ),
          linkedin_url: values.linkedin_url || null,
          no_linkedin: values.no_linkedin,
        };

        if (removedContactIds.length) {
          removedContactIds.forEach((contactId, index) => {
            patchData.employee_contacts?.push({
              id: removedContactIds[index],
              name: null,
              contact: null,
            });
          });
        }
        if (values.no_linkedin) {
          delete patchData.linkedin_url;
        }

        sendPatchRequest(patchData).then((r) => console.log(r));
        setIsFinished(true);
      }}
    >
      {(formik) => (
        <Form style={{ overflow: 'auto', height: '100%' }}>
          <Box px={4} py={3} width={720}>
            <Grid container direction={'column'} spacing={3}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography variant={'h6'}>General Information</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>Country</InputLabel>
                    <Field name={'country'}>
                      {({ field, form }: FieldProps) => (
                        <Select
                          options={countries}
                          value={field.value}
                          onChange={(newValue) => {
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }}
                          isDisabled={
                            employee.employee_physical_location
                              .theodolite_parsed
                          }
                          styles={selectStyles}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>City</InputLabel>
                    <Field name={'city'}>
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          size={'small'}
                          fullWidth
                          error={Boolean(
                            formik.errors.city && formik.touched.city
                          )}
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>Date of Birth</InputLabel>

                    <Field name={'dateOfBirth'}>
                      {({ field, form }: FieldProps) => (
                        <DatePicker
                          value={field.value}
                          onChange={(newValue) => {
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'YYYY/MM/DD',
                              }}
                              size={'small'}
                              fullWidth
                            />
                          )}
                          disableFuture
                          inputFormat='yyyy/MM/dd'
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>
                      Start in Profession
                    </InputLabel>

                    <Field name={'dateOfStart'}>
                      {({ field, form }: FieldProps) => (
                        <DatePicker
                          views={['year']}
                          value={field.value}
                          onChange={(newValue) => {
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'YYYY',
                              }}
                              size={'small'}
                              fullWidth
                            />
                          )}
                          disableFuture
                          inputFormat='yyyy'
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>

              {showTitle && (
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography variant={'h6'}>Position & Level</Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel sx={{ fontSize: 13 }}>Position</InputLabel>

                      <Field name={'position'}>
                        {({ field, form }: FieldProps) => (
                          <Select
                            options={positions}
                            value={field.value}
                            onChange={(newValue) => {
                              form.setFieldValue(field.name, newValue);
                              form.setFieldTouched(field.name, true);
                            }}
                            styles={selectStyles}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ fontSize: 13 }}>Level</InputLabel>

                      <Field name={'seniority'}>
                        {({ field, form }: FieldProps) => (
                          <Select
                            options={seniorities}
                            value={field.value}
                            onChange={(newValue) => {
                              form.setFieldValue(field.name, newValue);
                              form.setFieldTouched(field.name, true);
                            }}
                            styles={selectStyles}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item container direction={'column'} spacing={2}>
                <Grid item>
                  <Typography variant={'h6'}>English level</Typography>
                </Grid>
                <Grid item container>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>Level</InputLabel>
                    <Field name={'englishLevel'}>
                      {({ field, form }: FieldProps) => (
                        <Select
                          options={englishLevels}
                          value={field.value}
                          onChange={(newValue) => {
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }}
                          styles={selectStyles}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item>
                  <Typography variant={'h6'}>LinkedIn information</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel sx={{ fontSize: 13 }}>Link</InputLabel>
                    <Field name={'linkedin_url'}>
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          size={'small'}
                          value={field.value || ''}
                          fullWidth
                          error={Boolean(
                            formik.errors.linkedin_url &&
                              formik.touched.linkedin_url
                          )}
                          helperText={
                            formik.touched.linkedin_url &&
                            formik.errors.linkedin_url
                          }
                          disabled={formik.values.no_linkedin}
                          {...(formik.values.no_linkedin && {
                            placeholder: 'Disabled',
                          })}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid container item alignItems={'center'} xs={6}>
                    <Field name={'no_linkedin'}>
                      {({ field }: FieldProps) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox checked={field.value} />}
                          label={'No LinkedIn'}
                          componentsProps={{ typography: { fontSize: 14 } }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction={'column'} spacing={2}>
                <Grid item>
                  <Typography variant={'h6'}>Contact information</Typography>
                </Grid>
                <Grid item container>
                  <FieldArray name={'contacts'}>
                    {({ push, remove }) => (
                      <Grid item container>
                        {formik.values.contacts.map((contact, index) => (
                          <Grid item container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <InputLabel sx={{ fontSize: 13 }}>
                                Name
                              </InputLabel>
                              <Field name={`contacts[${index}].name`}>
                                {({ field, meta }: FieldProps) => {
                                  return (
                                    <TextField
                                      {...field}
                                      size={'small'}
                                      fullWidth
                                      placeholder='e.g. Skype, Telegram, relative number etc.'
                                      error={!!(meta.error && meta.touched)}
                                      helperText={meta.touched && meta.error}
                                    />
                                  );
                                }}
                              </Field>
                            </Grid>
                            <Grid item xs={6}>
                              <InputLabel sx={{ fontSize: 13 }}>
                                Content
                              </InputLabel>
                              <Field name={`contacts[${index}].contact`}>
                                {({ field, meta }: FieldProps) => (
                                  <TextField
                                    {...field}
                                    size={'small'}
                                    fullWidth
                                    placeholder='+49809048858, @houston'
                                    error={!!(meta.error && meta.touched)}
                                    helperText={meta.touched && meta.error}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={() => {
                                  const id = formik.values.contacts[index].id;
                                  if (id) {
                                    setRemovedContactIds((prev) => [
                                      ...prev,
                                      id,
                                    ]);
                                  }
                                  remove(index);
                                }}
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          onClick={() => {
                            const isThereUnfinished =
                              !!formik.values.contacts.filter(
                                (contact) => !contact.name || !contact.contact
                              ).length;
                            const maxAmountOfContacts = 3;
                            const isThereARoom =
                              formik.values.contacts.length <=
                              maxAmountOfContacts;
                            if (isThereUnfinished || !isThereARoom) {
                              return;
                            }
                            push({ name: '', contact: '' });
                          }}
                        >
                          + Add new contact
                        </Button>
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>

              <Certificates
                employeeId={employee.id}
                certificates={certificates}
              />

              <Grid item container spacing={2}>
                <Grid item>
                  <Button
                    variant={'outlined'}
                    disabled={formik.isSubmitting || !formik.dirty}
                    onClick={() => onCancelClick(formik)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={'contained'}
                    type='submit'
                    disabled={formik.isSubmitting || !formik.dirty}
                  >
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <PromptOnReroute
            message={'Changes you made will not be saved'}
            when={formik.dirty && !isFinished}
          />
        </Form>
      )}
    </Formik>
  );
};
