import PropTypes from 'prop-types';
import CustomIcon from '../../icon';
import { Button } from '@mui/material';
import { buttonStyles } from '../../../../styles';
import { useEffect, useState } from 'react';

export const SearchField = (props) => {
  const {
    searchValue,
    onSearchClick,
    handleChange,
    handleClear,
    searchPlaceholder,
    isWide,
  } = props;

  const [value, setValue] = useState(searchValue || '');

  useEffect(() => {
    if (searchValue !== value && searchValue === '') {
      clearSearch();
    }
  }, [searchValue]);

  const handleSearchClick = () => {
    onSearchClick && onSearchClick(value);
  };

  const handleSetSearchValue = (e) => {
    setValue(e.target.value);
    handleChange && handleChange(e.target.value);
  };

  const handleKeyUp = (e) => {
    const enterButtonKeyCode = 13;
    if (e.keyCode === enterButtonKeyCode) {
      onSearchClick && onSearchClick(value);
    }
  };

  const clearSearch = () => {
    setValue('');
    handleClear ? handleClear() : onSearchClick ? onSearchClick('') : null;
  };

  return (
    <div className='search-cont'>
      <input
        className={`search-field ${onSearchClick && 'with-btn'} ${
          isWide && 'search-field--wide'
        }`}
        type='text'
        placeholder={searchPlaceholder}
        value={value}
        onChange={handleSetSearchValue}
        onKeyUp={handleKeyUp}
      />

      <div className='search-actions'>
        {value && (
          <Button onClick={clearSearch} className='search-action'>
            <CustomIcon iconName='delete-searchbox' />
          </Button>
        )}
        <Button
          onClick={handleSearchClick}
          sx={buttonStyles}
          className={`search-action search-action-search ${
            value && 'active-action'
          }`}
        >
          <CustomIcon iconName='search-magnifier' />
        </Button>
      </div>
    </div>
  );
};

SearchField.defaultProps = {
  isWide: false,
};

SearchField.propTypes = {
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onSearchClick: PropTypes.func,
  isWide: PropTypes.bool,
  handleChange: PropTypes.func,
  handleClear: PropTypes.func,
};
