export const house = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5 7.66669V2.66669L8 0.166687L5.5 2.66669V4.33335H0.5V16H15.5V7.66669H10.5ZM3.83333 14.3334H2.16667V12.6667H3.83333V14.3334ZM3.83333 11H2.16667V9.33335H3.83333V11ZM3.83333 7.66669H2.16667V6.00002H3.83333V7.66669ZM8.83333 14.3334H7.16667V12.6667H8.83333V14.3334ZM8.83333 11H7.16667V9.33335H8.83333V11ZM8.83333 7.66669H7.16667V6.00002H8.83333V7.66669ZM8.83333 4.33335H7.16667V2.66669H8.83333V4.33335ZM13.8333 14.3334H12.1667V12.6667H13.8333V14.3334ZM13.8333 11H12.1667V9.33335H13.8333V11Z'
      fill='#EF7530'
    />
  </svg>
);
