import React, { FC, ReactNode } from 'react';
import { Box, Divider, Typography } from '@mui/material';

export const Section: FC<{ title?: string; children: ReactNode }> = ({
  title,
  children,
}) => (
  <Box mx={5} mb={4}>
    {title && (
      <Typography mb={2} fontWeight='bold' textTransform='uppercase'>
        {title}
      </Typography>
    )}
    {children}
    <Divider sx={{ mt: 4 }} />
  </Box>
);
