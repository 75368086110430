export const blueArrowDown = (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.5 1.49988L8.4425 0.442383L5 3.87738L1.5575 0.442382L0.5 1.49988L5 5.99988L9.5 1.49988Z'
      fill='#1976D2'
    />
  </svg>
);
