import { Route, Routes } from 'react-router-dom';
import { EmployeeCDPDetails } from './Detail/EmployeeCDPDetails';
import { EmployeeCDPCreate } from './Create/EmployeeCDPCreate';
import { canEditCDP } from '../../../../utils/permissions/checkAccess';
import { EmployeeCDPEdit } from './Edit/EmployeeCDPEdit';
import { CDPList } from './List/EmployeeCDPList';
import { ZoneType } from '../../../../utils/permissions/types';

export default function ({ zone }: { zone: ZoneType }) {
  return (
    <div className='page-cont' style={{ paddingBottom: '16px' }}>
      <Routes>
        <Route path={'/'} element={<CDPList />} />
        {canEditCDP(zone) && (
          <Route path='/create' element={<EmployeeCDPCreate />} />
        )}
        {canEditCDP(zone) && (
          <Route path={'/:cdpId/edit'} element={<EmployeeCDPEdit />} />
        )}
        <Route path='/:cdpId' element={<EmployeeCDPDetails />} />
      </Routes>
    </div>
  );
}
