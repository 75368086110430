import { Button, Grid, IconButton, Typography } from '@mui/material';
import {
  useAddNewCertificateMutation,
  useDeleteCertificateMutation,
  useEditCertificateMutation,
} from '../../../../api/apiSlice';
import {
  ICertificateRequest,
  ICertificateResponse,
} from '../../../../api/apiSlice.types';

import {
  closeDialog,
  showDialog,
} from '../../../../../../store/slices/modalDialog/modalDialogSlice';
import { ConfirmationDialog } from '../../../../../common/modal-dialogs/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import CertificateModal from './CertificateModal';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  employeeId: number;
  certificates: ICertificateResponse[];
}

export const Certificates = ({ employeeId, certificates }: Props) => {
  const dispatch = useDispatch();
  const [deleteCertificate] = useDeleteCertificateMutation();
  const [addNewCertificate] = useAddNewCertificateMutation();
  const [editEmployeeCertificate] = useEditCertificateMutation();

  const onRemoveCertificateClick = (certificateId: number) => {
    dispatch(
      // @ts-ignore
      showDialog(ConfirmationDialog, {
        dialogHeader: 'Delete certificate',
        dialogTitle: 'Are you sure you want to remove this certificate?',
        closeDialog: (dialogName: string) => dispatch(closeDialog(dialogName)),
        confirmDialog: async (dialogName: string) => {
          dispatch(closeDialog(dialogName));
          await deleteCertificate({ employeeId, certificateId });
        },
      })
    );
  };

  const confirmCertificate = async ({
    certificate_name,
    file,
  }: Partial<ICertificateRequest>) => {
    const formData: any = new FormData();
    formData.append('file', file);
    formData.append('certificate_name', certificate_name || '');
    formData.append('employee_id', employeeId);
    try {
      await addNewCertificate({
        employeeId,
        certificate: formData,
      }).unwrap();
      dispatch(closeDialog(CertificateModal.NAME));
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const editCertificate = async ({
    certificate_name,
    certificateId,
  }: {
    certificate_name: string;
    certificateId: number;
  }) => {
    try {
      await editEmployeeCertificate({
        employeeId,
        certificateId,
        certificate_name,
      }).unwrap();
      dispatch(closeDialog(CertificateModal.NAME));
    } catch (error) {
      throw new Error(error as string);
    }
  };

  return (
    <Grid item container direction={'column'} spacing={2}>
      <Grid item>
        <Typography variant={'h6'}>Certificates</Typography>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container>
          {certificates?.map((certificate, index) => (
            <Grid
              item
              container
              gap={1}
              key={`${certificate.certificate_name}${index}`}
            >
              <Grid item>
                <DescriptionIcon color='primary' />
              </Grid>
              <Grid item xs={4}>
                <Typography noWrap>{certificate.certificate_name}</Typography>
              </Grid>
              <Grid item justifyContent={'flex-end'}>
                <IconButton
                  aria-label='clear'
                  sx={{ p: 0.5 }}
                  onClick={() => {
                    dispatch(
                      // @ts-ignore
                      showDialog(CertificateModal, {
                        title: 'Edit',
                        initialCertificateName: certificate.certificate_name,
                        certificateId: certificate.id,
                        editCertificate,
                      })
                    );
                  }}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
                <IconButton
                  aria-label='clear'
                  sx={{ p: 0.5 }}
                  onClick={() => {
                    onRemoveCertificateClick(certificate.id);
                  }}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            onClick={() => {
              dispatch(
                // @ts-ignore
                showDialog(CertificateModal, {
                  title: 'Add',
                  confirmCertificate,
                })
              );
            }}
          >
            + Add new certificate
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
