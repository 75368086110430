import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetComponent,
  setComponent,
} from '../../store/slices/header/headerSlice';
import { TagsDialog } from './BenchTags/TagsDialog';
import { ITagsState } from './types';
import { BenchLayout } from './BenchLayout';

export const Bench = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setComponent({
        component: 'Bench management',
        isTitle: true,
      })
    );
    return () => {
      dispatch(resetComponent());
    };
  }, []);

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [tagsToEdit, setTagsToEdit] = useState<ITagsState>({
    id: null,
    tags: [],
  });

  return (
    <>
      <TagsDialog
        tags={tagsToEdit}
        setTagsToEdit={setTagsToEdit}
        setIsOpenDialog={setIsOpenDialog}
        open={isOpenDialog}
      />
      <BenchLayout
        setTagsToEdit={setTagsToEdit}
        setIsOpenDialog={setIsOpenDialog}
      />
    </>
  );
};
