import Grid from '@mui/material/Grid';
import { CSSProperties, FC, PropsWithChildren } from 'react';

interface IRowTitleProps {
  style?: CSSProperties;
}

export const RowTitle: FC<PropsWithChildren<IRowTitleProps>> = ({
  children,
  style,
}) => {
  return (
    <Grid item sx={style}>
      <span className='profile_block__row_title'>{children}</span>
    </Grid>
  );
};
