import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomIcon from '../../icon';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ config, item }) => {
  const [isOpen, handleToggle] = useState(false);

  const toggleShowOptions = (event) => {
    event.stopPropagation();
    handleToggle(!isOpen);
  };

  return (
    <div
      className='c-list__options-column c-list__options-column__svg'
      onClick={toggleShowOptions}
    >
      <CustomIcon iconName='three-dots' className='cursor_pointer' />
      {isOpen && (
        <div
          className='c-list-item__options-column__container'
          onMouseLeave={toggleShowOptions}
        >
          <div>{config && config.action.render({ item })}</div>
        </div>
      )}
    </div>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export const CellButtonList = withCellWrapper(
  Cell,
  'c-list__options_overflow_unset'
);
