import { Grid } from '@mui/material';

export const EMPTY_VALUE = '---';

// use <RowTitle/> instead
export const renderRowTitle = (title, style = {}) => (
  <Grid item style={style}>
    <span className='profile_block__row_title'>{title}</span>
  </Grid>
);

export const GridWrap = ({ children, xs }) => (
  <Grid item xs={xs}>
    <div className='profile-block'>{children}</div>
  </Grid>
);

export const getAssignmentType = (assignmentType) => {
  switch (assignmentType) {
    case 'Billable':
      return 'Billable';
    case 'Invested - akvelon':
    case 'Invested - client':
      return 'Invested';
    case 'Internal - akvelon':
      return 'Internal';
    default:
      return EMPTY_VALUE;
  }
};

export const formatEmptyValue = (value) => {
  if (
    typeof value !== 'undefined' &&
    value !== null &&
    typeof value === 'string' &&
    value.trim() !== ''
  ) {
    return value;
  }

  return EMPTY_VALUE;
};
