export const confirmDialogQuestion = (
  <svg
    width='42'
    height='42'
    viewBox='0 0 42 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.9998 0.166504C9.49984 0.166504 0.166504 9.49984 0.166504 20.9998C0.166504 32.4998 9.49984 41.8332 20.9998
    41.8332C32.4998 41.8332 41.8332 32.4998 41.8332 20.9998C41.8332 9.49984 32.4998 0.166504 20.9998 0.166504ZM23.0832
    35.5832H18.9165V31.4165H23.0832V35.5832ZM27.3957 19.4373L25.5207 21.354C24.0207 22.8748 23.0832 24.1248 23.0832
    27.2498H18.9165V26.2082C18.9165 23.9165 19.854 21.8332 21.354 20.3123L23.9373 17.6873C24.7082 16.9373 25.1665
    15.8957 25.1665 14.7498C25.1665 12.4582 23.2915 10.5832 20.9998 10.5832C18.7082 10.5832 16.8332 12.4582 16.8332
    14.7498H12.6665C12.6665 10.1457 16.3957 6.4165 20.9998 6.4165C25.604 6.4165 29.3332 10.1457 29.3332 14.7498C29.3332
    16.5832 28.5832 18.2498 27.3957 19.4373Z'
      fill='#D7D7D7'
    />
  </svg>
);
