import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import { DocViewer } from './DocViewer';
import { ModalView } from './ModalView';
import './DocViewer.css';
import { showDialog } from '../../../store/slices/modalDialog/modalDialogSlice';

interface Props {
  cvId: number;
  fileURL: string;
  onClose: () => void;
}

export const BoxView: FC<Props> = ({ cvId, fileURL, onClose }) => {
  const dispatch = useDispatch();
  const onFullScreenIconClick = () => {
    onClose();
    // @ts-ignore
    dispatch(showDialog(ModalView, { cvId, fileURL }));
  };

  return (
    <Box className='doc_viewer__wrapper'>
      <IconButton onClick={onFullScreenIconClick}>
        <FullscreenIcon />
      </IconButton>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DocViewer cvId={cvId} fileURL={fileURL} />
    </Box>
  );
};
