import { FC } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { ChecklistItemInterface } from '../../../EmployeesPage/api/apiSlice.types';

interface Props {
  disabled?: boolean;
  item: ChecklistItemInterface;
  onChange: (data: { checkbox_answer: boolean }) => void;
}

export const CheckboxField: FC<Props> = ({ disabled, item, onChange }) => {
  const handleChange = () =>
    !disabled && onChange({ checkbox_answer: !item.checkbox_answer });

  return (
    <Grid item>
      <Checkbox
        disabled={disabled}
        checked={item.checkbox_answer}
        onChange={handleChange}
      />
    </Grid>
  );
};
