import { generateId } from '../../../../utils/generateId';

const DEFAULT_CATEGORY = 'No Category';

export const getOptionsWithGroupAndUID = (options, groupedKey) => {
  const data = {};

  if (!options.length) {
    return [];
  }

  options.forEach((opt) => {
    if (Array.isArray(opt[groupedKey])) {
      if (!opt[groupedKey].length) {
        const group = data[DEFAULT_CATEGORY] ? data[DEFAULT_CATEGORY] : [];
        data[DEFAULT_CATEGORY] = [...group, { ...opt, uid: generateId() }];
        return;
      }

      opt[groupedKey].forEach((i) => {
        const group = data[i.name] ? data[i.name] : [];
        const oneCategoryOption = opt[groupedKey].find(
          (c) => c.name === i.name
        );
        data[i.name] = [
          ...group,
          { ...opt, [groupedKey]: oneCategoryOption, uid: generateId() },
        ];
      });
    } else {
      if (!opt[groupedKey]) {
        const group = data[DEFAULT_CATEGORY] ? data[DEFAULT_CATEGORY] : [];
        data[DEFAULT_CATEGORY] = [...group, { ...opt, uid: generateId() }];
        return;
      }
      const group = data[opt[groupedKey].name]
        ? data[opt[groupedKey].name]
        : [];
      data[opt[groupedKey].name] = [...group, { ...opt, uid: generateId() }];
    }
  });

  const finalData = [];

  Object.keys(data).forEach((key) => {
    data[key].forEach((option) => {
      finalData.push({ ...option, _group: key });
    });
  });

  return finalData;
};

export const removeExtraUID = (options) =>
  options.reduce((acc, item) => {
    const saved = { ...item };
    delete saved._group;
    delete saved.uid;
    acc.push(saved);
    return acc;
  }, []);

export const convertValues = (arr1, arr2, idKey) =>
  arr1.filter(
    (el1) => arr2 && arr2.map((el2) => el2[idKey]).includes(el1[idKey])
  );
