import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getValueFromObject } from './utils';
import {
  getProjectStatusColor,
  getStatusColor,
} from '../../../../utils/getStatusColor';

export const CustomStatusCell = ({ item, column, config }) => {
  const { isHaveRights, keys, needProjectsStatus } = config[column.Id];
  const status = keys?.length
    ? getValueFromObject(item, ...keys)
    : item[column.Id];
  const background = needProjectsStatus
    ? getProjectStatusColor(status)
    : getStatusColor(status);
  return (
    isHaveRights && (
      <div
        className={classNames(
          'c-list__item-column c-list__status',
          column.className ? column.className : 'small-col',
          item.employee_status === 'dismissed' && 'dismissed'
        )}
      >
        <span
          className='col-span'
          title={status}
          style={{
            background,
            color: background !== 'transparent' ? '#FFFFFF' : '#000000',
          }}
        >
          {status}
        </span>
      </div>
    )
  );
};

CustomStatusCell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};
