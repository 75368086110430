export const skillUncofirmed = (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.5 8L14.67 5.9075L14.925 3.14L12.2175 2.525L10.8 0.125L8.25 1.22L5.7 0.125L4.2825 2.5175L1.575 3.125L1.83 5.9L0 8L1.83 10.0925L1.575 12.8675L4.2825 13.4825L5.7 15.875L8.25 14.7725L10.8 15.8675L12.2175 13.475L14.925 12.86L14.67 10.0925L16.5 8ZM6.8175 11.54L3.9675 8.6825L5.0775 7.5725L6.8175 9.32L11.205 4.9175L12.315 6.0275L6.8175 11.54Z'
      fill='#FCAB40'
    />
  </svg>
);
