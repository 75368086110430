import React from 'react';
import { Field } from 'formik';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { FORMAT_MASK } from '../../../utils/FORMAT_MASK';
import { EMPTY_VALUE } from '../../EmployeesPage/components/EmployeeProfile/utils';

export const getFormattedDate = (date, format = 'yyyy-MM-dd') =>
  date ? DateTime.fromISO(date).toFormat(format) : EMPTY_VALUE;

const renderDatepicker = ({
  onChange,
  startText,
  field,
  label,
  maxDate,
  minDate,
  styles,
  formatMask = FORMAT_MASK,
  formatDate,
  isRequired,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        styles ? styles.container : '',
        'form-control__datepicker'
      )}
    >
      {label && (
        <label className='form_field__label'>
          <span>{label}</span>
          {isRequired && <span className='redux-form__required'> *</span>}
        </label>
      )}
      <DatePicker
        startText={startText}
        value={field.value || ''}
        onChange={onChange}
        inputFormat={formatDate}
        mask={formatMask}
        renderInput={(startProps) => <TextField {...startProps} />}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  );
};

export const DatePickerField = function ({ name, ...props }) {
  return (
    <Field
      className=''
      name={name}
      labelId={`field-${name}_label-id`}
      id={`field-${name}`}
      component={renderDatepicker}
      onChange={(value) => {
        if (props.setFieldValue) {
          props.setFieldValue(name, value);
        }
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      {...props}
    />
  );
};

DatePickerField.defaultProps = {
  formatDate: 'yyyy-MM-dd',
};
