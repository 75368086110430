export const emptyPhoto = (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3155_23134)'>
      <rect
        x='2'
        y='2'
        width='32'
        height='32'
        rx='16'
        fill='white'
        fillOpacity='0.01'
      />
      <rect x='2' y='2' width='32' height='32' rx='16' fill='#BDBDBD' />
      <path
        d='M18 18C20.21 18 22 16.21 22 14C22 11.79 20.21 10 18 10C15.79 10 14 11.79 14 14C14 16.21 15.79 18 18 18ZM18 20C15.33 20 10 21.34 10 24V26H26V24C26 21.34 20.67 20 18 20Z'
        fill='white'
      />
    </g>
    <rect
      x='1'
      y='1'
      width='34'
      height='34'
      rx='17'
      stroke='white'
      strokeWidth='2'
    />
    <defs>
      <clipPath id='clip0_3155_23134'>
        <rect x='2' y='2' width='32' height='32' rx='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
