export const questionMark = (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.875 11.25H8.125V10H6.875V11.25ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5
    13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563
    2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5
    12.5ZM7.5 3.75C6.11875 3.75 5 4.86875 5 6.25H6.25C6.25 5.5625 6.8125 5 7.5 5C8.1875 5 8.75 5.5625 8.75
    6.25C8.75 7.5 6.875 7.34375 6.875 9.375H8.125C8.125 7.96875 10 7.8125 10 6.25C10 4.86875 8.88125 3.75
    7.5 3.75Z'
      fill='#A5A5A5'
    />
  </svg>
);
