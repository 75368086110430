export const getBackfillOptions = (
  employees: { id: number; name: string }[],
  id: number
) => {
  if (!employees) {
    return [];
  }
  return employees
    .map((item) => ({
      label: item.name,
      value: item.id,
    }))
    .filter((item) => item.value !== id);
};
