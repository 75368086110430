import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../utils/http';

const extractPayload = (response) => response?.payload;

const getThunk = createAsyncThunk(
  'callGetApi',
  async ({ url }) => await http({ url, method: 'get' })
);

const thunk = createAsyncThunk(
  'callApi',
  async ({ url, method, data, ...props }) =>
    await http({ url, method, data, ...props })
);

export const callGetApi =
  ({ url }) =>
  async (dispatch) => {
    const response = await dispatch(getThunk({ url }));
    return extractPayload(response);
  };

export const callApi =
  ({ url, method, data, ...props }) =>
  async (dispatch) => {
    const response = await dispatch(thunk({ url, method, data, ...props }));
    return extractPayload(response);
  };
