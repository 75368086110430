import { FilterChip } from './FilterChip';
import { Box } from '@mui/material';
import React from 'react';
import { Option } from './types';

export const SkillFilterChips = ({
  values,
  setValues,
  isExcluded,
  prefix,
}: {
  values: Option[];
  setValues: (skill: Option) => void;
  isExcluded?: boolean;
  prefix?: string;
}) => (
  <>
    {values.map((value: Option, index) => {
      const label = prefix ? prefix + ' ' + value.name : value.name;

      return (
        <Box sx={{ mr: 2, mb: 2 }} key={value.name + value.id + index}>
          <FilterChip
            color={isExcluded ? '#FCE3DD' : ''}
            label={label}
            onDelete={() => {
              setValues(value);
            }}
          />
        </Box>
      );
    })}
  </>
);
