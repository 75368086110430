import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, Chip } from '@mui/material';
import { useGetEmployeeOrgInfoQuery } from '../../../api/apiSlice';

const BenchTags = () => {
  const { id } = useParams();

  const { data: employeeOrgInfo } = useGetEmployeeOrgInfoQuery(Number(id));

  return (
    <>
      <Grid item>
        <span className='org_title'>Tags</span>
      </Grid>
      <Grid container style={{ paddingTop: '18px' }} alignItems='center'>
        {employeeOrgInfo?.bench_tags?.length ? (
          employeeOrgInfo?.bench_tags?.map((tag) => (
            <Box key={tag?.id} mr={1}>
              <Chip label={tag?.name} variant='outlined' size='small' />
            </Box>
          ))
        ) : (
          <Typography variant='body2' color='#4b4b4b'>
            No tags
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default BenchTags;
