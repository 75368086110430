import { useState } from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
  checkboxes: React.ReactNode[];
}

const QuickFiltersPopover = ({ checkboxes }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { overflow: 'unset' },
        }}
      >
        {checkboxes}
      </Popover>
    </Box>
  );
};

export default QuickFiltersPopover;
