import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

const SkeletonComponent = ({ isLoading, styles, renderedValue }) =>
  isLoading ? <Skeleton {...styles} /> : renderedValue;

SkeletonComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
  renderedValue: PropTypes.node,
};

export default SkeletonComponent;
//todo: replace this. its obsolete
