import { createSlice } from '@reduxjs/toolkit';
import {
  registerModal,
  unregisterModal,
} from '../../../components/common/modal-dialogs';

const initialState = {
  dialogName: [],
};

const modalDialogSlice = createSlice({
  name: 'modalDialog',
  initialState,
  reducers: {
    showDialog: {
      reducer: (state, { payload: { component, componentProps } }) => {
        if (!state.dialogName.includes(component.NAME)) {
          registerModal(component, componentProps);
          state.dialogName.push(component.NAME);
        }
      },
      prepare: (component, componentProps) => {
        return {
          payload: {
            component,
            componentProps,
          },
        };
      },
    },
    closeDialog: {
      reducer: (state, action) => {
        return {
          dialogName: state.dialogName.filter(
            (name) => name !== action.payload
          ),
        };
      },
      prepare: (dialogName) => {
        unregisterModal(dialogName);
        return {
          payload: dialogName,
        };
      },
    },
  },
});

export const { showDialog, closeDialog } = modalDialogSlice.actions;

export const selectModalDialogConfig = (store) => store.modalDialogsReducers;

export default modalDialogSlice.reducer;
