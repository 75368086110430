import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../api/apiSlice';
import { Actions } from '../EmployeeProfile/ProfileInfo/Actions';

const DISMISSED_STATUS = 'dismissed';

const textForActive = 'Employee is currently active';
const textForDismissed = 'Employee is currently dismissed';

export const EmploymentEdit = () => {
  const { id } = useParams();
  const {
    data: employee,
    isLoading,
    isError,
  } = useGetEmployeeDetailsQuery(Number(id));

  const isDismissed = employee?.employee_status === DISMISSED_STATUS;

  const text = isDismissed ? textForDismissed : textForActive;

  if (isLoading) {
    return <Typography variant='body2'>Loading...</Typography>;
  }

  if (isError) {
    return <Typography variant='body2'>Error</Typography>;
  }

  return (
    <Stack spacing={2}>
      {employee && <Typography fontSize={14}>{text}</Typography>}
      <Actions isDismissed={isDismissed} />
    </Stack>
  );
};
