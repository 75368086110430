import * as React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  TextField as MaterialTextField,
  InputAdornment,
} from '@mui/material';
import './text-field.css';
import CustomIcon from '../../icon';
import { keyboardKeys } from '../../../../utils/const-variable';

const TextField = ({
  id,
  autoFocus = false,
  showClear = false,
  clearClassName = 'clear-svg',
  name,
  label,
  formClassName,
  inputClassName,
  internalInputClassName,
  type,
  value,
  placeholder,
  fullWidth,
  multiline,
  onChange,
  onFocus,
  onBlur,
  isRequired,
  adornment,
  disabled,
  helperText,
  error,
  maxlength,
  isDontNeedAutocomplete = false,
  dataCy,
  rows,
  variant,
  customLabel,
  clearValue,
  onKeyUp,
}) => {
  const handleClear = () => clearValue(name, id);

  const handleKeyUp = (event) => {
    if (event.keyCode === keyboardKeys.Enter) {
      event.stopPropagation();
    }
    onKeyUp && onKeyUp(event);
  };

  return (
    <FormControl className={formClassName} fullWidth={fullWidth}>
      {customLabel && label && (
        <label className='form_field__label'>
          <span>{label}</span>
          {isRequired && <span className='redux-form__required'> *</span>}
        </label>
      )}
      <MaterialTextField
        variant={variant}
        fullWidth
        id={id}
        autoFocus={autoFocus}
        name={name}
        label={!customLabel ? label || '' : undefined}
        className={inputClassName || ''}
        type={type}
        value={value}
        placeholder={placeholder}
        multiline={multiline}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        InputLabelProps={!customLabel ? { shrink: true } : undefined}
        required={isRequired}
        error={error}
        InputProps={{
          startAdornment: adornment && (
            <InputAdornment position='start'>{adornment}</InputAdornment>
          ),
          inputProps: {
            maxLength: maxlength,
            className: internalInputClassName,
          },
        }}
        disabled={disabled}
        title={helperText ? helperText : ''}
        helperText={error && helperText}
        autoComplete={isDontNeedAutocomplete ? 'off' : ''}
        data-cy={dataCy}
        minRows={rows}
        onKeyUp={handleKeyUp}
      />
      {showClear && value && !multiline && !disabled ? (
        <CustomIcon
          iconName='cross'
          className={clearClassName}
          onClick={handleClear}
        />
      ) : null}
    </FormControl>
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  formClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  internalInputClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  adornment: PropTypes.string,
  helperText: PropTypes.string,
  clearValue: PropTypes.func,
  maxlength: PropTypes.string,
};

TextField.defaultProps = {
  formClassName: '',
  fullWidth: false,
  value: '',
  placeholder: '',
  multiline: false,
};

export default TextField;
