import PropTypes from 'prop-types';
import './list-toolbar.css';
import { SearchField } from './components/search-field';

export const ListToolbar = (props) => {
  const { searchPlaceholder, onSearchClick, buttonsTemplate } = props;

  return (
    <div className={'toolbar-container'}>
      <div className='toolbar-container__main-part'>
        <div className={'toolbar'}>
          <div className='left-toolbar-part'>
            <div className='left-toolbar-part'>
              <SearchField
                searchPlaceholder={searchPlaceholder}
                onSearchClick={onSearchClick}
              />
            </div>
          </div>

          <div className='right-toolbar-part'>{buttonsTemplate}</div>
        </div>
      </div>
    </div>
  );
};

ListToolbar.propTypes = {
  searchPlaceholder: PropTypes.string,
};
