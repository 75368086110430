export const brilliant = (
  <svg
    width='20'
    height='19'
    viewBox='0 0 20 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 0.5H3L0 6.5L10 18.5L20 6.5L17 0.5ZM7.62 5.5L9.12 2.5H10.88L12.38 5.5H7.62ZM9 7.5V14.18L3.44 7.5H9ZM11 7.5H16.56L11 14.18V7.5ZM17.26 5.5H14.61L13.11 2.5H15.76L17.26 5.5ZM4.24 2.5H6.89L5.39 5.5H2.74L4.24 2.5Z'
      fill='#9B9B9B'
    />
  </svg>
);
