import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Tooltip } from '@mui/material';
import CustomIcon from '../../icon';
import { withCellWrapper } from '../../HOC/withCellWrapper';

const Cell = ({ item, config, column }) => {
  const onClick = (event) => {
    event.stopPropagation();
    const { onClick } = config[column.Id];
    !item.status_change_manager && onClick(item);
  };

  const { isHaveRights } = config[column.Id];
  return (
    <div className={item.employee_status === 'dismissed' ? 'dismissed' : ''}>
      {!item.status_change_manager ? (
        <div className='cell-manager-wrapper'>
          <span>{item?.admin_manager?.name}</span>
          {isHaveRights && (
            <CustomIcon iconName='edit-pencil' onClick={onClick} />
          )}
        </div>
      ) : (
        <Tooltip
          title={
            <div className='manager-tooltip-title'>
              <h3>{item?.new_manager_for_employee?.name}</h3>
            </div>
          }
          placement='right'
        >
          <Stack alignItems='center' direction='row'>
            {item?.manager?.name} <CustomIcon iconName='person-group' />
          </Stack>
        </Tooltip>
      )}
    </div>
  );
};

Cell.propTypes = {
  item: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export const ManagerStatusCell = withCellWrapper(Cell);
