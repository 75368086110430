import { FilterChip } from './FilterChip';
import { Box } from '@mui/material';
import React from 'react';
import { Option } from './types';

export const FilterChips = ({
  values,
  setValues,
  isExcluded,
  prefix,
}: {
  values: Option[];
  setValues: React.Dispatch<React.SetStateAction<any>>;
  isExcluded?: boolean;
  prefix?: string;
}) => (
  <>
    {values.map((value: Option, index) => {
      const label = prefix ? prefix + ' ' + value.name : value.name;

      return (
        <Box sx={{ mr: 1, mb: 0.5 }} key={value.name + value.id + index}>
          <FilterChip
            color={isExcluded ? '#FCE3DD' : ''}
            label={label}
            onDelete={() =>
              setValues(
                values.filter(
                  (prevStateItem) =>
                    prevStateItem.id !== value.id ||
                    (prevStateItem.id === value.id &&
                      prevStateItem?.category?.id !== value?.category?.id)
                )
              )
            }
          />
        </Box>
      );
    })}
  </>
);
