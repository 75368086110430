import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { keyboardKeys } from '../../../../utils/const-variable';
import { getFormattedDate } from '../../../common/formikFormControls/DatePickerField';
import { ClearableDatePicker } from './ClearableDatePicker';
import { IFilters } from '../../slices/feedbacksSlice';

export const TableToolbar = ({
  onToolbarChange,
  values,
}: {
  onToolbarChange: (values: IFilters) => void;
  values: any;
}) => {
  const [anchorEl, setAnchorEl] = useState(null as HTMLAnchorElement | null);
  const [search, setSearch] = useState(values.search);
  const [feedback_date_after, setFrom] = useState(
    values.feedback_date_after as null | DateTime
  );
  const [feedback_date_before, setTo] = useState(
    values.feedback_date_before as null | DateTime
  );

  const getCombinedValues = (): IFilters => ({
    search,
    feedback_date_before: feedback_date_before
      ? getFormattedDate(feedback_date_before)
      : null,
    feedback_date_after: feedback_date_after
      ? getFormattedDate(feedback_date_after)
      : null,
  });

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    onToolbarChange(getCombinedValues());
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);
  const onFromChange = (date: DateTime | null) => setFrom(date);
  const onToChange = (date: DateTime | null) => setTo(date);

  const onSearchBlur = () => onToolbarChange(getCombinedValues());
  const onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === keyboardKeys.Enter) {
      onToolbarChange(getCombinedValues());
    }
  };

  const isOpen = Boolean(anchorEl);
  return (
    <Grid container>
      <Grid item alignItems='center' display='flex'>
        <TextField
          className='table_toolbar__input'
          size='small'
          placeholder='Search by title, employee, position, manager, creator...'
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchChange}
          onBlur={onSearchBlur}
          onKeyDown={onSearchKeyDown}
        />
        <div>
          <IconButton
            className='table_toolbar__btn'
            aria-describedby='toolbar_popover'
            onClick={handleClick}
          >
            <FilterListIcon />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            id='toolbar_popover'
            open={isOpen}
            onClose={handleClose}
          >
            <Box className='toolbar_wrapper'>
              <Typography color='text.secondary'>Date Range:</Typography>
              <ClearableDatePicker
                label='From'
                value={feedback_date_after}
                onChange={onFromChange}
                maxDate={feedback_date_before}
              />
              <ClearableDatePicker
                label='To'
                value={feedback_date_before}
                onChange={onToChange}
                minDate={feedback_date_after}
              />
            </Box>
          </Popover>
        </div>
      </Grid>
    </Grid>
  );
};
