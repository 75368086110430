export const createCategoriesData = (skills) =>
  skills.reduce((acc, skill) => {
    let category = { ...skill.skill_category };
    if (!skill.skill) {
      // skill was removed
      return acc;
    }
    if (skill.skill && !skill.skill_category) {
      category = { id: 99999, name: 'No category' };
    }
    category.employee_skill_assessments_list = [skill];

    if (!!acc.length) {
      const matched = acc.findIndex((el) => el.id === category.id);
      if (matched !== -1) {
        acc[matched].employee_skill_assessments_list.push(skill);
        return acc;
      }
    }
    acc.push(category);
    return acc;
  }, []);

const updateSkill = (target, key, options) => {
  let copy = { ...target };
  if (target[key] === 0) {
    copy[key] = null;
  } else {
    const option = options.find((item) => item.id === target[key]);
    if (option) {
      copy[key] = option;
    }
  }
  copy.isTouched = true;
  return copy;
};

const substituteTargetById = (array, target) =>
  array.map((item) =>
    item.id === target.id && item.skill_category.id === target.skill_category.id
      ? target
      : item
  );

export const updateCategorySkillItems = (target, categories, key, options) => {
  const updatedSkill = updateSkill(target, key, options);

  return categories.map((item) => {
    if (item.id === updatedSkill.skill_category?.id) {
      const newSkills = substituteTargetById(
        item.employee_skill_assessments_list,
        updatedSkill
      );
      return {
        ...item,
        employee_skill_assessments_list: newSkills,
      };
    }
    return item;
  });
};

export const updateSkillItems = (target, skills, key, options) => {
  const updatedSkill = updateSkill(target, key, options);
  return substituteTargetById(skills, updatedSkill);
};

export const removeCategorySkill = (target, categories) =>
  categories.map((item) => {
    if (item.id === target.skill_category?.id) {
      const filteredSkills = item.employee_skill_assessments_list.filter(
        (skill) => {
          if (skill.id !== target.id) {
            return skill;
          }
        }
      );
      return {
        ...item,
        employee_skill_assessments_list: filteredSkills,
      };
    }
    return item;
  });

export const removeInitialSkill = (data, initialSkills) =>
  initialSkills.reduce((acc, item) => {
    if (item.id === data.id && data.isNew) {
      return acc;
    }
    if (item.id === data.id) {
      acc.push({ id: item.id, skill: null, isTouched: true });
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

const getTouchedSkills = (skills) => skills.filter((item) => item.isTouched);

const mappedNewSkills = (skills) =>
  skills.map((item) => (item.isNew ? { ...item, id: null } : item));

const removeUnnecessaryKeys = (skills) =>
  skills.map((item) => {
    if (item.isTouched || item.isNew) {
      let copy = { ...item };
      delete copy.isTouched;
      delete copy.isNew;
      return copy;
    } else {
      return item;
    }
  });

export const prepareSkillsToAPI = (skills) => {
  const onlyTouched = getTouchedSkills(skills);
  const touchedWithNew = mappedNewSkills(onlyTouched);
  return removeUnnecessaryKeys(touchedWithNew);
};

export const getUnusedSkills = (currentSkills, allSKills) =>
  currentSkills.reduce((acc, currentSkill) => {
    const found = allSKills.filter(
      (skill) => currentSkill.id === skill?.skill?.id
    );
    if (!!found.length) {
      if (found.length !== currentSkill.categories.length) {
        const usedCategoryIds = found.map((item) => item?.skill_category?.id);
        acc.push({
          ...currentSkill,
          categories: currentSkill.categories.filter(
            (cat) => !usedCategoryIds.includes(cat.id)
          ),
        });
      }
    } else {
      acc.push(currentSkill);
    }
    return acc;
  }, []);
