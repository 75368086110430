import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Option } from '../../../Bench/types';
import { AddButton } from './AddButton';
import { useGetBenchFiltersQuery } from '../../api/apiSlice';
import { SkillFilter as SkillFilterType } from '../../slices/employeeSlice.types';
import { Skill as MainSkill } from '../../api/apiSlice.types';
import { FilterInput } from './FilterInput';
import { SkillInputGroup } from './SkillInputGroup';
import { getFilterName } from './getFilterName';

type Skill = {
  id: number;
  name: string;
};

interface SkillRow {
  skills: Skill[];
  level: { id: number; name: string };
}

interface SkillRowForm {
  skills: Skill[];
  level: Option;
}

export const SkillFilter = ({
  isSkillsLoading,
  skillOptions,
  isLevelsLoading,
  levelOptions,
  skillRows,
  setSkillRows,
  setSkillFilter,
  skillFilter,
  interviewOptions,
}: {
  isSkillsLoading: boolean;
  skillOptions: Skill[];
  interviewOptions?: Skill[];
  isLevelsLoading: boolean;
  levelOptions: Option[];
  skillRows: SkillRow[];
  setSkillRows: React.Dispatch<React.SetStateAction<SkillRow[]>>;
  setSkillFilter: React.Dispatch<React.SetStateAction<SkillFilterType>>;
  skillFilter: SkillFilterType;
}) => {
  const { data: benchFilters, isLoading: isBenchFiltersLoading } =
    useGetBenchFiltersQuery();

  const [levelSelectOptions, setLevelSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    setLevelSelectOptions(
      levelOptions.filter(
        (level) =>
          !skillRows.find((skillRow) => skillRow.level?.id === level.id)
      )
    );
  }, [skillRows]);

  const addSkillRow = () => {
    const MAX_ROWS = 3;
    if (skillRows.length >= MAX_ROWS) {
      return;
    }
    const newSkillRow = { skills: [], level: levelSelectOptions[0] };
    setSkillRows((prevState) => [...prevState, newSkillRow]);
  };

  const removeSkillRow = (index: number) => {
    setSkillRows((prevState) => prevState.filter((_, i) => i !== index));
  };

  const editSkillRowSkills = (index: number, newSkills: Skill[]) => {
    setSkillRows((prevState) => {
      const newState: SkillRowForm[] = prevState.map((skillRowState) => ({
        skills: skillRowState.skills,
        level: skillRowState.level,
      }));
      newState[index].skills = newSkills;
      return newState;
    });
  };

  const editSkillRowLevels = (index: number, newLevel: Option) => {
    setSkillRows((prevState) => {
      const newState: SkillRowForm[] = prevState.map((skillRow) => ({
        skills: skillRow.skills,
        level: skillRow.level,
      }));
      newState[index].level = newLevel;
      return newState;
    });
  };
  const filtersOptions: {
    [key: string]: Partial<MainSkill>[];
  } = {
    main_tech_skills: benchFilters?.main_tech_skills || [],
    skills: [], // this is used to render an option in list
    interview_enabled_skills: interviewOptions || [],
  };

  const renderRow = () => {
    const skillInputs = [];
    for (let key in skillFilter) {
      if (key === 'main_tech_skills' || key === 'interview_enabled_skills') {
        skillInputs.push(
          <FilterInput
            label={getFilterName(key)}
            isLoading={isBenchFiltersLoading}
            key={key}
            options={filtersOptions[key] as MainSkill[]}
            value={skillFilter[key]}
            onChange={(newValue: MainSkill[]) => {
              setSkillFilter((prevState) => ({
                ...prevState,
                [key]: newValue,
              }));
            }}
            onClick={() =>
              setSkillFilter((skillFilters) => {
                const newSkillFilters = {
                  ...skillFilters,
                };
                delete newSkillFilters[key as keyof SkillFilterType];
                return newSkillFilters;
              })
            }
          />
        );
      }
    }
    skillInputs.push(
      ...skillRows.map((skillRow, index) => (
        <SkillInputGroup
          key={index}
          isLoading={isSkillsLoading || isLevelsLoading}
          skillOptions={skillOptions}
          value={skillRow}
          onChangeSkill={(newValue: Skill[]) => {
            editSkillRowSkills(index, newValue);
          }}
          levelOptions={levelOptions.filter((level) => {
            const otherSkillRows = [...skillRows];
            otherSkillRows.splice(index, 1);
            return !otherSkillRows.find(
              (skillRow) => skillRow.level?.id === level.id
            );
          })}
          onChangeLevel={(newValue: Option) => {
            editSkillRowLevels(index, newValue);
          }}
          onClick={() => {
            removeSkillRow(index);
          }}
        />
      ))
    );
    return skillInputs;
  };

  const list = Object.keys(filtersOptions).filter((item) => {
    if (skillRows.length >= 3 && item === 'skills') {
      return false;
    }
    if (item === 'main_tech_skills' && skillFilter[item]) {
      return false;
    }
    if (item === 'interview_enabled_skills' && skillFilter[item]) {
      return false;
    }
    return true;
  });

  const onClick = (filterName: string) => {
    if (filterName === 'skills') {
      addSkillRow();
    }
    setSkillFilter((filters) => ({
      ...filters,
      [filterName]: [],
    }));
  };

  return (
    <div>
      <Box minWidth={560}>
        {renderRow()}
        {list.length ? (
          <AddButton list={list} onClick={onClick} buttonName={'skills'} />
        ) : null}
      </Box>
    </div>
  );
};
