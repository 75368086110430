import { createApi } from '@reduxjs/toolkit/query/react';
import { filtersKeyIdValueName } from '../../../utils/updateFilters';
import { customFetchBase } from './customFetchBase';
import {
  ChecklistInterface,
  CvItem,
  CvsResponse,
  EmployeeFeedbackListItem,
  EmployeeManager,
  EmployeeMinimalResponseInterface,
  EmployeePosition,
  EmployeeSeniority,
  FeedbackFormDataInterface,
  FeedbackResponseInterface,
  AttritionReportTypes,
  AttritionReportTypesResponse,
  IBenchData,
  IBenchFilters,
  IBenchRequest,
  IBenchResponse,
  IBenchSummary,
  IBenchTag,
  ICDPFileResponse,
  ICDPRequest,
  ICDPResponse,
  ICertificateResponse,
  ICountryResponse,
  IEmployee,
  IEmployeeCDPListResponse,
  IEmployeeDetails,
  IEmployeeFiltersPrepared,
  IEmployeeFiltersResponse,
  IEmployeeLocationHistory,
  IEmployeeOrgInfoEditRequest,
  IEmployeeOrgInfoResponse,
  IEmployeePatchRequest,
  IEnglishLevel,
  IFeedback,
  IFeedbackCreateBody,
  IFeedbacksRequest,
  IFeedbacksResponse,
  IFilterReportParams,
  IGoalPatchPayload,
  IGoalPostPayload,
  IGoalResponse,
  ILocationReportResponse,
  IManagerChange,
  IManagerChangeResponse,
  IPaginationParams,
  IProbationStatus,
  IRiskReportResponse,
  Item2,
  IUserEditData,
  ListResponse,
  ListResponseWithScopeAndZone,
  MentorsMinimalResponseInterface,
  PostFeedbackInterface,
  Project,
  Skill,
  SkillCategory,
  SkillLevel,
  UserSkill,
  VersionResponse,
  ICertificateRequest,
  ICDPReportResponse,
  ITask,
  OnSubmitTaskParams,
  OnUpdateTaskStatusParams,
} from './apiSlice.types';
import { FormDataProps as CVModalFormDataInterface } from '../components/EmployeeCVs/cv-modal';
import { DateTime } from 'luxon';
import { ZoneType } from '../../../utils/permissions/types';
import { convertToFilterStr } from './utils/convertToFilterStr';
import { getSkillWithCategoryOptions } from './utils';
import { dictionaryToItem } from './utils/dictionaryToItem';
// @ts-ignore
import qs from 'qs';

import { Filters } from '../components/EmployeesList/types';

const fourHours = 3600 * 4;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customFetchBase,
  tagTypes: [
    'Employee',
    'Feedback',
    'FeedbackFilter',
    'CV',
    'UserSkill',
    'SkillCategory',
    'SkillLevel',
    'FeedbackDetail',
    'EmployeesMinimal',
    'MentorsMinimal',
    'Positions',
    'Seniorities',
    'Project',
    'EmployeeFilter',
    'OrgInfo',
    'ProbationStatus',
    'Feedbacks',
    'CV_File',
    'ManagerChangeRequest',
    'Bench',
    'CDP',
    'CDPStatus',
    'EmployeeCDPList',
    'Goal',
    'CDPList',
    'Position',
    'Seniority',
    'EnglishLevel',
    'EmployeeDetails',
    'EmployeeCertificate',
    'Country',
    'Version',
    'DismissReason',
    'AttritionReportTypes',
  ],
  endpoints: (builder) => ({
    getFeedbacks: builder.query<EmployeeFeedbackListItem[], number>({
      query: (employeeId) => ({
        url: `/employees/${employeeId}/feedback/`,
      }),
      providesTags: (result = []) => [
        'Feedback',
        ...result.map(({ id }) => ({ type: 'Feedback' as const, id })),
      ],
    }),
    getFeedback: builder.query<
      FeedbackResponseInterface,
      { employeeId: number; feedbackId: number }
    >({
      query: ({ employeeId, feedbackId }) =>
        `/employees/${employeeId}/feedback/${feedbackId}`,
      providesTags: ['FeedbackDetail'],
    }),
    addNewFeedback: builder.mutation<
      FeedbackResponseInterface,
      { userId: number; initialFeedback: PostFeedbackInterface }
    >({
      query: ({ userId, initialFeedback }) => ({
        url: `/employees/${userId}/feedback/`,
        method: 'POST',
        body: initialFeedback,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Feedback'],
    }),
    editFeedback: builder.mutation<
      FeedbackResponseInterface,
      { feedback: FeedbackFormDataInterface; id: number }
    >({
      query: ({ id, feedback }) => ({
        url: `employees/${id}/feedback/${feedback.id}`,
        method: 'PATCH',
        body: feedback,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['FeedbackDetail', 'Feedback'],
    }),
    getTemplates: builder.query<Array<ChecklistInterface>, void>({
      query: () => '/employees/feedbacks/template-checklists',
      transformResponse: (response: { results: Array<ChecklistInterface> }) =>
        response.results,
    }),

    // Tasks
    getEmployeesMinimalAll: builder.query<
      EmployeeMinimalResponseInterface[],
      void
    >({
      query: () => ({
        url: `/employees/minimal/all/`,
        params: { ordering: 'name' },
      }),
    }),
    getEmployeesMinimalDC: builder.query<
      EmployeeMinimalResponseInterface[],
      void
    >({
      query: () => ({
        url: `/employees/minimal/dc/`,
        params: { ordering: 'name' },
      }),
    }),

    addTask: builder.mutation<
      ITask,
      { employeeId: number; feedbackId: number; data: OnSubmitTaskParams }
    >({
      query: ({ employeeId, feedbackId, data }) => ({
        url: `/employees/${employeeId}/feedback/${feedbackId}/tasks/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Feedback', 'FeedbackDetail'],
    }),

    editTaskStatus: builder.mutation<
      ITask,
      {
        employeeId: number;
        feedbackId: number;
        taskId: number;
        data: OnUpdateTaskStatusParams;
      }
    >({
      query: ({ employeeId, feedbackId, taskId, data }) => ({
        url: `/employees/${employeeId}/feedback/${feedbackId}/tasks/${taskId}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Feedback', 'FeedbackDetail'],
    }),

    getCvs: builder.query<CvItem[], number>({
      query: (employeeId) => `/employees/${employeeId}/cvs/`,
      transformResponse: (response: CvsResponse) => response.results,
      providesTags: (result = []) => [
        'CV',
        ...result.map(({ id }) => ({ type: 'CV' as const, id })),
      ],
    }),
    deleteEmployeeCV: builder.mutation<any, number>({
      query: (cvId) => ({
        url: `/employees/cvs/${cvId}/`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CV', id: id }],
    }),
    editEmployeeCV: builder.mutation<
      CvItem,
      { cvId: number; formData: CVModalFormDataInterface }
    >({
      query: ({ cvId, formData }) => ({
        url: `/employees/cvs/${cvId}/`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: ['CV'],
    }),
    addEmployeeCV: builder.mutation<CvItem, CVModalFormDataInterface>({
      query: (formData) => ({
        url: `/employees/cvs/`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['CV'],
    }),
    getEmployee: builder.query<IEmployee, number>({
      query: (employeeId) => `/employees/${employeeId}/`,
      providesTags: (result, error, arg) => [{ type: 'Employee', id: arg }],
      transformResponse: (response: IEmployee, meta) => {
        return {
          ...response,
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
          // @ts-ignore
          scope: meta?.response.headers.get('x-scope-header') || '',
        };
      },
    }),
    // todo: use this instead of loadEmployees saga
    // todo: add "merge"
    getEmployees: builder.query<
      ListResponseWithScopeAndZone<IEmployee>,
      {
        offSet: number;
        limit: number;
        filters: Filters;
        skills: number[];
        employeePage: string;
        quickFilters: string;
        sortSettings: { column: string; sortDirection: string };
        searchValue: string;
      }
    >({
      query: ({
        offSet,
        limit,
        filters,
        skills,
        employeePage,
        quickFilters,
        sortSettings,
        searchValue,
      }) => {
        const field = sortSettings.column;
        const arrow = sortSettings.sortDirection;
        const search = searchValue;
        const direction = arrow === 'Up' ? '' : '-';
        const ordering = `${direction + field}`;
        const queryStrings = qs.stringify(
          {
            search,
            limit,
            ordering,
            skills,
            offset: offSet,
          },
          {
            encode: false,
            indices: false,
            arrayFormat: 'repeat',
            addQueryPrefix: true,
            skipNulls: true,
          }
        );

        return {
          url: `/employees/${employeePage}/${queryStrings}${quickFilters}`,
          method: 'GET',
          params: { ...filters },
        };
      },
      transformResponse: (
        response: ListResponseWithScopeAndZone<IEmployee>
      ) => {
        return {
          ...response,
          results: response.results.map((employee) => ({
            ...employee,
            admin_manager: employee.manager,
          })),
          // @ts-ignore
          zone: response?.zone || '',
          // @ts-ignore
          scope: response?.scope || '',
        };
      },
    }),
    getLocationHistory: builder.query<IEmployeeLocationHistory[], number>({
      query: (employeeId) => `employees/${employeeId}/locations/`,
    }),

    // todo: add types
    getFeedbackFilters: builder.query<any, void>({
      query: () => '/employees/feedbacks/filter-categories/',
      transformResponse: (response: any) => ({
        ...response,
        risk: filtersKeyIdValueName(response.risk),
      }),
      providesTags: ['FeedbackFilter'],
    }),
    getEmployeeFilters: builder.query<IEmployeeFiltersPrepared, void>({
      query: () => '/employees/filter-categories/',
      transformResponse: (response: IEmployeeFiltersResponse) => ({
        ...response,
        employee_status: response.employee_status?.map(dictionaryToItem),
        probation_status: response.probation_status?.map(dictionaryToItem),
      }),
      providesTags: ['EmployeeFilter'],
    }),
    getAllHRManagers: builder.query<EmployeeManager[], void>({
      query: () => '/employees/all-hrs/',
    }),
    getSkillCategories: builder.query<SkillCategory[], void>({
      query: () => '/skills/skill-categories/?ordering=name',
      transformResponse: (response: ListResponse<SkillCategory>) =>
        response.results,
      providesTags: (results = []) => [
        'SkillCategory',
        ...results.map(({ id }) => ({ type: 'SkillCategory' as const, id })),
      ],
    }),
    getUserSkills: builder.query<UserSkill[], number>({
      query: (employeeId) => `/employees/${employeeId}/skills/`,
      providesTags: (result, error, arg) => [{ type: 'UserSkill', id: arg }],
    }),
    getSkillLevels: builder.query<SkillLevel[], void>({
      query: () => '/dictionary/skill_levels/',
      transformResponse: (response: ListResponse<SkillLevel>) =>
        response.results,
      providesTags: (results = []) => [
        'SkillLevel',
        ...results.map(({ id }) => ({ type: 'SkillLevel' as const, id })),
      ],
    }),
    updateSkills: builder.mutation<
      UserSkill[],
      { userId: number; skills: UserSkill[]; isQuestionnaire: boolean }
    >({
      query: ({ userId, skills, isQuestionnaire = true }) => ({
        url: `/employees/${userId}/skills/`,
        method: 'POST',
        body: isQuestionnaire
          ? skills.map((skill) =>
              Object.assign({}, skill, { is_questionnaire: true })
            )
          : skills,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserSkill', id: arg.userId },
        { type: 'Employee', id: arg?.userId },
      ],
    }),
    getEmployeesMinimal: builder.query<
      EmployeeMinimalResponseInterface[],
      void
    >({
      query: () => ({
        url: `/employees/minimal/`,
        params: { ordering: 'name' },
      }),
      providesTags: ['EmployeesMinimal'],
    }),
    getMentorsMinimal: builder.query<MentorsMinimalResponseInterface[], void>({
      query: () =>
        '/employees/all-mentors/minimal/?search=&offset=0&ordering=name',
      providesTags: ['MentorsMinimal'],
    }),
    getPositions: builder.query<EmployeePosition[], void>({
      query: () => '/positions/',
      providesTags: ['Positions'],
      keepUnusedDataFor: fourHours,
    }),
    getSeniorities: builder.query<EmployeeSeniority[], void>({
      query: () => '/positions/seniorities/',
      providesTags: ['Seniorities'],
      keepUnusedDataFor: fourHours,
    }),
    getProjects: builder.query<Project[], number>({
      query: (employeeId) => `/employees/${employeeId}/projects/`,
      transformResponse: (response: ListResponse<Project>) => {
        return response.results.map((result) => {
          // DataGrid uses toLocaleDateString() for displaying dates and
          // doesn't allow configuration mechanism, that might be tricky and
          // limits application from defining the common date format for all the site
          // @ts-ignore todo: fix it
          result.start_date = DateTime.fromISO(result.start_date).toISODate();
          // @ts-ignore todo: fix it
          result.end_date = DateTime.fromISO(result.end_date).toISODate();
          return result;
        });
      },
      providesTags: ['Project'],
    }),
    getEmployeeOrgInfo: builder.query<IEmployeeOrgInfoResponse, number>({
      query: (employeeId) => `/employees/${employeeId}/org/`,
      providesTags: (result) => [
        'OrgInfo',
        { type: 'OrgInfo' as const, id: result?.id },
      ],
    }),
    editEmployeeOrgInfo: builder.mutation<
      IEmployeeOrgInfoResponse,
      { data: IEmployeeOrgInfoEditRequest; id: number }
    >({
      query: ({ id, data }) => ({
        url: `/employees/${id}/org/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['OrgInfo', 'Bench'],
    }),
    getProbationStatuses: builder.query<IProbationStatus[], void>({
      query: () => '/dictionary/probation_statuses/',
      transformResponse: (response: ListResponse<IProbationStatus>) =>
        response.results,
      providesTags: ['ProbationStatus'],
    }),
    getBenchTags: builder.query<IBenchTag[], void>({
      query: () => '/tags/bench_tags',
    }),
    getAllFeedbacks: builder.query<IFeedbacksResponse, IFeedbacksRequest>({
      query: (params) => ({
        url: '/employees/feedbacks/',
        params,
      }),
      transformResponse: (response: IFeedbacksResponse, meta) => {
        return {
          ...response,
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
          // @ts-ignore
          scope: meta?.response.headers.get('x-scope-header') || '',
        };
      },
      providesTags: ['Feedbacks'],
    }),
    createFeedback: builder.mutation<
      IFeedback,
      { userId: number; data: IFeedbackCreateBody }
    >({
      query: ({ userId, data }) => ({
        url: `/employees/${userId}/feedback/`,
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Feedbacks'],
    }),
    getCVFile: builder.query<Blob, number>({
      query: (id) => ({
        url: `/employees/cvs/${id}/file/`,
        responseHandler: (response) => response.blob(),
      }),
      providesTags: ['CV_File'],
    }),
    activateEmployee: builder.mutation<null, { userId: number }>({
      query: ({ userId }) => ({
        url: `/employees/${userId}/activate/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'EmployeeDetails', id: arg.userId },
      ],
    }),
    getRiskReport: builder.mutation<IRiskReportResponse[], IFilterReportParams>(
      {
        query: ({
          office,
          months,
          manager,
          project,
          status,
          seniorities,
          bench_tags,
          not_bench_tags,
        }) => {
          let paramsString = convertToFilterStr({
            tags: bench_tags,
            tagsToExclude: not_bench_tags,
          });
          if (paramsString.length) {
            paramsString = paramsString.replace(/&$/, '');
          }
          return {
            url: '/reports/report/risks/overall/' + paramsString,
            method: 'GET',
            params: {
              office,
              months,
              ...(manager ? { manager } : {}),
              ...(project ? { project } : {}),
              ...(status ? { status } : {}),
              ...(seniorities ? { seniorities } : {}),
            },
          };
        },
      }
    ),
    getExportRiskFile: builder.mutation<Blob, IFilterReportParams>({
      query: ({
        office,
        months,
        manager,
        project,
        status,
        seniorities,
        bench_tags,
        not_bench_tags,
      }) => {
        let paramsString = convertToFilterStr({
          tags: bench_tags,
          tagsToExclude: not_bench_tags,
        });
        if (paramsString.length) {
          paramsString = paramsString.replace(/&$/, '');
        }
        return {
          url: `/reports/risks/export/${paramsString}`,
          method: 'GET',
          params: {
            office,
            months,
            ...(manager ? { manager } : {}),
            ...(project ? { project } : {}),
            ...(status ? { status } : {}),
            ...(seniorities ? { seniorities } : {}),
          },
          responseHandler: async (response) => {
            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'risk_report';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          },
          cache: 'no-cache',
        };
      },
    }),

    getLocationReport: builder.mutation<
      ILocationReportResponse[],
      IFilterReportParams
    >({
      query: ({ office, months, manager, project, status }) => ({
        url: '/reports/report/locations/overall/',
        method: 'GET',
        params: {
          months,
          ...(office ? { office } : {}),
          ...(manager ? { manager } : {}),
          ...(project ? { project } : {}),
          ...(status ? { status } : {}),
        },
      }),
    }),

    getExportLocationFile: builder.mutation<Blob, IFilterReportParams>({
      query: ({ office, months, manager, project, status }) => {
        return {
          url: `/reports/locations/export/`,
          params: {
            months,
            ...(office ? { office } : {}),
            ...(manager ? { manager } : {}),
            ...(project ? { project } : {}),
            ...(status ? { status } : {}),
          },
          responseHandler: async (response) => {
            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'location_report';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          },
          cache: 'no-cache',
        };
      },
    }),

    getAttritionReportTypes: builder.query<AttritionReportTypes, void>({
      query: () => ({
        url: '/dictionary/attrition_report_types/',
        method: 'GET',
      }),
      transformResponse: (response: AttritionReportTypesResponse) => {
        return response.reduce((acc, result) => {
          const res = { ...acc, ...result };
          return res;
        }, {}) as AttritionReportTypes;
      },
      providesTags: ['AttritionReportTypes'],
    }),
    getAttritionReport: builder.mutation<
      ILocationReportResponse[],
      IFilterReportParams
    >({
      query: ({
        office,
        months,
        manager,
        project,
        status,
        chartType = 'dismissed_reason',
      }) => ({
        url: `/reports/attritions/overall/${chartType}`,
        method: 'GET',
        params: {
          months,
          ...(office ? { office } : {}),
          ...(manager ? { manager } : {}),
          ...(project ? { project } : {}),
          ...(status ? { status } : {}),
        },
      }),
    }),

    getManagerChangeRequests: builder.query<ListResponse<IManagerChange>, void>(
      {
        query: () => ({
          url: '/general/new-manager-requests/',
          method: 'GET',
        }),
        transformResponse: (
          response: ListResponse<IManagerChangeResponse>,
          meta
        ) => {
          return {
            ...response,
            results: response.results.map((result) => ({
              employeeId: result.employee_id,
              employeeName: result.employee_name,
              managerId: result.manager_id,
              managerName: result.manager_name,
              newManagerId: result.new_manager_id,
              newManagerName: result.new_manager_name,
              status: result.status,
              avatarUrl: result.avatar_url,
            })),
            // @ts-ignore
            zone: meta?.response.headers.get('x-zone-header') || '',
          };
        },
        providesTags: ['ManagerChangeRequest'],
      }
    ),
    // todo: replace with editEmployeeDetails
    editUser: builder.mutation<IEmployee, { id: number; data: IUserEditData }>({
      query: ({ id, data }) => ({
        url: `/employees/${id}/details/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, meta, arg) => [
        { type: 'Employee', id: arg.id },
        { type: 'EmployeeDetails', id: arg.id },
        'ManagerChangeRequest',
        'Bench',
      ],
    }),
    getBench: builder.query<
      {
        count: number;
        next?: string;
        previous?: string;
        results: IBenchData[];
        zone: ZoneType;
      },
      IBenchRequest
    >({
      query: (params) => {
        const paramsString = convertToFilterStr(params);

        return {
          url: '/employees/bench/' + paramsString,
          params: {
            limit: params.limit,
            offset: params.offset,
            ordering: params.ordering,
            // search: params.search,
          },
          cache: 'no-cache',
        };
      },
      transformResponse: (response: ListResponse<IBenchResponse>, meta) => {
        return {
          count: response.count,
          next: response.next,
          previous: response.previous,
          results: response.results.map((result) => ({
            id: result.id,
            name: result.name,
            location: result.employee_physical_location,
            startDateOnBench: result.bench_start_date,
            seniority: result.employee_seniority?.name,
            position: result.employee_position?.name,
            yearStartInProfession: result.year_start_in_profession,
            startDateInCompany: result.start_date_in_company,
            mainTech: result.main_technologies,
            stack: result.skill_stack,
            workedPreviouslyOn: result.historical_projects,
            currentUtilization: result.active_projects,
            tags: result.bench_tags,
            avatarUrl: result.avatar_url,
            comment: result.bench_comment,
          })),
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
        };
      },
      providesTags: ['Bench'],
    }),
    getBenchSummary: builder.query<IBenchSummary, { not_bench_tags?: number }>({
      query: (params) => ({
        url: '/general/bench-summary/',
        params,
      }),
    }),
    getBenchFilters: builder.query<IBenchFilters, void>({
      query: () => '/employees/bench-filter-categories/',
      transformResponse: (response: IBenchFilters) => ({
        ...response,
        main_tech_skills: getSkillWithCategoryOptions(
          response?.main_tech_skills
        ),
      }),
    }),
    getSkills: builder.query<Skill[], void>({
      query: () => '/skills/all/',
      transformResponse: (response: Skill[]) => {
        return getSkillWithCategoryOptions(response);
      },
    }),
    // todo: this should be a query, not a mutation
    getExportBenchFile: builder.mutation<Blob, IBenchRequest>({
      query: (params) => {
        const paramsString = convertToFilterStr(params);
        return {
          url: `/general/bench-export/${paramsString ? paramsString : ''}`,
          responseHandler: async (response) => {
            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'bench_report';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          },
          cache: 'no-cache',
        };
      },
    }),

    // CDP (Employee Profile)
    addEmployeeCDP: builder.mutation<ICDPResponse, { data: ICDPRequest }>({
      query: ({ data }) => ({
        url: `/employees/${data?.employee_id}/cdps/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CDP', 'EmployeeCDPList', 'CDPList'],
    }),
    editEmployeeCDP: builder.mutation<
      ICDPResponse,
      { data: ICDPRequest; cdpId: number }
    >({
      query: ({ data, cdpId }) => ({
        url: `/cdps/${cdpId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['CDP', 'EmployeeCDPList', 'CDPList'],
    }),
    getCDP: builder.query<ICDPResponse, { cdpId: number }>({
      query: ({ cdpId }) => `/cdps/${cdpId}/`,
      providesTags: ['CDP'],
    }),
    uploadEmployeeCDPFile: builder.mutation<
      ICDPFileResponse,
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: `/cdps/cdpfiles/`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteEmployeeCDPFile: builder.mutation<
      ICDPFileResponse,
      { fileId: number }
    >({
      query: ({ fileId }) => ({
        url: `/cdps/cdpfiles/${fileId}/`,
        method: 'DELETE',
      }),
    }),
    getCDPStatuses: builder.query<[{ [key: string]: string }], void>({
      query: () => '/dictionary/cdp_statuses/',
      providesTags: ['CDPStatus'],
    }),
    changeCDPStatus: builder.mutation<
      null,
      { cdpId: number; status: 'finish' | 'reject' }
    >({
      query: ({ cdpId, status }) => ({
        url: `/cdps/${cdpId}/`,
        method: 'PATCH',
        params: {
          [status]: true,
        },
      }),
      invalidatesTags: ['CDP', 'EmployeeCDPList', 'CDPList'],
    }),
    getEmployeeCDPList: builder.query<
      ListResponseWithScopeAndZone<IEmployeeCDPListResponse>,
      { employeeId: number }
    >({
      query: ({ employeeId }) => ({
        url: `/employees/${employeeId}/cdps/`,
      }),
      transformResponse: (
        response: ListResponse<IEmployeeCDPListResponse>,
        meta
      ) => {
        return {
          ...response,
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
          // @ts-ignore
          scope: meta?.response.headers.get('x-scope-header') || '',
        };
      },
      providesTags: ['EmployeeCDPList'],
    }),

    // CDP Goal
    addGoal: builder.mutation<
      IGoalResponse,
      { cdpId: number; data: IGoalPostPayload }
    >({
      query: ({ cdpId, data }) => ({
        url: `/cdps/${cdpId}/cdp_goals/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CDP', 'EmployeeCDPList', 'CDPList'],
    }),
    getGoal: builder.query<IGoalResponse, { cdpId: number; goalId: number }>({
      query: ({ cdpId, goalId }) => ({
        url: `/cdps/${cdpId}/cdp_goals/${goalId}/`,
      }),
      providesTags: (result) => [{ type: 'Goal', id: result?.id }],
    }),
    editGoal: builder.mutation<
      IGoalResponse,
      { cdpId: number; goalId: number; data: IGoalPatchPayload }
    >({
      query: ({ cdpId, goalId, data }) => ({
        url: `/cdps/${cdpId}/cdp_goals/${goalId}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result) => [
        'CDP',
        'EmployeeCDPList',
        'CDPList',
        { type: 'Goal', id: result?.id },
      ],
    }),
    deleteGoal: builder.mutation<any, { cdpId: number; goalId: number }>({
      query: ({ cdpId, goalId }) => ({
        url: `/cdps/${cdpId}/cdp_goals/${goalId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CDP', 'EmployeeCDPList', 'CDPList'],
    }),

    // CDP (Left Menu)
    getCDPList: builder.query<
      ListResponseWithScopeAndZone<IEmployeeCDPListResponse>,
      IPaginationParams
    >({
      query: (params) => ({
        url: `/cdps/`,
        params,
      }),
      transformResponse: (
        response: ListResponse<IEmployeeCDPListResponse>,
        meta
      ) => {
        return {
          ...response,
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
          // @ts-ignore
          scope: meta?.response.headers.get('x-scope-header') || '',
        };
      },
      providesTags: ['CDPList'],
    }),
    getEmployeeDetails: builder.query<IEmployeeDetails, number>({
      query: (employeeId) => `/employees/${employeeId}/details/`,
      transformResponse: (response: IEmployeeDetails, meta) => {
        return {
          ...response,
          // @ts-ignore
          zone: meta?.response.headers.get('x-zone-header') || '',
          // @ts-ignore
          scope: meta?.response.headers.get('x-scope-header') || '',
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'EmployeeDetails', id: arg },
      ],
    }),
    getEnglishLevels: builder.query<IEnglishLevel[], void>({
      query: () => '/dictionary/language_levels/',
      providesTags: ['EnglishLevel'],
      keepUnusedDataFor: fourHours,
      transformResponse: (response: ListResponse<IEnglishLevel>) => {
        return response.results;
      },
    }),
    editEmployeeDetails: builder.mutation<
      IEmployeeDetails,
      { employeeId: number; data: IEmployeePatchRequest }
    >({
      query: ({ employeeId, data }) => ({
        url: `/employees/${employeeId}/details/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Employee', id: arg.employeeId },
        { type: 'EmployeeDetails', id: arg.employeeId },
      ],
    }),
    getEmployeeCertificates: builder.query<ICertificateResponse[], number>({
      query: (employeeId) => ({
        url: `/employees/${employeeId}/certificates/`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'EmployeeCertificate', id: arg },
      ],
    }),
    addNewCertificate: builder.mutation<
      ICertificateRequest,
      { employeeId: number; certificate: ICertificateRequest }
    >({
      query: ({ employeeId, certificate }) => ({
        url: `/employees/${employeeId}/certificates/`,
        method: 'POST',
        body: certificate,
      }),
      invalidatesTags: ['EmployeeCertificate'],
    }),
    editCertificate: builder.mutation<
      string,
      { employeeId: number; certificateId: number; certificate_name: string }
    >({
      query: ({ employeeId, certificateId, certificate_name }) => ({
        url: `/employees/${employeeId}/certificates/${certificateId}/`,
        method: 'PATCH',
        body: { certificate_name },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'EmployeeCertificate', id: arg.employeeId },
      ],
    }),
    deleteCertificate: builder.mutation<
      string,
      { employeeId: number; certificateId: number }
    >({
      query: ({ employeeId, certificateId }) => ({
        url: `/employees/${employeeId}/certificates/${certificateId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'EmployeeCertificate', id: arg.employeeId },
      ],
    }),
    getCountries: builder.query<Item2[], void>({
      query: () => '/dictionary/countries/',
      providesTags: ['Country'],
      keepUnusedDataFor: fourHours,
      transformResponse: (response: ICountryResponse[]) => {
        return response.map((item) => ({
          id: item.country,
          name: item.country,
        }));
      },
    }),
    getVersion: builder.query<string, void>({
      query: () => '/general/version/',
      providesTags: ['Version'],
      transformResponse: (response: VersionResponse) => {
        return response?.version;
      },
    }),
    getDismissReasons: builder.query<{ value: number; label: string }[], void>({
      query: () => '/dictionary/dismissedreasons/',
      providesTags: ['DismissReason'],
      keepUnusedDataFor: fourHours,
      transformResponse: (
        response: ListResponse<{ id: number; value: string }>
      ) => {
        return response.results.map((item) => ({
          label: item.value,
          value: item.id,
        }));
      },
    }),
    getCDPReport: builder.mutation<ICDPReportResponse[], IFilterReportParams>({
      query: ({ months }) => ({
        url: `/reports/cdps/overall/`,
        method: 'GET',
        params: {
          months,
        },
      }),
    }),
  }),
});

export const {
  useGetFeedbacksQuery,
  useGetFeedbackQuery,
  useAddNewFeedbackMutation,
  useEditFeedbackMutation,
  useGetCvsQuery,
  useDeleteEmployeeCVMutation,
  useAddEmployeeCVMutation,
  useEditEmployeeCVMutation,
  useGetEmployeeQuery,
  useGetFeedbackFiltersQuery,
  useGetSkillCategoriesQuery,
  useGetUserSkillsQuery,
  useGetSkillLevelsQuery,
  useUpdateSkillsMutation,
  useGetTemplatesQuery,
  useGetEmployeesMinimalQuery,
  useGetMentorsMinimalQuery,
  useGetProjectsQuery,
  useGetEmployeeFiltersQuery,
  useGetEmployeeOrgInfoQuery,
  useEditEmployeeOrgInfoMutation,
  useGetProbationStatusesQuery,
  useGetBenchTagsQuery,
  useGetAllFeedbacksQuery,
  useCreateFeedbackMutation,
  useGetEmployeesMinimalAllQuery,
  useGetEmployeesMinimalDCQuery,
  useAddTaskMutation,
  useEditTaskStatusMutation,
  useGetCVFileQuery,
  useActivateEmployeeMutation,
  useGetAllHRManagersQuery,
  useGetRiskReportMutation,
  useGetExportRiskFileMutation,
  useGetLocationReportMutation,
  useGetAttritionReportMutation,
  useGetManagerChangeRequestsQuery,
  useEditUserMutation,
  useGetBenchQuery,
  useGetBenchSummaryQuery,
  useGetBenchFiltersQuery,
  useGetSkillsQuery,
  useGetExportBenchFileMutation,
  useAddEmployeeCDPMutation,
  useEditEmployeeCDPMutation,
  useGetCDPQuery,
  useUploadEmployeeCDPFileMutation,
  useDeleteEmployeeCDPFileMutation,
  useGetCDPStatusesQuery,
  useChangeCDPStatusMutation,
  useGetEmployeeCDPListQuery,
  useAddGoalMutation,
  useEditGoalMutation,
  useDeleteGoalMutation,
  useGetGoalQuery,
  useGetCDPListQuery,
  useGetEmployeeDetailsQuery,
  useGetEnglishLevelsQuery,
  useEditEmployeeDetailsMutation,
  useGetPositionsQuery,
  useGetSenioritiesQuery,
  useGetEmployeeCertificatesQuery,
  useAddNewCertificateMutation,
  useEditCertificateMutation,
  useDeleteCertificateMutation,
  useGetCountriesQuery,
  useGetVersionQuery,
  useGetDismissReasonsQuery,
  useGetAttritionReportTypesQuery,
  useGetLocationHistoryQuery,
  useGetExportLocationFileMutation,
  useGetCDPReportMutation,
} = apiSlice;
