import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Option, SkillRow } from './types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GroupHeader, GroupItems } from './OptionsGroup';
import { Skill } from '../EmployeesPage/api/apiSlice.types';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface SkillRowForm {
  skills: Skill[];
  level?: Option | null;
}

export const BenchSkillFilter = ({
  isSkillsLoading,
  skillOptions,
  isLevelsLoading,
  levelOptions,
  skillRows,
  setSkillRows,
  isIncludeUnconfirmed,
  setIsIncludeUnconfirmed,
}: {
  isSkillsLoading: boolean;
  skillOptions: Skill[];
  isLevelsLoading: boolean;
  levelOptions: Option[];
  skillRows: SkillRow[];
  setSkillRows: React.Dispatch<React.SetStateAction<SkillRow[]>>;
  isIncludeUnconfirmed: boolean;
  setIsIncludeUnconfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const initialSkillRowStateState = [{ skills: [] }];

  const [skillRowsState, setSkillRowsState] = useState<SkillRowForm[]>(
    initialSkillRowStateState
  );

  const [isIncludeUnconfirmedState, setIsIncludeUnconfirmedState] =
    useState(false);

  useEffect(() => {
    if (skillRows.length) {
      setSkillRowsState(skillRows);
    } else {
      setSkillRowsState(initialSkillRowStateState);
    }
    setIsIncludeUnconfirmedState(isIncludeUnconfirmed);
  }, [skillRows, isIncludeUnconfirmed]);

  const addSkillRow = () => {
    const MAX_ROWS = 5;
    if (skillRowsState.length >= MAX_ROWS) return;
    const newSkillRow = { skills: [], levels: [] };
    setSkillRowsState((prevState) => [...prevState, newSkillRow]);
  };

  const removeSkillRow = (index: number) => {
    if (skillRowsState.length === 1) {
      setSkillRowsState(initialSkillRowStateState);
      return;
    }
    setSkillRowsState((prevState) => prevState.filter((_, i) => i !== index));
  };

  const editSkillRowSkills = (index: number, newSkills: Skill[]) => {
    setSkillRowsState((prevState) => {
      const newState: SkillRowForm[] = prevState.map((skillRowState) => ({
        skills: skillRowState.skills,
        level: skillRowState.level,
      }));
      newState[index].skills = newSkills;
      return newState;
    });
  };

  const editSkillRowLevels = (index: number, newLevel: Option | null) => {
    setSkillRowsState((prevState) => {
      const newState: SkillRowForm[] = prevState.map((skillRow) => ({
        skills: skillRow.skills,
        level: skillRow.level,
      }));
      newState[index].level = newLevel;
      return newState;
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSkillRows(skillRowsState);
    setIsIncludeUnconfirmed(isIncludeUnconfirmedState);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          <Box sx={{ fontSize: 24, display: 'flex', alignItems: 'center' }}>
            <ArrowDropDownIcon />
          </Box>
        }
        variant={'outlined'}
        color={'inherit'}
        sx={{
          textTransform: 'none',
          height: 40,
          color: 'rgba(0, 0, 0, 0.6)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        Skills
      </Button>
      <Popover
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box minWidth={560} p={2}>
          {skillRowsState.map((skillRow, index) => (
            <Stack direction='row' spacing={2} mb={2} key={index}>
              <Autocomplete
                size={'small'}
                multiple
                disableCloseOnSelect
                loading={isSkillsLoading}
                options={skillOptions}
                getOptionLabel={(option) => option.name}
                style={{ width: 240 }}
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label='Skills' />
                )}
                value={skillRow.skills}
                onChange={(event: any, newValue: Skill[]) => {
                  editSkillRowSkills(index, newValue);
                }}
                groupBy={(option) => option.category.name}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />

              <Autocomplete
                size={'small'}
                loading={isLevelsLoading}
                options={levelOptions}
                getOptionLabel={(option) => option.name}
                style={{ width: 240 }}
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label='Level' />
                )}
                value={skillRow?.level || null}
                onChange={(event: any, newValue) => {
                  editSkillRowLevels(index, newValue);
                }}
              />

              <IconButton
                onClick={() => {
                  removeSkillRow(index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}

          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isIncludeUnconfirmedState}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsIncludeUnconfirmedState(event.target.checked);
                  }}
                />
              }
              label='Include unconfirmed'
            />

            <Button
              onClick={() => {
                addSkillRow();
              }}
            >
              + Add Filter
            </Button>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
};
