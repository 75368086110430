import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeeDetailsQuery } from '../../api/apiSlice';
import { getCanViewSensitiveInfo } from '../../../../utils/permissions/sensitiveInfo';

export const ResponsibilityAreas = () => {
  const { id } = useParams();
  const { data: employee } = useGetEmployeeDetailsQuery(Number(id));

  const attributes = [
    { name: 'Team lead', checked: !!employee?.is_team_lead },
    { name: 'Mentor', checked: !!employee?.is_mentor },
  ];

  if (getCanViewSensitiveInfo(employee?.scope)) {
    attributes.push(
      { name: 'Key Resource', checked: !!employee?.is_key_resource },
      { name: 'HR', checked: !!employee?.is_hr },
      { name: 'Non-Production', checked: !!employee?.is_nonprod },
      { name: 'Supplementary', checked: !!employee?.is_supplementary }
    );
  }

  return (
    <div>
      <Box mb={2}>
        <span className='profile_block__title'>Employee attributes</span>
      </Box>
      <Stack direction='row' spacing={2}>
        {attributes.map((item) => (
          <AttributeCheckbox key={item.name} {...item} />
        ))}
      </Stack>
    </div>
  );
};

const AttributeCheckbox = ({
  name,
  checked,
}: {
  name: string;
  checked: boolean;
}) => (
  <FormControlLabel
    // disabled
    checked={checked}
    onChange={() => {}}
    control={
      <Checkbox
        sx={{
          cursor: 'default',
        }}
      />
    }
    label={name}
    sx={{ cursor: 'default', '& .MuiTypography-root': { fontSize: 14 } }}
  />
);
